<template>
  <div>
    <v-card style="margin-bottom: 40px" class="pt-1 mt-4">
      <v-toolbar flat dense>
        <v-toolbar-title class="text-subtitle-1">SOLICITUDES</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="puedeCrearSolicitud"
          small
          color="primary"
          @click="showCreacion = true"
        >
          Crear
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table
        :headers="columns"
        :items="solicitudes"
        item-key="name"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
        no-data-text="Sin solicitudes"
        dense
      >
        <template v-slot:item.accion="{ item }">
          <v-icon small class="mr-2" @click="goToSolicitud(item.id)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <solicitudDialogForm
      v-if="showCreacion"
      :show="showCreacion"
      :emision="emision"
      route='/v1/solicitud/manual'
      @close="showCreacion = false"
    ></solicitudDialogForm>
  </div>
</template>

<script>
import solicitudDialogForm from "../../../../components/solicitudes/solicitudDialogForm.vue";
import { getSolicitudesByIdEmision } from "@/services/solicitudService";

export default {
  name: "Solicitudes",

  components: {
    solicitudDialogForm,
  },

  props: {
    emisionId: {
      type: Number,
      required: true,
    },
    emision: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      showCreacion: false,
      solicitudes: [],
      columns: [
        { text: "Título", sortable: false, value: "titulo" },
        { text: "Tipo", sortable: false, value: "tipo" },
        { text: "Ver", sortable: false, value: "accion" },
      ],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    puedeCrearSolicitud() {
      const rolesCreacion = ["OPERADOR", "OPERADORASIST", "ADMIN"];
      return rolesCreacion.some((rc) => rc == this.rol);
    },
  },

  mounted() {
    this.loadSolicitudes();
  },

  methods: {
    async loadSolicitudes() {
      this.loading = true;
      const response = await getSolicitudesByIdEmision(this.emisionId);
      this.solicitudes = response.solicitudes;
      this.loading = false;
    },

    goToSolicitud(id) {
      this.$router.push(`/solicitud/${id}`);
    },
  },
};
</script>
