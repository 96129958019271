<template>
    <v-row style="min-height: 100vh">
      <v-col
        md="12"
        sm="12"
        style="
          background: rgba(19,59,125,1);
          background: -moz-linear-gradient(-45deg, rgba(19,59,125,1) 0%, rgba(19,59,125,1) 50%, rgba(203,20,38,1) 50%, rgba(203,20,38,1) 100%);
          background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(19,59,125,1)), color-stop(50%, rgba(19,59,125,1)), color-stop(50%, rgba(203,20,38,1)), color-stop(100%, rgba(203,20,38,1)));
          background: -webkit-linear-gradient(-45deg, rgba(19,59,125,1) 0%, rgba(19,59,125,1) 50%, rgba(203,20,38,1) 50%, rgba(203,20,38,1) 100%);
          background: -o-linear-gradient(-45deg, rgba(19,59,125,1) 0%, rgba(19,59,125,1) 50%, rgba(203,20,38,1) 50%, rgba(203,20,38,1) 100%);
          background: -ms-linear-gradient(-45deg, rgba(19,59,125,1) 0%, rgba(19,59,125,1) 50%, rgba(203,20,38,1) 50%, rgba(203,20,38,1) 100%);
          background: linear-gradient(135deg, rgba(19,59,125,1) 0%, rgba(19,59,125,1) 50%, rgba(203,20,38,1) 50%, rgba(203,20,38,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#133b7d', endColorstr='#cb1426', GradientType=1 );
        "
      >
        <div>
          <div class="logo-container">
            <img src="../../assets/SEGURIFY_logo_BCO.png" class="segurify-logo" />
          </div>
          <div style="min-width: 60%">
          <div style="margin-bottom: 60px">
            <h1 style="text-align: center; color:white; font-size: 3em;">Bienvenido al portal Segurify - Bradescard</h1>
          </div>

          <div class="login-box">
            <v-card
              :loading="cargando"
              elevation="4"
              outlined
              shaped
              style="width: 100%"
            >
              <div style="padding: 30px">
                <v-form ref="form"  @submit.prevent="validate">
                  <h4>Correo:</h4>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <h4>Contraseña:</h4>
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  <div style="text-align: end !important;">
                    <v-btn
                      color="success"
                      class="mr-4"
                      type="submit"
                      style="margin-top: 20px;"
                    >
                      Iniciar Sesión
                    </v-btn>
                  </div>
                  <v-alert v-if="error" class="mt-5" type="error">
                    La contraseña o el correo son incorrectos.
                  </v-alert>
                </v-form>
              </div>
            </v-card>
          </div>
        </div>
        </div>
        <div class="bradescard-container" style=" text-align: end;
            float: none;
            position: absolute;
            right: 0;
            width:23%;
            bottom: 0;
            padding-bottom: 20px;
            padding-right: 1%;
        ">
          <img src="../../assets/BRADESCARD_logo_BCO.png" class="bradescard-logo"/>
        </div>
      </v-col>
    </v-row>
</template>

<script>
import { axiosLogin } from '../../axiosLogin';
export default {
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },
    login() {
      var qs = require('qs');
      var vm = this;
      vm.error = false;
      vm.cargando = true;
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: 'admin',
          password: 'g6PqgxyB0Z',
        },
      };
      var data = qs.stringify({
        grant_type: 'password',
        username: this.email,
        password: this.password,
      });
      axiosLogin
        .post('/oauth/token', data, config)
        .then((value) => {
          localStorage.agenteAccessToken = value.data.access_token;
          const configA = {
            headers: {
              Authorization: 'Bearer ' + localStorage.agenteAccessToken,
            },
          };
          this.$axios.get('/v1/agente/roles', configA).then((value) => {
            const roles = value.data;
            localStorage.setItem('roles', JSON.stringify(roles));
            axiosLogin.get('/user', configA).then((value) => {
              console.log("Value: ", value);
              localStorage.agenteName=
                value.data.principal.nombre +' '+value.data.principal.apellidoPaterno;
              localStorage.agenteUuid = value.data.principal.uuid;
              localStorage.agenteMail = this.email;
              if(Object.keys(this.$route.query).length === 0) {
                if(this.role == "admin" || this.role == "agente"){
                  vm.$router.push({ path: '/bradescard' });
                }else{
                  vm.$router.push({ path: '/inicio' });
                }
              } else {
                vm.$router.push({ path: `${this.$route.query.from}` });
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
          vm.error = true;
          vm.cargando = false;
          this.error = true;
        });
    },
    getRole(rolesUser){
        var roles=[]
        rolesUser.forEach((element) => {
            roles.push(element.rol)
        });
        const rolesAdmin = ["ADMIN", "ADMINCC", 'MANAGERCC', 'DIRECTOR', 'FINANZAS'];
        const rolesAgents = ["AGENTCC"];
        roles.forEach((element)=>{
            if(rolesAdmin.includes(element)){
                this.role = 'admin';
            }
            if(rolesAgents.includes(element)){
                this.role = 'agente';
            }
        });
    }
  },
  mounted() {
    console.log(process.env.VUE_APP_API_URL);
  },
  data() {
    return {
      role:'agente',
      cargando: false,
      email: '',
      emailRules: [(v) => !!v || 'El correo es requerido'],
      show1: false,
      rules: {
        required: (value) => !!value || 'Required.',
        emailMatch: () => `The email and password you entered don't match`,
      },
      password: '',
      passwordRules: [(v) => !!v || 'El password es requerido'],
      error: null,
    };
  },
};
</script>

<style scoped>

@media (max-width: 992px) {
  .segurify-logo {
      width: 23% !important;
      padding-bottom: 5%;
  }
  .logo-container{
    text-align: center !important;
  }
  .login-box{
    padding-left: 0% !important; 
    padding-right: 0% !important;
  }
  .bradescard-logo{
    width: 50% !important;
  }
  .bradescard-container{
    text-align: center !important;
  }
}
.logo-container{
  text-align: inherit;
}
.segurify-logo {
    width: 10%;
}
.login-box{
  padding-left: 38%; 
  padding-right: 38%;
}
.bradescard-logo{
  width: 100%;
}
.bradescard-container{
  text-align: end;
}

.v-application .success {
    background-color: #133b7d !important;
    border-color: #133b7d !important;
}

</style>
