<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte de Cobranza Hogar AIG</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker
            style="width: 100%"
            v-model="dates"
            range
          ></v-date-picker>
          <v-btn
            rounded
            color="primary"
            style="width: 100%"
            dark
            @click="consultar()"
            >Consultar</v-btn
          >
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
          >
            <download-csv :data="reportDownload" name="Informe_bradescard.csv">
              Descargar Información
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner
                :md-diameter="100"
                :md-stroke="102"
                md-mode="indeterminate"
              ></md-progress-spinner>
            </div>
          </div>

          <v-data-table
            :headers="columns"
            :items="polizas"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
              ></v-text-field>
            </template>

            <template v-slot:item.uuid="{ item }">
              <v-btn
                @click="
                  $router.push('/administrador/editar-emision/' + item.uuid)
                "
              >
                Ver
              </v-btn>
            </template>

            <template v-slot:item.urlPoliza="{ item }">
              <v-btn
                :href="
                  'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza
                "
                >Descargar</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      polizas: [],
      search: "",
      reportDownload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: "AGENTES" },
        { id: 2, name: "MASIVOS" },
        { id: 3, name: "DIRECTOS" },
      ],

      columns: [
        { text: "Numero de certificado", value: "certificadoBrades" },
        { text: "Estatus de la Póliza", value: "status" },
        { text: "Inicio de Vigencia", value: "inicioVigencia" },
        { text: "Fin de Vigencia", value: "finVigencia" },
        { text: "Primer pago", value: "primerPago" },
        { text: "Valor a pagar", value: "valorAPagar" },
        { text: "Cliente", value: "cliente" },
        { text: "RFC del asegurado", value: "rfcAsegurado" },
      ],

      statusEmisiones: {
        0: "Nueva",
        1: "Emitida Pendiente de Pago",
        2: "Pagada Parcial",
        3: "Pagada Total",
        4: "Renovada",
        5: "Finalizada",
        6: "Cancelada",
        8: "Emitido el certificado",
      },

      perioricidadValues: {
        "1": "Anual",
        "2": "Semestral",
        "3": "Trimestral",
        "4": "Mensual",
      },
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      this.$axios
        .post(`/v1/cuota/cobranza-hogar`, fechas, config)
        .then((value) => {
          this.reportDownload = [];
          this.polizas = [];
          console.log(value);
          value.data.map((element) => {
            this.polizas.push({
              certificadoBrades: element.certificadoBrades
                ? element.certificadoBrades
                : "No disponible",
              status: element.status,
              // cliente: moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
              cliente: element.cliente
                ? element.cliente
                : element.nombreCliente
                ? element.nombreCliente
                : `${element.nombre || "No disponible"}` +
                  " " +
                  `${element.apellido_Paterno || ""}` +
                  " " +
                  `${element.apellido_Materno || ""}`,
              agente: element.nombreAgente,
              nombreProducto: element.nombreProducto,
              aseguradora: element.nombreAseguradora,
              nuevaRenovacion: element.nuevaRenovacion,
              inicioVigencia: element.inicio
                ? moment.utc(element.inicio).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              finVigencia: element.fin
                ? moment.utc(element.fin).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              periodicidadPago: element.periodicidad,
              montoPoliza: "$" + element.montoPoliza,
              ejecutivo: element.nombreEjecutivo,
              primerPago: element.primerPago,
              valorAPagar: element.valorAPagar,
              rfcAsegurado: element.rfc,
            });

            this.reportDownload.push({
              "No. Póliza": element.poliza, //<---- Falta
              Producto: element.nombreProducto || "No disponible",
              "Inicio de Vigencia": element.inicio,
              "Fin de Vigencia": element.fin,
              Periodicidad:
                this.perioricidadValues[element.periodicidad] ||
                "No disponible",
              "Primer Pago": element.primerPago || "No disponible",
              "Pago Subsecuente": element.pagoSubsecuente || "No disponible",
              "No. de Cuota": element.orden || "No disponible",
              "Status de Couta": element.status || "No disponible",
              "Fecha de Pago Acordado":
                element.fechaPagoAcordada || "No disponible",
              "Fecha de Pago Aplicado": element.fechaPago || "No disponible",
              "Monto de Pago Aplicado": element.monto_pago || "No disponible",
              Cliente: element.cliente
                ? element.cliente
                : element.nombreCliente
                ? element.nombreCliente
                : `${element.nombre || "No disponible"}` +
                  " " +
                  `${element.apellido_Paterno || ""}` +
                  " " +
                  `${element.apellido_Materno || ""}`,
              "RFC Cliente": element.rfc,
              "Fecha Venta": element.fechaVenta,
              "Tipo poliza": element.tipo_poliza,
              "Status Póliza": this.statusEmisiones[element.estatusPoliza],
              "Certificado Bradescard": element.certificadoBrades,
              Archivo: element.archivo || "No disponible",
              "Fecha Archivo": element.fecha_archivo || "No disponible",
              "Fecha Movimiento": element.fecha_movimiento || "No disponible",
            });
          });
          this.block = false;
          this.bloquear = false;
        });
    },
    newEmision() {
      this.$router.push("/administrador/carga-emisiones");
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
    canShow() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "DIRECTOR", "FINANZAS"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
  },
};
</script>
