<template>
    <v-card>
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">NOTAS Y DOCUMENTOS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark color="primary" small @click="openForm">
                Crear
            </v-btn>
            
        </v-toolbar>

        <v-divider></v-divider>

        <v-data-table :headers="columns" :items="items" item-key="name" class="elevation-1" loading-text="Cargando..."
            dense :items-per-page="5">
            <template v-slot:[`item.url`]="{ item }">
                <v-icon small class="mr-2" @click="openForm(item)">
                    mdi-eye
                </v-icon>
            </template>
        </v-data-table>

        <DialogDocument v-if="dialog" :opcion="opcion" :identificador="identificador" :active.sync="dialog" :form="form" />
    </v-card>
</template>

<script>
import DialogDocument from "./documentos/DocumentDialog.vue";
import moment from 'moment';
import { mainAxios } from "@/mainAxios";

export default {
    name: 'leads.detalles.Documentos',
    props: {
        opcion: {
            type: String,
            required: true
        },
        identificador: {
            type: Number,
            required: true
        },
        list: {
            type: Array,
            default: () => [],
        }
    },
    components: {
        DialogDocument
    },
    data: function () {
        return {
            dialog: false,
            columns: [
                { text: "Nombre", align: "start", sortable: false, value: "nombre" },
                { text: "Tipo", value: "tipo" },
                { text: "Descripción", value: "descripcion" },
                { text: "Creado por", value: "creadoPor" },
                { text: "Fecha", value: "fecha" },
                { text: "Ver", value: "url" },
            ],
            items: [],
            form: null
        }
    }, mounted() {
         this.getDocumentos()
    }, methods: {
    async getDocumentos() {
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
        };
        mainAxios.get('v1/lead/documento/list/'+this.identificador, config).then(response => {
            response.data.map(element => {
                 this.items.push({
                     nombre: element.nombre ? element.nombre : 'No Disponible',
                     fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
                     tipo: element.tipo ? element.tipo : 'No Disponible',
                     tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
                     descripcion: element.nota ? element.nota : 'No Disponible',
                     creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
                     url: element.url
                 });
             });
        });

        },
        openForm(row = null){
            this.form = null;
            if(row != null){
                this.form = row;
            }
            this.dialog = true;
        }
    }
}
</script>