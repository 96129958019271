<template>
    <v-card class="pt-1">
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">Pagos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="open" v-if="false">
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table :headers="columns" :items="pagos" item-key="name" class="elevation-1" :loading="loading"
            loading-text="Cargando..." dense>
            <template v-slot:item.status="{ item }">
                {{ item.status == 0 ? 'No aplica' : 'Aplicado' }}
                <!-- <v-icon small class="mr-2" @click="verDocumento(item)">
                    mdi-eye
                </v-icon> -->
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: {
        cuota: {
            type: Number,
            require: true,
        }, monto:{
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            totalMonto: 0,
            pagos: [],
            columns: [
                { text: "Cuotas", align: "start", sortable: false, value: "id" },
                { text: "Estado", sortable: false, value: "status" },
                { text: "Monto", sortable: false, value: "monto" },
                { text: "Fecha Aplicación", sortable: false, value: "fecha_aplicacion" },
                { text: "Creada por", sortable: false, value: "creado_por" },
                // { text: "Ver", sortable: false, value: "accion" }
            ],
        }
    }, mounted() {
        this.getPagosCuotas();
    }, watch:{
        totalMonto: function(val){
            this.$emit('update:monto', val);
        }
    }, methods: {
        async getPagosCuotas() {
            try {
                let { data } = await this.$axios.get(`v1/pagoCuota/list/${this.cuota}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                });
                let monto = 0;
                this.pagos = data;
                this.pagos.forEach(item => {
                    monto = item.monto;
                });
                this.totalMonto = monto;
            } catch (exception) {
                console.log(exception);
            }
        },
        verDocumento(item) {
            console.log(item);
        }
    }
}
</script>