<template>
  <div class="mb-10">
    <!-- HEAD TITLE -->
    <v-row style="margin-top: 10px">
      <v-col cols="10">
        <h2>Cuotas</h2>
      </v-col>

      <v-col cols="1">
        <v-btn @click="goBack()" elevation="2" style="margin-left: 55px">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper v-model="stepper" non-linear>
                <v-stepper-header style="justify-content: left">
                  <v-stepper-step step="1" editable>
                    Cuota de la Póliza
                  </v-stepper-step>

                  <v-stepper-step step="2" editable>
                    Desglose de Comisiones
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">
                    <v-row style="padding: 20px">
                      <v-col @click="$router.push(`/actualizar-emision/${poliza}`)" cols="6">
                        <v-text-field label="Pólizas" v-model="poliza" class="enlacesForms"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Orden" v-model="orden"></v-text-field>
                      </v-col>
                      <!-- End 1nd Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-select label="Estado de la Cuota" :items="estadoCuotaItems" v-model="estadoCuotaSelected"
                          readonly></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Valor a Pagar" v-model="valorPagar" prefix="$"></v-text-field>
                      </v-col>
                      <!-- End 2nd Col -->
                    </v-row>

                    <!-- <v-row style="padding: 20px; margin-top: -70px"> -->
                    <v-col style="padding: 20px; margin-top: -60px; margin-bottom: 15px" cols="6"
                      v-if="mostrarCheckRehabilitarCuota()">
                      <v-checkbox label="Rehabilitar Cuota" v-model="checkRehabilitarCuota"></v-checkbox>
                    </v-col>
                    <v-col style="padding: 20px; margin-top: -60px; margin-bottom: 15px" cols="6"
                      v-if="checkRehabilitarCuota">
                      <v-text-field label="Motivo de rehabilitar cuota" v-model="motivoRehabilitarCuota"></v-text-field>
                    </v-col>
                    <!-- End 2nd Col -->
                    <!-- </v-row> -->

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field :value="computedDateInicioVigenciaFormattedMomentjs" clearable
                          label="Fecha de pago acordada" v-model="fechaPagoAcordada"
                          placeholder="Requerido (dd/mm/yyyy)" v-mask="'##/##/####'" prepend-icon="mdi-calendar"
                          v-bind="attrs" v-on="on" @click:clear="fechaPagoAcordada = null"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field :value="computedDateInicioVigenciaFormattedMomentjs" clearable
                          label="Fecha límite de pago" v-model="fechaLimitePago" placeholder="Requerido (dd/mm/yyyy)"
                          v-mask="'##/##/####'" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"
                          @click:clear="fechaLimitePago = null"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6" v-if="estadoCuotaSelected == 'Pendiente de pago'">
                        <v-checkbox label="Cuota Pagada" v-model="checkCuotaPagada"></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field :value="computedDateInicioVigenciaFormattedMomentjs" clearable
                          label="Fecha pago cuota" v-model="fechaPagoCuota" placeholder="Requerido (dd/mm/yyyy)"
                          v-mask="'##/##/####'" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"
                          :rules="checkCuotaPagada == true ? inputsRequeridos : ''"
                          @click:clear="fechaPagoCuota = null"></v-text-field>
                      </v-col>

                      <!-- End 7th Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-file-input v-model="adjuntoPago" color="primary" counter label="Adjunto de Pago" multiple
                          prepend-icon="mdi-paperclip" :show-size="1000">
                          <template v-slot:selection="{ index, text }">
                            <v-chip v-if="index < 2" color="primary" dark label small>
                              {{ text }}
                            </v-chip>

                            <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                              +{{ adjuntoPago.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Creado por" v-model="creadoPor"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha de Creación" v-model="fechaCreacion" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Modificado por" v-model="modificadoPor"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Última Modificación" v-model="ultimaModificacion"></v-text-field>
                      </v-col>
                    </v-row>
                    <!--  -->
                    <v-btn color="primary" :disabled="comportamientoBotonGuardar()" @click="actualizarCuota()">
                      Guardar
                    </v-btn>
                  </v-stepper-content>

                  <!-- 2ND ITEM (2ND FORM) -->
                  <v-stepper-content step="2">
                    <v-row style="padding: 20px; margin-top: -35px">
                      <v-col cols="6">
                        <v-select label="Estatus de Comisión" :items="statusComisionItems" v-model="statusComision"
                          readonly></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Base Comisión" v-model="baseComision"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-checkbox label="Comisión Recibida" v-model="checkComisionRecibida"></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha de Comisión Recibidas" v-model="fechaComisionRecibida">
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Folio de Movimiento Bancario Comisión" v-model="folioMovimientoBancario">
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Comisión pagada por la aseguradora" v-model="comisionPagadaAseguradora">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto Comisión pagada por la aseguradora"
                          v-model="montoComisionPagadaAseguradora"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Comisión Agente" v-model="comisionAgente"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto de Comisión Agente" v-model="montoComisionAgente"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6" style="aling: right">
                        <v-text-field label="IVA" v-model="iva"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6">
                        <v-text-field label="ISR" v-model="isr"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6">
                        <v-text-field label="Retención IVA" v-model="retencionIva"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6" v-if="statusComision == 'Pendiente de Pago al Agente'">
                        <v-text-field label="Folio de la factura por pagar" v-model="folioFacturaPagar" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <template>
                          <v-file-input v-model="documentosFacturaAgente" color="primary"
                            placeholder="Adjunto de Factura del Agente" prepend-icon="mdi-paperclip">
                            <template v-slot:selection="{ index, text }">
                              <v-chip v-if="index < 2" color="primary" dark label small>
                                {{ text }}
                              </v-chip>
                            </template>
                          </v-file-input>
                        </template>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-checkbox label="Comisión Agente Pagada" v-model="comisionAgentePagada"></v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha pagado de Comisión Agente" v-model="fechaPagoComisionAgente">
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px" v-if="comisionAgentePagada == true">
                      <v-col cols="6">
                        <template>
                          <v-file-input v-model="documentosPagoRealizadoAgente" color="primary"
                            placeholder="Adjunto de pago realizado al Agente" prepend-icon="mdi-paperclip">
                            <template v-slot:selection="{ index, text }">
                              <v-chip v-if="index < 2" color="primary" dark label small>
                                {{ text }}
                              </v-chip>
                            </template>
                          </v-file-input>
                        </template>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% de comisión para Alige" v-model="comisionAlige"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto comisión para Alige" v-model="montoComisionAlige"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Bono" v-model="bono"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto Bono para Alige" v-model="montoBonoAlige"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Folio de Movimiento Bancario Bono" v-model="folioMovimientoBancarioBono">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha Pago de Bono" v-model="fechaPagoBono"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Comisión total" v-model="comisionTotal"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto Comisión Total Alige" v-model="montoComisionTotalAlige">
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-btn color="primary" :disabled="!comportamientoBotonGuardar()" @click="actualizarCuota()">
                      Guardar
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <!-- ALERT MODAL -->
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
          <v-card-text>
            <div class="text-h4" style="margin-top: 10px; ">
              Actualización de comisiones exitoso!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text
              @click="dialog.value = false, $router.push(`/visualizacion-comisiones/${this.$route.params.uuid}`)">Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- END OF ALERT MODAL -->
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      dialog: false,
      stepper: 1,
      poliza: null,
      cuotaId: null,
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      orden: null,
      estadoCuotaItems: [
        'Pendiente de pago',
        'Vencida',
        'Pagada',
        'Cancelada'
      ],
      estadoCuotaSelected: null,
      valorPagar: null,
      checkRehabilitarCuota: false,
      motivoRehabilitarCuota: null,
      fechaPagoAcordada: null,
      fechaLimitePago: null,
      checkCuotaPagada: null,
      fechaPagoCuota: null,
      ejecutivo: null,
      creadoPor: null,
      fechaCreacion: null,
      modificadoPor: null,
      ultimaModificacion: null,
      statusComisionItems: [
        'Pendiente de Pago Aseguradora',
        'Pendiente de Pago al Agente',
        'Pagada al Agente',
        'Cancelada'
      ],
      statusComision: null,
      baseComision: null,
      checkComisionRecibida: null,
      fechaComisionRecibida: null,
      folioMovimientoBancario: null,
      comisionPagadaAseguradora: null,
      montoComisionPagadaAseguradora: null,
      comisionAgente: null,
      montoComisionAgente: null,
      iva: null,
      isr: null,
      retencionIva: null,
      folioFacturaPagar: null,
      importePagarAgente: null,
      checkComisionAgentePagada: null,
      fechaPagoComisionAgente: null,
      comisionAlige: null,
      montoComisionAlige: null,
      bono: null,
      montoBonoAlige: null,
      folioMovimientoBancarioBono: null,
      fechaPagoBono: null,
      comisionTotal: null,
      montoComisionTotalAlige: null,
      adjuntoPago: [],
      documentosFacturaAgente: [],
      documentosPagoRealizadoAgente: [],
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    }
  },
  watch: {
    dialog(val) {
      if (!val) return

      setTimeout(() => (this.dialog = false), 4000)
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    mostrarCheckRehabilitarCuota() {
      if (this.estadoCuotaSelected === 'Vencida') {
        if (this.rolToCompare === 'ADMIN' || this.rolToCompare === 'OPERACIONES' || this.rolToCompare === 'OPERADOR' || this.rolToCompare === 'MESADECONTROL') {
          return true
        }
        return false
      }
      return false
    },
    actualizarCuota() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      if (this.adjuntoPago.length > 0) {
        this.cargarArchivos()
      }
      if (this.checkRehabilitarCuota) {
        this.notaMotivoRehabilitacionCuota()
      }
      let request = {
        poliza: this.poliza,
        orden: this.orden,
        estadoCuotaSelected: this.estadoCuotaSelected,
        valorPagar: this.valorPagar,
        checkRehabilitarCuota: this.checkRehabilitarCuota,
        motivoRehabilitarCuota: this.motivoRehabilitarCuota ? this.motivoRehabilitarCuota : null,
        fechaPagoAcordada: this.fechaPagoAcordada ? moment(this.fechaPagoAcordada, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        fechaLimitePago: this.fechaLimitePago ? moment(this.fechaLimitePago, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        checkPagada: this.checkCuotaPagada ? this.checkCuotaPagada : false,
        fechaPagoCuota: this.fechaPagoCuota ? moment(this.fechaPagoCuota, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        fechaCreacion: this.fechaCreacion ? moment(this.fechaCreacion, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        ultimaModificacion: this.ultimaModificacion,
        statusComision: this.statusComision,
        baseComision: this.baseComision,
        checkComisionRecibida: this.checkComisionRecibida,
        fechaComisionRecibida: this.fechaComisionRecibida ? moment(this.fechaComisionRecibida, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        folioMovimientoBancario: this.folioMovimientoBancario,
        comisionPagadaAseguradora: this.comisionPagadaAseguradora,
        montoComisionPagadaAseguradora: this.montoComisionPagadaAseguradora,
        comisionAgente: this.comisionAgente,
        montoComisionAgente: this.montoComisionAgente,
        iva: this.iva,
        isr: this.isr,
        retencionIva: this.retencionIva,
        folioFacturaPagar: this.folioFacturaPagar,
        importePagarAgente: this.importePagarAgente,
        checkComisionAgentePagada: this.checkComisionAgentePagada,
        fechaPagoComisionAgente: this.fechaPagoComisionAgente ? moment(this.fechaPagoComisionAgente, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        comisionAlige: this.comisionAlige,
        montoComisionAlige: this.montoComisionAlige,
        bono: this.bono,
        montoBonoAlige: this.montoBonoAlige,
        folioMovimientoBancarioBono: this.folioMovimientoBancarioBono,
        fechaPagoBono: this.fechaPagoBono ? moment(this.fechaPagoBono, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        comisionTotal: this.comisionTotal,
        montoComisionTotalAlige: this.montoComisionTotalAlige,
      }
      this.$axios
        .post(`/v1/cuota/update/${this.cuotaId}`, request, config)
        .then(resp => {
          if (resp.status == 200) {
            this.dialog = true;
          }
          setTimeout(() => (this.$router.push(`/lista-comisiones`)), 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      for (let i = 0; i < this.adjuntoPago.length; i++) {
        const formData = new FormData();
        let file = this.adjuntoPago[i];
        formData.append('file', file);
        this.$axios.post(`/v1/cuota/documento/pago/upload/${this.cuotaId}`, formData, config)
          .then(function (result) {
            console.log(result);
          }, function (error) {
            console.log(error);
          });
      }
    },
    notaMotivoRehabilitacionCuota() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      const tipo = 'Nota'
      const nombre = `Cuota #${this.cuotaId} Rehabilitada`
      formData.append('nota', this.motivoRehabilitarCuota ? this.motivoRehabilitarCuota : null);
      formData.append('tipo', tipo);
      formData.append('nombre', nombre);

      this.$axios.post(`/v1/cuota/documento/upload/${this.cuotaId}`, formData, config)
        .then(function (result) {
          console.log(result);
        }, function (error) {
          console.log(error);
        });
    },

    cargarCuota() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/cuota/uuid/${this.$route.params.uuid}`, config).then(response => {
        console.log('Comision: ', response);
        this.poliza = response.data.emisionId
        this.orden = response.data.orden
        this.estadoCuotaSelected = response.data.status
        this.valorPagar = response.data.valorAPagar
        this.fechaPagoAcordada = response.data.fechaPagoAcordada ? moment(response.data.fechaPagoAcordada).format('DD/MM/YYYY') : null
        this.fechaLimitePago = response.data.fechaLimitePago ? moment(response.data.fechaLimitePago).format('DD/MM/YYYY') : null
        // this.checkCuotaPagada = null
        this.fechaPagoCuota = response.data.fechaPago ? moment(response.data.fechaPago).format('DD/MM/YYYY') : null
        this.ejecutivo = response.data.operadorId ? response.data.operadorId : null
        this.creadoPor = response.data.creadoPor ? response.data.creadoPor : null
        this.fechaCreacion = response.data.fechaCreacion ? moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : null
        this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : null,
          this.ultimaModificacion = response.data.ultimaModificacion ? moment.utc(response.data.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : null
        this.statusComision = response.data.statusComision
        this.baseComision = response.data.baseComision
        this.checkComisionRecibida = response.data.comisionRecibida ? response.data.comisionRecibida == 0 ? false : true : null
        this.fechaComisionRecibida = response.data.fechaComisionRecibida ? moment(response.data.fechaComisionRecibida).format('DD/MM/YYYY') : null
        this.folioMovimientoBancario = response.data.folioMovimientoBancario
        this.comisionPagadaAseguradora = response.data.porcentajeComisionAseguradora
        this.montoComisionPagadaAseguradora = response.data.montoComisionPagadaAseguradora
        this.comisionAgente = response.data.porcentajeComisionAgente
        this.montoComisionAgente = response.data.montoComisionAgente
        this.iva = response.data.iva
        this.isr = response.data.isr
        this.retencionIva = response.data.retencionIva
        this.folioFacturaPagar = response.data.folioFacturaAPagar
        this.importePagarAgente = response.data.importeAPagarAgente
        this.checkComisionAgentePagada = response.data.comisionAgentePagada ? response.data.comisionAgentePagada == 0 ? false : true : null
        this.fechaPagoComisionAgente = response.data.fechaPago
        this.comisionAlige = response.data.porcentajeComisionSegurify
        this.montoComisionAlige = response.data.montoComisionSegurify
        this.bono = response.data.porcentajeBono
        this.montoBonoAlige = response.data.montoBonoSegurify
        this.folioMovimientoBancarioBono = response.data.folioMovimientoBancarioBono
        this.fechaPagoBono = response.data.fechaPagoBono
        this.comisionTotal = response.data.porcentajeComisionTotal
        this.montoComisionTotalAlige = response.data.montoComisiontotalSegurify
        this.cuotaId = response.data.id
      })
    },
    comportamientoBotonGuardar() {
      if (this.checkCuotaPagada == true) {
        return this.validarFormCuotaPagada
      }
      if (this.statusComision == 'Pendiente de Pago al Agente') {
        return this.validarFormPendientePagoAgente
      }
      if (this.comisionAgentePagada) {
        return this.validarFormComisionPagadaAgente
      }
      return false
    },
  },
  computed: {
    validarFormCuotaPagada() {
      return (
        this.fechaPagoCuota != null &&
        this.adjuntoPago.length < 0
      )
    },
    validarFormPendientePagoAgente() {
      return (
        this.folioFacturaPagar != null &&
        this.documentosFacturaAgente.length < 0
      )
    },
    validarFormComisionPagadaAgente() {
      return (
        this.documentosPagoRealizadoAgente.length < 0
      )
    }
  },
  mounted() {
    this.cargarCuota()
  }
}
</script>

<style>
.v-stepper__step__step {
  color: transparent !important;
  display: none !important;
}
</style>