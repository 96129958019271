<template>
  <v-row>
    <v-col cols="12">
      <h2>Agentes</h2>
    </v-col>

    <v-col cols="12" class="mb-10">

      <v-data-table :headers="agentesTable" :items="report" item-key="name" class="elevation-1" :search="search"
        :loading="loading" loading-text="Cargando...">
        <template v-slot:top>
          <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
        </template>
        <template v-slot:item.sexo="{ item }">
          <div v-if="item.sexo == 1"> Masculino </div>
          <div v-if="item.sexo == 2">Femenino</div>
        </template>
        <template v-slot:item.status="{ item }">
          <div v-if="item.status == -1">
            <v-chip color="red" dark>Inactivo</v-chip>
          </div>
          <div v-if="item.status == 1">
            <v-chip color="green" dark>Activo</v-chip>
          </div>
        </template>
        <template v-slot:item.uuid="{ item }">
          <v-icon small class="mr-2" @click="cotizaItem(item.uuid)">
            mdi-file-find
          </v-icon>
        </template>

      </v-data-table>

    </v-col>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      agentes: [],
      loading: true,
      search: "",
      agentesTable: [

        { text: "Nombre", align: "start", sortable: false, value: "nombreCompleto", },
        { text: "Correo Electronico", value: "correo" },
        { text: "Celular", value: "celular" },
        { text: "Teléfono", value: "telefono" },
        { text: "Fecha de Nacimiento", value: "fechaNacimiento" },
        { text: "Sexo", value: "sexo" },
        { text: "Activo", value: "status" },
        { text: "RFC", value: "rfc" },
        { text: "Curp", value: "curp" },
        { text: "Cotiza como agente", value: "uuid" },
      ],
      report: [],
    };
  },

  created() {
    this.getAgent();
  },

  computed: {},

  methods: {
    getAgent() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(`/v1/agente/list`, config)
        .then((response) => {
          this.loading = false;
          this.agentes = response.data
          this.report = response.data
        });
    },

    cotizaItem(item) {
      this.$router.push(`/cotiza/segurify/${item}`)
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
<style scoped>
</style>
