<template>
  <div>
    <v-row class="d-flex justify-center mt-5">
      <v-col cols="8">
        <v-row>
          <v-col cols="4">
            <img src="../../../assets/logo_segurify.png" width="100px" alt="">
          </v-col>
          <v-col cols="8">
            <h2>WhatsApp Outbound <br> Seguimiento a Leads</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="whats.nombreLead" label="*Nombre del Lead:" placeholder="Nombre del Lead">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="whats.userAgent" label="*Usuario Agente:" placeholder="Usuario agente">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="whats.telefono" label="*Teléfono" placeholder="##########" v-mask="'##########'">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="whats.cotizacion" label="UUID / Cotización:" placeholder="UUID / Cotización">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select label="Campaña:" placeholder="Campaña" :items="campaniaList" v-model="whats.campania"></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn @click="enviarOutbound()">
              Enviar
            </v-btn>
          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="modalWhats" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Envio Outbound
        </v-card-title>

        <v-card-text>
          El envio Outbound se ha cargado.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modalWhats = false">
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalWhatsProcesando" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Cargando envio Outbound
        </v-card-title>

        <v-card-text>
          Procesando.....
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modalWhatsProcesando = false">
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      modalWhats: false,
      modalWhatsProcesando: false,
      whats: {
        nombreLead: null,
        userAgent: null,
        telefono: null,
        cotizacion: null,
        campania: null,
      },
      campaniaList: [
        "AIG",
        "ANA",
        "AXA",
        "COMPARADOR",
        "COMPARADOR-ADT",
        "COMPARADOR-AUTOCOSMOS",
        "GNP",
        "HDI",
        "PROVEEDORES",
        "QUÁLITAS",
        "SGMM SEGURIFY",
        "SURA",
        "SURA MOVILIDAD",
        "SURA SGMM            ",
      ]
    };
  },

  mounted() {
    let user = localStorage.agenteMail.split("@")[0]
    this.whats.userAgent = user
  },

  methods: {
    enviarOutbound() {
      this.modalWhatsProcesando = true
      let dataNormal = {
        "flowId": "3915",
        "outboundMessage": {
          "params": [`${this.whats.nombreLead}`, `${this.whats.userAgent}`, "Segurify",],
          "id": "1e076800-bfeb-40b8-9a1a-bd8532f3133e",
          "mediaUrl": ""
        },
        "channelOwnerId": "60e3233b7ca38300d31f7f4e",
        "person": {
          "name": `${this.whats.nombreLead}`,
          "phoneNumber": `521${this.whats.telefono}`
        }
      }
      let dataJson = JSON.stringify(dataNormal)

      let session_url = 'https://chat.gus.chat/routing/v1/outboundMessage';
      let username = 'api@segurify-kqrnrvph_kt8yfae4';
      let password = 'QPyTaLBtgMBEXZiHlbKH5pN7JzoIGUTs3JRBqh7nNozbFIEpLbRVEsYQ5EKeOBwolvSfhiqAg0mfNdoqUUtNoXjLl2ZT8pOUYtDgLJbAbjVXzO9sz06EFJN4ZzgpIZNX';

      let data = dataJson
      console.log(data);

      this.$axios.post(session_url, data, {
        auth: {
          username: username,
          password: password
        },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        },
      }).then((response) => {
        console.log(response.data);
        this.modalWhatsProcesando = false
        this.modalWhats = true;
      });
    }
  },
};
</script>
<style scoped>
</style>
