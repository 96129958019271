<template>
    <v-row align="center">
      <v-col cols="12" md="6" lg="8">
        <div class="d-flex align-center pb-0">
          <v-img :max-width="widthLogoSeg" :src="logoSegurify"></v-img>
          <h2 class="ml-5">Seguros / Asistencias</h2>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import logoSegurify from "@/assets/logo_segurify.png";
  export default {
    data() {
      return {
        logoSegurify,
        rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      };
    },
    computed: {
      widthLogoSeg() {
        if (this.$vuetify.breakpoint.smAndDown) return "50";
        if (this.$vuetify.breakpoint.mdAndDown) return "50";
        return "80";
      },
    },
  };
  </script>