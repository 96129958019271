var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',{staticClass:"mb-10",attrs:{"dense":"","align":"center"}},[_c('v-img',{attrs:{"max-height":_vm.width_log2,"max-width":_vm.height_log2,"src":_vm.logo_2}}),_c('h2',{staticClass:"ml-5"},[_vm._v("Solcitudes")]),_c('v-spacer')],1),_c('v-row',[(
        _vm.currentRol == 'ADMIN' ||
          _vm.currentRol == 'OPERADORASIST' ||
          _vm.currentRol == 'OPERADOR' ||
          _vm.currentRol == 'MESADECONTROL' ||
          _vm.currentRol == 'MESACONTROLASIST' ||
          _vm.currentRol == 'ADMINCC'
      )?_c('v-row',[_c('v-col',{staticStyle:{"text-align":"end","padding-right":"24px"},attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ml-11 mt-2",staticStyle:{"align-items":"right"},attrs:{"color":"primary"},on:{"click":_vm.getInspirumBalanceMethod}},[_vm._v(" Consultar saldo Inspirium ")]),_c('v-btn',{staticClass:"ml-11 mt-2",staticStyle:{"align-items":"right"},attrs:{"color":"primary"},on:{"click":function($event){(_vm.showCreateModal = true), (_vm.route = '/v1/solicitud/manual')}}},[_vm._v(" Crear ")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('BuscarSolicitud')],1)],1),_c('solicitudDialogForm',{attrs:{"show":_vm.showCreateModal,"route":_vm.route},on:{"close":function($event){_vm.showCreateModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }