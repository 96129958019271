<template>
  <div>
    <div v-if="leadSinAsignar">
      <!-- HEAD TITLE -->
      <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
        <v-toolbar>
          <v-toolbar-title>
            <h2>Polizas</h2>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            @click="goBack()"
            style="background-color: #ff0042; color: white;"
          >
            FINALIZAR
          </v-btn>
        </v-toolbar>
      </v-card>

      <v-row class="mb-8">
        <v-col cols="8">
          <v-form>
            <v-row>
              <v-col>
                <v-stepper v-model="stepper" non-linear>
                  <v-stepper-header class="d-flex justify-space-around">
                    <v-stepper-step step="1" editable>
                      Cotizacion / Poliza
                    </v-stepper-step>
                    <v-stepper-step step="2" editable>
                      Datos del cliente
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <!-- 1ST ITEM (FORM) -->
                    <v-stepper-content step="1">
                      <v-row style="padding: 20px; margin-top: -35px">
                        <!-- <v-col cols="4">
                        <v-text-field label="No. Póliza" v-model="poliza.poliza" :readonly="updateCuotas()">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="invisible">
                        <v-text-field label="Poliza ID Interna" v-model="poliza.id" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="invisible">
                        <v-select label="Tipo de Póliza" v-model="poliza.type" :items="typePolizaList"
                          :readonly="poliza.status > 0"></v-select>
                      </v-col> -->
                        <v-col cols="4">
                          <v-select
                            label="Estatus de la Póliza"
                            v-model="poliza.status"
                            :items="statusList"
                            item-text="name"
                            item-value="id"
                            :disabled="roles != 'ADMIN'"
                            readonly
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Cliente"
                            v-model="poliza.newCliente"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            label="Tipo de contratante"
                            v-model="poliza.tipo_contratante"
                            :items="tipos_contratante"
                            v-if="global_productoAseguradora == 'Hogar'"
                            item-text="text"
                            item-value="value"
                            :disabled="blockEdit"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            label="Plan"
                            v-model="poliza.productos"
                            :items="productos"
                            :disabled="blockEdit"
                            item-text="nombre"
                            item-value="id"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="poliza.status == 3 || poliza.status == 4"
                        >
                          <v-text-field
                            label="Póliza renovada por"
                            v-model="anterior"
                            :readonly="poliza.status != 3"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="invisible">
                          <v-text-field
                            label="No. Cotización / Lead ID"
                            v-model="poliza.leadId"
                            :readonly="poliza.status > 0"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="poliza.id != null && poliza.status == 0"
                        >
                          <v-checkbox
                            label="Obtener certificado"
                            v-model="poliza.checkgetCertificado"
                            :disabled="canViewEmitir"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="
                            (poliza.poliza == '' || poliza.poliza == null) &&
                              (currentEtiquetaAseguradora == 1 ||
                                currentEtiquetaAseguradora == 2 ||
                                currentEtiquetaAseguradora == 3 ||
                                currentEtiquetaAseguradora == 5) &&
                              poliza.status == 0
                          "
                        >
                          <v-checkbox
                            label="Volver a cotizar WS"
                            v-model="poliza.checkCotizarWs"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-alert
                            dense
                            text
                            type="error"
                            v-if="poliza.id != null && canViewEmitir"
                          >
                            Por favor, debes crear uno o hasta 5 beneficiarios
                            que cubran el 100% de porcentaje de asignación para
                            que se pueda asignar un certificado
                          </v-alert>
                        </v-col>
                        <!-- INICIO DE CHECKS -->
                        <v-col cols="12" v-if="poliza.status == 4">
                          <v-checkbox
                            label="Renovada"
                            v-model="poliza.checkRenovada"
                          ></v-checkbox>
                        </v-col>
                        <!-- <v-col cols="6" v-if="poliza.status == 8 && currentRol != 'AGENTCC' && poliza.validacionCodigo == false && poliza.globalInteractionId != '0000'">
                        <v-checkbox label="Póliza emitida" v-model="poliza.emitidaCheck"></v-checkbox>
                      </v-col> -->
                        <v-col
                          cols="6"
                          v-if="
                            (canEmit && puedeVerCheckSinCodigo) ||
                              showCertificadoExcepcion
                          "
                        >
                          <v-checkbox
                            label="Aprobación sin Código de Verificación obtenido"
                            v-model="poliza.sinCodigoCheck"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="6" v-if="poliza.status == 1">
                          <v-text-field
                            v-model="poliza.emitidDate"
                            label="Fecha de Emisión"
                            prepend-icon="mdi-calendar"
                            :disabled="blockEdit"
                            v-mask="'##/##/####'"
                            placeholder="Requerido"
                            :rules="inputsRequeridos"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          v-if="
                            poliza.status == 0 ||
                              poliza.status == 1 ||
                              poliza.status == 2
                          "
                        >
                          <v-checkbox
                            label="Póliza Cancelada"
                            v-model="poliza.canceladaCheck"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="4" v-if="poliza.status == 6">
                          <v-text-field
                            v-model="poliza.canceladaDate"
                            label="Fecha de Cancelación"
                            disable="true"
                            prepend-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            placeholder="Requerido"
                            :rules="inputsRequeridos"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="poliza.status == 3">
                          <v-checkbox
                            label="Póliza Finalizada"
                            v-model="poliza.finalizadaCheck"
                          ></v-checkbox>
                        </v-col>

                        <v-col
                          cols="6"
                          v-if="
                            poliza.canceladaCheck ||
                              poliza.finalizadaCheck ||
                              poliza.status == 5 ||
                              poliza.status == 6
                          "
                        >
                          <v-select
                            label="Motivo de Cancelación / Finalización"
                            v-model="poliza.canceladaFinalizadaMotivo"
                            :items="motivosCancelacionFiltrados"
                            item-text="name"
                            item-value="name"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          v-if="poliza.status == 1 || poliza.status == 2"
                        >
                          <v-checkbox
                            label="Generar Cuotas nuevamente"
                            v-model="poliza.checkGenerarCuotas"
                            :disabled="poliza.status < 1"
                            v-show="canEdit"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="
                            (poliza.status == 1 ||
                              poliza.status == 2 ||
                              poliza.status == 3) &&
                              emision.aseguradoraId == 7 &&
                              (currentRol == 'ADMIN' ||
                                currentRol == 'ADMINCC' ||
                                currentRol == 'OPERADOR' ||
                                currentRol == 'OPERACIONES' ||
                                currentRol == 'MANAGERCC')
                          "
                        >
                          <v-checkbox
                            label="Reenviar centificado"
                            v-model="reenviarCertificado"
                            :disabled="poliza.status < 1"
                          ></v-checkbox>
                        </v-col>

                        <v-col
                          cols="4"
                          v-if="
                            poliza.status == 1 ||
                              poliza.status == 2 ||
                              poliza.status == 3
                          "
                        >
                          <v-checkbox
                            label="Aplicar endoso"
                            v-model="poliza.checkEndosoAplicado"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="4" v-if="poliza.checkEndosoAplicado">
                          <v-text-field
                            v-model="poliza.fechaEndoso"
                            label="Fecha de endoso"
                            prepend-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            placeholder="Requerido"
                            :rules="inputsRequeridos"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.checkEndosoAplicado">
                          <v-select
                            label="Motivo de Endoso"
                            v-model="poliza.tipoEndoso"
                            :items="[
                              'Corrección de RFC',
                              'Corrección de datos del contratante',
                              'Corrección de dirección del contratante',
                            ]"
                            item-text="name"
                            item-value="name"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" v-if="poliza.status == 6">
                          <v-checkbox
                            label="Reactivar Póliza"
                            v-model="poliza.checkReactivarPoliza"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="4" v-if="poliza.status == 5">
                          <v-text-field
                            v-model="poliza.finalizadaDate"
                            label="Fecha de Finalización"
                            prepend-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            placeholder="Requerido"
                            :rules="inputsRequeridos"
                          ></v-text-field>
                        </v-col>

                        <!-- FINALIZACION DE CHECKS -->
                        <v-row style="padding: 15px">
                          <!-- <v-col cols="4">
                          <v-autocomplete label="Agente" v-model="poliza.agente" :items="agentes" item-text="nombre"
                            item-value="id" :readonly="poliza.status > 0" @change="esDirecto()"></v-autocomplete>
                        </v-col> -->
                          <v-col cols="4" v-if="esAgentDirecto">
                            <v-autocomplete
                              label="Comercial Asignado"
                              :items="comercialAsignadoList"
                              item-text="nombre"
                              item-value="id"
                              v-model="comercialAsignado"
                              placeholder="Requerido"
                              :rules="inputsRequeridos"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="4">
                            <v-menu
                              v-model="menuInicioVigencia"
                              :close-on-content-click="false"
                              disabled
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                              :readonly="poliza.status > 0"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateFormattedInicioVigencia"
                                  label="Fecha inicio de vigencia"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  @blur="
                                    comboInicioVigencia = parseDate(
                                      dateFormattedInicioVigencia
                                    )
                                  "
                                  v-on="on"
                                  v-mask="'##/##/####'"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                @input="menuInicioVigencia = false"
                                v-model="comboInicioVigencia"
                                no-title
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                              ref="menuFinVigencia"
                              v-model="menuFinVigencia"
                              :close-on-content-click="false"
                              disabled
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateFormattedFinVigencia"
                                  label="Fecha fin de vigencia"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  @blur="
                                    comboFinVigencia = parseDate(
                                      dateFormattedFinVigencia
                                    )
                                  "
                                  v-on="on"
                                  v-mask="'##/##/####'"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="comboFinVigencia"
                                no-title
                                @input="menuFinVigencia = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <!-- <v-col cols="4">
                          <v-select label="Periodicidad de pago" v-model="poliza.periodicidad" :items="periodicidadList"
                            item-text="name" item-value="id" :readonly="updateCuotas()"></v-select>
                        </v-col> -->
                          <v-col cols="4" v-if="invisible">
                            <v-text-field
                              label="Años"
                              v-model="years"
                              type="number"
                              :readonly="updateCuotas()"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="4" v-if="invisible">
                            <v-text-field
                              label="Número de cuotas"
                              v-model="poliza.numeroCuotas"
                              type="number"
                              readonly
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="ramo != 'AHORRO'">
                            <v-text-field
                              label="Prima neta"
                              v-model="poliza.primaNeta"
                              :rules="inputsRequeridos"
                              :disabled="blockEdit"
                              :readonly="updateCuotas()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="ramo != 'AHORRO'">
                            <v-text-field
                              label="Prima total"
                              v-model="poliza.primaTotal"
                              :rules="inputsRequeridos"
                              :disabled="blockEdit"
                              :readonly="updateCuotas()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" v-if="invisible">
                            <v-select
                              label="Forma de Pago"
                              v-model="poliza.formaPago"
                              :items="formaPagoList"
                              :disabled="blockEdit"
                              item-text="name"
                              item-value="id"
                              :readonly="updateCuotas()"
                            ></v-select>
                          </v-col>
                          <v-col cols="4" v-if="ramo != 'AHORRO'">
                            <v-text-field
                              label="Monto de Primer Pago"
                              v-model="poliza.montoPrimerPago"
                              :disabled="blockEdit"
                              :rules="inputsRequeridos"
                              :readonly="updateCuotas()"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col cols="4" v-if="(poliza.periodicidad != 1) && (ramo != 'AHORRO')">
                          <v-text-field label="Monto de Pago Subsecuente" v-model="poliza.montoSubsecuente"
                            :rules="inputsRequeridos" :readonly="updateCuotas()"></v-text-field>
                        </v-col> -->
                          <v-col cols="4">
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateFormattedPrimerPago"
                                  label="Fecha primer pago"
                                  persistent-hint
                                  :disabled="blockEdit"
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  @blur="
                                    comboPrimerPagoDate = parseDate(
                                      dateFormattedPrimerPago
                                    )
                                  "
                                  v-on="on"
                                  v-mask="'##/##/####'"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="comboPrimerPagoDate"
                                no-title
                                @input="menu1 = false"
                              >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="Notas de la Póliza"
                              v-model="poliza.notas"
                              :readonly="poliza.status > 0"
                            >
                            </v-text-field>
                          </v-col>
                          <!-- <v-col cols="6">
                          <v-text-field label="Creado por" v-model="poliza.creadoPor" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="poliza.creadoPorDate" label="Fecha de Creación"
                            prepend-icon="mdi-calendar" placeholder="Requerido" v-bind="attrs" v-on="on" readonly>
                          </v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field label="Modificado por" v-model="poliza.ultimaModoficacion" readonly>
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="poliza.ultimaModoficacionDate" label="Última Modificación"
                            prepend-icon="mdi-calendar" placeholder="Requerido" v-bind="attrs" v-on="on" readonly>
                          </v-text-field>
                        </v-col> -->
                        </v-row>
                      </v-row>
                      <!-- ENDS HERE -->

                      <v-btn
                        color="primary"
                        @click="actualizarPoliza()"
                        :disabled="
                          !comportamientoBotonGuardar() ||
                            puedeGuardarCancelacion ||
                            enviado
                        "
                      >
                        Guardar
                      </v-btn>
                      <v-btn
                        color="primary"
                        style="margin-left: 15px"
                        @click="stepper = 2"
                      >
                        Siguiente
                      </v-btn>
                    </v-stepper-content>

                    <!-- 2ND ITEM (2ND FORM) -->
                    <v-stepper-content step="2">
                      <v-row style="padding-left: 20px;">
                        <v-col cols="4">
                          <v-select
                            label="Tipo de cliente"
                            v-model="poliza.tipo_cliente"
                            :disabled="blockEdit && isContratanteEndoso"
                            :items="tipos_cliente"
                            :rules="[$rules.required]"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row style="padding: 20px; margin-top: -35px">
                        <v-col cols="4">
                          <v-select
                            label="Tipo de cliente"
                            v-model="poliza.tipo_cliente"
                            :disabled="blockEdit && isContratanteEndoso"
                            :items="tipos_cliente"
                            :rules="[$rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            label="Tipo de Persona (Régimen)"
                            v-model="poliza.regimen"
                            :items="regimentList"
                            :disabled="blockEdit && isContratanteEndoso"
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="poliza.regimen == 'Moral'"
                          :disabled="blockEdit"
                        >
                          <v-text-field
                            label="Razón Social"
                            v-model="poliza.razonSocial"
                            :disabled="blockEdit"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Nombre(s)"
                            v-model="poliza.nombres"
                            :disabled="blockEdit && isContratanteEndoso"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Apellido Paterno"
                            v-model="poliza.apellidoPaterno"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Apellido Materno"
                            v-model="poliza.apellidoMaterno"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="
                            poliza.regimen != 'Moral' && poliza.regimen != null
                          "
                        >
                          <v-select
                            label="Estado Civil"
                            :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
                            v-model="poliza.statusCivil"
                            :rules="[$rules.required]"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-select>
                        </v-col>

                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-menu
                            ref="menuFechaNacimiento"
                            v-model="menuFechaNacimiento"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{}">
                              <v-text-field
                                v-model="dateFormattedFechaNacimiento"
                                label="Fecha de Nacimiento"
                                :disabled="blockEdit && isContratanteEndoso"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                @blur="
                                  comboFechaNacimiento = parseDate(
                                    dateFormattedFechaNacimiento
                                  )
                                "
                                v-mask="'##/##/####'"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="comboFechaNacimiento"
                              no-title
                              @input="menuFechaNacimiento = false"
                              :disabled="blockEdit && isContratanteEndoso"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="poliza.edad"
                            label="EDAD"
                            placeholder="Requerido"
                            readonly
                            :disabled="blockEdit"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="RFC"
                            v-model="poliza.rfc"
                            :readonly="updateCuotas() && isRFCendoso"
                            :disabled="blockEdit && isRFCendoso"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-select
                            label="Género"
                            v-model="poliza.selectedGenero"
                            :items="generoList"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-select>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            label="Teléfono"
                            v-model="poliza.celular"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Correo Electrónico"
                            v-model="poliza.correoElectronico"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="8"
                          v-if="poliza.regimen == 'Moral'"
                        ></v-col>
                        <v-col cols="4">
                          <v-select
                            label="Tipo de Persona (Régimen)"
                            v-model="poliza.regimen"
                            :items="regimentList"
                            :disabled="blockEdit && isContratanteEndoso"
                          >
                          </v-select>
                        </v-col>
                        <v-col v-if="poliza.regimen == 'Física'" cols="4">
                          <v-select
                            v-model="facturar"
                            label="¿Desea facturar?"
                            item-value="value"
                            item-text="text"
                            :items="facturacionItems"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="RFC"
                            v-model="poliza.rfc"
                            :readonly="updateCuotas() && isRFCendoso"
                            :disabled="blockEdit && isRFCendoso"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col v-if="openCFDI" cols="6">
                          <v-select
                            v-model="poliza['regimen-fiscal']"
                            label="Tipo de régimen"
                            :items="tipoRegimenItems"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-select>
                        </v-col>
                        <v-col v-if="openCFDI" cols="6">
                          <v-select
                            v-model="poliza.cfdi"
                            label="Concepto CFDI"
                            :items="conceptoCFDIItems"
                            :disabled="blockEdit && isContratanteEndoso"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Calle"
                            v-model="poliza.calle"
                            :rules="[$rules.required]"
                            :disabled="blockEdit && isDireccionEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="No. Exterior"
                            v-model="poliza.num_exterior"
                            :rules="[$rules.required]"
                            :disabled="blockEdit && isDireccionEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="No. Interior"
                            v-model="poliza.num_interior"
                            :rules="[$rules.required]"
                            :disabled="blockEdit && isDireccionEndoso"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            label="Colonia"
                            :items="colonias"
                            v-model="poliza.colonia"
                            :rules="[$rules.required]"
                            :disabled="blockEdit"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Código Postal"
                            v-model="poliza.codigoPostal"
                            v-mask="'#####'"
                            :disabled="blockEdit"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            label="Estado de la República"
                            v-model="poliza.estadoRepublicaSelected"
                            :items="estados"
                            item-text="name"
                            item-value="id"
                            :disabled="blockEdit"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Municipio"
                            v-model="poliza.municipio"
                            :rules="[$rules.required]"
                            :disabled="blockEdit"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="8" style="padding-buttom:5%">
                          <v-textarea
                            label="Dirección"
                            :rules="[$rules.required]"
                            v-model="poliza.direccion"
                            rows="1"
                            auto-grow
                            :disabled="blockEdit"
                          >
                          </v-textarea>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="global_productoAseguradora == 'Hogar'"
                        >
                          <v-text-field
                            label="Año en que se construyo"
                            v-model="poliza.años_construccion"
                            type="number"
                            v-mask="'####'"
                            :rules="[$rules.required]"
                            :disabled="blockEdit"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="global_productoAseguradora == 'Hogar'"
                        >
                          <v-select
                            label="Tipo de construcción"
                            :items="['Maciza']"
                            v-model="poliza.tipo_construccion"
                            :rules="[$rules.required]"
                            :disabled="blockEdit"
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="global_productoAseguradora == 'Hogar'"
                        >
                          <v-text-field
                            label="No. de Pisos"
                            v-model="poliza.num_pisos"
                            type="number"
                            v-mask="'##'"
                            :rules="[$rules.required]"
                            :disabled="blockEdit"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="global_productoAseguradora == 'Hogar'"
                        >
                          <v-text-field
                            label="Piso en el que vive"
                            v-model="poliza.piso_vive"
                            type="number"
                            v-mask="'##'"
                            :rules="[$rules.required]"
                            :disabled="blockEdit"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-btn
                        color="primary"
                        @click="actualizarPoliza()"
                        :disabled="
                          !comportamientoBotonGuardar() ||
                            puedeGuardarCancelacion ||
                            enviado
                        "
                      >
                        Guardar
                      </v-btn>

                      <v-btn text @click="stepper = 1"> Regresar </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <!-- ALERT MODAL -->
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="400"
        v-model="actualizadaExito"
      >
        <template v-slot:default="actualizadaExito">
          <v-card>
            <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
            <v-card-text>
              <div class="text-h4" style="margin-top: 10px; ">
                Actualización de Poliza con exitoso!
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="
                  (actualizadaExito.value = false),
                    $router.push(
                      `/seguimiento-solicitud/${this.$route.params.id}`
                    )
                "
              >
                Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <!-- END OF ALERT MODAL -->
    </div>
    <div v-else>
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            Ups parece que esta Venta no te corresponde" Por favor valida con tu
            supervisor.
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click="$router.go(-1)"
              elevation="2"
              style="background-color: white; color: black;"
            >
              FINALIZAR
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <form-cancelacion
      v-if="showCancelacionDialog"
      :poliza="poliza"
      :active="showCancelacionDialog"
      :producto="usuario_detalle.producto"
      :productoAseguradora="usuario_detalle.productoAseguradora"
      :corte="usuario_detalle.corte"
      :identificador="usuario_detalle.id"
      :motivoCancelacion="poliza.canceladaFinalizadaMotivo"
    ></form-cancelacion>

    <cancelacion-dialog
      :v-if="showConfirmacionDialog"
      :show="showConfirmacionDialog"
      @close="showConfirmacionDialog = false"
      @numeroSmsChanged="numeroSmsChanged"
    ></cancelacion-dialog>
  </div>
</template>

<script>
var moment = require("moment-timezone");

// import { getLeadById } from "../../../services/leadService";

export default {
  components: {
    "form-cancelacion": () =>
      import("../bradescard/components/documentos/FormPolizaCancelacion.vue"),
    "cancelacion-dialog": () => import("./confirmacionCancelacionDialog.vue"),
  },

  data() {
    return {
      lead: null,
      emailRules: [
        (value) => (value || "").length <= 20 || "Max 20 caracteres",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Dirección de correo incorrecta";
        },
      ],
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      invisible: false,
      stepper: 1,
      operadores: [],
      tmp: {
        telefono: "",
        correo: "",
      },
      poliza: {
        poliza: null,
        id: null,
        status: null,
        type: null,
        productos: null,
        ejecutivoOperacion: null,
        cliente: null,
        newCliente: null,
        agente: null,
        newAgente: null,
        landing: null,
        leadId: null,
        aseguradoraId: null,
        emitidaCheck: null,
        emitidDate: null,
        primerPagoCheck: null,
        primerPagoDate: null,
        canceladaCheck: null,
        canceladaDate: null,
        canceladaMotivo: null,
        finalizadaCheck: null,
        finalizadaDate: null,
        finalizadaMotivo: null,
        inicioDate: null,
        finDate: null,
        periodicidad: 4,
        primaNeta: null,
        primaTotal: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        formaPago: 1,
        numeroCuotas: null,
        noCuotasPagadas: null,
        creadoPorDate: null,
        creadoPor: null,
        ultimaModoficacionDate: null,
        ultimaModoficacion: null,
        observaciones: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        estadoRepublicaSelected: null,
        codigoPostal: null,
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalleAuto: null,
        plan: null,
        regimen: null,
        razonSocial: null,
        origenAuto: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
        notas: null,
        rfc: null,
        canceladaFinalizadaMotivo: null,
        checkRenovada: null,
        checkGenerarCuotas: null,
        checkEndosoAplicado: null,
        checkReactivarPoliza: null,
        checkgetCertificado: null,
        operadorId: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        municipio: null,
        tipo_contratante: null,
        años_construccion: null,
        tipo_construccion: null,
        num_pisos: null,
        piso_vive: null,
        suma_asegurada: null,
        direccion: null,
        edad: null,
        tipo_cliente: null,
        statusCivil: null,
        polizaAseguradora: null,
        operador_id: null,
        checkCotizarWs: false,
      },
      backpack: {
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        rfc: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
      },
      years: 1,
      yearsNumber: 0,
      anterior: null,
      actualizadaExito: false,
      emision: null,
      uuid: this.$route.params.id,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      rolesToView: JSON.parse(localStorage.getItem("roles")),
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      productos: [],
      agentes: [],
      statusList: [
        { id: 0, name: "Nueva" },
        { id: 1, name: "Emitida Pendente de Pago" },
        { id: 2, name: "Pagada Parcial" },
        { id: 3, name: "Pagada Total" },
        { id: 4, name: "Renovada" },
        { id: 5, name: "Finalizada" },
        { id: 6, name: "Cancelada" },
        { id: 8, name: "Emitido el certificado" },
      ],
      cancelacionFinalizacionList: [
        { id: 0, name: "Falta de pago" },
        { id: 1, name: "Incremento en prima / Renovación" },
        { id: 2, name: "Mal servicio agente" },
        { id: 3, name: "Mal servicio compañía" },
        { id: 4, name: "Económicas" },
        { id: 5, name: "Inconformidad con el producto" },
        { id: 6, name: "Mejora en competencia" },
        {
          id: 7,
          name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente",
        },
        { id: 8, name: "Cancelará tarjeta Bradescard" },
        { id: 9, name: "Contrató una póliza similiar" },
        { id: 10, name: "Es muy caro" },
        { id: 11, name: "Fallecimiento de asegurado" },
        { id: 12, name: "Inconformidad con el producto" },
        { id: 13, name: "Llegó al límite de morosidad de su cuenta" },
        { id: 14, name: "Mal servicio con la compañía de seguros" },
        { id: 15, name: "No le interesa" },
        { id: 16, name: "No tiene dinero" },
        { id: 17, name: "No tiene el saldo suficiente" },
        { id: 18, name: "Siniestro no cubierto" },
      ],
      typePolizaList: ["Nueva", "Renovación"],
      landList: [
        "AIG",
        "ANA",
        "AXA",
        "COMPARADOR",
        "COMPARADOR-ADT",
        "COMPARADOR-AUTOCOSMOS",
        "GNP",
        "HDI",
        "PROVEEDORES",
        "QUÁLITAS",
        "SGMM SEGURIFY",
        "SURA",
        "SURA MOVILIDAD",
        "SURA SGMM",
      ],
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero" },
        { id: 5, name: "Qualitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "Sura" },
        { id: 8, name: "AIG" },
        { id: 9, name: "HDI" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "Banorte" },
      ],
      periodicidadList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      clienteList: [
        { id: 1, name: "Cliente 1" },
        { id: 2, name: "Cliente 2" },
        { id: 3, name: "Cliente 3" },
        { id: 4, name: "Cliente 4" },
      ],
      formaPagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      generoList: ["Masculino", "Femenino"],
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      regimentList: ["Física", "Moral"],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      tipos_cliente: [
        { text: "Titular", value: "titular" },
        { text: "Asegurado", value: "asegurado" },
      ],
      canal: [],
      comercialAsignadoList: [],
      comercialAsignado: null,
      esAgentDirecto: null,
      identificador: null,
      comboInicioVigencia: null,
      dateFormattedInicioVigencia: null,
      menuInicioVigencia: false,

      comboFinVigencia: null,
      dateFormattedFinVigencia: null,
      menuFinVigencia: false,

      comboPrimerPagoDate: null,
      dateFormattedPrimerPago: null,
      menu1: false,

      comboFechaNacimiento: null,
      dateFormattedFechaNacimiento: null,
      menuFechaNacimiento: false,

      ramo: null,
      aseguradoraId: null,
      ramoAuto: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalleAuto: null,
        origenAuto: null,
        plan: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
      },
      ramoDanios: {
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },

      ramoSalud: {
        nivelHospitalario: null,
        sumaAsegurada: null,
        deducible: null,
        coaseguro: null,
        ceroDeducible: null,
        medicinaFueraHospital: null,
        emergenciaExtranjero: null,
        enfermedadesCatastroficas: null,
        coberturaExtranjero: null,
        preexistencias: null,
        proteccionDental: null,
        maternidadExtendida: null,
        coberturaNacional: null,
        reconocimientoAntiguedad: null,
        fechasParaCotizar: null,
        dependientes: null,
      },
      ramoVida: {
        plan: null,
        fumador: null,
        sumaAsegurada: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      },
      ramoAhorro: {
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },
      ramoFianzas: {
        contratoAdjunto: null,
      },
      global_producto: null,
      global_productoAseguradora: null,
      tipos_contratante: [
        { text: "Propietario", value: "propietario" },
        { text: "Inquilino", value: "inquilino" },
      ],
      copia_productos_originales: [],
      tipo_contratante: null,
      usuario_detalle: null,
      canViewEmitir: true,
      colonias: [],
      leadSinAsignar: true,
      sinCodigoCheck: false,
      actualStatus: "",
      showCancelacionDialog: false,
      showConfirmacionDialog: false,
      reenviarCertificado: false,
      enviado: false,
      currentEtiquetaAseguradora: "",
      facturacionItems: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      tipoRegimenItems: [
        "Sueldos y Salarios e Ingresos Asimilados a Salarios",
        "Arrendamiento",
        "Régimen de Enajenación o Adquisición de Bienes",
        "Demás ingresos",
        "Residentes en el Extranjero sin Establecimiento Permanente en México",
        "Ingresos por Dividendos (socios y accionistas)",
        "Personas Físicas con Actividades Empresariales y Profesionales",
        "Ingresos por intereses",
        "Régimen de los ingresos por obtención de premios",
        "Sin obligaciones fiscales",
        "Incorporación Fiscal",
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        "Régimen Simplificado de Confía",
        "",
      ],
      conceptoCFDIItems: [
        "Gastos en general",
        "Primas por seguros de gastos médicos",
        "Sin efectos fiscales",
      ],
      facturar: false,
      numeroSms: "",
    };
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
    years(value) {
      if (value.typeof != "number") {
        this.years = parseInt(value);
        const meses = this.years * 12;
        switch (this.poliza.periodicidad) {
          case 1:
            this.poliza.numeroCuotas = meses / 12;
            break;
          case 2:
            this.poliza.numeroCuotas = meses / 6;
            break;
          case 3:
            this.poliza.numeroCuotas = meses / 3;
            break;
          case 4:
            this.poliza.numeroCuotas = meses / 1;
            break;
          default:
            break;
        }
      }
    },
    anterior(val) {
      if (!val) return;

      if (val) {
        this.poliza.checkRenovada = true;
      }
    },
    comboInicioVigencia() {
      this.dateFormattedInicioVigencia = this.formatDate(
        this.comboInicioVigencia
      );
    },
    comboFinVigencia() {
      this.dateFormattedFinVigencia = this.formatDate(this.comboFinVigencia);
    },
    comboPrimerPagoDate() {
      this.dateFormattedPrimerPago = this.formatDate(this.comboPrimerPagoDate);
    },
    comboFechaNacimiento() {
      this.dateFormattedFechaNacimiento = this.formatDate(
        this.comboFechaNacimiento
      );
    },
    "poliza.tipo_contratante": function(value) {
      this.productos = [];
      var nombre = "";
      var result = null;
      const Inquilino = "Inquilino";

      this.copia_productos_originales.forEach((element) => {
        nombre = element.nombre;
        result = nombre.includes(Inquilino);
        if (value == "inquilino" && result) {
          this.productos.push({
            nombre: element.nombre,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId,
            primaNeta: element.primaNeta,
            primaTotal: element.primaTotal,
            primerPago: element.primerPago,
            Subsecuente: element.Subsecuente,
            url_imagen: element.url_imagen,
            suma_asegurada: element.suma_asegurada,
          });
        } else if (value == "propietario" && !result) {
          this.productos.push({
            nombre: element.nombre,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId,
            primaNeta: element.primaNeta,
            primaTotal: element.primaTotal,
            primerPago: element.primerPago,
            Subsecuente: element.Subsecuente,
            url_imagen: element.url_imagen,
            suma_asegurada: element.suma_asegurada,
          });
        }
      });
    },
    "poliza.tipo_cliente"(val) {
      if (this.usuario_detalle != null) {
        if (val == "titular") {
          this.poliza.nombres = this.usuario_detalle.nombre;
          this.poliza.apellidoPaterno = this.usuario_detalle.nombre;
          this.poliza.apellidoMaterno = this.usuario_detalle.nombre;
          this.poliza.celular = this.usuario_detalle.celular;
          this.poliza.selectedGenero = this.usuario_detalle.sexo;
          this.poliza.correoElectronico = this.usuario_detalle.correo;
          this.poliza.direccion = this.usuario_detalle.direccion;
          this.poliza.codigoPostal = this.usuario_detalle.codigoPostal;
          this.poliza.rfc = this.usuario_detalle.rfc;
          var año = moment(this.usuario_detalle.fecha_nacimiento).format(
            "YYYY"
          );
          var dia = moment(this.usuario_detalle.fecha_nacimiento).format("DD");
          var mes = moment(this.usuario_detalle.fecha_nacimiento).format("MM");
          this.dateFormattedFechaNacimiento = dia + "/" + mes + "/" + año;
        } else {
          this.poliza.nombres = "";
          this.poliza.apellidoPaterno = "";
          this.poliza.apellidoMaterno = "";
          this.poliza.celular = "";
          this.poliza.selectedGenero = "";
          this.poliza.correoElectronico = "";
          this.poliza.direccion = "";
          this.poliza.codigoPostal = "";
          (this.poliza.rfc = ""), (this.dateFormattedFechaNacimiento = "");
        }
      }
    },
    dateFormattedFechaNacimiento(newValue) {
      var years = moment(newValue, "DD/MM/YYYY");
      var today = moment().format("DD/MM/YYYY");
      var diference = moment(today, "DD/MM/YYYY").diff(
        moment(years, "DD/MM/YYYY"),
        "years"
      );
      this.poliza.edad = diference;
    },
    "poliza.productos"(value) {
      this.obtenerRamo(value);
      this.obtenerAseguradoraId(value);
      this.ObtenerDatosPagos(value);
      this.ObtenerSumaAsegurada(value);
    },
    "poliza.codigoPostal"(val) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get("v1/catalogo/direccion?cp=" + val, config)
        .then((response) => {
          this.colonias = [];
          response.data.listaColonias.forEach((item) => {
            this.colonias.push(item.nombreColonia);
          });
          this.poliza.municipio = response.data.nombreMunicipio;
          this.poliza.estadoRepublicaSelected = Number(response.data.nEstado);
        });
    },
    global_productoAseguradora(val) {
      if (
        ["Hogar", "HOGAR", "Celular", "Hospitalizacion", "Asistencias"].find(
          (productoAseguradora) => productoAseguradora == val
        )
      )
        this.canViewEmitir = false;
    },
    "poliza.checkEndosoAplicado"(newValue) {
      if (newValue) {
        this.poliza.fechaEndoso = moment().format("DD/MM/YYYY");
      } else {
        this.restoreEndosoData();
      }
    },
    "poliza.tipoEndoso"() {
      this.restoreEndosoData();
    },
  },
  methods: {
    /* montaje emision */
    cargarEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/emision/id/" + this.uuid, config).then((value) => {
        if (value.data == "") {
          this.leadSinAsignar = false;
        }
        this.emision = value.data;
        this.cargarLeadEmision();
        if (this.emision.aseguradoraId == 3) {
          this.$axios
            .get("/v1/cotizacion/id/" + this.emision.cotizacionId, config)
            .then((value2) => {
              this.cotizacion = value2.data.cotizacion;
            });
        }
        this.poliza.poliza =
          this.emision.poliza == "null" ? this.emision.id : this.emision.poliza;
        this.poliza.id = this.emision.id;
        this.poliza.status = this.emision.status;
        this.poliza.type = this.emision.tipoPoliza;
        this.poliza.productos = this.emision.productoId;
        this.poliza.ejecutivoOperacion = this.emision.operadorId;
        this.poliza.cliente = this.emision.cliente;
        this.poliza.agente = this.emision.agenteId;
        this.poliza.landing = this.emision.campania;
        this.poliza.aseguradoraId = this.emision.aseguradoraId;
        this.poliza.leadId = null;
        this.anterior = this.emision.polizaAnterior
          ? this.emision.polizaAnterior
          : null;
        this.poliza.emitidaCheck = null;
        this.poliza.emitidDate = this.emision.fechaEmision
          ? moment(this.emision.fechaEmision, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.poliza.primerPagoCheck = null;
        this.poliza.canceladaCheck = null;
        this.poliza.canceladaDate = this.emision.fechaCancelacion
          ? moment(this.emision.fechaCancelacion, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.canceladaMotivo = null;
        this.poliza.finalizadaCheck = null;
        this.poliza.finalizadaDate = this.emision.fechaFinalizacion
          ? moment(this.emision.fechaFinalizacion, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.finalizadaMotivo = null;
        this.poliza.inicioDate = moment(
          this.emision.inicio,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
        //moment(this.emision.inicio).format('YYYY/MM/DD')
        this.poliza.finDate = this.emision.fin
          ? moment(this.emision.fin, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;

        this.poliza.periodicidad = this.emision.pagoId; /* == 1 ? 'Anual' */
        // :  this.emision.pagoId == 2 ? 'Semestral'
        // :  this.emision.pagoId == 3 ? 'Trimestral'
        // : 'Mensual'
        this.years = this.emision.anios ? this.emision.anios : 1;
        this.poliza.primaNeta = this.emision.primaNeta;
        this.poliza.primaTotal = this.emision.total;
        this.poliza.montoPrimerPago = this.emision.primerPago;
        this.poliza.montoSubsecuente = this.emision.subsecuentePago;
        this.poliza.formaPago = this.emision.formaPago; /* == 1 ? 'Cargo en linea' */
        // : this.formaPago == 2 ? 'Domiciliación'
        // : 'Efectivo'
        // this.poliza.numeroCuotas = this.emision.pagoId == 1 ? 1
        //                           :  this.emision.pagoId == 2 ? 2
        //                           :  this.emision.pagoId == 3 ? 4
        //                           : 6
        this.poliza.primerPagoDate = moment(
          this.emision.fechaPrimerPago
        ).format("YYYY-MM-DD");
        this.poliza.noCuotasPagadas = null;
        this.poliza.creadoPorDate = moment
          .utc(this.emision.fecha)
          .subtract(6, "hours")
          .format("DD/MM/YYYY HH:mm");
        this.poliza.creadoPor = this.emision.creadoPor
          ? this.emision.creadoPor
          : "";
        this.poliza.ultimaModoficacionDate = this.emision.ultimaModificacion
          ? moment
              .utc(this.emision.ultimaModificacion)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm")
          : "";
        this.poliza.ultimaModoficacion = this.emision.modificadoPor
          ? this.emision.modificadoPor
          : "";
        this.poliza.observaciones = this.emision.observaciones;
        this.poliza.nombres = this.emision.nombre;
        this.poliza.apellidoPaterno = this.emision.apellidoPaterno;
        this.poliza.apellidoMaterno = this.emision.apellidoMaterno;
        this.poliza.fechaNacimiento = moment(
          this.emision.fechaNacimiento
        ).format("DD/MM/YYYY");
        this.poliza.selectedGenero = this.emision.genero;
        this.tmp.telefono = this.emision.telefonoContacto;
        this.poliza.celular =
          this.poliza.status != 0
            ? `******${this.emision.telefonoContacto.slice(6, 10)}`
            : this.emision.telefonoContacto;
        this.tmp.correo = this.emision.correoContacto;
        if (
          this.poliza.status != 0 &&
          this.emision.correoContacto &&
          this.emision.correoContacto.includes("@")
        ) {
          const [username, domain] = this.emision.correoContacto.split("@");
          const maskedUsername =
            username.length > 3
              ? `${username.slice(0, 3)}${"*".repeat(username.length - 3)}`
              : username;
          this.poliza.correoElectronico = `${maskedUsername}@${domain}`;
        } else {
          this.poliza.correoElectronico = this.emision.correoContacto;
        }
        this.poliza.estadoRepublicaSelected = this.emision.estadoId;
        this.poliza.codigoPostal = this.emision.codigoPostal;
        this.poliza.regimen =
          this.emision.tipoPersona == "Fisica" ||
          this.emision.tipoPersona == "Física"
            ? "Física"
            : this.emision.tipoPersona == "Moral"
            ? "Moral"
            : "";
        (this.poliza.razonSocial = this.emision.razonSocial
          ? this.emision.razonSocial
          : null),
          (this.poliza.notas = this.emision.notas);
        this.poliza.rfc = this.emision.rfc;
        this.poliza.canceladaFinalizadaMotivo = this.emision.motivoCancelacion;
        this.poliza.operadorId = this.emision.operadorId;
        this.poliza.certificadoBradescard = this.emision.certificadoBradescard;
        this.comercialAsignado = this.emision.comercialAsignado
          ? this.emision.comercialAsignado
          : null;
        let jsonFormat = JSON.parse(this.emision.requestJson);
        this.facturar = jsonFormat.facturar || false;
        this.poliza["regimen-fiscal"] = jsonFormat["regimen-fiscal"];
        this.poliza.cfdi = jsonFormat.cfdi;
        this.poliza.statusCivil = jsonFormat.estado_civil;
        this.poliza.calle = jsonFormat.calle;
        this.poliza.num_exterior = jsonFormat.num_exterior;
        this.poliza.num_interior = jsonFormat.num_interior;
        this.poliza.colonia = jsonFormat.colonia;
        this.poliza.municipio = jsonFormat.municipio;
        this.poliza.checkgetCertificado = jsonFormat.checkgetCertificado;
        this.identificador = this.emision.lead_id ? this.emision.lead_id : null;
        (this.tipo_contratante = jsonFormat.tipo_contratante
          ? jsonFormat.tipo_contratante
          : null),
          (this.poliza.años_construccion = jsonFormat.años_construccion
            ? jsonFormat.años_construccion
            : null);
        this.poliza.tipo_construccion = jsonFormat.tipo_construccion
          ? jsonFormat.tipo_construccion
          : null;
        this.poliza.num_pisos = jsonFormat.num_pisos
          ? jsonFormat.num_pisos
          : null;
        this.poliza.piso_vive = jsonFormat.piso_vive
          ? jsonFormat.piso_vive
          : null;
        this.dateFormattedInicioVigencia = this.emision.inicio
          ? moment(this.emision.inicio, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.dateFormattedFinVigencia = this.emision.fin
          ? moment(this.emision.fin, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.dateFormattedPrimerPago = this.emision.fechaPrimerPago
          ? moment(this.emision.fechaPrimerPago).format("YYYY-MM-DD")
          : null;
        this.dateFormattedFechaNacimiento = jsonFormat.fecha_nacimiento
          ? moment(jsonFormat.fecha_nacimiento, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.direccion = jsonFormat.direccion
          ? jsonFormat.direccion
          : null;
        this.poliza.edad = jsonFormat.edad ? jsonFormat.edad : null;
        this.poliza.tipo_cliente = jsonFormat.tipo_cliente
          ? jsonFormat.tipo_cliente
          : null;
        this.poliza.ramo = jsonFormat.ramo ? jsonFormat.ramo : null;
        this.poliza.operador_id = jsonFormat.operador_id
          ? jsonFormat.operador_id
          : null;
        if (!this.poliza.operador_id)
          this.poliza.operador_id = this.emision.operadorId
            ? this.emision.operadorId
            : null;
        this.poliza.polizaAseguradora = this.emision.polizaAseguradora
          ? this.emision.polizaAseguradora
          : null;
        this.poliza.validacionCodigo = this.emision.validacionCodigo
          ? this.emision.validacionCodigo
          : false;
        this.poliza.globalInteractionId = this.emision.globalInteractionId
          ? this.emision.globalInteractionId
          : "0000";
        //Campos recobery para endoso
        this.backpack.nombres = this.emision.nombre;
        this.backpack.apellidoPaterno = this.emision.apellidoPaterno;
        this.backpack.apellidoMaterno = this.emision.apellidoMaterno;
        this.backpack.fechaNacimiento = moment(
          this.emision.fechaNacimiento
        ).format("DD/MM/YYYY");
        this.backpack.rfc = this.emision.rfc;
        this.backpack.selectedGenero = this.emision.genero;
        this.backpack.celular = this.emision.telefonoContacto;
        this.backpack.correoElectronico = this.emision.correoContacto;
        this.backpack.calle = jsonFormat.calle;
        this.backpack.num_exterior = jsonFormat.num_exterior;
        this.backpack.num_interior = jsonFormat.num_interior;
        this.backpack.tipo_cliente = jsonFormat.tipo_cliente;
        this.backpack.edad = jsonFormat.edad ? jsonFormat.edad : null;
        this.backpack.regimen =
          this.emision.tipoPersona == "Fisica" ||
          this.emision.tipoPersona == "Física"
            ? "Física"
            : this.emision.tipoPersona == "Moral"
            ? "Moral"
            : "";

        //Termina recobery endoso
        //this.obtenerCliente(this.poliza.cliente);
        this.obtenerAgente(this.poliza.agente);
        this.LoadDetalleLead();
        this.loadBeneficiarios();
      });
    },
    /* fin montaje emisio */

    goBack() {
      return this.$router.go(-1);
    },

    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
          });
        });
      });
    },
    esDirecto() {
      this.agentes.map((element) => {
        let elemento = JSON.parse(JSON.stringify(element));
        let canalList = elemento.canalList;
        if (element.id == this.poliza.agente) {
          canalList.map((elm) => {
            if (elm.id == 3) {
              this.esAgentDirecto = true;
            } else {
              this.esAgentDirecto = false;
            }
          });
        }
      });
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .post(
          "/v1/producto/leadbradescard",
          {
            producto_lead: this.global_producto,
            producto_aseguradora: this.global_productoAseguradora,
          },
          config
        )
        .then((response) => {
          response.data.map((element) => {
            this.productos.push({
              nombre: element.nombre,
              id: element.id,
              ramo: element.ramo,
              aseguradoraId: element.aseguradoraId,
              primaNeta: element.primaNeta,
              primaTotal: element.total,
              primerPago: element.primerPago,
              Subsecuente: element.subsecuentePago,
              url_imagen: element.url_imagen,
              suma_asegurada: element.suma_asegurada,
              etiqueta_aseguradora: element.etiqueta_aseguradora,
            });
          });
          this.copia_productos_originales = this.productos;
          setTimeout(() => {
            this.poliza.tipo_contratante = this.tipo_contratante;
            this.getcurrentEtiquetaAseguradora();
          }, 300);

          console.log({ productos: this.productos });
        });
    },

    async loadBeneficiarios() {
      try {
        this.porcentajeBeneficiarios = 0;
        this.beneficiariosCount = 0;
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let { data } = await this.$axios.get(
          `v1/beneficiario/list/${this.poliza.id}`,
          config
        );
        this.beneficiarios = data;
        this.beneficiarios.forEach((item) => {
          this.porcentajeBeneficiarios += parseFloat(item.porcentaje);
        });
        this.beneficiariosCount = this.beneficiarios.length;
        if (
          this.porcentajeBeneficiarios >= 100 ||
          this.beneficiarios.length >= 5
        ) {
          this.canViewEmitir = false;
        }
      } catch (exception) {
        console.error(exception);
      }
    },

    obtenerCliente(cliente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/cliente/id/${cliente}`, config).then((resp) => {
        this.poliza.newCliente =
          `${resp.data.nombre}` +
          " " +
          `${resp.data.apellidoPaterno}` +
          " " +
          `${resp.data.apellidoMaterno}`;
      });
    },
    ObtenerDatosPagos(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      console.log({ productoSelected, productos: this.productos });
      this.poliza.primaNeta = productoSelected.primaNeta ?? "NA";
      this.poliza.primaTotal = productoSelected.primaTotal ?? "NA";
      this.poliza.montoPrimerPago = productoSelected.primerPago ?? "NA";
      this.poliza.montoSubsecuente = productoSelected.Subsecuente ?? "NA";
    },
    obtenerRamo(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.ramo = productoSelected ? productoSelected.ramo : null;
    },
    obtenerAseguradoraId(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.aseguradoraId = productoSelected
        ? productoSelected.aseguradoraId
        : null;
    },

    obtenerAgente(agente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/agente/id/${agente}`, config).then((response) => {
        // console.log("Agente: ", response.data)
        this.poliza.newAgente =
          `${response.data.nombre}` +
          " " +
          `${response.data.apellidoPaterno}` +
          " " +
          `${response.data.apellidoMaterno}`;
      });
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get("/v1/solicitud/agente/directo/list", config)
        .then((response) => {
          response.data.map((element) => {
            this.comercialAsignadoList.push({
              nombre: element.nombre,
              id: element.id,
              uuid: element.uuid,
            });
          });
        });
    },
    ObtenerSumaAsegurada(id) {
      let productoSelected = this.productos.find(
        (element) => element.id === id
      );
      this.poliza.aseguradoraId = productoSelected
        ? productoSelected.aseguradoraId
        : null;
    },

    async loadOperadores() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      const { data } = await this.$axios.get("/v1/agentes/operador", config);
      this.operadores = data;
    },

    construirEndosoDetalle() {
      var detalleEndoso = "";
      if (this.poliza.nombres != this.backpack.nombres) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el nombre de " +
          this.backpack.nombres +
          " a " +
          this.poliza.nombres +
          " ,";
      }
      if (this.poliza.apellidoPaterno != this.backpack.apellidoPaterno) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el apellido parterno de " +
          this.backpack.apellidoPaterno +
          " a " +
          this.poliza.apellidoPaterno +
          " ,";
      }
      if (this.poliza.apellidoMaterno != this.backpack.apellidoMaterno) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el apellido materno de " +
          this.backpack.apellidoMaterno +
          " a " +
          this.poliza.apellidoMaterno +
          " ,";
      }
      if (this.poliza.fechaNacimiento != this.backpack.fechaNacimiento) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio la fecha de nacimiento de " +
          this.backpack.fechaNacimiento +
          " a " +
          this.poliza.fechaNacimiento +
          " ,";
      }
      if (this.poliza.rfc != this.backpack.rfc) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el RFC de " +
          this.backpack.rfc +
          " a " +
          this.poliza.rfc +
          " ,";
      }
      if (this.poliza.selectedGenero != this.backpack.selectedGenero) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el genero de " +
          this.backpack.selectedGenero +
          " a " +
          this.poliza.selectedGenero +
          " ,";
      }
      if (this.poliza.celular != this.backpack.celular) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el telefono de " +
          this.backpack.celular +
          " a " +
          this.poliza.celular +
          " ,";
      }
      if (this.poliza.correoElectronico != this.backpack.correoElectronico) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el correo de " +
          this.backpack.correoElectronico +
          " a " +
          this.poliza.correoElectronico +
          " ,";
      }
      if (this.poliza.calle != this.backpack.calle) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio la calle de " +
          this.backpack.calle +
          " a " +
          this.poliza.calle +
          " ,";
      }
      if (this.poliza.num_exterior != this.backpack.num_exterior) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el numero exterior de " +
          this.backpack.num_exterior +
          " a " +
          this.poliza.num_exterior +
          " ,";
      }
      if (this.poliza.num_interior != this.backpack.num_interior) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el numero interior de " +
          this.backpack.num_interior +
          " a " +
          this.poliza.num_interior +
          " ,";
      }
      if (this.poliza.tipo_cliente != this.backpack.tipo_cliente) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el tipo de cliente de " +
          this.backpack.tipo_cliente +
          " a " +
          this.poliza.tipo_cliente +
          " ,";
      }
      if (this.poliza.edad != this.backpack.edad) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el tipo de cliente de " +
          this.backpack.edad +
          " a " +
          this.poliza.edad +
          " ,";
      }
      if (this.poliza.regimen != this.backpack.regimen) {
        detalleEndoso =
          detalleEndoso +
          " Se cambio el tipo de persona de " +
          this.backpack.regimen +
          " a " +
          this.poliza.regimen +
          " ,";
      }

      return detalleEndoso;
    },
    restoreEndosoData() {
      this.poliza.nombres = this.backpack.nombres;
      this.poliza.apellidoPaterno = this.backpack.apellidoPaterno;
      this.poliza.apellidoMaterno = this.backpack.apellidoMaterno;
      this.poliza.fechaNacimiento = this.backpack.fechaNacimiento;
      this.poliza.rfc = this.backpack.rfc;
      this.poliza.selectedGenero = this.backpack.selectedGenero;
      this.poliza.celular = this.backpack.celular;
      this.poliza.correoElectronico = this.backpack.correoElectronico;
      this.poliza.calle = this.backpack.calle;
      this.poliza.num_exterior = this.backpack.num_exterior;
      this.poliza.num_interior = this.backpack.num_interior;
      this.poliza.tipo_cliente = this.backpack.tipo_cliente;
      this.poliza.edad = this.backpack.edad;
      this.poliza.regimen = this.backpack.regimen;
    },

    actualizarPoliza() {
      if (
        this.poliza.canceladaCheck &&
        !this.debeMostrarCancelacionDialog &&
        !this.numeroSms
      ) {
        this.showConfirmacionDialog = true;
        return;
      }

      this.enviado = true;
      if (this.reenviarCertificado) {
        this.reenviarCertificadoMethod();
      }
      if (this.emision.aseguradoraId == 7 && this.poliza.sinCodigoCheck) {
        this.$toast.info(
          "Estamos emitiendo con la asegurado podria tardar un poco..",
          {
            position: "top-right",
            timeout: 10000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
      if (this.poliza.checkEndosoAplicado) {
        this.poliza.detalleEndoso = this.construirEndosoDetalle();
      }
      const modificarOperador = this.operadorId && this.poliza.status < 8;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      if (this.debeMostrarCancelacionDialog) {
        this.showCancelacionDialog = true;
        return;
      }

      let request = {
        id: this.poliza.id,
        comercial_asignado: this.comercialAsignado
          ? this.comercialAsignado
          : null,
        correo_operador: "",
        forma_pago: this.poliza.formaPago,
        fecha: this.poliza.creadoPorDate
          ? moment(this.poliza.creadoPorDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        poliza: this.poliza.poliza ? this.poliza.poliza : null,
        prima_neta: this.poliza.primaNeta,
        prima_total: this.poliza.primaTotal,
        primer_pago: this.poliza.montoPrimerPago,
        subsecuente_pago: this.poliza.montoSubsecuente,
        pago_id: this.poliza.periodicidad,
        aseguradora_id: this.poliza.aseguradoraId,
        estado_id: this.poliza.estadoRepublicaSelected,
        origen: "",
        anios: this.years,
        campania: this.poliza.landing,
        url: "",
        status: this.poliza.status,
        status_cobro: "",
        vehiculo: this.poliza.version,
        tipo_persona: this.poliza.regimen,
        razon_social: this.poliza.razonSocial,
        ...(this.poliza.status == 0 && { telefono: this.poliza.celular }),
        ...(this.poliza.status == 0 && {
          correo_electronico: this.poliza.correoElectronico,
        }),
        producto_id: this.poliza.productos,
        observaciones: this.poliza.observaciones,
        modificado_por: parseInt(this.poliza.ultimaModoficacion),
        nombre: this.poliza.nombres,
        apellido_paterno: this.poliza.apellidoPaterno,
        apellido_materno: this.poliza.apellidoMaterno,
        codigo_postal: this.poliza.codigoPostal,
        notas: this.poliza.notas,

        /* nuevos campos */
        tipo_poliza: this.poliza.type,
        ejecutivo_de_operaciones: this.poliza.ejecutivoOperacion,
        cliente_id: this.poliza.cliente ? this.poliza.cliente : null,
        cotizacion: this.poliza.leadId,
        poliza_anterior: this.anterior ? this.anterior : null,
        primer_pago_poliza: this.poliza.primerPagoCheck,
        checkCancelada: this.poliza.canceladaCheck
          ? this.poliza.canceladaCheck
          : false,
        fecha_cancelacion: this.poliza.canceladaDate
          ? moment(this.poliza.canceladaDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        motivoCancelacion: this.poliza.canceladaFinalizadaMotivo
          ? this.poliza.canceladaFinalizadaMotivo
          : null,
        checkPolizaFinalizada: this.poliza.finalizadaCheck
          ? this.poliza.finalizadaCheck
          : false,
        fecha_finalizacion: this.poliza.finalizadaDate
          ? moment(this.poliza.finalizadaDate, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        motivoFinalizacion: this.poliza.finalizadaMotivo,
        checkPolizaEmitida: this.poliza.emitidaCheck
          ? this.poliza.emitidaCheck
          : false,
        fecha_emision: this.poliza.emitidDate
          ? moment(this.poliza.emitidDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : null,
        ultima_modificacion: moment(new Date()).format("YYYY-MM-DD"),
        genero: this.poliza.selectedGenero,
        rfc: this.poliza.rfc ? this.poliza.rfc : null,
        checkGenerarCuotas: this.poliza.checkGenerarCuotas
          ? this.poliza.checkGenerarCuotas
          : false,
        checkReactivarPoliza: this.poliza.checkReactivarPoliza
          ? this.poliza.checkReactivarPoliza
          : false,
        checkPolizaRenovada: this.poliza.checkRenovada
          ? this.poliza.checkRenovada
          : false,
        agente_id: this.poliza.agente,
        checkgetCertificado: this.poliza.checkgetCertificado
          ? this.poliza.checkgetCertificado
          : false,
        calle: this.poliza.calle ? this.poliza.calle : null,
        num_exterior: this.poliza.num_exterior
          ? this.poliza.num_exterior
          : null,
        num_interior: this.poliza.num_interior
          ? this.poliza.num_interior
          : null,
        colonia: this.poliza.colonia ? this.poliza.colonia : null,
        municipio: this.poliza.municipio ? this.poliza.municipio : null,
        suma_asegurada: this.poliza.suma_asegurada
          ? this.poliza.suma_asegurada
          : null,
        tipo_contratante: this.poliza.tipo_contratante
          ? this.poliza.tipo_contratante
          : null,
        piso_vive: this.poliza.piso_vive ? this.poliza.piso_vive : null,
        num_pisos: this.poliza.num_pisos ? this.poliza.num_pisos : null,
        tipo_construccion: this.poliza.tipo_construccion
          ? this.poliza.tipo_construccion
          : null,
        años_construccion: this.poliza.años_construccion
          ? this.poliza.años_construccion
          : null,
        edad: this.poliza.edad ? this.poliza.edad : null,
        estado_civil: this.poliza.statusCivil ? this.poliza.statusCivil : null,
        direccion: this.poliza.direccion ? this.poliza.direccion : null,
        fecha_nacimiento: this.dateFormattedFechaNacimiento
          ? moment(this.dateFormattedFechaNacimiento, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        fin: this.dateFormattedFinVigencia
          ? moment(this.dateFormattedFinVigencia, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        inicio: this.dateFormattedInicioVigencia
          ? moment(this.dateFormattedInicioVigencia, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        lead_id: this.identificador,
        tipo_cliente: this.poliza.tipo_cliente
          ? this.poliza.tipo_cliente
          : null,
        fechaPrimerPago: this.dateFormattedPrimerPago
          ? moment(this.dateFormattedPrimerPago, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
        operador_id: this.poliza.operador_id
          ? this.poliza.operador_id
          : this.usuario_detalle.operador_id
          ? Number(this.usuario_detalle.operadorId)
          : null,
        clave_proveedor: "06",
        poliza_aseguradora: this.poliza.polizaAseguradora
          ? this.poliza.polizaAseguradora
          : null,
        sinCodigoCheck: this.poliza.sinCodigoCheck
          ? this.poliza.sinCodigoCheck
          : null,
        globalInteractionId: this.poliza.globalInteractionId
          ? this.poliza.globalInteractionId
          : "0000",
        inbound: this.isInbound,
        endoso: this.poliza.checkEndosoAplicado
          ? JSON.stringify({
              detalleEndoso: this.poliza.detalleEndoso,
              fechaEndoso: this.poliza.fechaEndoso,
              tipoEndoso: this.poliza.tipoEndoso,
              usuario: localStorage.agenteName,
            })
          : null,

        ...(modificarOperador && { operador_id: this.operadorId }),
        facturar: this.facturar,
        "regimen-fiscal": this.poliza["regimen-fiscal"],
        cfdi: this.poliza.cfdi,
        numeroSms: this.numeroSms || null,
      };
      this.actualStatus = request.status;
      switch (this.ramo) {
        case "AUTOS":
          request.plan_id = this.poliza.plan;
          (request.vehiculo = this.poliza.version),
            (request.modelo = this.poliza.modelo),
            (request.marca = this.poliza.marca),
            (request.submarca = this.poliza.submarca),
            (request.version = this.poliza.version),
            (request.detalle_auto = this.poliza.detallePoliza),
            (request.origen_auto = this.poliza.origenAuto),
            (request.uso_auto = this.poliza.usoAuto),
            (request.num_motor = this.poliza.noMotor),
            (request.num_serie = this.poliza.noSerie),
            (request.placas = this.poliza.placas);
          break;
        case "DAÑOS":
          (request.tipoInmueble = this.poliza.tipoInmueble),
            (request.valorInmueble = this.poliza.valorInmueble),
            (request.nivelDepartamento = this.poliza.nivelDepartamento),
            (request.pisosTotales = this.poliza.pisosTotales),
            (request.numeroPisosAltos = this.poliza.numeroPisosAltos),
            (request.sotanos = this.poliza.sotanos),
            (request.valorContenidos = this.poliza.valorContenidos),
            (request.valorEquipoElectronico = this.poliza.valorEquipoElectronico),
            (request.sumaAseguradaRC = this.poliza.sumaAseguradaRC),
            (request.sumaAseguradaCristales = this.poliza.sumaAseguradaCristales),
            (request.sumaAseguradaRoboViolencia = this.poliza.sumaAseguradaRoboViolencia),
            (request.sumaAseguradaDineroValores = this.poliza.sumaAseguradaDineroValores),
            (request.sumaAseguradaObrasDeArte = this.poliza.sumaAseguradaObrasDeArte);
          break;
        case "SALUD":
          request.nivelHospitalario = this.poliza.nivelHospitalario;
          request.sumaAsegurada = this.poliza.sumaAsegurada;
          request.deducible = this.poliza.deducible;
          request.coaseguro = this.poliza.coaseguro;
          request.ceroDeducible = this.poliza.ceroDeducible;
          request.medicinaFueraHospital = this.poliza.medicinaFueraHospital;
          request.emergenciaExtranjero = this.poliza.emergenciaExtranjero;
          request.enfermedadesCatastroficas = this.poliza.enfermedadesCatastroficas;
          request.coberturaExtranjero = this.poliza.coberturaExtranjero;
          request.preexistencias = this.poliza.preexistencias;
          request.proteccionDental = this.poliza.proteccionDental;
          request.maternidadExtendida = this.poliza.maternidadExtendida;
          request.coberturaNacional = this.poliza.coberturaNacional;
          request.reconocimientoAntiguedad = this.poliza.reconocimientoAntiguedad;
          request.fechasParaCotizar = this.poliza.fechasParaCotizar;
          request.dependientes = this.poliza.dependientes;
          break;
        case "VIDA":
          request.plan = this.poliza.plan;
          request.fumador = this.poliza.fumador;
          request.sumaAsegurada = this.poliza.sumaAsegurada;
          request.muerteAccidental = this.poliza.muerteAccidental;
          request.perdidasOrganicas = this.poliza.perdidasOrganicas;
          request.invalidezTotal = this.poliza.invalidezTotal;
          request.gastosFunerarios = this.poliza.gastosFunerarios;
          break;
        case "AHORRO":
          request.tipoPortafolios = this.poliza.tipoPortafolios;
          request.fumador = this.poliza.fumador;
          request.plazoComprometido = this.poliza.plazoComprometido;
          request.deducibilidad = this.poliza.deducibilidad;
          request.revaluableInflacion = this.poliza.revaluableInflacion;
          request.valorPlan = this.poliza.valorPlan;
          request.montoInversion = this.poliza.montoInversion;
          break;
        case "FIANZAS":
          request.contratoAdjunto = this.poliza.contratoAdjunto;
          break;
        default:
          break;
      }
      console.log("Primer request: ", request);
      this.$axios
        .post("/v1/emision/manual/actualizar", request, config)
        .then((value) => {
          if (this.emision.aseguradoraId == 7 && this.poliza.sinCodigoCheck) {
            this.$toast.success("Emitido con exito !", {
              position: "top-right",
              timeout: 2038,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
          if (this.poliza.checkCotizarWs) {
            this.cotizarWs(value.data);
          }

          this.enviandoPoliza = false;
          this.polizaEnviada = true;
          this.actualizadaExito = true;
          this.$axios
            .get("/v1/emision/id/" + this.uuid, config)
            .then((valueResponse) => {
              var statusActualizada = valueResponse.data.status;
              if (this.actualStatus != statusActualizada) {
                const configNota = {
                  headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    "Content-Type": "multipart/form-data",
                  },
                };
                var nombreEstatus =
                  statusActualizada == 0
                    ? "Nueva"
                    : statusActualizada == 1
                    ? "Emitida_Pendente_de_Pago"
                    : statusActualizada == 2
                    ? "Pagada_Parcial"
                    : statusActualizada == 3
                    ? "Pagada_Total"
                    : statusActualizada == 4
                    ? "Renovada"
                    : statusActualizada == 5
                    ? "Finalizada"
                    : statusActualizada == 6
                    ? "Cancelada"
                    : statusActualizada == 8
                    ? "Emitido_el_certificado"
                    : "otra";

                var Mensaje = "Estatus_cambio_a_" + nombreEstatus;
                var formData = new FormData();
                var descripcion = Mensaje;
                var tipoSelected = "Nota";
                var tipoDocumentoSelected = false;
                var nombre = "Cambio_de_estatus_de_poliza";

                formData.append("nota", descripcion ? descripcion : null);
                formData.append("tipo", tipoSelected ? tipoSelected : null);
                formData.append(
                  "tipo_documento",
                  tipoDocumentoSelected ? tipoDocumentoSelected : null
                );
                formData.append("nombre", nombre ? nombre : null);
                this.$axios
                  .post(
                    `/v1/emision/documento/upload/${this.poliza.id}`,
                    formData,
                    configNota
                  )
                  .then(
                    function(result) {
                      console.log(result);
                    },
                    function(error) {
                      console.log(error);
                    }
                  );

                if (nombreEstatus == "Emitida_Pendente_de_Pago") {
                  this.nuevas_fechas(
                    valueResponse.data.fechaEmision,
                    valueResponse.data.aseguradoraId
                  );
                }
              }
              if (
                this.poliza.sinCodigoCheck &&
                this.global_productoAseguradora != "Celular"
              ) {
                this.actualizarEmisionPoliza(valueResponse);
              }
            });

          setTimeout(() => {
            this.actualizadaExito = false;
            this.$router.push(`/emision/${this.uuid}`);
          }, 2000);
        });
    },
    nuevas_fechas(fechaEmision, aseguradoraId) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (aseguradoraId == "8") {
        var fecha_emision_moment8 = moment(fechaEmision, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        var newinicio8 = moment(fecha_emision_moment8, "DD/MM/YYYY")
          .add(1, "d")
          .format("DD/MM/YYYY");
        var newFin8 = moment(newinicio8, "DD/MM/YYYY")
          .add(1, "y")
          .format("DD/MM/YYYY");

        let requestFechas8 = {
          id: this.poliza.id,
          fin: newFin8
            ? moment(newFin8, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          inicio: newinicio8
            ? moment(newinicio8, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          agente_id: 707,
        };

        this.$axios
          .post("/v1/emision/manual/actualizar", requestFechas8, config)
          .then((value) => {
            console.log(value);
          });
      } else if (aseguradoraId == "7") {
        var fecha_emision_moment7 = moment(fechaEmision, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        var newinicio7 = moment(fecha_emision_moment7, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        );
        var newFin7 = moment(newinicio7, "DD/MM/YYYY")
          .add(1, "y")
          .format("DD/MM/YYYY");

        let requestFechas7 = {
          id: this.poliza.id,
          fin: newFin7
            ? moment(newFin7, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          inicio: newinicio7
            ? moment(newinicio7, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          agente_id: 707,
        };
        console.log("tercero request: ", requestFechas7);
        this.$axios
          .post("/v1/emision/manual/actualizar", requestFechas7, config)
          .then((value) => {
            console.log(value);
          });
      }
    },

    cargarLeadEmision() {
      this.global_producto = this.emision.detalleProducto.producto_lead;
      this.global_productoAseguradora = this.emision.detalleProducto.producto_aseguradora;
      this.obtenerProductos();
      return;
    },

    comportamientoBotonGuardar() {
      if (
        (this.poliza.emitidaCheck || this.poliza.checkGenerarCuotas) &&
        this.poliza.periodicidad != 1
      ) {
        return this.formIsValid;
      } else if (
        (this.poliza.emitidaCheck || this.poliza.checkGenerarCuotas) &&
        this.poliza.periodicidad == 1
      ) {
        return this.formIsValidForAnualPeriodity;
      } else {
        return true;
      }
    },
    updateCuotas() {
      if (this.poliza.checkGenerarCuotas != null) {
        if (this.poliza.checkGenerarCuotas == true) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.poliza.status > 0) return true;
      }
    },
    // Datos para formulario
    onResultados(datos) {
      switch (this.ramo) {
        case "AUTOS":
          this.poliza.modelo = datos.modelo;
          this.poliza.marca = datos.marca;
          this.poliza.submarca = datos.submarca;
          this.poliza.version = datos.version;
          this.poliza.vehiculo = datos.version;
          this.poliza.detallePoliza = datos.detallePoliza;
          this.poliza.plan = datos.plan;
          this.poliza.origenAuto = datos.origenAuto;
          this.poliza.usoAuto = datos.usoAuto;
          this.poliza.noMotor = datos.noMotor;
          this.poliza.noSerie = datos.noSerie;
          this.poliza.placas = datos.placas;
          break;
        case "DAÑOS":
          this.poliza.tipoInmueble = datos.tipoInmueble;
          this.poliza.valorInmueble = datos.valorInmueble;
          this.poliza.nivelDepartamento = datos.nivelDepartamento;
          this.poliza.pisosTotales = datos.pisosTotales;
          this.poliza.numeroPisosAltos = datos.numeroPisosAltos;
          this.poliza.sotanos = datos.sotanos;
          this.poliza.valorContenidos = datos.valorContenidos;
          this.poliza.valorEquipoElectronico = datos.valorEquipoElectronico;
          this.poliza.sumaAseguradaRC = datos.sumaAseguradaRC;
          this.poliza.sumaAseguradaCristales = datos.sumaAseguradaCristales;
          this.poliza.sumaAseguradaRoboViolencia =
            datos.sumaAseguradaRoboViolencia;
          this.poliza.sumaAseguradaDineroValores =
            datos.sumaAseguradaDineroValores;
          this.poliza.sumaAseguradaObrasDeArte = datos.sumaAseguradaObrasDeArte;
          break;
        case "SALUD":
          this.poliza.nivelHospitalario = datos.nivelHospitalario;
          this.poliza.sumaAsegurada = datos.sumaAsegurada;
          this.poliza.deducible = datos.deducible;
          this.poliza.coaseguro = datos.coaseguro;
          this.poliza.ceroDeducible = datos.ceroDeducible;
          this.poliza.medicinaFueraHospital = datos.medicinaFueraHospital;
          this.poliza.emergenciaExtranjero = datos.emergenciaExtranjero;
          this.poliza.enfermedadesCatastroficas =
            datos.enfermedadesCatastroficas;
          this.poliza.coberturaExtranjero = datos.coberturaExtranjero;
          this.poliza.preexistencias = datos.preexistencias;
          this.poliza.proteccionDental = datos.proteccionDental;
          this.poliza.maternidadExtendida = datos.maternidadExtendida;
          this.poliza.coberturaNacional = datos.coberturaNacional;
          this.poliza.reconocimientoAntiguedad = datos.reconocimientoAntiguedad;
          this.poliza.fechasParaCotizar = datos.comboFechasParaCotizar;
          this.poliza.dependientes = datos.dependientes;
          break;
        case "VIDA":
          this.poliza.plan = datos.plan;
          this.poliza.fumador = datos.fumador;
          this.poliza.sumaAsegurada = datos.sumaAsegurada;
          this.poliza.muerteAccidental = datos.muerteAccidental;
          this.poliza.perdidasOrganicas = datos.perdidasOrganicas;
          this.poliza.invalidezTotal = datos.invalidezTotal;
          this.poliza.gastosFunerarios = datos.gastosFunerarios;
          break;
        case "AHORRO":
          this.poliza.tipoPortafolios = datos.tipoPortafolios;
          this.poliza.fumador = datos.fumador;
          this.poliza.plazoComprometido = datos.plazoComprometido;
          this.poliza.deducibilidad = datos.deducibilidad;
          this.poliza.revaluableInflacion = datos.revaluableInflacion;
          this.poliza.valorPlan = datos.valorPlan;
          this.poliza.primaTotal = datos.valorPlan;
          this.poliza.montoInversion = datos.montoInversion;
          this.poliza.primaNeta = datos.montoInversion;
          this.poliza.montoPrimerPago = datos.montoInversion;
          this.poliza.montoSubsecuente = datos.montoInversion;
          break;
        case "FIANZAS":
          this.poliza.contratoAdjunto = datos.contratoAdjunto;
          break;

        default:
          break;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async LoadDetalleLead() {
      if (this.esAsistencias && !this.emision.lead_id) return;
      try {
        let { data } = await this.$axios.get(
          `v1/bradescard/id/${this.identificador}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        this.usuario_detalle = { ...data };
        this.global_producto = data.producto;
        this.global_productoAseguradora = data.productoAseguradora;
        console.log({
          gp: this.global_producto,
          gpa: this.global_productoAseguradora,
        });
        // this.obtenerProductos();
        console.log(data);
      } catch (exception) {
        console.error(exception);
      }
    },

    reenviarCertificadoMethod() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(
          "/v1/bradescard/poliza/reenviar/" + this.poliza.globalInteractionId,
          config
        )
        .then(() => {
          this.$toast.success("Certificado reenviado exitosamente");
        });
    },
    cotizarWs(request) {
      let route = "";
      if (
        this.currentEtiquetaAseguradora == 1 ||
        this.currentEtiquetaAseguradora == 2 ||
        this.currentEtiquetaAseguradora == 3
      ) {
        route = "v1/bradescard/cotiza/landing";
      } else if (this.currentEtiquetaAseguradora == 5) {
        route = "v1/aurrera/cotiza/landing";
      }
      this.$toast.info(
        "Estamos cotizando con la aseguradora espera un poco! ",
        {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }
      );
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let jsonResponse = JSON.parse(request.requestJson);
      let arrayNumeroExterior = jsonResponse.num_exterior.split("");
      let arrayNumeroInterior = jsonResponse.num_interior.split("");
      let abecedario = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "ñ",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "w",
        "x",
        "y",
        "z",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let numeroInteriorSingle = "";
      let numeroExteriorSingle = "";

      for (var i = 0; i < arrayNumeroInterior.length - 1; i++) {
        var isLetra = false;
        for (var j = 0; j < abecedario.length - 1; j++) {
          if (arrayNumeroInterior[i] == abecedario[j]) {
            isLetra = true;
          }
        }
        if (!isLetra)
          numeroInteriorSingle =
            numeroInteriorSingle + String(arrayNumeroInterior[i]);
      }
      for (var k = 0; k < arrayNumeroExterior.length - 1; k++) {
        var isLetra2 = false;
        for (var l = 0; l < abecedario.length - 1; l++) {
          if (arrayNumeroExterior[k] == abecedario[l]) {
            isLetra2 = true;
          }
        }
        if (!isLetra2)
          numeroExteriorSingle =
            numeroExteriorSingle + String(arrayNumeroExterior[k]);
      }
      jsonResponse.num_exterior =
        numeroExteriorSingle != "" ? numeroExteriorSingle : "1";
      jsonResponse.num_interior =
        numeroInteriorSingle != "" ? numeroInteriorSingle : "1";
      request.requestJson = JSON.stringify(jsonResponse);

      this.$axios.post(route, request, config).then((response) => {
        if (response.data.status == "FAILED") {
          this.$toast.error(
            "Ocurrio un error al cotizar: " + response.data.message
          );
        } else {
          if (this.global_productoAseguradora != "Celular")
            this.actualizarEmisionCotizacion(response.data);
        }
      });
    },
    actualizarEmisionCotizacion() {
      // Se quitó porque el proceso ya lo hace el back
      /*
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let request = {
        id: response.id,
        agente_id: 707,
        poliza: response.uuid_cotizacion,
        poliza_aseguradora: response.uuid_cotizacion,
      };

      var route = "v1/emision/manual/actualizar";

      this.$axios.post(route, request, config).then((response) => {
        if (response.data) {
          this.$toast.success("Cotizacion exitosa ");
          location.reload(true);
        }
      });
      */
    },
    getcurrentEtiquetaAseguradora() {
      if (this.productos.length > 0) {
        this.productos.forEach((e) => {
          if (e.id == this.poliza.productos) {
            this.currentEtiquetaAseguradora = e.etiqueta_aseguradora
              ? e.etiqueta_aseguradora
              : "No tiene ";
          }
        });
      }
    },
    actualizarEmisionPoliza() {
      this.$toast.success("Emision exitosa ");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + localStorage.agenteAccessToken,
      //   },
      // };
      // let request = {
      //   id: response.data.id,
      //   poliza: response.data.poliza,
      //   poliza_aseguradora: response.data.poliza,
      // };

      // var route = "v1/emision/manual/actualizar";

      // this.$axios.post(route, request, config).then((response) => {
      //   if (response.data) {
      //     this.$toast.success("Emision exitosa ");
      //   }
      // });
    },

    numeroSmsChanged(numeroSms) {
      this.numeroSms = numeroSms;
      this.showConfirmacionDialog = false;
      this.actualizarPoliza();
    },
  },
  computed: {
    openCFDI() {
      if (!this.poliza) return false;
      return this.poliza.regimen === "Física" && this.facturar;
    },

    formIsValid() {
      return (
        this.poliza.poliza != null &&
        this.poliza.poliza != undefined &&
        this.poliza.poliza != "" &&
        this.poliza.primaNeta != null &&
        this.poliza.primaNeta != undefined &&
        this.poliza.primaTotal != null &&
        this.poliza.primaTotal != undefined &&
        this.poliza.montoPrimerPago != null &&
        this.poliza.montoPrimerPago != 0 &&
        this.poliza.montoSubsecuente != null &&
        this.poliza.montoSubsecuente != 0 &&
        this.poliza.primerPagoDate != null &&
        this.poliza.primerPagoDate != ""
      );
    },
    formIsValidForAnualPeriodity() {
      return (
        this.poliza.poliza != null &&
        this.poliza.poliza != undefined &&
        this.poliza.poliza != "" &&
        this.poliza.primaNeta != null &&
        this.poliza.primaNeta != undefined &&
        this.poliza.primaTotal != null &&
        this.poliza.primaTotal != undefined &&
        this.poliza.montoPrimerPago != null &&
        this.poliza.montoPrimerPago != 0 &&
        this.poliza.primerPagoDate != null &&
        this.poliza.primerPagoDate != ""
      );
    },
    canEdit() {
      let canView = false;
      let roles = [];
      this.rolesToView.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "OPERACIONES",
        "OPERADOR",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentRol() {
      let roles = [];
      let current_log_rol = JSON.parse(localStorage.getItem("roles"));
      current_log_rol.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
    isInbound() {
      if (this.currentRol == "OPERACIONES" || this.currentRol == "OPERADOR") {
        if (this.poliza.status == 0 || this.poliza.status == 8) return true;
        else return false;
      } else {
        return false;
      }
    },
    canEmit() {
      if (
        this.poliza.status == 8 &&
        this.currentRol != "AGENTCC" &&
        this.poliza.validacionCodigo != true &&
        this.poliza.globalInteractionId != "0000"
      )
        return true;
      else if (
        this.poliza.status == 8 &&
        this.poliza.validacionCodigo == false &&
        this.poliza.id == this.poliza.globalInteractionId
      )
        return true;
      else if (
        this.poliza.status == 8 &&
        this.poliza.globalInteractionId == "0000" &&
        this.lead.operador_id == null
      )
        return true;
      else return false;
    },
    operadorId() {
      if (this.currentRol == "OPERADOR" || this.currentRol == "OPERACIONES") {
        if (this.operadores.length > 0) {
          var currentOperador = this.operadores.find(
            (e) => e.uuid == localStorage.agenteUuid
          );
          return currentOperador != -1 ? currentOperador.id : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    esOperaciones() {
      return this.currentRol == "OPERACIONES" || this.currentRol == "OPERADOR";
    },
    puedeVerCheckSinCodigo() {
      return (
        this.currentRol == "OPERACIONES" ||
        this.currentRol == "OPERADOR" ||
        this.currentRol == "ADMIN" ||
        this.currentRol == "ADMINCC" ||
        this.currentRol == "MANAGERCC"
      );
    },
    showCertificadoExcepcion() {
      return (
        this.currentRol == "ADMIN" &&
        this.poliza.status == 1 &&
        !this.poliza.certificadoBradescard
      );
    },
    puedeGuardarCancelacion() {
      if (this.poliza.canceladaCheck) {
        if (this.poliza.canceladaFinalizadaMotivo != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isRFCendoso() {
      if (
        this.poliza.checkEndosoAplicado &&
        (this.poliza.tipoEndoso == "Corrección de RFC" ||
          this.poliza.tipoEndoso == "Corrección de datos del contratante")
      ) {
        return false;
      } else {
        return true;
      }
    },
    isContratanteEndoso() {
      if (
        this.poliza.checkEndosoAplicado &&
        this.poliza.tipoEndoso == "Corrección de datos del contratante"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isDireccionEndoso() {
      if (
        this.poliza.checkEndosoAplicado &&
        this.poliza.tipoEndoso == "Corrección de dirección del contratante"
      ) {
        return false;
      } else {
        return true;
      }
    },
    motivosCancelacionFiltrados() {
      let motivos = [];
      motivos = this.cancelacionFinalizacionList;
      if (
        (this.poliza.status == 1 ||
          this.poliza.status == 2 ||
          this.poliza.status == 6) &&
        !!["ADMIN", "OPERACIONES", "OPERADOR"].find(
          (e) => JSON.parse(localStorage.roles)[0].rol == e
        )
      )
        motivos = [
          { id: 19, name: "Cambio de plan" },
          { id: 20, name: "Cambio de contratante" },
          { id: 21, name: "Cambio de plan y contratante" },
          ...(this.usuario_detalle.productoAseguradora == "Hogar"
            ? [{ id: 22, name: "Cambio de dirección" }]
            : []),
          ...this.cancelacionFinalizacionList,
        ];

      const esRamoAsistencias = this.getCurrentProduct.ramo == "ASISTENCIAS";
      if (esRamoAsistencias) {
        motivos.push(
          {
            id: 23,
            name: "Cancelación de Asistencia Redimida",
          },
          {
            id: 24,
            name: "Cancelación de Asistencia No Redimida",
          }
        );
      }

      return motivos;
    },
    debeMostrarCancelacionDialog() {
      if (this.poliza.canceladaCheck)
        return !![
          { id: 19, name: "Cambio de plan" },
          { id: 20, name: "Cambio de contratante" },
          { id: 21, name: "Cambio de plan y contratante" },
          ...(this.usuario_detalle.productoAseguradora == "Hogar"
            ? [{ id: 22, name: "Cambio de dirección" }]
            : []),
        ].find((e) => e.name == this.poliza.canceladaFinalizadaMotivo);
      return false;
    },
    blockEdit() {
      if (
        this.poliza.status == 1 ||
        this.poliza.status == 2 ||
        this.poliza.status == 3 ||
        this.poliza.status == 8
      ) {
        return true;
      } else {
        return false;
      }
    },

    getCurrentProduct() {
      const producto = this.productos.find(
        (element) => element.id == this.poliza.productos
      );
      return producto;
    },

    esAsistencias() {
      return this.emision && this.emision.asistencias;
    },
  },

  mounted() {
    this.years = 12;
    this.cargarEmision();
    this.obtenerAgentes();
    this.obtenerUsersCanalDirecto();
    this.loadOperadores();
  },
};
</script>
