<template>
  <div>
    <!-- HEAD TITLE -->
    <v-row style="margin-top: 10px">
      <v-col cols="6">
        <h2>Solicitudes</h2>
      </v-col>

      <v-col cols="6">
        <v-btn @click="goBack()" elevation="2" style="margin-left: 527px">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper v-model="stepper" non-linear>
                <v-stepper-header>
                  <v-stepper-step step="1" editable>
                    Datos de la Solicitud
                  </v-stepper-step>

                  <v-stepper-step step="2" editable>
                    Seguimiento de la Solicitud
                  </v-stepper-step>

                  <v-stepper-step step="3" editable>
                    SLA de la Solicitud
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">
                    <v-row style="padding: 20px">
                      <v-col cols="4">
                        <v-select label="Origen de la solicitud" :items="origenSolicitud"
                          v-model="origenSolicitudSelected" :readonly="deshabilitarInputs()" :disabled="!canEdit">
                        </v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Status Solicitud" :items="estado" v-model="estadoSelected"
                          :readonly="deshabilitarInputs()" :disabled="roles != 'ADMIN'"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete label="Agente" :items="agentes" item-text="nombre" item-value="id"
                          v-model="agente" placeholder="Requerido" :rules="inputsRequeridos"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit" @change="esDirecto()"></v-autocomplete>
                      </v-col>
                      <v-col cols="4" v-if="esAgentDirecto">
                        <v-autocomplete label="Comercial Asignado" :items="comercialAsignadoList" item-text="nombre"
                          item-value="id" v-model="comercialAsignado" :readonly="deshabilitarInputs()"
                          placeholder="Requerido" :rules="inputsRequeridos"></v-autocomplete>
                      </v-col>
                      <!-- End 1st Col -->

                      <v-col cols="4">
                        <v-select label="Tipo" :items="tipo" v-model="tipoSelected" placeholder="Requerido"
                          :rules="inputsRequeridos && tipoNoGeneral" :readonly="deshabilitarInputs()"
                          :disabled="!canEdit"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Ramo" :items="categoria" v-model="categoriaSelected" placeholder="Requerido"
                          :rules="inputsRequeridos" :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete label="Productos" :items="productos" v-model="productoSelected"
                          item-text="nombre" item-value="id" placeholder="Requerido" :rules="inputsRequeridos"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-autocomplete>
                      </v-col>
                      <!-- End 2nd Col -->

                      <div>
                        <v-row style="padding: 20px">
                          <v-col cols="4" v-if="estadoSelected == 'Pendiente de Aprobacion'">
                            <v-checkbox label="Cotización / Renovación Aprobada"
                              v-model="checkCotizacionRenovacionAprobada" :readonly="deshabilitarInputs()"></v-checkbox>
                          </v-col>
                          <v-col cols="4"
                            v-if="(checkCotizacionRenovacionAprobada == false) && (estadoSelected == 'Pendiente de Aprobacion')">
                            <v-checkbox label="Cotización / Renovación Rechazada"
                              v-model="checkCotizacionRenovacionRechazada" :readonly="deshabilitarInputs()">
                            </v-checkbox>
                          </v-col>
                          <v-col cols="4"
                            v-if="(checkCotizacionRenovacionAprobada == false) && (estadoSelected == 'Pendiente de Aprobacion')">
                            <v-checkbox label="Recotizar" v-model="checkRecotizar" :readonly="deshabilitarInputs()">
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" v-if="checkCotizacionRenovacionRechazada || checkRecotizar">
                            <v-text-field label="Motivo de Recotización o Rechazo" v-model="motivoRecotizacionRechazo"
                              :rules="inputsRequeridos"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>

                      <v-col cols="12">
                        <v-textarea label="Detalle Solicitud" v-model="detalle" rows="1" auto-grow
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-textarea>
                      </v-col>
                      <v-col cols="4">
                        <v-select :items="regimenList" v-model="regimenSelected" label="Tipo de Persona"
                          placeholder="Requerido" :rules="inputsRequeridos" :readonly="deshabilitarInputs()"
                          :disabled="!canEdit"></v-select>
                      </v-col>
                      <!-- End 3rd Col -->

                      <v-col cols="4" v-if="regimenSelected == 'Física'">
                        <v-text-field label="Nombre(s)" v-model="nombres" placeholder="Requerido"
                          :rules="inputsRequeridos" :readonly="deshabilitarInputs()" :disabled="!canEdit">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimenSelected == 'Física'">
                        <v-text-field label="Apellido Paterno" v-model="apellidoPaterno" placeholder="Requerido"
                          :rules="inputsRequeridos" :readonly="deshabilitarInputs()" :disabled="!canEdit">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimenSelected == 'Física'">
                        <v-text-field label="Apellido Materno" v-model="apellidoMaterno"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <!-- End 4th Col -->

                      <v-col cols="4" v-if="mostrarInputs() && regimenSelected == 'Física'">
                        <v-select :items="estadoCivil" v-model="estadoCivilSelected" label="Estado Civil"
                          placeholder="Requerido" :rules="inputsRequeridos" :disabled="!canEdit"></v-select>
                      </v-col>
                      <v-col cols="4" v-if="regimenSelected != ''">
                        <v-select placeholder="Requerido" :items="estados" item-text="estadoRepublica"
                          item-value="estadoRepublica" v-model="estadoRepublicaSelected" label="Estado de la República"
                          :rules="inputsRequeridos" :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-select>
                      </v-col>
                      <v-col cols="4" v-if="mostrarInputs() && regimenSelected == 'Física'">
                        <v-select :items="genero" v-model="selectedGenero" label="Género" placeholder="Requerido"
                          :rules="inputsRequeridos" :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-select>
                      </v-col>
                      <!-- End 5th Col -->

                      <v-col cols="4" v-if="mostrarInputs() && regimenSelected == 'Física'">
                        <v-text-field v-model="fechaNacimiento" label="Fecha de Nacimiento" prepend-icon="mdi-calendar"
                          placeholder="Requerido (DD/MM/AAAA)" v-mask="'##/##/####'" :rules="inputsRequeridos"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimenSelected == 'Moral'">
                        <v-text-field label="Razón Social" v-model="razonSocial" :rules="inputsRequeridos"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="RFC" placeholder="Requerido" v-model="rfc" :rules="rfcRules"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>

                      <!-- End 6th Col -->

                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-text-field placeholder="(Requerido)##########" v-mask="'##########'" v-model="celular"
                          label="Teléfono" :rules="inputsRequeridos" :readonly="deshabilitarInputs()"
                          :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-text-field v-model="correoElectronico" label="Correo Electrónico" :rules="emailRules"
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-text-field label="Código Postal" v-model="codigoPostal" placeholder="(Requerido)#####"
                          v-mask="'#####'" :rules="inputsRequeridos" :readonly="deshabilitarInputs()"
                          :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <!-- End 7th Col -->
                      <v-col cols="8" v-if="mostrarInputs()">
                        <v-textarea placeholder="(Requerido)" label="Dirección" v-model="direccion"
                          :rules="inputsRequeridos" rows="1" auto-grow :disabled="!canEdit"></v-textarea>
                      </v-col>
                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-text-field v-model="inicioVigencia" label="Inicio de Vigencia" prepend-icon="mdi-calendar"
                          placeholder="Requerido (DD/MM/AAAA)" :rules="inputsRequeridos" v-mask="'##/##/####'" clearable
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <!-- End 8th Col -->

                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-text-field v-model="finVigencia" label="Fin de Vigencia" prepend-icon="mdi-calendar"
                          placeholder="Requerido (DD/MM/AAAA)" :rules="inputsRequeridos" v-mask="'##/##/####'" clearable
                          :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-select :items="periodicidadItems" v-model="periodicidadSelected" label="Periodicidad"
                          :rules="inputsRequeridos" :readonly="deshabilitarInputs()" :disabled="!canEdit"></v-select>
                      </v-col>
                      <v-col cols="4" v-if="mostrarInputs()">
                        <v-select :items="formaPago" v-model="formaPagoSelected" label="Forma de Pago"
                          placeholder="Requerido" :rules="inputsRequeridos" :readonly="deshabilitarInputs()"
                          :disabled="!canEdit"></v-select>
                      </v-col>
                      <v-col cols="4" v-if="polizaRenovar">
                        <v-text-field label="No. de Póliza a renovar" v-model="polizaRenovar"
                          @click="verPolizaRelacionada()" class="enlacesForms" readonly :disabled="!canEdit">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="polizaRenovar">
                        <v-text-field label="Fecha Inicio de Vigencia a renovar" v-model="fechaInicioVigenciaRenovar"
                          readonly prepend-icon="mdi-calendar" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="polizaRenovar">
                        <v-text-field label="Fecha Fin de Vigencia a renovar" v-model="fechaFinVigenciaRenovar" readonly
                          prepend-icon="mdi-calendar" :disabled="!canEdit"></v-text-field>
                      </v-col>
                      <!-- End 9th Col -->

                      <!-- Components -->
                      <div v-if="(categoriaSelected == 'AUTOS') && (mostrarInputs())">
                        <RamoAutosComponentUpdate :origenSolicitud="origenSolicitudSelected"
                          :statusSolicitud="estadoSelected" :agente="agente" :tipo="tipoSelected"
                          :ramo="categoriaSelected" :producto="productoSelected" :detalleSolicitud="detalle"
                          :nombres="nombres" :apellidoPaterno="apellidoPaterno" :apellidoMaterno="apellidoMaterno"
                          :estadoCivil="estadoCivilSelected" :estadoNacimiento="estadoRepublicaSelected"
                          :genero="selectedGenero" :fechaNacimiento="fechaNacimiento" :rfc="rfc"
                          :tipoPersona="regimenSelected" :telefono="celular" :correo="correoElectronico"
                          :codigoPostal="codigoPostal" :direccion="direccion" :inicioVigencia="inicioVigencia"
                          :finVigencia="finVigencia" :periodicidad="periodicidadSelected" :formaPago="formaPagoSelected"
                          :operador="ejecutivo" :solicitudCompleta="solicitudCompletaSelected"
                          :procesoCotizacion="procesoCotizacionSelected" :solicitudId="solicitudId"
                          :bodyAutos="bodySolicitud" :checkTramite="checkTramite"
                          :checkCotizacionRenovacionTerminada="checkCotizacionRenovacionTerminada"
                          :checkRecotizar="checkRecotizar"
                          :checkCotizacionRenovacionAprobada="checkCotizacionRenovacionAprobada"
                          :checkCotizacionRenovacionRechazada="checkCotizacionRenovacionRechazada"
                          :checkPendienteInfo="checkPendienteInfo" :checkEmisionFinalizada="checkEmisionFinalizada"
                          :checkFinalizada="checkFinalizada" :checkCancelacion="checkCancelacion"
                          :motivoCancelacion="motivoCancelacion" :motivoRecotizacionRechazo="motivoRecotizacionRechazo"
                          :causaPendienteInformacion="causaPendienteInformacion" :valorOportunidad="valorOportunidad"
                          :documentos="documentos" :comercialAsignado="comercialAsignado" :creadoPor="creadoPor"
                          :fechaCreacion="fechaCreacion" :modificadoPor="modificadoPor"
                          :fechaModificacion="fechaModificacion" :aseguradoraId="aseguradoraId"
                          :razonSocial="razonSocial" />
                      </div>
                      <div v-if="(categoriaSelected == 'DAÑOS') && (mostrarInputs())">
                        <RamoDaniosComponentUpdate :origenSolicitud="origenSolicitudSelected"
                          :statusSolicitud="estadoSelected" :agente="agente" :tipo="tipoSelected"
                          :ramo="categoriaSelected" :producto="productoSelected" :detalleSolicitud="detalle"
                          :nombres="nombres" :apellidoPaterno="apellidoPaterno" :apellidoMaterno="apellidoMaterno"
                          :estadoCivil="estadoCivilSelected" :estadoNacimiento="estadoRepublicaSelected"
                          :genero="selectedGenero" :fechaNacimiento="fechaNacimiento" :rfc="rfc"
                          :tipoPersona="regimenSelected" :telefono="celular" :correo="correoElectronico"
                          :codigoPostal="codigoPostal" :direccion="direccion" :inicioVigencia="inicioVigencia"
                          :finVigencia="finVigencia" :periodicidad="periodicidadSelected" :formaPago="formaPagoSelected"
                          :operador="ejecutivo" :solicitudCompleta="solicitudCompletaSelected"
                          :procesoCotizacion="procesoCotizacionSelected" :solicitudId="solicitudId"
                          :bodyDanios="bodySolicitud" :checkTramite="checkTramite"
                          :checkCotizacionRenovacionTerminada="checkCotizacionRenovacionTerminada"
                          :checkRecotizar="checkRecotizar"
                          :checkCotizacionRenovacionAprobada="checkCotizacionRenovacionAprobada"
                          :checkCotizacionRenovacionRechazada="checkCotizacionRenovacionRechazada"
                          :checkPendienteInfo="checkPendienteInfo" :checkEmisionFinalizada="checkEmisionFinalizada"
                          :checkFinalizada="checkFinalizada" :checkCancelacion="checkCancelacion"
                          :motivoCancelacion="motivoCancelacion" :motivoRecotizacionRechazo="motivoRecotizacionRechazo"
                          :causaPendienteInformacion="causaPendienteInformacion" :valorOportunidad="valorOportunidad"
                          :documentos="documentos" :comercialAsignado="comercialAsignado" :creadoPor="creadoPor"
                          :fechaCreacion="fechaCreacion" :modificadoPor="modificadoPor"
                          :fechaModificacion="fechaModificacion" :aseguradoraId="aseguradoraId"
                          :razonSocial="razonSocial" />
                      </div>
                      <div v-if="(categoriaSelected == 'SALUD') && (mostrarInputs())">
                        <RamoSaludComponentUpdate :origenSolicitud="origenSolicitudSelected"
                          :statusSolicitud="estadoSelected" :agente="agente" :tipo="tipoSelected"
                          :ramo="categoriaSelected" :producto="productoSelected" :detalleSolicitud="detalle"
                          :nombres="nombres" :apellidoPaterno="apellidoPaterno" :apellidoMaterno="apellidoMaterno"
                          :estadoCivil="estadoCivilSelected" :estadoNacimiento="estadoRepublicaSelected"
                          :genero="selectedGenero" :fechaNacimiento="fechaNacimiento" :rfc="rfc"
                          :tipoPersona="regimenSelected" :telefono="celular" :correo="correoElectronico"
                          :codigoPostal="codigoPostal" :direccion="direccion" :inicioVigencia="inicioVigencia"
                          :finVigencia="finVigencia" :periodicidad="periodicidadSelected" :formaPago="formaPagoSelected"
                          :operador="ejecutivo" :solicitudCompleta="solicitudCompletaSelected"
                          :procesoCotizacion="procesoCotizacionSelected" :solicitudId="solicitudId"
                          :bodySalud="bodySolicitud" :checkTramite="checkTramite"
                          :checkCotizacionRenovacionTerminada="checkCotizacionRenovacionTerminada"
                          :checkRecotizar="checkRecotizar"
                          :checkCotizacionRenovacionAprobada="checkCotizacionRenovacionAprobada"
                          :checkCotizacionRenovacionRechazada="checkCotizacionRenovacionRechazada"
                          :checkPendienteInfo="checkPendienteInfo" :checkEmisionFinalizada="checkEmisionFinalizada"
                          :checkFinalizada="checkFinalizada" :checkCancelacion="checkCancelacion"
                          :motivoCancelacion="motivoCancelacion" :motivoRecotizacionRechazo="motivoRecotizacionRechazo"
                          :causaPendienteInformacion="causaPendienteInformacion" :valorOportunidad="valorOportunidad"
                          :documentos="documentos" :comercialAsignado="comercialAsignado" :creadoPor="creadoPor"
                          :fechaCreacion="fechaCreacion" :modificadoPor="modificadoPor"
                          :fechaModificacion="fechaModificacion" :aseguradoraId="aseguradoraId"
                          :razonSocial="razonSocial" />
                      </div>
                      <div v-if="(categoriaSelected == 'VIDA') && (mostrarInputs())">
                        <RamoVidaComponentUpdate :origenSolicitud="origenSolicitudSelected"
                          :statusSolicitud="estadoSelected" :agente="agente" :tipo="tipoSelected"
                          :ramo="categoriaSelected" :producto="productoSelected" :detalleSolicitud="detalle"
                          :nombres="nombres" :apellidoPaterno="apellidoPaterno" :apellidoMaterno="apellidoMaterno"
                          :estadoCivil="estadoCivilSelected" :estadoNacimiento="estadoRepublicaSelected"
                          :genero="selectedGenero" :fechaNacimiento="fechaNacimiento" :rfc="rfc"
                          :tipoPersona="regimenSelected" :telefono="celular" :correo="correoElectronico"
                          :codigoPostal="codigoPostal" :direccion="direccion" :inicioVigencia="inicioVigencia"
                          :finVigencia="finVigencia" :periodicidad="periodicidadSelected" :formaPago="formaPagoSelected"
                          :operador="ejecutivo" :solicitudCompleta="solicitudCompletaSelected"
                          :procesoCotizacion="procesoCotizacionSelected" :solicitudId="solicitudId"
                          :bodyVida="bodySolicitud" :checkTramite="checkTramite"
                          :checkCotizacionRenovacionTerminada="checkCotizacionRenovacionTerminada"
                          :checkRecotizar="checkRecotizar"
                          :checkCotizacionRenovacionAprobada="checkCotizacionRenovacionAprobada"
                          :checkCotizacionRenovacionRechazada="checkCotizacionRenovacionRechazada"
                          :checkPendienteInfo="checkPendienteInfo" :checkEmisionFinalizada="checkEmisionFinalizada"
                          :checkFinalizada="checkFinalizada" :checkCancelacion="checkCancelacion"
                          :motivoCancelacion="motivoCancelacion" :motivoRecotizacionRechazo="motivoRecotizacionRechazo"
                          :causaPendienteInformacion="causaPendienteInformacion" :valorOportunidad="valorOportunidad"
                          :documentos="documentos" :comercialAsignado="comercialAsignado" :creadoPor="creadoPor"
                          :fechaCreacion="fechaCreacion" :modificadoPor="modificadoPor"
                          :fechaModificacion="fechaModificacion" :aseguradoraId="aseguradoraId"
                          :razonSocial="razonSocial" />
                      </div>
                      <div v-if="(categoriaSelected == 'AHORRO') && (mostrarInputs())">
                        <RamoAhorroComponentUpdate :origenSolicitud="origenSolicitudSelected"
                          :statusSolicitud="estadoSelected" :agente="agente" :tipo="tipoSelected"
                          :ramo="categoriaSelected" :producto="productoSelected" :detalleSolicitud="detalle"
                          :nombres="nombres" :apellidoPaterno="apellidoPaterno" :apellidoMaterno="apellidoMaterno"
                          :estadoCivil="estadoCivilSelected" :estadoNacimiento="estadoRepublicaSelected"
                          :genero="selectedGenero" :fechaNacimiento="fechaNacimiento" :rfc="rfc"
                          :tipoPersona="regimenSelected" :telefono="celular" :correo="correoElectronico"
                          :codigoPostal="codigoPostal" :direccion="direccion" :inicioVigencia="inicioVigencia"
                          :finVigencia="finVigencia" :periodicidad="periodicidadSelected" :formaPago="formaPagoSelected"
                          :operador="ejecutivo" :solicitudCompleta="solicitudCompletaSelected"
                          :procesoCotizacion="procesoCotizacionSelected" :solicitudId="solicitudId"
                          :bodyAhorro="bodySolicitud" :checkTramite="checkTramite"
                          :checkCotizacionRenovacionTerminada="checkCotizacionRenovacionTerminada"
                          :checkRecotizar="checkRecotizar"
                          :checkCotizacionRenovacionAprobada="checkCotizacionRenovacionAprobada"
                          :checkCotizacionRenovacionRechazada="checkCotizacionRenovacionRechazada"
                          :checkPendienteInfo="checkPendienteInfo" :checkEmisionFinalizada="checkEmisionFinalizada"
                          :checkFinalizada="checkFinalizada" :checkCancelacion="checkCancelacion"
                          :motivoCancelacion="motivoCancelacion" :motivoRecotizacionRechazo="motivoRecotizacionRechazo"
                          :causaPendienteInformacion="causaPendienteInformacion" :valorOportunidad="valorOportunidad"
                          :documentos="documentos" :comercialAsignado="comercialAsignado" :creadoPor="creadoPor"
                          :fechaCreacion="fechaCreacion" :modificadoPor="modificadoPor"
                          :fechaModificacion="fechaModificacion" :aseguradoraId="aseguradoraId"
                          :razonSocial="razonSocial" />
                      </div>
                      <div v-if="(categoriaSelected == 'FIANZA') && (mostrarInputs())">
                        <RamoFianzasComponentUpdate :origenSolicitud="origenSolicitudSelected"
                          :statusSolicitud="estadoSelected" :agente="agente" :tipo="tipoSelected"
                          :ramo="categoriaSelected" :producto="productoSelected" :detalleSolicitud="detalle"
                          :nombres="nombres" :apellidoPaterno="apellidoPaterno" :apellidoMaterno="apellidoMaterno"
                          :estadoCivil="estadoCivilSelected" :estadoNacimiento="estadoRepublicaSelected"
                          :genero="selectedGenero" :fechaNacimiento="fechaNacimiento" :rfc="rfc"
                          :tipoPersona="regimenSelected" :telefono="celular" :correo="correoElectronico"
                          :codigoPostal="codigoPostal" :direccion="direccion" :inicioVigencia="inicioVigencia"
                          :finVigencia="finVigencia" :periodicidad="periodicidadSelected" :formaPago="formaPagoSelected"
                          :operador="ejecutivo" :solicitudCompleta="solicitudCompletaSelected"
                          :procesoCotizacion="procesoCotizacionSelected" :solicitudId="solicitudId"
                          :bodyFianzas="bodySolicitud" :checkTramite="checkTramite"
                          :checkCotizacionRenovacionTerminada="checkCotizacionRenovacionTerminada"
                          :checkRecotizar="checkRecotizar"
                          :checkCotizacionRenovacionAprobada="checkCotizacionRenovacionAprobada"
                          :checkCotizacionRenovacionRechazada="checkCotizacionRenovacionRechazada"
                          :checkPendienteInfo="checkPendienteInfo" :checkEmisionFinalizada="checkEmisionFinalizada"
                          :checkFinalizada="checkFinalizada" :checkCancelacion="checkCancelacion"
                          :motivoCancelacion="motivoCancelacion" :motivoRecotizacionRechazo="motivoRecotizacionRechazo"
                          :causaPendienteInformacion="causaPendienteInformacion" :valorOportunidad="valorOportunidad"
                          :documentos="documentos" :comercialAsignado="comercialAsignado" :creadoPor="creadoPor"
                          :fechaCreacion="fechaCreacion" :modificadoPor="modificadoPor"
                          :fechaModificacion="fechaModificacion" :aseguradoraId="aseguradoraId"
                          :razonSocial="razonSocial" />
                      </div>
                    </v-row>
                    <v-btn color="primary" class="mt-5" @click="sendSolicitud()" :disabled="!formIsValid3"
                      v-if="tipoSelected != 'Cotización' && tipoSelected != 'Emisión' && tipoSelected != 'Renovación'">
                      Guardar
                    </v-btn>
                    <v-btn color="primary" style="margin-left: 15px" @click="stepper = 2" class="mt-5"
                      v-if="tipoSelected != 'Cotización' && tipoSelected != 'Emisión' && tipoSelected != 'Renovación'"
                      v-show="rolToCompare != 'AGENT'">
                      Siguiente
                    </v-btn>
                    <v-btn text @click="cancelarSolicitud()" v-if="mostrarSolicitudDuplicada()" class="mt-5">
                      Solicitud Duplicada
                    </v-btn>
                  </v-stepper-content>

                  <!-- 2ND ITEM (2ND FORM) -->
                  <v-stepper-content step="2">
                    <v-row style="padding: 20px; margin-top: -35px">
                      <v-col cols="6">
                        <v-autocomplete :items="ejecutivos" label="Ejecutivo" v-model="ejecutivo" item-text="nombre"
                          item-value="id"></v-autocomplete>
                      </v-col>
                      <v-col cols="6" v-if="mostrarInputs()">
                        <v-select label="Proceso de Cotización" :items="procesoCotizacion"
                          v-model="procesoCotizacionSelected"></v-select>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-select label="¿Solicitud Completa?" :items="solicitudCompleta"
                          v-model="solicitudCompletaSelected"></v-select>
                      </v-col>

                      <v-col v-if="checkCotizacionRenovacionTerminada || valorOportunidad">
                        <v-text-field label="Valor de la Oportunidad" prefix="$" v-model="valorOportunidad"
                          type="number" :rules="inputsRequeridos"></v-text-field>
                      </v-col>

                      <v-col cols="6" v-if="estadoSelected == 'En tramite' && checkCotizacionRenovacionTerminada">
                        <template>
                          <v-file-input v-model="documentos" color="primary" counter multiple
                            placeholder="Selecciona tus archivos" prepend-icon="mdi-paperclip" :show-size="1000">
                            <template v-slot:selection="{ index, text }">
                              <v-chip v-if="index < 2" color="primary" dark label small>
                                {{ text }}
                              </v-chip>

                              <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </template>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="4" v-if="visualizarEnTramite">
                        <v-checkbox label="En Trámite" v-model="checkTramite"></v-checkbox>
                      </v-col>
                      <!-- @change="changeTramite()" -->

                      <v-col cols="4"
                        v-if="(estadoSelected == 'En tramite' && checkCotizacionRenovacionAprobada == false && mostrarInputs())">
                        <v-checkbox label="Cotización / Renovación Terminada"
                          v-model="checkCotizacionRenovacionTerminada"></v-checkbox>
                      </v-col>
                      <v-col cols="4" v-if="estadoSelected == 'En tramite' && !mostrarInputs()">
                        <v-checkbox label="Finalizada" v-model="checkFinalizada"></v-checkbox>
                      </v-col>
                      <!-- <v-col cols="4" v-if="visualizarRecotizar">
                            <v-checkbox
                              label="Recotizar"
                              v-model="checkRecotizar"
                              @change="changeRecotizar()"
                            ></v-checkbox>
                          </v-col> -->
                      <!-- End 6th Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <!-- <v-col cols="4">
                            <v-checkbox
                              label="Cotización / Renovación Rechazada"
                              v-model="checkCotizacionRenovacionRechazada"
                            ></v-checkbox>
                          </v-col> -->
                      <v-col cols="4">
                        <v-checkbox label="Pendiente de Información" v-model="checkPendienteInfo"></v-checkbox>
                      </v-col>
                      <!-- End 7th Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px" v-if="checkPendienteInfo == true">
                      <v-col cols="6">
                        <v-textarea label="Causa de Pendiente de Información" v-model="causaPendienteInformacion"
                          rows="1" auto-grow></v-textarea>
                      </v-col>
                      <!-- End 8th Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <!-- <v-col cols="4">
                            <v-checkbox
                              label="Emisión Finalizada"
                              v-model="checkEmisionFinalizada"
                            ></v-checkbox>
                          </v-col> -->
                      <!-- <v-col cols="4">
                            <v-checkbox
                              label="Finalizada"
                              v-model="checkFinalizada"
                            ></v-checkbox>
                          </v-col> -->
                      <v-col cols="4">
                        <v-checkbox label="Cancelación" v-model="checkCancelacion"></v-checkbox>
                      </v-col>

                      <!-- End 9th Col -->
                    </v-row>
                    <v-row style="padding: 20px; margin-top: -70px" v-if="checkCancelacion == true">
                      <v-col cols="6">
                        <v-textarea label="Causa de Cancelación" v-model="motivoCancelacion" rows="1" auto-grow>
                        </v-textarea>
                      </v-col>
                      <!-- End 8th Col -->
                    </v-row>

                    <v-btn color="primary" @click="sendSolicitud()" :disabled="!comportamientoBotonGuardar()">
                      Guardar
                    </v-btn>

                    <v-btn text @click="stepper = 1"> Regresar </v-btn>
                    <v-btn text @click="cancelarSolicitud()" v-if="mostrarSolicitudDuplicada()">
                      Solicitud Duplicada
                    </v-btn>
                  </v-stepper-content>

                  <!-- 3RD ITEM (SLA) -->
                  <v-stepper-content step="3">
                    <v-row style="padding: 10px">
                      <v-col cols="6">
                        <p>SLA</p>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -90px">
                      <v-col cols="12">
                        <v-select :items="genero" v-model="selectedGenero" label="Cumple" disabled></v-select>
                      </v-col>
                      <!-- End 1st Col -->
                    </v-row>

                    <v-row style="padding: 10px; margin-top: -50px">
                      <v-col cols="6">
                        <p>Fecha de Información completa</p>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -80px">
                      <v-col cols="6">
                        <v-text-field v-model="sla1" placeholder="dd/mm/yyyy" prepend-icon="mdi-calendar" disabled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="sla1" placeholder="hh:mm" prepend-icon="mdi-clock" disabled>
                        </v-text-field>
                      </v-col>
                      <!-- End 2nd Col -->
                    </v-row>

                    <v-row style="padding: 10px; margin-top: -50px">
                      <v-col cols="6">
                        <p>Fecha de Pendiente de información</p>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -80px">
                      <v-col cols="6">
                        <v-text-field v-model="sla2" placeholder="dd/mm/yyyy" prepend-icon="mdi-calendar" disabled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="sla2" placeholder="hh:mm" prepend-icon="mdi-clock" disabled>
                        </v-text-field>
                      </v-col>
                      <!-- End 3rd Col -->
                    </v-row>

                    <v-row style="padding: 10px; margin-top: -50px">
                      <v-col cols="6">
                        <p>Fecha de Emisión de Datos Recibidos</p>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -80px">
                      <v-col cols="6">
                        <v-text-field :items="genero" v-model="selectedGenero" placeholder="dd/mm/yyyy"
                          prepend-icon="mdi-calendar" disabled></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field :items="genero" v-model="selectedGenero" placeholder="hh:mm"
                          prepend-icon="mdi-clock" disabled></v-text-field>
                      </v-col>
                      <!-- End 4th Col -->
                    </v-row>

                    <v-row style="padding: 10px; margin-top: -50px">
                      <v-col cols="6">
                        <p>Fecha de Emisión en procesp</p>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -80px">
                      <v-col cols="6">
                        <v-text-field :items="genero" v-model="selectedGenero" placeholder="dd/mm/yyyy"
                          prepend-icon="mdi-calendar" disabled></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field :items="genero" v-model="selectedGenero" placeholder="hh:mm"
                          prepend-icon="mdi-clock" disabled></v-text-field>
                      </v-col>
                      <!-- End 5th Col -->
                    </v-row>

                    <v-row style="padding: 10px; margin-top: -50px">
                      <v-col cols="6">
                        <p>Fecha de Finalización</p>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -80px">
                      <v-col cols="6">
                        <v-text-field :items="genero" v-model="selectedGenero" placeholder="dd/mm/yyyy"
                          prepend-icon="mdi-calendar" disabled></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field :items="genero" v-model="selectedGenero" placeholder="hh:mm"
                          prepend-icon="mdi-clock" disabled></v-text-field>
                      </v-col>
                      <!-- End 6th Col -->
                    </v-row>

                    <v-btn text @click="stepper = 2"> Regresar </v-btn>

                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- BANNER HERE -->
    </v-row>

    <!-- ALERT MODAL -->
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
          <v-card-text>
            <div class="text-h4" style="margin-top: 10px; ">
              Actualización de solicitud exitoso!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false, $router.push(`/seguimiento-solicitud/${this.$route.params.id}`)">
              Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- END OF ALERT MODAL -->

    <!-- ALERT MODAL CANCELACION -->
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialogCancelar">
      <template v-slot:default="dialogCancelar">
        <v-card>
          <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
          <v-card-text>
            <div class="text-h4" style="margin-top: 10px; ">
              Cancelación de solicitud exitoso!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text
              @click="dialogCancelar.value = false, $router.push(`/seguimiento-solicitud/${this.$route.params.id}`)">
              Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- END OF ALERT MODAL CANCELACION -->
  </div>
</template>

<script>
import moment from 'moment';
import RamoAutosComponentUpdate from '../../components/ramosComponents/update/RamoAutosComponent.vue';
import RamoAhorroComponentUpdate from '../../components/ramosComponents/update/RamoAhorroComponent.vue';
import RamoDaniosComponentUpdate from '../../components/ramosComponents/update/RamoDaniosComponent.vue';
import RamoFianzasComponentUpdate from '../../components/ramosComponents/update/RamoFianzasComponent.vue';
import RamoSaludComponentUpdate from '../../components/ramosComponents/update/RamoSaludComponent.vue';
import RamoVidaComponentUpdate from '../../components/ramosComponents/update/RamoVidaComponent.vue';

export default {
  components: {
    RamoAutosComponentUpdate,
    RamoAhorroComponentUpdate,
    RamoDaniosComponentUpdate,
    RamoFianzasComponentUpdate,
    RamoSaludComponentUpdate,
    RamoVidaComponentUpdate,
  },
  data() {
    return {
      emailRules: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Dirección de correo incorrecta'
        },
      ],
      rfcRules: [
        (v) => (v || '').length <= 13 || 'RFC No debe ser mayor de 13 caracteres',
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(v) ||
          "RFC no es valido recordar escribir en mayusculas",
      ],
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
      tipoNoGeneral: [
        value => value != 'General' || 'Cambiar el tipo de Solicitud',
      ],
      menuInicio: false,
      menuFin: false,
      menuFechaNacimiento: false,
      dialog: false,
      dialogCancelar: false,
      bandera: true,
      stepper: 1,
      roles: JSON.parse(localStorage.getItem("roles")),
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      isAdmin: false,
      solicitud: [],
      bodySolicitud: null,
      mostrarFormulario2: false,
      infoTarjetaComercial: null,
      origenSolicitud: [
        "Correo electronico",
        "Llamada telefónica",
        "Presencial",
        "Whatsapp",
        "Portal",
        "Otro",
      ],
      agentes: [],
      ejecutivos: [],
      estado: [
        "Nueva",
        "Asignada",
        "En tramite",
        "Pendiente de Aprobacion",
        "Pendiente de Informacion",
        "Recotizar",
        "Cerrada",
        "Cancelada",
      ],
      estadoCivil: [
        "Soltero(a)",
        "Casado(a)",
      ],
      tipo: [
        "Cotización",
        "Emisión",
        "Renovación",
        "Endoso A y D",
        "Endoso B",
        "Facturacion",
        "Cancelación",
        "General",
        "Otro",
      ],
      categoria: [
        "AUTOS",
        "DAÑOS",
        "SALUD",
        "VIDA",
        "AHORRO",
        "FIANZA",
      ],
      estados: [
        { id: 1, estadoRepublica: 'Aguascalientes' },
        { id: 2, estadoRepublica: 'Baja California' },
        { id: 3, estadoRepublica: 'Baja California Sur' },
        { id: 4, estadoRepublica: 'Campeche' },
        { id: 5, estadoRepublica: 'Coahuila de Zaragoza' },
        { id: 6, estadoRepublica: 'Colima' },
        { id: 7, estadoRepublica: 'Chiapas' },
        { id: 8, estadoRepublica: 'Chihuahua' },
        { id: 9, estadoRepublica: 'Ciudad de México' },
        { id: 10, estadoRepublica: 'Durango' },
        { id: 11, estadoRepublica: 'Guanajuato' },
        { id: 12, estadoRepublica: 'Guerrero' },
        { id: 13, estadoRepublica: 'Hidalgo' },
        { id: 14, estadoRepublica: 'Jalisco' },
        { id: 15, estadoRepublica: 'Estado de México' },
        { id: 16, estadoRepublica: 'Michoacán de Ocampo' },
        { id: 17, estadoRepublica: 'Morelos' },
        { id: 18, estadoRepublica: 'Nayarit' },
        { id: 19, estadoRepublica: 'Nuevo León' },
        { id: 20, estadoRepublica: 'Oaxaca' },
        { id: 21, estadoRepublica: 'Puebla' },
        { id: 22, estadoRepublica: 'Querétaro' },
        { id: 23, estadoRepublica: 'Quintana Roo' },
        { id: 24, estadoRepublica: 'San Luis Potosí' },
        { id: 25, estadoRepublica: 'Sinaloa' },
        { id: 26, estadoRepublica: 'Sonora' },
        { id: 27, estadoRepublica: 'Tabasco' },
        { id: 28, estadoRepublica: 'Tamaulipas' },
        { id: 29, estadoRepublica: 'Tlaxcala' },
        { id: 30, estadoRepublica: 'Veracruz de Ignacio de la Llave' },
        { id: 31, estadoRepublica: 'Yucatán' },
        { id: 32, estadoRepublica: 'Zacatecas' },
      ],
      estadoRepublicaSelected: null,
      procesoCotizacion: ['Cotización', 'Emision', 'Finalizada'],
      formaPago: ['Tarjeta de Crédito', 'Tarjeta de Débito', 'Deposito Bancario', 'Transferencia', 'Cheque', 'Efectivo', 'Domiciliación'],
      periodicidadItems: ['Anual', 'Semestral', 'Trimestral', 'Mensual'],
      tipoVehiculosItems: ['Auto', 'Motocicleta', 'Pick-up', 'Camión', 'Tractocamión'],
      coberturas: ['Amplia', 'Amplia-Plus', 'Limitada', 'RC'],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      regimenList: [
        "Física",
        "Moral",
      ],
      agenteUuid: localStorage.getItem("agenteUuid"),
      newAgenteUuid: null,
      origenSolicitudSelected: null,
      estadoSelected: null,
      estadoCivilSelected: null,
      tipoSelected: null,
      categoriaSelected: null,
      agente: null,
      productos: [],
      aseguradora: [],
      aseguradoraId: null,
      productoSelected: null,
      ejecutivo: null,
      seguimientoSolicitud: null,
      oportunidades: null,
      nombres: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      fechaNacimiento: null,
      genero: ["Masculino", "Femenino"],
      selectedGenero: null,
      modelo: null,
      marca: null,
      submarca: null,
      detalle: null,
      version: null,
      inicioVigencia: null,
      finVigencia: null,
      periodicidadSelected: null,
      tipoVehiculoSelected: null,
      coberturasSelected: null,
      formaPagoSelected: null,
      cobertura: null,
      placas: null,
      numSerie: null,
      numMotor: null,
      celular: null,
      correoElectronico: null,
      codigoPostal: null,
      edad: null,
      direccion: null,
      rfc: null,
      solicitudId: null,
      polizaRenovar: null,
      fechaInicioVigenciaRenovar: null,
      fechaFinVigenciaRenovar: null,
      usoAutoSelected: null,
      regimenSelected: null,

      procesoCotizacionSelected: null,
      solicitudCompleta: ['Si', 'No'],
      solicitudCompletaSelected: null,
      checkTramite: false,
      checkCotizacionRenovacionTerminada: false,
      checkRecotizar: false,
      checkCotizacionRenovacionAprobada: false,
      checkCotizacionRenovacionRechazada: false,
      checkPendienteInfo: false,
      checkEmisionFinalizada: false,
      checkFinalizada: false,
      checkCancelacion: false,
      motivoCancelacion: null,
      motivoRecotizacionRechazo: null,
      causaPendienteInformacion: null,
      valorOportunidad: null,
      razonSocial: null,
      documentos: [],
      canal: [],
      comercialAsignadoList: [],
      comercialAsignado: null,
      esAgentDirecto: null,
      creadoPor: null,
      fechaCreacion: null,
      modificadoPor: null,
      fechaModificacion: null,

      valorAnteriorTramite: null,

      sla1: null,
      sla2: null,
      sla3: null,
    };
  },
  watch: {
    dialog(val) {
      if (!val) return

      setTimeout(() => (this.dialog = false), 4000)
    },
    dialogCancelar(val) {
      if (!val) return

      setTimeout(() => (this.dialogCancelar = false), 4000)
    },
    // productoSelected(val) {
    //   if (!val) return

    //   let productoSelected = this.productos.find(element => {
    //     console.log("Element: ", element);
    //   })
    //   // let productoSelected = this.productos.find(element => element.id === val)
    //   // console.log("Watch: ", val);
    //   this.aseguradoraId = productoSelected ? productoSelected.aseguradoraId : null
    // }
  },
  methods: {
    mostrarSolicitudDuplicada() {
      if (this.tipoSelected === 'General' && this.estadoSelected === 'Nueva' && this.origenSolicitudSelected === 'Correo electronico') {
        if (this.rolToCompare === 'ADMIN' || this.rolToCompare === 'MESADECONTROL' || this.rolToCompare === 'OPERACIONES') {
          return true
        }
        return false
      } else return false
    },
    cancelarSolicitud() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };

      this.$axios.get(`/v1/solicitud/cancelar/${this.solicitudId}`, config).then(resp => {
        if (resp.status === 200) {
          this.dialogCancelar = true
        }
        setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
      })
    },
    mostrarInputs() {
      if (this.tipoSelected == 'Cotización' || this.tipoSelected == 'Emisión' || this.tipoSelected == 'Renovación') {
        return true
      } else return false
    },
    deshabilitarInputs() {
      if ((this.estadoSelected == 'En tramite' && this.procesoCotizacionSelected == 'Emision') || (this.estadoSelected == 'Cerrada') || (this.estadoSelected == 'Cancelada')) {
        return true
      } else return false
    },

    verPolizaRelacionada() {
      this.$router.push(`/emision/${this.polizaRenovar}`)
    },
    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      for (var i = 0; i < this.documentos.length; i++) {
        const formData = new FormData();
        let file = this.documentos[i];
        let nota = 'Solicitud #' + `${this.solicitudId}` + ' Cotización Enviada';
        const tipo = 'Documento';
        const tipoDocumento = 'Cotización';
        formData.append('file', file);
        formData.append('nota', nota);
        formData.append('tipo', tipo);
        formData.append('nombre', nota);
        formData.append('tipo_documento', tipoDocumento);
        this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData, config)
          .then(function (result) {
            console.log(result);
          }, function (error) {
            console.log(error);
          });
      }
    },
    crearNotaRechazoCancelacion() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      const tipo = 'Nota';
      let nota = 'Solicitud #' + `${this.solicitudId}` + ' Rechazada o cancelada'
      formData.append('nota', this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : this.motivoCancelacion);
      formData.append('tipo', tipo);
      formData.append('nombre', nota);
      this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData, config)
        .then(function (result) {
          console.log(result);
        }, function (error) {
          console.log(error);
        });
    },
    crearNotaPendienteInformacion() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      const tipo = 'Nota';
      let nota = 'Solicitud #' + `${this.solicitudId}` + ' Rechazada o cancelada'
      formData.append('nota', this.causaPendienteInformacion);
      formData.append('tipo', tipo);
      formData.append('nombre', nota);
      this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData, config)
        .then(function (result) {
          console.log(result);
        }, function (error) {
          console.log(error);
        });
    },

    /****METODOS DE VALIDACION DE CHECKS****/
    changeSolicitudState() {
      if (this.solicitudCompletaSelected == 'Si') {
        this.estadoSelected = 'Asignado';
      }
    },
    changeTramite() {
      console.log('Entro!');
      if (this.checkTramite) {
        this.valorAnteriorTramite = this.estadoSelected
        this.estadoSelected = 'En tramite'
      } else {
        this.estadoSelected = this.valorAnteriorTramite
      }
    },
    /****FIN DE METODOS DE VALIDACION DE CHECKS****/

    goBack() {
      return this.$router.go(-1);
    },
    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (this.documentos.length > 0) {
        this.cargarArchivos()
      }

      if (this.checkCotizacionRenovacionRechazada || this.checkCancelacion) {
        this.crearNotaRechazoCancelacion()
      }

      if (this.checkPendienteInfo || this.checkRecotizar) {
        this.crearNotaPendienteInformacion()
      }

      let requestAutos = {
        origenSolicitudSelected: this.origenSolicitudSelected
          ? this.origenSolicitudSelected
          : "",
        tipoSelected: this.tipoSelected ? this.tipoSelected : "",
        categoriaSelected: this.categoriaSelected ? this.categoriaSelected : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.productoSelected ? this.productoSelected : "",
        ramo: this.ramo ? this.ramo : "",
        operadorUuid: this.ejecutivo ? this.ejecutivo : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.selectedGenero ? this.selectedGenero : "",
        detalle: this.detalle ? this.detalle : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.celular ? this.celular : "",
        correoElectronico: this.correoElectronico ? this.correoElectronico : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : "",
        estadoCivilSelected: this.estadoCivilSelected ? this.estadoCivilSelected : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidadSelected == 'Anual' ? 1
          : this.periodicidadSelected == 'Semestral' ? 2
            : this.periodicidadSelected == 'Trimestral' ? 3
              : this.periodicidadSelected == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPagoSelected ? this.formaPagoSelected : "",
        solicitudCompletaSelected: this.solicitudCompletaSelected ? this.solicitudCompletaSelected : "",
        procesoCotizacionSelected: this.procesoCotizacionSelected ? this.procesoCotizacionSelected : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        regimen_persona: this.regimenSelected ? this.regimenSelected : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        modelo: this.bodySolicitud.modelo ? this.bodySolicitud.modelo : "",
        marca: this.bodySolicitud.marca ? this.bodySolicitud.marca : "",
        submarca: this.bodySolicitud.submarca ? this.bodySolicitud.submarca : "",
        version: this.bodySolicitud.version ? this.bodySolicitud.version : "",
        coberturasSelected: this.bodySolicitud.coberturasSelected ? this.bodySolicitud.coberturasSelected : "",
        numMotor: this.bodySolicitud.numMotor ? this.bodySolicitud.numMotor : "",
        numSerie: this.bodySolicitud.numSerie ? this.bodySolicitud.numSerie : "",
        placas: this.bodySolicitud.placas ? this.bodySolicitud.placas : "",
        uso_auto: this.bodySolicitud.uso_auto ? this.bodySolicitud.uso_auto : '',
        tipo_vehiculo: this.bodySolicitud.tipo_vehiculo ? this.bodySolicitud.tipo_vehiculo : '',
        origenAuto: this.bodySolicitud.origenAuto ? this.bodySolicitud.origenAuto : ''
      };
      let requestDanios = {
        origenSolicitudSelected: this.origenSolicitudSelected
          ? this.origenSolicitudSelected
          : "",
        tipoSelected: this.tipoSelected ? this.tipoSelected : "",
        categoriaSelected: this.categoriaSelected ? this.categoriaSelected : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.productoSelected ? this.productoSelected : "",
        ramo: this.ramo ? this.ramo : "",
        operadorUuid: this.ejecutivo ? this.ejecutivo : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.selectedGenero ? this.selectedGenero : "",
        detalle: this.detalle ? this.detalle : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.celular ? this.celular : "",
        correoElectronico: this.correoElectronico ? this.correoElectronico : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : "",
        estadoCivilSelected: this.estadoCivilSelected ? this.estadoCivilSelected : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidadSelected == 'Anual' ? 1
          : this.periodicidadSelected == 'Semestral' ? 2
            : this.periodicidadSelected == 'Trimestral' ? 3
              : this.periodicidadSelected == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPagoSelected ? this.formaPagoSelected : "",
        solicitudCompletaSelected: this.solicitudCompletaSelected ? this.solicitudCompletaSelected : "",
        procesoCotizacionSelected: this.procesoCotizacionSelected ? this.procesoCotizacionSelected : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        regimen_persona: this.regimenSelected ? this.regimenSelected : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        tipoInmueble: this.bodySolicitud.tipoInmueble,
        otroTipo: this.bodySolicitud.otroTipo ? this.bodySolicitud.otroTipo : null,
        valorInmueble: this.bodySolicitud.valorInmueble,
        nivelDepartamento: this.bodySolicitud.nivelDepartamento,
        pisosTotales: this.bodySolicitud.pisosTotales,
        numeroPisosAltos: this.bodySolicitud.numeroPisosAltos,
        sotanos: this.bodySolicitud.sotanos,
        valorContenidos: this.bodySolicitud.valorContenidos,
        valorEquipoElectronico: this.bodySolicitud.valorEquipoElectronico,
        sumaAseguradaRC: this.bodySolicitud.sumaAseguradaRC,
        sumaAseguradaCristales: this.bodySolicitud.sumaAseguradaCristales,
        sumaAseguradaRoboViolencia: this.bodySolicitud.sumaAseguradaRoboViolencia,
        sumaAseguradaDineroValores: this.bodySolicitud.sumaAseguradaDineroValores,
        sumaAseguradaObrasDeArte: this.bodySolicitud.sumaAseguradaObrasDeArte,
      };
      let requestSalud = {
        origenSolicitudSelected: this.origenSolicitudSelected
          ? this.origenSolicitudSelected
          : "",
        tipoSelected: this.tipoSelected ? this.tipoSelected : "",
        categoriaSelected: this.categoriaSelected ? this.categoriaSelected : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.productoSelected ? this.productoSelected : "",
        ramo: this.ramo ? this.ramo : "",
        operadorUuid: this.ejecutivo ? this.ejecutivo : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.selectedGenero ? this.selectedGenero : "",
        detalle: this.detalle ? this.detalle : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.celular ? this.celular : "",
        correoElectronico: this.correoElectronico ? this.correoElectronico : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : "",
        estadoCivilSelected: this.estadoCivilSelected ? this.estadoCivilSelected : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidadSelected == 'Anual' ? 1
          : this.periodicidadSelected == 'Semestral' ? 2
            : this.periodicidadSelected == 'Trimestral' ? 3
              : this.periodicidadSelected == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPagoSelected ? this.formaPagoSelected : "",
        solicitudCompletaSelected: this.solicitudCompletaSelected ? this.solicitudCompletaSelected : "",
        procesoCotizacionSelected: this.procesoCotizacionSelected ? this.procesoCotizacionSelected : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        regimen_persona: this.regimenSelected ? this.regimenSelected : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        nivelHospitalario: this.bodySolicitud.nivelHospitalario,
        sumaAsegurada: this.bodySolicitud.sumaAsegurada,
        deducible: this.bodySolicitud.deducible,
        coaseguro: this.bodySolicitud.coaseguro,
        ceroDeduciblePorAccidente: this.bodySolicitud.ceroDeduciblePorAccidente,
        medicamentosFueraDeHospital: this.bodySolicitud.medicamentosFueraDeHospital,
        emergenciaEnExtranjero: this.bodySolicitud.emergenciaEnExtranjero,
        enfermedadesCatastroficas: this.bodySolicitud.enfermedadesCatastroficas,
        coberturaExtranjero: this.bodySolicitud.coberturaExtranjero,
        preexistencias: this.bodySolicitud.preexistencias,
        proteccionDental: this.bodySolicitud.proteccionDental,
        maternidadExtendida: this.bodySolicitud.maternidadExtendida,
        coberturaNacional: this.bodySolicitud.coberturaNacional,
        reconocimientoAntiguedad: this.bodySolicitud.reconocimientoAntiguedad,
        fechasParaCotizar: this.bodySolicitud.fechasParaCotizar,
        dependientes: this.bodySolicitud.dependientes,
      };
      let requestVida = {
        origenSolicitudSelected: this.origenSolicitudSelected
          ? this.origenSolicitudSelected
          : "",
        tipoSelected: this.tipoSelected ? this.tipoSelected : "",
        categoriaSelected: this.categoriaSelected ? this.categoriaSelected : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.productoSelected ? this.productoSelected : "",
        ramo: this.ramo ? this.ramo : "",
        operadorUuid: this.ejecutivo ? this.ejecutivo : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.selectedGenero ? this.selectedGenero : "",
        detalle: this.detalle ? this.detalle : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.celular ? this.celular : "",
        correoElectronico: this.correoElectronico ? this.correoElectronico : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : "",
        estadoCivilSelected: this.estadoCivilSelected ? this.estadoCivilSelected : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidadSelected == 'Anual' ? 1
          : this.periodicidadSelected == 'Semestral' ? 2
            : this.periodicidadSelected == 'Trimestral' ? 3
              : this.periodicidadSelected == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPagoSelected ? this.formaPagoSelected : "",
        solicitudCompletaSelected: this.solicitudCompletaSelected ? this.solicitudCompletaSelected : "",
        procesoCotizacionSelected: this.procesoCotizacionSelected ? this.procesoCotizacionSelected : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        regimen_persona: this.regimenSelected ? this.regimenSelected : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        plan: this.bodySolicitud.plan,
        fumador: this.bodySolicitud.fumador,
        sumaAsegurada: this.bodySolicitud.sumaAsegurada,
        muerteAccidental: this.bodySolicitud.muerteAccidental,
        perdidasOrganicas: this.bodySolicitud.perdidasOrganicas,
        invalidezTotalPermanente: this.bodySolicitud.invalidezTotalPermanente,
        gastosFunerarios: this.bodySolicitud.gastosFunerarios,
      };
      let requestAhorro = {
        origenSolicitudSelected: this.origenSolicitudSelected
          ? this.origenSolicitudSelected
          : "",
        tipoSelected: this.tipoSelected ? this.tipoSelected : "",
        categoriaSelected: this.categoriaSelected ? this.categoriaSelected : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.productoSelected ? this.productoSelected : "",
        ramo: this.ramo ? this.ramo : "",
        operadorUuid: this.ejecutivo ? this.ejecutivo : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.selectedGenero ? this.selectedGenero : "",
        detalle: this.detalle ? this.detalle : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.celular ? this.celular : "",
        correoElectronico: this.correoElectronico ? this.correoElectronico : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : "",
        estadoCivilSelected: this.estadoCivilSelected ? this.estadoCivilSelected : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidadSelected == 'Anual' ? 1
          : this.periodicidadSelected == 'Semestral' ? 2
            : this.periodicidadSelected == 'Trimestral' ? 3
              : this.periodicidadSelected == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPagoSelected ? this.formaPagoSelected : "",
        solicitudCompletaSelected: this.solicitudCompletaSelected ? this.solicitudCompletaSelected : "",
        procesoCotizacionSelected: this.procesoCotizacionSelected ? this.procesoCotizacionSelected : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        regimen_persona: this.regimenSelected ? this.regimenSelected : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        tipoPortafolios: this.bodySolicitud.tipoPortafolios,
        fumador: this.bodySolicitud.fumador,
        plazoComprometido: this.bodySolicitud.plazoComprometido,
        deducibilidad: this.bodySolicitud.deducibilidad,
        revaluableConInflacion: this.bodySolicitud.revaluableConInflacion,
        valorPlan: this.bodySolicitud.valorPlan,
        montoInversionAportacion: this.bodySolicitud.montoInversionAportacion,
      };
      let requestFianzas = {
        origenSolicitudSelected: this.origenSolicitudSelected
          ? this.origenSolicitudSelected
          : "",
        tipoSelected: this.tipoSelected ? this.tipoSelected : "",
        categoriaSelected: this.categoriaSelected ? this.categoriaSelected : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.productoSelected ? this.productoSelected : "",
        ramo: this.ramo ? this.ramo : "",
        operadorUuid: this.ejecutivo ? this.ejecutivo : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.selectedGenero ? this.selectedGenero : "",
        detalle: this.detalle ? this.detalle : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.celular ? this.celular : "",
        correoElectronico: this.correoElectronico ? this.correoElectronico : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : "",
        estadoCivilSelected: this.estadoCivilSelected ? this.estadoCivilSelected : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidadSelected == 'Anual' ? 1
          : this.periodicidadSelected == 'Semestral' ? 2
            : this.periodicidadSelected == 'Trimestral' ? 3
              : this.periodicidadSelected == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPagoSelected ? this.formaPagoSelected : "",
        solicitudCompletaSelected: this.solicitudCompletaSelected ? this.solicitudCompletaSelected : "",
        procesoCotizacionSelected: this.procesoCotizacionSelected ? this.procesoCotizacionSelected : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        regimen_persona: this.regimenSelected ? this.regimenSelected : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,

        contratoAdjunto: this.bodySolicitud.contratoAdjunto
      };

      switch (this.categoriaSelected) {
        case 'AUTOS':
          this.$axios
            .post("v1/solicitud/update/" + this.$route.params.id, requestAutos, config)
            .then((response) => {
              console.log("Entro!");
              if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                  this.generarPrePoliza();
                }
                this.dialog = true;
              }
              setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case 'DAÑOS':
          this.$axios
            .post("v1/solicitud/update/" + this.$route.params.id, requestDanios, config)
            .then((response) => {
              console.log("Entro!");
              if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                  this.generarPrePoliza();
                }
                this.dialog = true;
              }
              setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case 'SALUD':
          this.$axios
            .post("v1/solicitud/update/" + this.$route.params.id, requestSalud, config)
            .then((response) => {
              console.log("Entro!");
              if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                  this.generarPrePoliza();
                }
                this.dialog = true;
              }
              setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case 'VIDA':
          this.$axios
            .post("v1/solicitud/update/" + this.$route.params.id, requestVida, config)
            .then((response) => {
              console.log("Entro!");
              if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                  this.generarPrePoliza();
                }
                this.dialog = true;
              }
              setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case 'AHORRO':
          this.$axios
            .post("v1/solicitud/update/" + this.$route.params.id, requestAhorro, config)
            .then((response) => {
              console.log("Entro!");
              if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                  this.generarPrePoliza();
                }
                this.dialog = true;
              }
              setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        case 'FIANZAS':
          this.$axios
            .post("v1/solicitud/update/" + this.$route.params.id, requestFianzas, config)
            .then((response) => {
              console.log("Entro!");
              if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                  this.generarPrePoliza();
                }
                this.dialog = true;
              }
              setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.$route.params.id}`)), 3000);
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        default:
          break;
      }

    },

    generarPrePoliza() {
      if (this.checkCotizacionRenovacionAprobada == true) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };

        this.agentes.map(element => {
          console.log("Recorrido de map: ", element)
          // if (element.id == this.agente) {
          //   this.newAgenteUuid = element.uuid
          // }
        })
        let request = {
          apellido_materno: this.apellidoMaterno ? this.apellidoMaterno : '',
          apellido_paterno: this.apellidoPaterno ? this.apellidoPaterno : '',
          aseguradora_id: this.aseguradoraId,
          compania: "AXA",
          cliente: null,
          agente: this.agente ? this.agente : null,
          codigo_postal: this.codigoPostal ? this.codigoPostal : '',
          correo_electronico: this.correoElectronico ? this.correoElectronico : '',
          cotizacion: null,
          creado_por: this.ejecutivo,
          detalle: this.detalle ? this.detalle : '',
          ejecutivo_de_operaciones: this.ejecutivo ? this.ejecutivo : '',
          estado_id: 9,
          // estado_id: this.estadoRepublicaSelected ? this.estadoRepublicaSelected : '',
          fecha_cancelacion: null,
          fecha_emision: null,
          fecha_finalizacion: null,
          fecha_primer_pago: moment(new (Date)).format('YYYY-MM-DD'),
          fecha_nacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
          fin: moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          // forma_pago: this.periodicidadSelected == 'Anual' ? 1
          //                 : this.periodicidadSelected == 'Semestral' ? 2
          //                 : this.periodicidadSelected == 'Trimestral' ? 3
          //                 : this.periodicidadSelected == 'Mensual' ? 4 : '',
          forma_pago: this.formaPagoSelected == 'Tarjeta de Crédito' ? 1
            : this.formaPagoSelected == 'Tarjeta de Débito' ? 2
              : this.formaPagoSelected == 'Deposito Bancario' ? 3
                : this.formaPagoSelected == 'Transferencia' ? 4
                  : this.formaPagoSelected == 'Cheque' ? 5
                    : this.formaPagoSelected == 'Efectivo' ? 6
                      : this.formaPagoSelected == 'Domiciliación' ? 7 : '',
          genero: this.selectedGenero ? this.selectedGenero : '',
          comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
          inicio: moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          marca: this.marca ? this.marca : '',
          modelo: this.modelo ? this.modelo : '',
          modificado_por: null,
          motivo_cancelacion: null,
          motivo_finalizacion: null,
          nombre: this.nombres,
          nombre_cliente: this.nombres ? this.nombres : '',
          notas: "",
          num_motor: this.numMotor ? this.numMotor : '',
          num_serie: this.numSerie ? this.numSerie : '',
          observaciones: "",
          operador_id: this.ejecutivo ? this.ejecutivo : '',
          origen: this.origenSolicitudSelected ? this.origenSolicitudSelected : '',
          origen_auto: "Nacional",
          pago_id: this.periodicidadSelected == 'Anual' ? 1
            : this.periodicidadSelected == 'Semestral' ? 2
              : this.periodicidadSelected == 'Trimestral' ? 3
                : this.periodicidadSelected == 'Mensual' ? 4 : '',
          placas: this.placas ? this.placas : '',
          plan_id: 1,
          /* */ poliza_anterior: null,
          /* */ poliza_cancelada: null,
          /* */ poliza_finalizada: null,
          /* */ prima_neta: 0,
          /* */ prima_total: 0,
          /* */ primer_pago: 0,
          /* */ primer_pago_poliza: 0,
          producto_id: this.productoSelected ? this.productoSelected : '',
          /* */ status: 0,
          status_cobro: 1,
          submarca: this.submarca ? this.submarca : '',
          subsecuente_pago: 0,
          telefono: this.celular ? this.celular : '',
          tipo_persona: this.regimenSelected ? this.regimenSelected : null,
          /* */ tipo_poliza: this.tipoSelected == 'Cotización' || this.tipoSelected == 'Emisión' ? 'Nueva' :
            this.tipoSelected == 'Renovación' ? 'Renovación' : '',
          ultima_modificacion: null,
          url: null,
          uso_auto: this.usoAutoSelected ? this.usoAutoSelected : null,
          vehiculo: `${this.marca ? this.marca : ''} ${this.submarca ? this.submarca : ''} ${this.version ? this.version : ''}`,
          version: this.version ? this.version : '',
          rfc: this.rfc ? this.rfc : '',
          solicitud_id: this.solicitudId
        };

        this.$axios
          .post('/v1/emision/manual', request, config)
          .then(response => {
            console.log('Entro a generar pre-poliza', response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    /****METODOS PARA OBTENER LOS DATOS DE LA SOLICITUD****/
    obtnerDatosSolicitud() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/solicitud/id/${this.$route.params.id}`, config).then((response) => {
        console.log('Datos solicitud: ', response);
        this.solicitud.push(response.data);
        this.bodySolicitud = response.data.body ? JSON.parse(response.data.body) : '';
        // console.log(this.bodySolicitud);
        // this.agente = response.data.body.agente;
        // this.tipo = response.data.body.oportunidades;
        this.origenSolicitudSelected = response.data.origen ? response.data.origen : ''
        this.estadoSelected = response.data.status ? response.data.status : ''
        this.tipoSelected = response.data.tipo ? response.data.tipo : ''
        this.categoriaSelected = response.data.categoria ? response.data.categoria : ''
        this.agente = response.data.agenteId ? response.data.agenteId : ''
        this.productoSelected = response.data.productoId ? response.data.productoId : ''
        this.ejecutivo = response.data.operadorId ? response.data.operadorId : ''
        this.seguimientoSolicitud = this.bodySolicitud.seguimientoSolicitud ? this.bodySolicitud.seguimientoSolicitud : ''
        this.oportunidades = this.bodySolicitud.oportunidades ? this.bodySolicitud.oportunidades : ''
        this.nombres = response.data.nombre ? response.data.nombre : ''
        this.apellidoPaterno = response.data.apellidoPaterno ? response.data.apellidoPaterno : ''
        this.apellidoMaterno = response.data.apellidoMaterno ? response.data.apellidoMaterno : ''
        this.fechaNacimiento = response.data.fechaNacimiento ? moment(response.data.fechaNacimiento, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
        this.selectedGenero = response.data.sexo ? response.data.sexo : ''
        this.modelo = this.bodySolicitud.modelo ? this.bodySolicitud.modelo : ''
        this.marca = this.bodySolicitud.marca ? this.bodySolicitud.marca : ''
        this.submarca = this.bodySolicitud.submarca ? this.bodySolicitud.submarca : ''
        this.detalle = this.bodySolicitud.detalle ? this.bodySolicitud.detalle : ''
        this.version = this.bodySolicitud.version ? this.bodySolicitud.version : ''
        this.inicioVigencia = response.data.fechaInicioVigencia ? moment(response.data.fechaInicioVigencia, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
        this.finVigencia = response.data.fechaFinVigencia ? moment(response.data.fechaFinVigencia, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
        this.cobertura = this.bodySolicitud.cobertura ? this.bodySolicitud.cobertura : ''
        this.celular = response.data.celular ? response.data.celular : ''
        this.correoElectronico = response.data.correoElectronico ? response.data.correoElectronico : ''
        this.edad = this.bodySolicitud.edad ? this.bodySolicitud.edad : ''
        this.direccion = this.bodySolicitud.direccion ? this.bodySolicitud.direccion : ''
        this.coberturasSelected = this.bodySolicitud.coberturasSelected ? this.bodySolicitud.coberturasSelected : ''
        this.formaPagoSelected = response.data.formaDePago ? response.data.formaDePago : ''
        this.estadoRepublicaSelected = response.data.estadoRepublica ? response.data.estadoRepublica : ''
        this.estadoCivilSelected = response.data.estadoCivil ? response.data.estadoCivil : ''
        this.codigoPostal = response.data.codigoPostal ? response.data.codigoPostal : ''
        this.solicitudCompletaSelected = response.data.solicitudCompleta ? response.data.solicitudCompleta == 0 ? 'No' : 'Si' : ''
        this.rfc = response.data.rfc ? response.data.rfc : ''
        this.procesoCotizacionSelected = response.data.procesoCotizacion ? response.data.procesoCotizacion : 'Cotización',
          this.solicitudId = response.data.id
        this.polizaRenovar = response.data.polizaARenovar ? response.data.polizaARenovar : null,
          this.fechaInicioVigenciaRenovar = response.data.fechaInicioPolizaAnt ? moment(response.data.fechaInicioPolizaAnt, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        this.fechaFinVigenciaRenovar = response.data.fechaFinPolizaAnt ? moment(response.data.fechaFinPolizaAnt, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        this.coberturasSelected = this.bodySolicitud.coberturasSelected ? this.bodySolicitud.coberturasSelected : '',
          this.tipoVehiculoSelected = this.bodySolicitud.tipoVehiculoSelected ? this.bodySolicitud.tipoVehiculoSelected : '',
          this.numMotor = this.bodySolicitud.numMotor ? this.bodySolicitud.numMotor : '',
          this.numSerie = this.bodySolicitud.numSerie ? this.bodySolicitud.numSerie : '',
          this.placas = this.bodySolicitud.placas ? this.bodySolicitud.placas : '',
          this.periodicidadSelected = response.data.periodicidadPago == 1 ? 'Anual'
            : response.data.periodicidadPago == 2 ? 'Semestral'
              : response.data.periodicidadPago == 3 ? 'Trimestral'
                : response.data.periodicidadPago == 4 ? 'Mensual' : '',
          this.tipoVehiculoSelected = this.bodySolicitud.tipo_vehiculo ? this.bodySolicitud.tipo_vehiculo : ''
        this.usoAutoSelected = this.bodySolicitud.uso_auto ? this.bodySolicitud.uso_auto : '',
          this.regimenSelected = this.bodySolicitud.regimen_persona ? this.bodySolicitud.regimen_persona : ''
        this.checkCotizacionRenovacionAprobada = response.data.cotizacionRenovacionAprobada == 1 ? true
          : response.data.cotizacionRenovacionAprobada == 0 ? false : null
        this.valorOportunidad = response.data.valorOportunidad ? response.data.valorOportunidad : null
        this.comercialAsignado = response.data.comercialAsignado ? response.data.comercialAsignado : null
        this.razonSocial = this.bodySolicitud.razon_social ? this.bodySolicitud.razon_social : null
        this.creadoPor = response.data.creadoPor ? response.data.creadoPor : 'No Disponible',
          this.fechaCreacion = response.data.fechaCreacion ? moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
          this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : 'No Disponible',
          this.fechaModificacion = response.data.fechaModificacion ? moment.utc(response.data.fechaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible'
        this.obtenerProductos(response.data.productoId)
      });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/solicitud/agente/list', config).then(response => {
        console.log("Agentes: ", response.data)
        response.data.map(element => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList
          })
        })
      });
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/solicitud/agente/directo/list", config).then((response) => {
        console.log(response.data);
        response.data.map((element) => {
          this.comercialAsignadoList.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid
          });
        });
      });
    },
    esDirecto() {
      this.agentes.map(element => {
        let elemento = JSON.parse(JSON.stringify(element))
        let canalList = elemento.canalList
        if (element.id == this.agente) {
          canalList.map(elm => {
            if (elm.id == 3) {
              this.esAgentDirecto = true
            } else {
              this.esAgentDirecto = false
            }
          })
        }
      })
    },
    // guardarUuidAgente() {
    //   this.agentes.map(element => {
    //     // console.log("Array agentes: ", element.id)
    //     if (element.id == this.agente) {
    //       this.newAgenteUuid = element.uuid
    //     }
    //   })
    // },
    admin() {
      this.roles.forEach((element) => {
        if (element.rol == "ADMIN") {
          this.isAdmin = true;
        }
      });
      return this.isAdmin;
    },
    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/agentes/operador', config).then(response => {
        // console.log("Ejecutivos: ", response.data);
        response.data.map(element => {
          this.ejecutivos.push({
            nombre: `${element.nombre}` + " " + `${element.apellidoPaterno}` + " " + `${element.apellidoMaterno ? element.apellidoMaterno : ''}`,
            id: element.id,
            uuid: element.uuid
          })
        })
      });
    },
    obtenerProductos(id) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/producto/list', config).then(response => {
        // console.log('Productos: ', response.data);
        response.data.map(element => {
          this.productos.push({
            nombre: `${element.ramo.toUpperCase()}` + ' / ' + `${element.nombre.toUpperCase()}` + ' / ' + `${element.nombreAseguradora.toUpperCase()}`,
            id: element.id,
            aseguradoraId: element.aseguradoraId
          });
        })
        if (this.productoSelected === id) {
          let productoSelected = this.productos.find(element => element.id === id)
          console.log("Producto en parametro: ", id);
          this.aseguradoraId = productoSelected ? productoSelected.aseguradoraId : null
        }
      });
    },
    // obtenerAseguradoraId(id) {
    // },
    /****FIN DE METODOS PARA OBTENER LOS DATOS DE LA SOLICITUD****/
    obtenerId() {
      const id = localStorage;
      console.log("id:", id);
    },
    comportamientoBotonGuardar() {
      switch (this.tipoSelected) {
        case 'Cotización':
          if (this.checkPendienteInfo && this.causaPendienteInformacion == null) {
            return false
          }
          if (this.estadoSelected == 'En tramite' && this.checkCotizacionRenovacionTerminada) {
            return this.formIsValidCheckCotizacionTerminada
          }
          if (this.estadoSelected == 'Pendiente de Aprobacion' && this.checkCotizacionRenovacionAprobada) {
            return this.formIsValid2
          }
          if ((this.checkCotizacionRenovacionRechazada || this.checkRecotizar) && this.motivoRecotizacionRechazo == null) {
            return false
          }
          if (this.checkCancelacion) {
            if (this.motivoCancelacion != null && this.agente != null) {
              return true
            }
            return false
          }
          return this.formIsValid
        case 'Emisión':
        case 'Renovación':
          if (this.checkPendienteInfo && this.causaPendienteInformacion == null) {
            return false
          }
          if (this.estadoSelected == 'En tramite' && this.checkCotizacionRenovacionTerminada) {
            return this.formIsValid2CheckCotizacionTerminada
          }
          if ((this.checkCotizacionRenovacionRechazada || this.checkRecotizar) && this.motivoRecotizacionRechazo == null) {
            return false
          }
          if (this.checkCancelacion) {
            if (this.motivoCancelacion != null && this.agente != null) {
              return true
            }
            return false
          }
          return this.formIsValid2
        case 'Endoso A y D':
        case 'Endoso B':
        case 'Facturacion':
        case 'Cancelación':
        case 'Otro':
          if (this.checkPendienteInfo && this.causaPendienteInformacion == null) {
            return false
          }
          if (this.checkCancelacion) {
            if (this.motivoCancelacion != null && this.agente != null) {
              return true
            }
            return false
          }
          return this.formIsValid3
        default:
          break;
      }
    }
  },

  computed: {
    canEdit() {
      var canView = false;
      var roles = []
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "AGENTCC",
        "DIRECTOR",
        "FINANZAS",
        "MANAGERCC",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR"
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      })
      return canView;
    },
    titulo() {
      return "Solicitud de " + this.tipo + " " + this.producto;
    },
    botonGuardarDisabled() {
      if (this.estadoSelected == 'En tramite' && this.checkCotizacionRenovacionTerminada) {
        if (this.valorOportunidad != null && this.documentos.length > 0) {
          return false
        }
        return true
      }
      return false
    },
    visualizarEnTramite() {
      if (this.checkTramite) {
        return true
      } else {
        if (this.estadoSelected == 'Asignada' || this.estadoSelected == 'Pendiente de Informacion' || this.estadoSelected == 'Recotizar') {
          return true
        } else {
          return false
        }
      }
    },
    visualizarRecotizar() {
      if (this.checkRecotizar) {
        return true
      } else {
        if (this.estadoSelected == 'Pendiente de Aprobacion') {
          return true
        } else {
          return false
        }
      }
    },

    //ESTE FUNCIONA PARA TIPO "COTIZACIÓN"
    formIsValid() {
      if (this.regimenSelected == 'Moral') {
        return (
          (this.agente != null && this.agente != '') &&
          (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
          (this.categoriaSelected != null && this.categoriaSelected != '') &&
          (this.inicioVigencia != null && this.inicioVigencia != '') &&
          (this.finVigencia != null && this.finVigencia != '') &&
          (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
          (this.regimenSelected != null && this.regimenSelected != '') &&
          (this.rfc != null && this.rfc != '') &&
          (this.celular != null && this.celular != '') &&
          (this.correoElectronico != null && this.correoElectronico != '') &&
          (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
          (this.codigoPostal != null && this.codigoPostal != '') &&
          (this.productoSelected != null && this.productoSelected != '') &&
          (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '')
        )
      }
      return (
        (this.agente != null && this.agente != '') &&
        (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
        (this.categoriaSelected != null && this.categoriaSelected != '') &&
        (this.inicioVigencia != null && this.inicioVigencia != '') &&
        (this.finVigencia != null && this.finVigencia != '') &&
        (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
        (this.nombres != null && this.nombres != '') &&
        (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
        (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
        (this.regimenSelected != null && this.regimenSelected != '') &&
        (this.selectedGenero != null && this.selectedGenero != '') &&
        (this.estadoCivilSelected != null && this.estadoCivilSelected != '') &&
        (this.rfc != null && this.rfc != '') &&
        (this.celular != null && this.celular != '') &&
        (this.correoElectronico != null && this.correoElectronico != '') &&
        (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
        (this.codigoPostal != null && this.codigoPostal != '') &&
        (this.productoSelected != null && this.productoSelected != '') &&
        (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '')
      )
    },
    formIsValidCheckCotizacionTerminada() {
      if (this.regimenSelected == 'Moral') {
        return (
          (this.agente != null && this.agente != '') &&
          (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
          (this.categoriaSelected != null && this.categoriaSelected != '') &&
          (this.inicioVigencia != null && this.inicioVigencia != '') &&
          (this.finVigencia != null && this.finVigencia != '') &&
          (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
          (this.regimenSelected != null && this.regimenSelected != '') &&
          (this.rfc != null && this.rfc != '') &&
          (this.celular != null && this.celular != '') &&
          (this.correoElectronico != null && this.correoElectronico != '') &&
          (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
          (this.codigoPostal != null && this.codigoPostal != '') &&
          (this.productoSelected != null && this.productoSelected != '') &&
          (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '') &&
          (this.valorOportunidad != null && this.valorOportunidad != '')
        )
      }
      return (
        (this.agente != null && this.agente != '') &&
        (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
        (this.categoriaSelected != null && this.categoriaSelected != '') &&
        (this.inicioVigencia != null && this.inicioVigencia != '') &&
        (this.finVigencia != null && this.finVigencia != '') &&
        (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
        (this.nombres != null && this.nombres != '') &&
        (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
        (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
        (this.regimenSelected != null && this.regimenSelected != '') &&
        (this.selectedGenero != null && this.selectedGenero != '') &&
        (this.estadoCivilSelected != null && this.estadoCivilSelected != '') &&
        (this.rfc != null && this.rfc != '') &&
        (this.celular != null && this.celular != '') &&
        (this.correoElectronico != null && this.correoElectronico != '') &&
        (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
        (this.codigoPostal != null && this.codigoPostal != '') &&
        (this.productoSelected != null && this.productoSelected != '') &&
        (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '') &&
        (this.valorOportunidad != null && this.valorOportunidad != '')
      )
    },

    //ESTE FUNCIONA PARA TIPO "EMISIÓN" Y "RENOVACIÓN"
    formIsValid2() {
      if (this.regimenSelected == 'Moral') {
        return (
          (this.agente != null && this.agente != '') &&
          (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
          (this.categoriaSelected != null && this.categoriaSelected != '') &&
          (this.inicioVigencia != null && this.inicioVigencia != '') &&
          (this.finVigencia != null && this.finVigencia != '') &&
          (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
          (this.regimenSelected != null && this.regimenSelected != '') &&
          (this.rfc != null && this.rfc != '') &&
          (this.celular != null && this.celular != '') &&
          (this.correoElectronico != null && this.correoElectronico != '') &&
          (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
          (this.codigoPostal != null && this.codigoPostal != '') &&
          (this.productoSelected != null && this.productoSelected != '') &&
          (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '')
        )
      }
      return (
        (this.agente != null && this.agente != '') &&
        (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
        (this.categoriaSelected != null && this.categoriaSelected != '') &&
        (this.inicioVigencia != null && this.inicioVigencia != '') &&
        (this.finVigencia != null && this.finVigencia != '') &&
        (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
        (this.nombres != null && this.nombres != '') &&
        (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
        (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
        (this.regimenSelected != null && this.regimenSelected != '') &&
        (this.selectedGenero != null && this.selectedGenero != '') &&
        (this.estadoCivilSelected != null && this.estadoCivilSelected != '') &&
        (this.rfc != null && this.rfc != '') &&
        (this.celular != null && this.celular != '') &&
        (this.correoElectronico != null && this.correoElectronico != '') &&
        (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
        (this.codigoPostal != null && this.codigoPostal != '') &&
        (this.productoSelected != null && this.productoSelected != '') &&
        (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '')
      )
    },

    formIsValid2CheckCotizacionTerminada() {
      if (this.regimenSelected == 'Moral') {
        return (
          (this.agente != null && this.agente != '') &&
          (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
          (this.categoriaSelected != null && this.categoriaSelected != '') &&
          (this.inicioVigencia != null && this.inicioVigencia != '') &&
          (this.finVigencia != null && this.finVigencia != '') &&
          (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
          (this.regimenSelected != null && this.regimenSelected != '') &&
          (this.rfc != null && this.rfc != '') &&
          (this.celular != null && this.celular != '') &&
          (this.correoElectronico != null && this.correoElectronico != '') &&
          (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
          (this.codigoPostal != null && this.codigoPostal != '') &&
          (this.productoSelected != null && this.productoSelected != '') &&
          (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '') &&
          (this.valorOportunidad != null && this.valorOportunidad != '')
        )
      }
      return (
        (this.agente != null && this.agente != '') &&
        (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
        (this.categoriaSelected != null && this.categoriaSelected != '') &&
        (this.inicioVigencia != null && this.inicioVigencia != '') &&
        (this.finVigencia != null && this.finVigencia != '') &&
        (this.periodicidadSelected != null && this.periodicidadSelected != '') &&
        (this.nombres != null && this.nombres != '') &&
        (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
        (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
        (this.regimenSelected != null && this.regimenSelected != '') &&
        (this.selectedGenero != null && this.selectedGenero != '') &&
        (this.estadoCivilSelected != null && this.estadoCivilSelected != '') &&
        (this.rfc != null && this.rfc != '') &&
        (this.celular != null && this.celular != '') &&
        (this.correoElectronico != null && this.correoElectronico != '') &&
        (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
        (this.codigoPostal != null && this.codigoPostal != '') &&
        (this.productoSelected != null && this.productoSelected != '') &&
        (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '') &&
        (this.valorOportunidad != null && this.valorOportunidad != '')
      )
    },

    //ESTE FUNCIONA PARA TIPOS QUE NO SON "COTIZACIÓN", "EMISIÓN" Y "RENOVACIÓN"
    formIsValid3() {
      if (this.regimenSelected == 'Moral') {
        return (
          (this.agente != null && this.agente != '') &&
          (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
          (this.categoriaSelected != null && this.categoriaSelected != '') &&
          (this.detalle != null && this.detalle != '') &&
          (this.regimenSelected != null && this.regimenSelected) &&
          (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
          (this.razonSocial != null && this.razonSocial != '') &&
          (this.rfc != null && this.rfc != '') &&
          (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '')
        )
      }
      return (
        (this.agente != null && this.agente != '') &&
        (this.tipoSelected != null && this.tipoSelected != '' && this.tipoSelected != 'General') &&
        (this.categoriaSelected != null && this.categoriaSelected != '') &&
        (this.detalle != null && this.detalle != '') &&
        (this.regimenSelected != null && this.regimenSelected != '') &&
        (this.nombres != null && this.nombres != '') &&
        (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
        (this.estadoRepublicaSelected != null && this.estadoRepublicaSelected != '') &&
        (this.rfc != null && this.rfc != '') &&
        (this.solicitudCompletaSelected != null && this.solicitudCompletaSelected != '')
      )
    },
  },
  mounted() {
    this.obtnerDatosSolicitud(),
      this.obtenerAgentes(),
      this.obtenerEjecutivos(),
      this.obtenerProductos(),
      this.obtenerId(),
      this.deshabilitarInputs(),
      this.obtenerUsersCanalDirecto(),
      this.botonGuardarDisabled,
      console.log("LocalStorage: ", this.roles)
  },
};
</script>

<style>
.enlacesForms input {
  color: red;
  cursor: pointer;
}
</style>
