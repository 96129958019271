<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Cuotas Canceladas</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px">
        <v-col cols="4">
          <v-select label="Canal" outlined style="max-width: 420px" :items="canales" item-text="name" item-value="id"
            v-model="canal" v-show="canShow"></v-select>
        </v-col>
      </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="consultar">Consultar</v-btn>
          <v-btn rounded color="primary" style="width:100%;margin-top:20px;" :disabled="bloquear">
            <download-csv :data="reportDownload" name="Reporte_Cuotas_Canceladas.csv">
              Descargar Información
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table :headers="columns" :items="cuotas" item-key="name" class="elevation-1" :search="search"
            :custom-filter="filterOnlyCapsText">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>

            <template v-slot:item.uuid="{ item }">
              <v-btn @click="$router.push('/administrador/editar-emision/' + item.uuid)">
                Ver
              </v-btn>
            </template>

            <template v-slot:item.urlPoliza="{ item }">
              <v-btn :href="'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza">Descargar</v-btn>
            </template>
          </v-data-table>


        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem('roles')),
      agenteUuid: localStorage.getItem('agenteUuid'),
      loading: false,
      reportType: "lead",
      cuotas: [],
      search: "",
      reportDownload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: 'AGENTES' },
        { id: 2, name: 'MASIVOS' },
        { id: 3, name: 'DIRECTOS' }
      ],

      columns: [
        { text: "No. Póliza", value: "idPoliza" },
        { text: "Estatus de la Póliza", value: "status" },
        { text: "Tipo", value: "tipo", },
        { text: "Número de Cuotas", value: "numCuotas" },
        { text: "Cuota", value: "cuotaId" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Ramo", value: "ramo" },
        { text: "Orden", value: "orden" },
        { text: "Estatus de la Cuota", value: "statusCuota" },
        { text: "Fecha de Pago Acordado", value: "fechaPagoAcordado" },
        { text: "Ejecutivo", value: "ejecutivo" },
        { text: "Valor a Pagar", value: "valorPagar" },
        { text: "Agente", value: "agente" },
        { text: "Cliente", value: "cliente" },
      ],
    };
  }, methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      this.$axios.post(`/v1/cuota/${this.canal ? this.canal : 0}/4/${this.agenteUuid}`, fechas, config).then((value) => {
        this.reportDownload = []
        this.cuotas = []
        console.log(value)
        value.data.map(element => {
          this.cuotas.push({
            idPoliza: element.poliza,
            status: element.statusEmision,
            // cliente: moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            tipo: element.tipo_Poliza,
            numCuotas: element.noCuotas,
            cuotaId: element.cuota,
            aseguradora: element.aseguradora,
            ramo: element.ramo,
            orden: element.orden,
            statusCuota: element.statusCuota,
            fechaPagoAcordado: element.fPagoAcordado ? moment(element.fPagoAcordado, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            ejecutivo: element.nombreEjecutivo ? element.nombreEjecutivo : 'No Disponible',
            valorPagar: "$" + element.valorAPagar,
            agente: element.nombreAgente,
            cliente: element.nombreCliente ? element.nombreCliente : 'No Disponible',
          })

          this.reportDownload.push({
            "No. Póliza": element.poliza,
            "Estatus de la Póliza": element.statusEmision,
            "Tipo": element.tipo_Poliza,
            "Número de Cuotas": element.noCuotas,
            "Cuota": element.cuota,
            "Aseguradora": element.aseguradora,
            "Ramo": element.ramo,
            "Inicio de Vigencia": element.vigenciaInicio ? moment(element.vigenciaInicio, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            "Fin de Vigencia": element.vigenciaFin ? moment(element.vigenciaFin, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            "Observaciones": element.observaciones ? element.observaciones : 'No Disponible',
            "Periodicidad": element.peridiocidad,
            "Primer Pago": "$" + element.primer_Pago,
            "Pago Subsecuente": "$" + element.subsecuente_pago,
            "Orden": element.orden,
            "Estatus de la Cuota": element.statusCuota,
            "Fecha de Pago Acordado": element.fPagoAcordado ? moment(element.fPagoAcordado, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'No Disponible',
            "Ejecutivo": element.nombreEjecutivo ? element.nombreEjecutivo : 'No Disponible',
            "Valor a Pagar": "$" + element.valorAPagar,
            "Canal": element.canal,
            "Cliente": element.nombreCliente ? element.nombreCliente : 'No Disponible',
            "Teléfono Cliente": element.celularCliente ? element.celularCliente : 'No Disponible',
            "Teléfono Oficina Cliente": element.tOficinaCliente ? element.tOficinaCliente : 'No Disponible',
            "Correo electrónico Cliente": element.correoCliente ? element.correoCliente : 'No Disponible',
            "Agente": element.nombreAgente,
            "Celular Agente": element.celularAgente ? element.celularAgente : 'No Disponible',
            "Teléfono de Oficina Agente": element.tOficinaAgente ? element.tOficinaAgente : 'No Disponible',
            "Correo del Agente": element.correoAgente ? element.correoAgente : 'No Disponible',
          })
          this.bloquear = false
        })
      });
    },
    newEmision() {
      this.$router.push('/administrador/carga-emisiones')
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    blockBoton() {
      return (
        this.canal != null &&
        this.dates != ["", ""]
      )
    },
    canShow() {
      var canView = false;
      var roles = []
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = [
        "ADMIN",
        "DIRECTOR",
        "FINANZAS",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      })
      return canView;
    },
  }
};
</script>
