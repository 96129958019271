<template>
    <div>
        <v-row class="mb-2 fill-height" >
            <v-col cols="12"> 
                <v-card>
                    <v-card-title primary-title>
                        Leads Call Center ( {{query.status }} )
                        <v-spacer></v-spacer>
                        <v-btn @click="$router.go(-1)" style="background-color: #ff0042; color: white;">
                            FINALIZAR
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense justify="center">
            <v-card dense v-for="(producto, i) in productos" :key="i" :color="query.producto == producto.name ? 'primary': ''" :dark="query.producto == producto.name" tile @click="addQueryBuilder('producto', producto.name)">
                <v-card-text class="text-subtitle-2 text-center">
                    <span>{{producto.name}} ({{producto.total}})</span>
                </v-card-text>
            </v-card>
        </v-row>

        <v-expand-transition>
            <v-row v-if="query.producto != null" dense class="d-flex justify-space-around mt-4">
                <v-col cols="12">
                    <v-card dense >
                        <v-card-text>
                            <v-form ref="filters">
                                <v-row>
                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Producto aseguradora"
                                            v-model="query.aseguradora"
                                            :items="IdBradescard"
                                            hide-details
                                            outlined
                                            dense
                                            :rules="[$rules.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    
                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Etiqueta"
                                            :items="etiquetas"
                                            v-model="query.etiqueta"
                                            hide-details
                                            outlined
                                            dense
                                            :rules="[$rules.required]"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Corte"
                                            :items="cortes"
                                            v-model="query.corte"
                                            hide-details
                                            multiple
                                            outlined
                                            dense
                                            :rules="[$rules.required]"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Base mes"
                                            :items="base_mes_disponibles"
                                            v-model="query.base_mes"
                                            item-value="value"
                                            item-text="name" 
                                            hide-details
                                            multiple
                                            outlined
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Intentos"
                                            :items="intentos"
                                            v-model="query.intentos"
                                            hide-details
                                            multiple
                                            outlined
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Última disposición"
                                            :items="ultimasDisposiciones"
                                            v-model="query.ultima_disposicion"
                                            hide-details
                                            item-text="disposicion"
                                            outlined
                                            dense
                                            multiple
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="6" md="3">
                                        <v-autocomplete
                                            label="Operador"
                                            v-model="query.operador_id"
                                            :items="agentes"
                                            item-value="id"
                                            hide-details
                                            outlined
                                            dense
                                            clearable
                                        >
                                            <template v-slot:item="{item}">
                                                {{item.nombre}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}
                                            </template>
                                            <template v-slot:selection="{item}">
                                                {{item.nombre}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="clearFilters" small>
                                <v-icon>mdi-trash</v-icon>Limpiar filtros
                            </v-btn>
                            <v-btn color="primary" @click="LoadLeads" small>
                                <v-icon>mdi-magnify</v-icon>Buscar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-expand-transition>

        <v-row v-if="query.producto == null" class="mt-2">
            <v-col cols="12">
                <v-alert dense text type="info">
                    Debe seleccionar un producto
                </v-alert>
            </v-col>
        </v-row>

        <v-row dense class="mt-3 mb-12" v-if="leads != null"> 
            <v-col cols="12">
                <v-card flat>
                    <v-data-table
                        :headers="columns"
                        :items="leads"
                        item-key="name"
                        class="elevation-1"
                        :search="search"
                        :loading='loading'
                        loading-text="Cargando..."
                    >
                        <template v-slot:top>
                            <v-toolbar dense flat>
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" plain :disabled="leads.length <= 0">
                                            <v-icon>mdi-clipboard-text</v-icon>Asignación leads
                                        </v-btn>
                                    </template>

                                    <v-card dense v-if="leads.length > 0">
                                        <v-card-text>
                                            <v-form ref="asignacion">
                                                <v-row dense>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            label="No. Registros por asignar"
                                                            dense
                                                            v-model.number="form.registros"
                                                            :rules="[$rules.menorIgual(leads.length)]"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-autocomplete
                                                            label="Asignar a"
                                                            v-model="form.operadorId"
                                                            :items="agentesActivos"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            hide-details
                                                            dense
                                                        >
                                                            <template v-slot:item="{item}">
                                                                {{item.nombre}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}
                                                            </template>
                                                            <template v-slot:selection="{item}">
                                                                {{item.nombre}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}
                                                            </template>
                                                        </v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card-text>
                                        
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" small @click="asignacion">
                                                Asignar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                                <v-spacer></v-spacer>

                                <v-text-field
                                    class="mt-4"
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    dense
                                ></v-text-field>
                            </v-toolbar>
                        </template>

                        <template #[`item.mail`]="{ item }">
                            <span v-email="item.correo"></span>
                        </template>

                        <template #[`item.actions`]="{ item }">
                            <v-btn icon :to="{name: 'DetalleLeadBradescard', params:{uuid:item.uuid}}">
                                <v-icon small class="mr-2" >mdi-eye</v-icon>
                            </v-btn>
                        </template>

                        <template #no-data>
                            Se tiene que seleccionar todos los filtros
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="LeadsModal" max-width="500px">
            <v-card>
            <v-card-title>
                {{ mensajeData }}
            </v-card-title>

            <v-card-actions>
                <v-btn color="primary" text @click="backIndex">Cerrar</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    
    import moment from "moment";
import {ListExpanded} from "./../../../../../plugins/mixins";
    export default {
        name:'Bradescard.ListaLeads',
        props: {
            status: {
                type: String,
                default: ""
            }
        },
        mixins: [ListExpanded],
        data:function(){
            return {
                mensajeData: null,
                LeadsModal: false,
                time: null,
                cortes: [],
                menu: false,
                loading: false,
                search: '',
                leads: [],
                agentesActivos:[],
                columns: [
                    { text: 'Estatus Lead', value: 'status' },
                    { text: 'Ultima disposición', value: 'ultimaDisposicion' },
                    { text: 'Número de llamadas Realizadas', value: 'numLlamadas' },
                    // { text: '4UD', value: 'fourUD' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Ciudad', value: 'ciudad' },
                    { text: 'Estado', value: 'estado' },
                    { text: 'Corte', value: 'corte' },
                    { text: 'Edad', value: 'edad' },
                    { text: 'Correo electronico', value: 'mail' },
                    { text: 'Ver', value: 'actions' },
                ],
                query: {
                    status: null,
                    producto: null,
                    aseguradora: null,
                    etiqueta: null,
                    corte: null,
                    ultima_disposicion: null,
                    operador_id: null,
                    intentos:null,
                    base_mes:null,
                },

                form: {
                    operadorId: null,
                    registros: null
                },
                base_mes_disponibles:[],
                intentos:[]
            }
        }, async mounted(){
            if(this.status != ""){ // verifico que no se haya un status
                if( localStorage.getItem('status') ){ // verifico que exista el status en localstore
                    if(this.status == localStorage.getItem('status')) { // verifico si el valor que esta en localstorage, le asigno al query
                        this.query.status = localStorage.getItem('status');
                    }else{ // en caso de que sea diferente, agrego el nuevo dato
                        localStorage.setItem('status', this.status);
                        this.query.status = this.status;
                    }
                }else{ // en caso de que no exista, agrego el status
                    localStorage.setItem('status', this.status);
                    this.query.status = this.status;
                }
            }else{ // en caso de que no se haya enviado un status, tomo el localstore
                this.query.status = localStorage.getItem('status');
            }
            this.getProductos();
            this.getAgentes(0);
            this.getAgentes(1);
            this.getProductosAseguradora();
            this.getDisposiciones();
        }, methods: {
            async getProductosAseguradora(){
                let {data} = await this.$axios.get(`v1/bradescard/aseguradoras `,{
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                });
                this.IdBradescard = [];
                this.cortes = [];
                for(let i in data.aseguradoras){
                    this.IdBradescard.push(i);
                }
                 for(let i in data.cortes){
                    this.cortes.push(i);
                }
                for(let i in data.base_mes){
                    let fecha = moment(i).format('DD/MM/YYYY')
                    if(fecha != 'Invalid date')
                        this.base_mes_disponibles.push({value:i,name:fecha})
                }
                for(let i in data.intentos){
                    this.intentos.push(i)
                }
            },
            async getDisposiciones(){
                let {data} = await this.$axios.get(`v1/bradescard/disposicion/list `,{
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                });
                this.ultimasDisposiciones = data;
            },
            backIndex(){
                clearTimeout(this.time);
                this.LeadsModal = false;
                this.$router.go(-1)
            },
            addQueryBuilder(type, value){
                if(this.query.producto != value){
                    this.leads = [];
                }
                this.query.producto = value;
            },
            async clearFilters(){
                this.query = {};
                Object.assign(this.query,{
                    status: null,
                    producto: null,
                    aseguradora: null,
                    etiqueta: null,
                    corte: null,
                    ultima_disposicion: null,
                    operador_id: null,
                    intentos:null,
                    base_mes:null,
                })
                if(this.loadFilters){
                    this.filterLeads();
                }
                this.loadFilters = !this.loadFilters
            },
            async getAgentes(status){
                try{
                    this.agentes = [];
                    this.agentesActivos = [];

                    let {data} = await this.$axios.get(`v1/agente/operadores/${status}`,{
                        headers: {
                            Authorization: "Bearer " + localStorage.agenteAccessToken,
                        },
                    });
                    if(status == 0){
                        this.agentes = [...data];
                    }else if(status == 1){
                        this.agentesActivos = [...data]
                    }
                }catch(exception){
                    console.error(exception);
                }
            },
            async getProductos(){
                try{
                    let {data} = await this.$axios.get('v1/bradescard/productos',{
                        headers: {
                            Authorization: "Bearer " + localStorage.agenteAccessToken,
                        },
                    });
                    this.productos = [];
                    for(let key in data.status){
                        this.productos.push({
                            name:key,
                            total: data.status[key]
                        });
                    }
                }catch(exception){
                    console.error(exception);
                }
            },
            async LoadLeads(){
                try{
                    let valid = this.$refs.filters.validate();
                    if(!valid){
                        return false;
                    }
                    let selectUltimaDisposicion = this.query.ultima_disposicion;
                    if(selectUltimaDisposicion != null){
                        if(selectUltimaDisposicion.length <= 0){
                            this.query.ultima_disposicion == null;
                        }
                    }

                    this.loading = true;
                    let {data} = await this.$axios.post(`v1/bradescard/list/${localStorage.agenteUuid}`, this.query, {
                        headers: {
                            Authorization: "Bearer " + localStorage.agenteAccessToken,
                        },
                    });

                    this.loading = false;
                    this.leads = [...data];
                }catch(exception){
                    this.loading = false;
                    console.error(exception);
                }
            },
            async asignacion(){
                try{
                    let valid = this.$refs.asignacion.validate();
                    if(!valid){
                        return false;
                    }

                    let rand = this.randomLeads();
                    this.form.listaBardescard = rand;
                    let {data} = await this.$axios.post("v1/bradescard/update/random", this.form, {
                        headers: {
                            Authorization: "Bearer " + localStorage.agenteAccessToken,
                        },
                    });
                
                    console.log(data);
                    this.LeadsModal = true;
                    this.mensajeData = "Se han asignados los leads al agente"
                    
                    this.time = setTimeout(()=>{
                        this.LeadsModal = false;
                        this.$router.go(-1)
                    }, 10000);
                    
                }catch(exception){
                    console.error(exception);
                }
            },
            randomLeads(){
                let number = new Set();
                do{
                    number.add(Math.floor(
                        Math.random() * (this.leads.length)
                    ));
                }while(number.size != this.form.registros);
                let leadsRandom = [];
                number.forEach((item)=>{
                    leadsRandom.push(this.leads[item]);
                });
                return leadsRandom;
            }
        }
    }
</script>

<style>

</style>