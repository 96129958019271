<template>
  <v-dialog v-model="show" persistent max-width="700">
    <v-card>
      <v-toolbar color="primary" dense dark flat tile>
        <v-toolbar-title>
          <span>Nueva solicitud</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <solicitudForm
          :emision="emision"
          :route="route"
          @cancel="$emit('close')"
        ></solicitudForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import solicitudForm from "./solicitudForm.vue";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    emision: {
      type: Object,
    },
    route:{
      type:String
    },
  },

  components: {
    solicitudForm,
  },
};
</script>
