<template>
  <div>
    <v-menu v-if="habilitarBoton" offset-y left :close-on-content-click="false">
      <template #activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" color="primary" block>
          <v-icon small class="mr-2">mdi-phone-plus</v-icon>
          <span>Añadir teléfono de contacto</span>
        </v-btn>
      </template>
      <v-card class="px-2 py-4">
        <v-alert v-if="error" type="error" dense>
          <span>{{ error }}</span>
        </v-alert>
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-text-field
            v-model="formData.telefono"
            label="Teléfono a guardar"
            dense
            filled
            type="number"
            prepend-inner-icon="mdi-phone"
            :maxLength="10"
            :rules="[$rules.min(10), $rules.max(10)]"
            @keypress.enter.prevent="save()"
          ></v-text-field>
        </v-form>
        <div class="d-flex justify-end">
          <v-btn
            color="success darken-3"
            small
            :loading="loading"
            :disabled="!isFormValid || isCurrentlySaved"
            @click="save()"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            <span>{{
              isCurrentlySaved ? "Teléfono ya guardado" : "Guardar"
            }}</span>
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  crearTelefonoContacto,
  obtenerTelefonosContacto,
} from "./telefonoContactoService";

export default {
  props: {
    tarjeta: {
      type: [String, Number],
      required: true,
    },
    telefono: {
      type: [String, Number],
    },
    leadId: {
      type: [Number],
    },
    emisionId: {
      type: [Number],
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      isFormValid: false,
      telefonosContacto: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      formData: {
        tarjeta: this.tarjeta,
        telefono: this.telefono,
        leadId: this.leadId,
        emisionId: this.emisionId,
      },
    };
  },

  computed: {
    isCurrentlySaved() {
      return !!this.telefonosContacto.find(
        (tel) => tel.telefono == this.formData.telefono
      );
    },

    habilitarBoton() {
      const rolesPermitidos = ["OPERADOR", "OPERACIONES", "ADMIN"];
      return rolesPermitidos.some((rol) => rol == this.rol);
    },
  },

  mounted() {
    this.loadCurrentTelefonos();
  },

  methods: {
    async save() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      this.loading = true;
      this.error = null;
      try {
        const response = await crearTelefonoContacto(this.formData);
        this.telefonosContacto.push(response.telefonoContacto);
        this.$toast.success(
          "Teléfono agregado con éxito",
          {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    async loadCurrentTelefonos() {
      this.loading = true;
      this.error = null;
      try {
        const query = { ...this.formData };
        delete query.telefono;
        const response = await obtenerTelefonosContacto(query);
        this.telefonosContacto = response.telefonosContacto;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
