<template>
    <v-card color="grey lighten-4" flat tile>
        <v-toolbar>
            <v-toolbar-title>
                Crear Nueva Solicitud
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn @click="goBack()" icon>
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-toolbar>

        <v-tabs fixed-tabs background-color="primary" dark>
            <v-tab>
                Tarjeta comercial
            </v-tab>
            <v-tab>
                Información requerida
            </v-tab>
            <v-tab>
                Seguimiento de la solicitud
            </v-tab>
            <v-tab>
                Mostrar más
            </v-tab>
        </v-tabs>
    </v-card>
</template>

<script>

export default {
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
    },

}
</script>

<style>
</style>
