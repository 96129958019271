<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>
            Reporte de Sura Movilidad
          </h1>
        </v-col>
      </v-row>
      <v-row class="mb-15" style="padding: 30px">
        <v-col cols="12" lg="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="generateReport">Generar Reporte</v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px" :disabled="!report.length > 0">
            <download-csv :data="reportDownload" name="reporteSuraMovilidad.csv">
              Descargar Información
            </download-csv>
          </v-btn>


        </v-col>
        <v-col cols="12" lg="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table v-if="!loading && reportType == 'lead'" :headers="columns" :items="report" item-key="name"
            class="elevation-1" :search="search" :custom-filter="filterOnlyCapsText" :loading="loading"
            loading-text="Cargando...">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require('moment-timezone');

export default {
  data() {
    return {
      roles: JSON.parse(localStorage.getItem('roles')),
      uuid: localStorage.getItem('agenteUuid'),
      dates: [],
      downloadText: 'Descargar Reporte En Excel',
      downloading: false,
      loading: false,
      reportType: 'lead',
      report: [],
      reportDownload: [],
      search: '',
      columns: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Uuid', value: 'uuid', },
        { text: 'Medio', value: 'Medio' },
        { text: 'Fecha de envio', value: 'Fecha envio' },
        { text: 'Nombre', value: 'Nombre' },
        { text: 'Apellido Paterno', value: 'Apellido Paterno' },
        { text: 'Apellido Materno', value: 'Apellido Materno' },
        { text: 'Telefono', value: 'Telefono' },
        { text: 'Correo', value: 'Correo' },
        { text: 'Codigo Postal', value: 'Codigo Postal' },
        { text: 'Sexo', value: 'Sexo' },
        { text: 'Fecha nacimiento', value: 'Fecha nacimiento' },
        { text: 'Correo', value: 'Correo' },
        { text: 'Modelo', value: 'Modelo' },
        { text: 'Submarca', value: 'Submarca' },
        { text: 'Version', value: 'Version' },
        { text: 'Fecha contratacion', value: 'Fecha contratacion' },
        { text: 'Fecha', value: 'Fecha' },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz('UTC', true);
      return dateRes.tz('America/Mexico_City').format('DD-MM-YYYY HH:mm');
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    generateReport() {
      let vm = this;
      vm.report = []
      vm.reportType = 'lead';
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
        //restar 6 horas al reques
        //
      }

      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      vm.loading = true;
      this.$axios
        .post('/v1/reportes/leadsMovilidad', fechas, config)
        .then((response) => {
          response.data.map((element) => {
            vm.report.push({
              'id': element.id,
              'uuid': element.uuid,
              'Medio': element.medio,
              'Fecha envio': element.fechaEnvio ? element.fechaEnvio : 'NA',
              'Nombre': element.nombre,
              'Apellido Paterno': element.apellidoPaterno,
              'Apellido Materno': element.apellidoMaterno,
              'Telefono': element.telefono,
              'Correo': element.correo,
              'Codigo Postal': element.cp,
              'Sexo': element.sexo,
              'Fecha nacimiento': element.fechaNacimiento,
              'Modelo': element.modelo,
              'Submarca': element.submarca,
              'Version': element.version,
              'Fecha contratacion': element.fechaContratacion,
              'Fecha': moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            });
          })
          response.data.map((element) => {
            vm.reportDownload.push({
              'id': element.id,
              'uuid': element.uuid,
              'Medio': element.medio,
              'Fecha envio': element.fechaEnvio ? element.fechaEnvio : 'NA',
              'Nombre': element.nombre,
              'Apellido Paterno': element.apellidoPaterno,
              'Apellido Materno': element.apellidoMaterno,
              'Telefono': element.telefono,
              'Correo': element.correo,
              'Codigo Postal': element.cp,
              'Sexo': element.sexo,
              'Fecha nacimiento': element.fechaNacimiento,
              'Modelo': element.modelo,
              'Submarca': element.submarca,
              'Version': element.version,
              'Fecha contratacion': element.fechaContratacion,
              'Fecha': moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            });
          })

          vm.loading = false;
        });
    },

    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
