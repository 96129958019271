export const getAgenteAccessToken = () => {
  return localStorage.getItem("agenteAccessToken");
};

export const getDefaultAuthConfig = () => {
  return {
    Authorization: `Bearer ${getAgenteAccessToken()}`,
  };
};

export const getDefaultHeaders = () => {
  return {
    headers: {
      ...getDefaultAuthConfig(),
    },
  };
};

export const getErrorMessage = (error) => {
  if (error.response?.data?.message) return error.response.data.message;
  else {
    console.error(error);
    return "Error inesperado";
  }
};
