<template>
    <v-form>
        <v-row style="padding: 10px">
            <v-col cols="4">
                <v-select
                    label="Tipo de Inmueble"
                    placeholder="Requerido"
                    :items="tipoInmuebleItems"
                    v-model="tipoInmueble"
                ></v-select>
            </v-col>
            <v-col cols="4" v-if="tipoInmueble == 'Otro'">
                <v-text-field
                    label="Indicar que tipo"
                    placeholder="Requerido"
                    v-model="otroTipo"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Valor del inmueble"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="valorInmueble"
                ></v-text-field>
            </v-col>
            <v-col cols="4" v-if="tipoInmueble == 'Departamento'">
                <v-text-field
                    label="Nivel del departamento"
                    placeholder="Requerido"
                    type="number"
                    v-model="nivelDepartamento"
                ></v-text-field>
            </v-col>

            <v-col cols="4" v-if="tipoInmueble == 'Departamento'">
                <v-text-field
                    label="Pisos Totales"
                    placeholder="Requerido"
                    type="number"
                    v-model="pisosTotales"
                ></v-text-field>
            </v-col>
            <v-col cols="4" v-if="tipoInmueble == 'Casa'">
                <v-text-field
                    label="Número de pisos altos"
                    placeholder="Requerido"
                    type="number"
                    v-model="pisosAltos"
                ></v-text-field>
            </v-col>
            <v-col cols="4" v-if="tipoInmueble == 'Casa'">
                <v-text-field
                    label="Sotanos"
                    placeholder="Requerido"
                    type="number"
                    v-model="sotanos"
                ></v-text-field>
            </v-col>
            <!-- <v-col cols="4">
                <v-text-field
                    label="Valor del inmueble"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="valorInmueble"
                ></v-text-field>
            </v-col> -->

            <v-col cols="4">
                <v-text-field
                    label="Valor de los contenidos"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="valorContenidos"
                ></v-text-field>
            </v-col>

            <v-col cols="4">
                <v-text-field
                    label="Valor del equipo electrónico"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="valorEquipoElectronico"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Suma asegurada Responsabilidad Civil"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="sumaAseguradaRC"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Suma asegurada Cristales"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="sumaAseguradaCristales"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Suma asegurada robo con violencia"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="sumaAseguradaRoboConViolencia"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Suma asegurada dinero y valores"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="sumaAseguradaDineroValores"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Suma asegurada obras de arte o piezas de difícil reposición"
                    placeholder="Requerido"
                    prefix="$"
                    v-model="sumaAseguradaPiezasDificilReposicion"
                ></v-text-field>
            </v-col>

            <!-- ALERT MODAL -->
            <v-col cols="auto">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="400"
                v-model="dialog"
            >
                <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar color="success" dark
                    >Confirmación de envío</v-toolbar
                    >
                    <v-card-text>
                    <div class="text-h4" style="margin-top: 10px; ">
                        Envio de solicitud exitoso!
                    </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                    <v-btn
                        text
                        @click="
                        (dialog.value = false), $router.push('/solicitudes')
                        "
                        >Cerrar</v-btn
                    >
                    </v-card-actions>
                </v-card>
                </template>
            </v-dialog>
            </v-col>
            <!-- END OF ALERT MODAL -->
        </v-row>
        <v-row style="padding: 10px">
            <v-col cols="6">
                <v-text-field
                    v-model="creadoPor"
                    label="Creado por"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="fechaCreacion"
                    label="Fecha de Creacion"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col cols="6" style="margin-top: -30px">
                <v-text-field
                    v-model="modificadoPor"
                    label="Modificado Por"
                    readonly
                ></v-text-field>
            </v-col>
            <v-col cols="6" style="margin-top: -30px">
                <v-text-field
                    v-model="fechaModificacion"
                    label="Fecha de Modificacion"
                    readonly
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn
                    color="primary"
                    @click="sendSolicitud()"
                    :disabled="!comportamientoBotonGuardar()"
                >
                    Guardar
                </v-btn>
                <v-btn
                    color="primary" 
                    style="margin-left: 10px"
                    v-show="rolToCompare != 'AGENT'"
                >
                    Siguiente
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        origenSolicitud: String,
        statusSolicitud: String,
        agente: Number,
        tipo: String,
        ramo: String,
        producto: Number,
        detalleSolicitud: String,
        nombres: String,
        apellidoPaterno: String,
        apellidoMaterno: String,
        estadoCivil: String,
        estadoNacimiento: String,
        genero: String,
        fechaNacimiento: String,
        rfc: String,
        tipoPersona: String,
        telefono: String,
        correo: String,
        codigoPostal: String,
        direccion: String,
        inicioVigencia: String,
        finVigencia: String,
        periodicidad: String,
        formaPago: String,
        operador: Number,
        solicitudCompleta: String,
        procesoCotizacion: String,
        solicitudId: Number,
        bodyDanios: Object,
        checkTramite: Boolean,
        checkCotizacionRenovacionTerminada: Boolean,
        checkRecotizar: Boolean,
        checkCotizacionRenovacionAprobada: Boolean,
        checkCotizacionRenovacionRechazada: Boolean,
        checkPendienteInfo: Boolean,
        checkEmisionFinalizada: Boolean,
        checkFinalizada: Boolean,
        checkCancelacion: Boolean,
        motivoCancelacion: String,
        motivoRecotizacionRechazo: String,
        causaPendienteInformacion: String,
        valorOportunidad: String,
        documentos: Array,
        comercialAsignado: Number,
        creadoPor: String,
        fechaCreacion: String,
        modificadoPor: String,
        fechaModificacion: String,
        aseguradoraId: Number,
        razonSocial: String,
    },
    data() {
        return {
            rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
            dialog: false,
            tipoInmueble: this.bodyDanios.tipoInmueble ? this.bodyDanios.tipoInmueble : null,
            otroTipo: this.bodyDanios.otroTipo ? this.bodyDanios.otroTipo : null,
            valorInmueble: this.bodyDanios.valorInmueble ? this.bodyDanios.valorInmueble : null,
            nivelDepartamento: this.bodyDanios.nivelDepartamento ? this.bodyDanios.nivelDepartamento : null,
            pisosTotales: this.bodyDanios.pisosTotales ? this.bodyDanios.pisosTotales : null,
            pisosAltos: this.bodyDanios.numeroPisosAltos ? this.bodyDanios.numeroPisosAltos : null,
            sotanos: this.bodyDanios.sotanos ? this.bodyDanios.sotanos : null,
            valorContenidos: this.bodyDanios.valorContenidos ? this.bodyDanios.valorContenidos : null,
            valorEqupioElectronico: this.bodyDanios.valorEquipoElectronico ? this.bodyDanios.valorEquipoElectronico : null,
            sumaAseguradaRC: this.bodyDanios.sumaAseguradaRC ? this.bodyDanios.sumaAseguradaRC : null,
            sumaAseguradaCristales: this.bodyDanios.sumaAseguradaCristales ? this.bodyDanios.sumaAseguradaCristales : null,
            sumaAseguradaRoboConViolencia: this.bodyDanios.sumaAseguradaRoboViolencia ? this.bodyDanios.sumaAseguradaRoboViolencia : null,
            sumaAseguradaDineroValores: this.bodyDanios.sumaAseguradaDineroValores ? this.bodyDanios.sumaAseguradaDineroValores : null,
            sumaAseguradaPiezasDificilReposicion: this.bodyDanios.sumaAseguradaObrasDeArte ? this.bodyDanios.sumaAseguradaObrasDeArte : null,
            tipoInmuebleItems: [
                'Casa',
                'Departamento',
                'Bodega',
                'Edificio',
                'Otro',
            ],
        }
    },
    watch: {
        dialog(val) {
            if (!val) return;

            setTimeout(() => (this.dialog = false), 2500);
        },
    },
    methods: {
        cargarArchivos() {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    'Content-Type': 'multipart/form-data'
                },
            };
            for( var i = 0; i < this.documentos.length; i++ ){
                const formData = new FormData();
                let file = this.documentos[i];
                let nota = 'Solicitud #' + `${this.solicitudId}` + ' Cotización Enviada';
                const tipo = 'Documento';
                const tipoDocumento = 'Cotización';
                formData.append('file', file);
                formData.append('nota', nota);
                formData.append('nombre', nota);
                formData.append('tipo', tipo);
                formData.append('tipo_documento', tipoDocumento);
                this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData,config)
                .then(function (result) {
                    console.log(result);
                }, function (error) {
                    console.log(error);
                });
            }
        },
        crearNotaRechazoCancelacion() {
            const config = {
                headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
                'Content-Type': 'multipart/form-data'
                },
            };
            const formData = new FormData();
            const tipo = 'Nota';
            let nota = 'Solicitud #' + `${this.solicitudId}` + ' Rechazada o cancelada'
            formData.append('nota', this.motivoRecotizacionRechazo);
            formData.append('tipo', tipo);
            formData.append('nombre', nota);
            this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData,config)
            .then(function (result) {
                console.log(result);
            }, function (error) {
                console.log(error);
            });
        },
        crearNotaPendienteInformacion(){
            const config = {
                headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
                'Content-Type': 'multipart/form-data'
                },
            };
            const formData = new FormData();
            const tipo = 'Nota';
            let nota = 'Solicitud #' + `${this.solicitudId}` + ' Rechazada o cancelada'
            formData.append('nota', this.causaPendienteInformacion);
            formData.append('tipo', tipo);
            formData.append('nombre', nota);
            this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData,config)
            .then(function (result) {
                console.log(result);
            }, function (error) {
                console.log(error);
            });
        },
        sendSolicitud() {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };

            if (this.documentos.length > 0) {
                this.cargarArchivos()
            }

            if (this.checkCotizacionRenovacionRechazada || this.checkRecotizar) {
                this.crearNotaRechazoCancelacion()
            }

            if (this.checkPendienteInfo) {
                this.crearNotaPendienteInformacion()
            }

            let request = {
                origenSolicitudSelected: this.origenSolicitud,
                tipoSelected: this.tipo ? this.tipo : "",
                categoriaSelected: this.ramo ? this.ramo : "",
                agenteId: this.agente ? this.agente : "",
                productoId: this.producto ? this.producto : "",
                detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
                operadorUuid: this.operador ? this.operador : "",
                nombres: this.nombres ? this.nombres : "",
                apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
                apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
                fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
                genero: this.genero ? this.genero : "",
                inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
                finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
                celular: this.telefono ? this.telefono : "",
                correoElectronico: this.correo ? this.correo : "",
                direccion: this.direccion ? this.direccion : "",
                codigoPostal: this.codigoPostal ? this.codigoPostal : "",
                estadoRepublicaSelected: this.estadoNacimiento ? this.estadoNacimiento : "",
                estadoCivilSelected: this.estadoCivil ? this.estadoCivil : "",
                rfc: this.rfc ? this.rfc : '',
                periodicidadPago: this.periodicidad == 'Anual' ? 1
                                : this.periodicidad == 'Semestral' ? 2
                                : this.periodicidad == 'Trimestral' ? 3
                                : this.periodicidad == 'Mensual' ? 4 : '',
                formaPagoSelected: this.formaPago ? this.formaPago : "",
                solicitudCompletaSelected: this.solicitudCompleta ? this.solicitudCompleta : "",
                procesoCotizacionSelected: this.procesoCotizacion ? this.procesoCotizacion : '',
                checkTramite: this.checkTramite,
                checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
                checkRecotizar: this.checkRecotizar,
                checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
                checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
                checkPendienteInfo: this.checkPendienteInfo,
                checkEmisionFinalizada: this.checkEmisionFinalizada,
                checkFinalizada: this.checkFinalizada,
                checkCancelacion: this.checkCancelacion,
                motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
                motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
                causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
                valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
                regimen_persona: this.tipoPersona,
                comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
                razon_social: this.razonSocial ? this.razonSocial : null,

                //REQUEST DE DAÑOS
                tipoInmueble: this.tipoInmueble,
                otroTipo: this.otroTipo ? this.otroTipo : null,
                valorInmueble: this.valorInmueble,
                nivelDepartamento: this.nivelDepartamento,
                pisosTotales: this.pisosTotales,
                numeroPisosAltos: this.pisosAltos,
                sotanos: this.sotanos,
                valorContenidos: this.valorContenidos,
                valorEquipoElectronico: this.valorEquipoElectronico,
                sumaAseguradaRC: this.sumaAseguradaRC,
                sumaAseguradaCristales: this.sumaAseguradaCristales,
                sumaAseguradaRoboViolencia: this.sumaAseguradaRoboConViolencia,
                sumaAseguradaDineroValores: this.sumaAseguradaDineroValores,
                sumaAseguradaObrasDeArte: this.sumaAseguradaPiezasDificilReposicion,
            };

            this.$axios
            .post("v1/solicitud/update/" + this.solicitudId, request, config)
            .then((response) => {
            console.log("Entro!");
            if (response.status == 200) {
                if (this.checkCotizacionRenovacionAprobada == true) {
                    this.generarPrePoliza();
                }
                this.dialog = true;
            }
            setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.solicitudId}`)), 3000);
            })
            .catch((error) => {
            console.log(error);
            });
        },
        generarPrePoliza() {
            if (this.checkCotizacionRenovacionAprobada == true) {
                let config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                };
                
                let request = {
                    /* CAMPOS PARA TODAS LAS EMISIONES */
                    apellido_materno: this.apellidoMaterno ? this.apellidoMaterno : '',
                    apellido_paterno: this.apellidoPaterno ? this.apellidoPaterno : '',
                    aseguradora_id: this.aseguradoraId,
                    compania: "AXA",
                    cliente: null,
                    agente: this.agente ? this.agente : null,
                    codigo_postal: this.codigoPostal ? this.codigoPostal : '',
                    correo_electronico: this.correo ? this.correo : '',
                    cotizacion: null,
                    creado_por: this.operador,
                    detalle: this.detalleSolicitud ? this.detalleSolicitud : '',
                    ejecutivo_de_operaciones: this.operador ? this.operador : '',
                    estado_id: 9,
                    fecha_cancelacion: null,
                    fecha_emision: null,
                    fecha_finalizacion: null,
                    fecha_primer_pago: moment(new(Date)).format('YYYY-MM-DD'),
                    fecha_nacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
                    fin: moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    forma_pago: this.formaPago == 'Tarjeta de Crédito' ? 1
                                : this.formaPago == 'Tarjeta de Débito' ? 2
                                : this.formaPago == 'Deposito Bancario' ? 3
                                : this.formaPago == 'Transferencia' ? 4
                                : this.formaPago == 'Cheque' ? 5
                                : this.formaPago == 'Efectivo' ? 6
                                : this.formaPago == 'Domiciliación' ? 7 : '',
                    genero: this.genero ? this.genero : '',
                    inicio: moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    modificado_por: null,
                    motivo_cancelacion: null,
                    motivo_finalizacion: null,
                    nombre: this.nombres,
                    nombre_cliente: this.nombres ? this.nombres : '',
                    notas: "pruebaNotas",
                    observaciones: "pruebaO",
                    operador_id: this.operador ? this.operador : '',
                    origen: this.origenSolicitud ? this.origenSolicitud : '',
                    origen_auto: "Nacional",
                    pago_id: this.periodicidad == 'Anual' ? 1
                                    : this.periodicidad == 'Semestral' ? 2
                                    : this.periodicidad == 'Trimestral' ? 3
                                    : this.periodicidad == 'Mensual' ? 4 : '',
                    /* */ poliza_anterior: null,
                    /* */ poliza_cancelada: null,
                    /* */ poliza_finalizada: null,
                    /* */ prima_neta: 0,
                    /* */ prima_total: 0,
                    /* */ primer_pago: 0,
                    /* */ primer_pago_poliza: 0,
                    producto_id: this.producto ? this.producto : '',
                    /* */ status: 0,
                    status_cobro: "",
                    subsecuente_pago: 0,
                    telefono: this.telefono ? this.telefono : '',
                    tipo_persona: this.tipoPersona ? this.tipoPersona : null,
                    /* */ tipo_poliza: this.tipo == 'Cotización' || this.tipo == 'Emisión' ? 'Nueva' : 
                                        this.tipo == 'Renovación' ? 'Renovación' : '',
                    ultima_modificacion: null,
                    url: null,
                    rfc: this.rfc ? this.rfc : '',
                    solicitud_id: this.solicitudId,
                    comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
                    razon_social: this.razonSocial ? this.razonSocial : null,
                    /* FIN DE CAMPOS PARA TODAS LAS EMISIONES */

                    /* EMISIONES EN DAÑOS */
                    tipoInmueble: this.tipoInmueble,
                    otroTipo: this.otroTipo ? this.otroTipo : null,
                    valorInmueble: this.valorInmueble,
                    nivelDepartamento: this.nivelDepartamento,
                    pisosTotales: this.pisosTotales,
                    numeroPisosAltos: this.pisosAltos,
                    sotanos: this.sotanos,
                    valorContenidos: this.valorContenidos,
                    valorEquipoElectronico: this.valorEquipoElectronico,
                    sumaAseguradaRC: this.sumaAseguradaRC,
                    sumaAseguradaCristales: this.sumaAseguradaCristales,
                    sumaAseguradaRoboViolencia: this.sumaAseguradaRoboConViolencia,
                    sumaAseguradaDineroValores: this.sumaAseguradaDineroValores,
                    sumaAseguradaObrasDeArte: this.sumaAseguradaPiezasDificilReposicion,
                };

                this.$axios
                .post('/v1/emision/manual', request, config)
                .then(response => {
                    console.log('Entro a generar pre-poliza', response);
                })
                .catch(error => {
                    console.log(error);
                });
            }
        },
        comportamientoBotonGuardar() {
            if (this.tipoInmueble == 'Otro') {
                return this.botonGuardarOtroTipo
            }
            if (this.tipoInmueble == 'Departamento') {
                return this.botonGuardarDepartamento
            }
            if (this.tipoInmueble != 'Otro' && this.tipoInmueble != 'Departamento') {
                return this.botonGuardar
            }
        },
    },
    computed: {
        botonGuardar() {
            if (this.tipoPersona == 'Moral') {
                return(
                    (this.origenSolicitud != null && this.origenSolicitud != '') &&
                    (this.statusSolicitud != null && this.statusSolicitud != '') &&
                    (this.agente != null && this.agente != '') &&
                    (this.tipo != null && this.tipo != '') &&
                    (this.ramo != null && this.ramo != '') &&
                    (this.detalleSolicitud != null && this.detalleSolicitud != '') &&
                    (this.tipoPersona != null && this.tipoPersona != '') &&
                    (this.razonSocial != null && this.razonSocial != '') &&
                    (this.estadoNacimiento != null && this.estadoNacimiento != '') &&
                    (this.rfc != null && this.rfc != '') &&
                    (this.telefono != null && this.telefono != '') &&
                    (this.correo != null && this.correo != '') &&
                    (this.codigoPostal != null && this.codigoPostal != '') &&
                    (this.direccion != null && this.direccion != '') &&
                    (this.inicioVigencia != null && this.inicioVigencia != '') &&
                    (this.finVigencia != null && this.finVigencia != '') &&
                    (this.periodicidad != null && this.periodicidad != '') &&
                    (this.formaPago != null && this.formaPago != '') &&

                    /* CAMPOS DEL RAMO */
                    this.tipoInmueble != null &&
                    this.valorInmueble != null
                )
            }
            return(
                (this.origenSolicitud != null && this.origenSolicitud != '') &&
                (this.statusSolicitud != null && this.statusSolicitud != '') &&
                (this.agente != null && this.agente != '') &&
                (this.tipo != null && this.tipo != '') &&
                (this.ramo != null && this.ramo != '') &&
                (this.producto != null && this.producto != '') &&
                (this.detalleSolicitud != null && this.detalleSolicitud != '') &&
                (this.nombres != null && this.nombres != '') &&
                (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
                (this.genero != null && this.genero != '') &&
                (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
                (this.estadoNacimiento != null && this.estadoNacimiento != '') &&
                (this.estadoCivil != null && this.estadoCivil != '') &&
                (this.tipoPersona != null && this.tipoPersona != '') &&
                (this.rfc != null && this.rfc != '') &&
                (this.telefono != null && this.telefono != '') &&
                (this.correo != null && this.correo != '') &&
                (this.codigoPostal != null && this.codigoPostal != '') &&
                (this.direccion != null && this.direccion != '') &&
                (this.inicioVigencia != null && this.inicioVigencia != '') &&
                (this.finVigencia != null && this.finVigencia != '') &&
                (this.periodicidad != null && this.periodicidad != '') &&
                (this.formaPago != null && this.formaPago != '') &&

                /* CAMPOS DEL RAMO */
                this.tipoInmueble != null &&
                this.valorInmueble != null
            )
        },
        botonGuardarOtroTipo() {
            if (this.tipoPersona == 'Moral') {
                return(
                    (this.origenSolicitud != null && this.origenSolicitud != '') &&
                    (this.statusSolicitud != null && this.statusSolicitud != '') &&
                    (this.agente != null && this.agente != '') &&
                    (this.tipo != null && this.tipo != '') &&
                    (this.ramo != null && this.ramo != '') &&
                    (this.detalleSolicitud != null && this.detalleSolicitud != '') &&
                    (this.tipoPersona != null && this.tipoPersona != '') &&
                    (this.razonSocial != null && this.razonSocial != '') &&
                    (this.estadoNacimiento != null && this.estadoNacimiento != '') &&
                    (this.rfc != null && this.rfc != '') &&
                    (this.telefono != null && this.telefono != '') &&
                    (this.correo != null && this.correo != '') &&
                    (this.codigoPostal != null && this.codigoPostal != '') &&
                    (this.direccion != null && this.direccion != '') &&
                    (this.inicioVigencia != null && this.inicioVigencia != '') &&
                    (this.finVigencia != null && this.finVigencia != '') &&
                    (this.periodicidad != null && this.periodicidad != '') &&
                    (this.formaPago != null && this.formaPago != '') &&

                    /* CAMPOS DEL RAMO */
                    this.tipoInmueble != null &&
                    this.otroTipo != null &&
                    this.valorInmueble != null
                )
            }
            return(
                (this.origenSolicitud != null && this.origenSolicitud != '') &&
                (this.statusSolicitud != null && this.statusSolicitud != '') &&
                (this.agente != null && this.agente != '') &&
                (this.tipo != null && this.tipo != '') &&
                (this.ramo != null && this.ramo != '') &&
                (this.producto != null && this.producto != '') &&
                (this.detalleSolicitud != null && this.detalleSolicitud != '') &&
                (this.nombres != null && this.nombres != '') &&
                (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
                (this.genero != null && this.genero != '') &&
                (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
                (this.estadoNacimiento != null && this.estadoNacimiento != '') &&
                (this.estadoCivil != null && this.estadoCivil != '') &&
                (this.tipoPersona != null && this.tipoPersona != '') &&
                (this.rfc != null && this.rfc != '') &&
                (this.telefono != null && this.telefono != '') &&
                (this.correo != null && this.correo != '') &&
                (this.codigoPostal != null && this.codigoPostal != '') &&
                (this.direccion != null && this.direccion != '') &&
                (this.inicioVigencia != null && this.inicioVigencia != '') &&
                (this.finVigencia != null && this.finVigencia != '') &&
                (this.periodicidad != null && this.periodicidad != '') &&
                (this.formaPago != null && this.formaPago != '') &&
                (this.solicitudCompleta != null && this.solicitudCompleta != '') &&

                /* CAMPOS DEL RAMO */
                this.tipoInmueble != null &&
                this.otroTipo != null &&
                this.valorInmueble != null
            )
        },
        botonGuardarDepartamento() {
            if (this.tipoPersona == 'Moral') {
                return(
                    (this.origenSolicitud != null && this.origenSolicitud != '') &&
                    (this.statusSolicitud != null && this.statusSolicitud != '') &&
                    (this.agente != null && this.agente != '') &&
                    (this.tipo != null && this.tipo != '') &&
                    (this.ramo != null && this.ramo != '') &&
                    (this.detalleSolicitud != null && this.detalleSolicitud != '') &&
                    (this.tipoPersona != null && this.tipoPersona != '') &&
                    (this.razonSocial != null && this.razonSocial != '') &&
                    (this.estadoNacimiento != null && this.estadoNacimiento != '') &&
                    (this.rfc != null && this.rfc != '') &&
                    (this.telefono != null && this.telefono != '') &&
                    (this.correo != null && this.correo != '') &&
                    (this.codigoPostal != null && this.codigoPostal != '') &&
                    (this.direccion != null && this.direccion != '') &&
                    (this.inicioVigencia != null && this.inicioVigencia != '') &&
                    (this.finVigencia != null && this.finVigencia != '') &&
                    (this.periodicidad != null && this.periodicidad != '') &&
                    (this.formaPago != null && this.formaPago != '') &&

                    /* CAMPOS DEL RAMO */
                    this.tipoInmueble != null &&
                    this.valorInmueble != null &&
                    this.nivelDepartamento != null &&
                    this.pisosTotales != null
                )
            }
            return(
                (this.origenSolicitud != null && this.origenSolicitud != '') &&
                (this.statusSolicitud != null && this.statusSolicitud != '') &&
                (this.agente != null && this.agente != '') &&
                (this.tipo != null && this.tipo != '') &&
                (this.ramo != null && this.ramo != '') &&
                (this.producto != null && this.producto != '') &&
                (this.detalleSolicitud != null && this.detalleSolicitud != '') &&
                (this.nombres != null && this.nombres != '') &&
                (this.apellidoPaterno != null && this.apellidoPaterno != '') &&
                (this.genero != null && this.genero != '') &&
                (this.fechaNacimiento != null && this.fechaNacimiento != '') &&
                (this.estadoNacimiento != null && this.estadoNacimiento != '') &&
                (this.estadoCivil != null && this.estadoCivil != '') &&
                (this.tipoPersona != null && this.tipoPersona != '') &&
                (this.rfc != null && this.rfc != '') &&
                (this.telefono != null && this.telefono != '') &&
                (this.correo != null && this.correo != '') &&
                (this.codigoPostal != null && this.codigoPostal != '') &&
                (this.direccion != null && this.direccion != '') &&
                (this.inicioVigencia != null && this.inicioVigencia != '') &&
                (this.finVigencia != null && this.finVigencia != '') &&
                (this.periodicidad != null && this.periodicidad != '') &&
                (this.formaPago != null && this.formaPago != '') &&

                /* CAMPOS DEL RAMO */
                this.tipoInmueble != null &&
                this.valorInmueble != null &&
                this.nivelDepartamento != null &&
                this.pisosTotales != null
            )
        }
    }
}
</script>

<style>

</style>
