
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import creditcard from './plugins/credit-card';
import toast from './plugins/toast';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { VueMaskDirective } from 'v-mask'
import JsonCSV from 'vue-json-csv'
import * as echarts from "echarts";
import Bus from "./event";
import store from "./store/store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the CSS or use your own!
const options = {
  // You can set your default options here
};
Vue.use(Toast, options);
require("./mainAxios");

Vue.prototype.$emmited = Bus;
Vue.prototype.$chart = echarts;

Vue.directive('email', {
  bind(el, binding) {
    if (binding.value == null) {
      return 'Sin correo electronico'
    }
    if (binding.value == "") {
      el.textContent = null;
      return null;
    }

    let emailBody = binding.value.split("@")
    let format = `${emailBody[0].slice(0, 3)}******@${emailBody[1]}`;
    el.textContent = format
  }
});

Vue.prototype.$rols = () => {
  let view = 'admin'
  const rolesUsers = JSON.parse(localStorage.getItem("roles"));
  var roles = []


  rolesUsers.forEach((element) => {
    roles.push(element.rol)
  });

  const rolesAdmin = ["ADMIN", "ADMINCC", 'MANAGERCC', 'DIRECTOR', 'FINANZAS'];
  const rolesAgents = ["AGENTCC"];
  roles.forEach((element) => {
    if (rolesAdmin.includes(element)) {
      view = 'admin';
    }
    if (rolesAgents.includes(element)) {
      view = 'agente';
    }
  });

  return view;
},

  moment.tz.guess()
Vue.component('downloadCsv', JsonCSV)
Vue.use(VueMoment, { moment })
Vue.directive('mask', VueMaskDirective);
Vue.prototype.$rules = require("./plugins/rules").default;
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  creditcard,
  toast,
  store,
  render: h => h(App)
}).$mount('#app')