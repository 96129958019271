<template>
    <v-app>
        <Navigation />

        <!-- <v-main> -->
        <v-container fluid>
            <router-view></router-view>
        </v-container>
        <!-- </v-main> -->

        <Footer />
    </v-app>
</template>



<script>
import Navigation from "~/components/Nav.vue";
import Footer from "~/views/templates/footer.vue";

export default {
    name: 'App',
    components: {
        Navigation,
        Footer
    },
    data: () => ({
        //
    }),
};
</script>
