<template>
  <v-row style="padding: 10px">
    <v-row>
      <v-col cols="12">
        <v-select
          label="Adjuntar Contrato"
          placeholder="Requerido"
          :items="['Si', 'No']"
          v-model="contratoAdjunto"
        ></v-select>
      </v-col>
    </v-row>
    <v-row style="padding: 10px">
      <v-col cols="6">
        <v-text-field
          v-model="creadoPor"
          label="Creado por"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="fechaCreacion"
          label="Fecha de Creacion"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" style="margin-top: -30px">
        <v-text-field
          v-model="modificadoPor"
          label="Modificado Por"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="6" style="margin-top: -30px">
        <v-text-field
          v-model="fechaModificacion"
          label="Fecha de Modificacion"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="sendSolicitud()"
        >
          Guardar
        </v-btn>
        <v-btn
          color="primary" 
          style="margin-left: 10px"
          v-show="rolToCompare != 'AGENT'"
        >
          Siguiente
        </v-btn>
      </v-col>
    </v-row>

    <!-- ALERT MODAL -->
    <v-col cols="auto">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="400"
        v-model="dialog"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="success" dark
              >Confirmación de envío</v-toolbar
            >
            <v-card-text>
              <div class="text-h4" style="margin-top: 10px; ">
                Envio de solicitud exitoso!
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="
                  (dialog.value = false), $router.push('/solicitudes')
                "
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <!-- END OF ALERT MODAL -->
  </v-row>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    origenSolicitud: String,
    statusSolicitud: String,
    agente: Number,
    tipo: String,
    ramo: String,
    producto: Number,
    detalleSolicitud: String,
    nombres: String,
    apellidoPaterno: String,
    apellidoMaterno: String,
    estadoCivil: String,
    estadoNacimiento: String,
    genero: String,
    fechaNacimiento: String,
    rfc: String,
    tipoPersona: String,
    telefono: String,
    correo: String,
    codigoPostal: String,
    direccion: String,
    inicioVigencia: String,
    finVigencia: String,
    periodicidad: String,
    formaPago: String,
    operador: Number,
    solicitudCompleta: String,
    procesoCotizacion: String,
    solicitudId: Number,
    bodyFianzas: Object,
    checkTramite: Boolean,
    checkCotizacionRenovacionTerminada: Boolean,
    checkRecotizar: Boolean,
    checkCotizacionRenovacionAprobada: Boolean,
    checkCotizacionRenovacionRechazada: Boolean,
    checkPendienteInfo: Boolean,
    checkEmisionFinalizada: Boolean,
    checkFinalizada: Boolean,
    checkCancelacion: Boolean,
    motivoCancelacion: String,
    motivoRecotizacionRechazo: String,
    causaPendienteInformacion: String,
    valorOportunidad: String,
    documentos: Array,
    comercialAsignado: Number,
    creadoPor: String,
    fechaCreacion: String,
    modificadoPor: String,
    fechaModificacion: String,
    aseguradoraId: Number,
    razonSocial: String,
  },
  data() {
    return {
      rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dialog: false,
      contratoAdjunto: this.bodyFianzas.contratoAdjunto ? this.bodyFianzas.contratoAdjunto : null,
    }
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 2500);
    },
  },
  methods: {
    cargarArchivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      for( var i = 0; i < this.documentos.length; i++ ){
        const formData = new FormData();
        let file = this.documentos[i];
        let nota = 'Solicitud #' + `${this.solicitudId}` + ' Cotización Enviada';
        const tipo = 'Documento';
        const tipoDocumento = 'Cotización';
        formData.append('file', file);
        formData.append('nota', nota);
        formData.append('nombre', nota);
        formData.append('tipo', tipo);
        formData.append('tipo_documento', tipoDocumento);
        this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData,config)
        .then(function (result) {
          console.log(result);
        }, function (error) {
          console.log(error);
        });
      }
    },
    crearNotaRechazoCancelacion() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      const tipo = 'Nota';
      let nota = 'Solicitud #' + `${this.solicitudId}` + ' Rechazada o cancelada'
      formData.append('nota', this.motivoRecotizacionRechazo);
      formData.append('tipo', tipo);
      formData.append('nombre', nota);
      this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData,config)
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
    },
    crearNotaPendienteInformacion(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      const formData = new FormData();
      const tipo = 'Nota';
      let nota = 'Solicitud #' + `${this.solicitudId}` + ' Rechazada o cancelada'
      formData.append('nota', this.causaPendienteInformacion);
      formData.append('tipo', tipo);
      formData.append('nombre', nota);
      this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitudId}`, formData,config)
      .then(function (result) {
        console.log(result);
      }, function (error) {
        console.log(error);
      });
    },
    sendSolicitud() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (this.documentos.length > 0) {
        this.cargarArchivos()
      }

      if (this.checkCotizacionRenovacionRechazada || this.checkRecotizar) {
        this.crearNotaRechazoCancelacion()
      }

      if (this.checkPendienteInfo) {
        this.crearNotaPendienteInformacion()
      }

      let request = {
        /* CAMPOS DE COMPONENTE PADRE */
        origenSolicitudSelected: this.origenSolicitud,
        tipoSelected: this.tipo ? this.tipo : "",
        categoriaSelected: this.ramo ? this.ramo : "",
        agenteId: this.agente ? this.agente : "",
        productoId: this.producto ? this.producto : "",
        detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
        operadorUuid: this.operador ? this.operador : "",
        nombres: this.nombres ? this.nombres : "",
        apellidoPaterno: this.apellidoPaterno ? this.apellidoPaterno : "",
        apellidoMaterno: this.apellidoMaterno ? this.apellidoMaterno : "",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        genero: this.genero ? this.genero : "",
        inicioVigencia: this.inicioVigencia ? moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        finVigencia: this.finVigencia ? moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        celular: this.telefono ? this.telefono : "",
        correoElectronico: this.correo ? this.correo : "",
        direccion: this.direccion ? this.direccion : "",
        codigoPostal: this.codigoPostal ? this.codigoPostal : "",
        estadoRepublicaSelected: this.estadoNacimiento ? this.estadoNacimiento : "",
        estadoCivilSelected: this.estadoCivil ? this.estadoCivil : "",
        rfc: this.rfc ? this.rfc : '',
        periodicidadPago: this.periodicidad == 'Anual' ? 1
                        : this.periodicidad == 'Semestral' ? 2
                        : this.periodicidad == 'Trimestral' ? 3
                        : this.periodicidad == 'Mensual' ? 4 : '',
        formaPagoSelected: this.formaPago ? this.formaPago : "",
        solicitudCompletaSelected: this.solicitudCompleta ? this.solicitudCompleta : "",
        procesoCotizacionSelected: this.procesoCotizacion ? this.procesoCotizacion : '',
        checkTramite: this.checkTramite,
        checkCotizacionRenovacionTerminada: this.checkCotizacionRenovacionTerminada,
        checkRecotizar: this.checkRecotizar,
        checkCotizacionRenovacionAprobada: this.checkCotizacionRenovacionAprobada,
        checkCotizacionRenovacionRechazada: this.checkCotizacionRenovacionRechazada,
        checkPendienteInfo: this.checkPendienteInfo,
        checkEmisionFinalizada: this.checkEmisionFinalizada,
        checkFinalizada: this.checkFinalizada,
        checkCancelacion: this.checkCancelacion,
        motivoCancelacion: this.motivoCancelacion ? this.motivoCancelacion : null,
        motivoRecotizacionRechazo: this.motivoRecotizacionRechazo ? this.motivoRecotizacionRechazo : null,
        causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : '',
        valor_oportunidad: this.valorOportunidad ? this.valorOportunidad : null,
        regimen_persona: this.tipoPersona,
        comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
        razon_social: this.razonSocial ? this.razonSocial : null,
        /* FIN DE CAMPOS DE COMPONENTE PADRE */

        /* CAMPOS DE COMPONENTE HIJO(RAMO) */
        contratoAdjunto: this.contratoAdjunto
      };

      this.$axios
      .post("v1/solicitud/update/" + this.solicitudId, request, config)
      .then((response) => {
      console.log("Entro!");
      if (response.status == 200) {
          if (this.checkCotizacionRenovacionAprobada == true) {
              this.generarPrePoliza();
          }
          this.dialog = true;
      }
      setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.solicitudId}`)), 3000);
      })
      .catch((error) => {
      console.log(error);
      });
    },
    generarPrePoliza() {
      if (this.checkCotizacionRenovacionAprobada == true) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
          
        let request = {
          /* CAMPOS PARA TODAS LAS EMISIONES */
          apellido_materno: this.apellidoMaterno ? this.apellidoMaterno : '',
          apellido_paterno: this.apellidoPaterno ? this.apellidoPaterno : '',
          aseguradora_id: this.aseguradoraId,
          compania: "AXA",
          cliente: null,
          agente: this.agente ? this.agente : null,
          codigo_postal: this.codigoPostal ? this.codigoPostal : '',
          correo_electronico: this.correo ? this.correo : '',
          cotizacion: null,
          creado_por: this.operador,
          detalle: this.detalleSolicitud ? this.detalleSolicitud : '',
          ejecutivo_de_operaciones: this.operador ? this.operador : '',
          estado_id: 9,
          fecha_cancelacion: null,
          fecha_emision: null,
          fecha_finalizacion: null,
          fecha_primer_pago: moment(new(Date)).format('YYYY-MM-DD'),
          fecha_nacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
          fin: moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          forma_pago: this.formaPago == 'Tarjeta de Crédito' ? 1
                      : this.formaPago == 'Tarjeta de Débito' ? 2
                      : this.formaPago == 'Deposito Bancario' ? 3
                      : this.formaPago == 'Transferencia' ? 4
                      : this.formaPago == 'Cheque' ? 5
                      : this.formaPago == 'Efectivo' ? 6
                      : this.formaPago == 'Domiciliación' ? 7 : '',
          genero: this.genero ? this.genero : '',
          inicio: moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          modificado_por: null,
          motivo_cancelacion: null,
          motivo_finalizacion: null,
          nombre: this.nombres,
          nombre_cliente: this.nombres ? this.nombres : '',
          notas: "pruebaNotas",
          observaciones: "pruebaO",
          operador_id: this.operador ? this.operador : '',
          origen: this.origenSolicitud ? this.origenSolicitud : '',
          origen_auto: "Nacional",
          pago_id: this.periodicidad == 'Anual' ? 1
                          : this.periodicidad == 'Semestral' ? 2
                          : this.periodicidad == 'Trimestral' ? 3
                          : this.periodicidad == 'Mensual' ? 4 : '',
          /* */ poliza_anterior: null,
          /* */ poliza_cancelada: null,
          /* */ poliza_finalizada: null,
          /* */ prima_neta: 0,
          /* */ prima_total: 0,
          /* */ primer_pago: 0,
          /* */ primer_pago_poliza: 0,
          producto_id: this.producto ? this.producto : '',
          /* */ status: 0,
          status_cobro: "",
          subsecuente_pago: 0,
          telefono: this.telefono ? this.telefono : '',
          tipo_persona: this.tipoPersona ? this.tipoPersona : null,
          /* */ tipo_poliza: this.tipo == 'Cotización' || this.tipo == 'Emisión' ? 'Nueva' : 
                              this.tipo == 'Renovación' ? 'Renovación' : '',
          ultima_modificacion: null,
          url: null,
          rfc: this.rfc ? this.rfc : '',
          solicitud_id: this.solicitudId,
          comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
          razon_social: this.razonSocial ? this.razonSocial : null,
          /* FIN DE CAMPOS PARA TODAS LAS EMISIONES */

          /* EMISIONES EN FIANZAS */
          contratoAdjunto: this.contratoAdjunto
        };

        this.$axios
        .post('/v1/emision/manual', request, config)
        .then(response => {
          console.log('Entro a generar pre-poliza', response);
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
}
</script>

<style>

</style>