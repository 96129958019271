<template>
  <div>
    <v-card>
      <v-toolbar dense dark flat color="#133B7D">
        <v-toolbar-title>
          <span>Resultados de búsqueda</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :items="clientes"
          :headers="headers"
          no-data-text="Sin clientes para mostrar"
          loading-text="Buscando clientes..."
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
          :server-items-length="totalRegistros"
          @update:page="pagina = $event"
          @update:items-per-page="elementosPagina = $event"
        >
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            <div v-if="itemsLength">
              Mostrando {{ pageStart }} - {{ pageStop }} de
              {{ itemsLength }} clientes
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    clientes: {
      type: Array,
      default: () => [],
    },
    totalRegistros: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "center",
          value: "id",
        },
        {
          text: "Status",
          align: "center",
          value: "statusNombre",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "RFC",
          align: "center",
          value: "rfc",
        },
        {
          text: "Teléfono",
          align: "center",
          value: "telefonoMovil",
        },
        {
          text: "Correo",
          align: "center",
          value: "correo",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
      ],
      elementosPagina: 10,
      pagina: 1,
    };
  },
  watch: {
    pagina(v) {
      if (v)
        this.$emit("paginacionModificada", {
          pagina: v,
          registrosPagina:
            this.elementosPagina > 0
              ? this.elementosPagina
              : this.totalRegistros,
        });
    },

    elementosPagina(v) {
      if (v)
        this.$emit("paginacionModificada", {
          pagina: this.pagina,
          registrosPagina: v > 0 ? v : this.totalRegistros,
        });
    },
  },
  methods: {
    changeRoute(item) {
      this.$router.push("/verCliente/" + item.uuid);
    },
  },
};
</script>
