<template>
  <div>
    <!-- HEAD TITLE -->
    <v-row style="margin-top: 10px">
      <v-col cols="6">
        <h2>Visualización de Clientes</h2>
      </v-col>

      <v-col cols="6">
        <v-btn @click="goBack()" elevation="2" style="margin-left: 527px">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper v-model="stepper" non-linear>
                <v-stepper-header style="justify-content: left">
                  <v-stepper-step step="1" editable>
                    Datos Generales
                  </v-stepper-step>

                  <v-stepper-step step="2" editable>
                    Direcciones y Otros datos
                  </v-stepper-step>

                  <v-stepper-step step="2" editable>
                    Mostrar más
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">
                    <v-row style="padding: 20px">
                      <v-col cols="6">
                        <v-text-field label="Estatus del Cliente" v-model="comision[0].id" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Comercial Asignado" v-model="comision[0].orden" readonly></v-text-field>
                      </v-col>
                      <!-- End 1nd Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Agente" v-model="comision[0].status" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="No. Clientes" v-model="comision[0].valorAPagar" readonly></v-text-field>
                      </v-col>
                      <!-- End 2nd Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Regimen" v-model="comision[0].fechaPagoAcordada" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Razón Social" v-model="comision[0].pagada" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Nombre de Empresa Moral" v-model="comision[0].fechaPago" readonly>
                        </v-text-field>
                      </v-col>
                      <!-- End 7th Col -->
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="RFC" v-model="comision[0].fechaPago" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha de Constitución" v-model="comision[0].fechaCreacion"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Teléfono de Oficina" v-model="comision[0].ultimaModificacion" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Teléfono Móvil" v-model="comision[0].ultimaModificacion" readonly>
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6" style="aling: right">
                        <v-text-field label="Dirección de Correo Electrónico" v-model="comision[0].ultimaModificacion"
                          readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <!-- 2ND ITEM (2ND FORM) -->
                  <v-stepper-content step="2">
                    <v-row style="padding: 20px; margin-top: -35px">
                      <v-col cols="6">
                        <v-text-field label="Fuente de Ingreso" v-model="comision[0].pagada" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <h5>Dirección de Facturación</h5>
                      </v-col>
                      <v-col cols="6">
                        <h5>Dirección de Envío</h5>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Folio de Movimiento Bancario Comisión"
                          v-model="comision[0].folioMovimientoBancario" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Comisión pagada por la aseguradora"
                          v-model="comision[0].porcentajeComisionAseguradora" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto Comisión pagada por la aseguradora"
                          v-model="comision[0].montoComisionPagadaAseguradora" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Comisión Agente" v-model="comision[0].porcentajeComisionAgente" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto de Comisión Agente" v-model="comision[0].montoComisionAgente"
                          readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6" style="aling: right">
                        <v-text-field label="IVA" v-model="comision[0].iva" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6">
                        <v-text-field label="ISR" v-model="comision[0].isr" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px; justify-content: right">
                      <v-col cols="6">
                        <v-text-field label="Retención IVA" v-model="comision[0].retencionIva" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Folio de la factura por pagar" v-model="comision[0].folioFacturaAPagar"
                          readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Importe a pagar al Agente" v-model="comision[0].importeAPagarAgente"
                          readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-checkbox label="Comisión Agente Pagada" v-model="comision[0].comisionAgentePagada" readonly>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha pagado de Comisión Agente"
                          v-model="comision[0].fechaComisionRecibida" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% de comisión para Alige" v-model="comision[0].porcentajeComisionSegurify"
                          readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto comisión para Alige" v-model="comision[0].montoComisionSegurify"
                          readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Bono" v-model="comision[0].porcentajeBono" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto Bono para Alige" v-model="comision[0].montoBonoSegurify" readonly>
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="Folio de Movimiento Bancario Bono"
                          v-model="comision[0].folioMovimientoBancarioBono" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha Pago de Bono" v-model="comision[0].fechaPagoBono" readonly>
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row style="padding: 20px; margin-top: -70px">
                      <v-col cols="6">
                        <v-text-field label="% Comisión total" v-model="comision[0].porcentajeComisionTotal" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Monto Comisión Total Alige"
                          v-model="comision[0].montoComisiontotalSegurify" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-btn color="primary">
                      Editar Cliente
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <!-- <v-col cols="5">
            <v-card style="margin-bottom: 40px">
              <v-subheader>RESUMEN DE SOLICITUD</v-subheader>
              <v-divider></v-divider>
              <v-data-table
                :headers="columnsNotas"
                :items="solicitudes"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
                :loading="loading"
                loading-text="Cargando..."
              > -->
      <!-- <template v-slot:top> -->
      <!-- <v-text-field
                                    v-model="search"
                                    label="Buscar"
                                    class="mx-4"
                                    ></v-text-field> -->
      <!-- </template>
                <template v-slot:item.uuid="{ item }">
                  <v-icon small class="mr-2" @click="verSolicitud(item.id)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card> -->

      <!-- <v-card style="margin-top: 15px">
              <v-row>
                <v-col cols="9">
                  <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-data-table
                :headers="columns"
                :items="correos"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
                :loading="loading"
                loading-text="Cargando..."
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item.uuid="{ item }">
                  <v-icon small class="mr-2" @click="verSolicitud(item.id)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col> -->
    </v-row>

    <!-- ALERT MODAL -->
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
          <v-card-text>
            <div class="text-h4" style="margin-top: 10px; ">
              Envio de solicitud exitoso!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false, $router.push('/solicitudes')">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- END OF ALERT MODAL -->
  </div>
</template>

<script>

export default {
  data() {
    return {
      stepper: 1,
      columns: [
        {
          text: "De",
          align: "start",
          sortable: false,
          value: "remitente",
        },
        { text: "Asunto", value: "cuerpo" },
        { text: "Estado", value: "subject" },
        { text: "Fecha", value: "fecha" },
        { text: "Asignado A", value: "tipo" },
        { text: "Relacionado Con", value: "fechaCreacion" },
      ],
      cliente: []
    }
  }, methods: {
    goBack() {
      return this.$router.go(-1);
    },
    obtenerDatosComision() {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/cliente/uuid/${this.$route.params.uuid}`, config).then(response => {
        console.log('Cliente: ', response);
        // this.comision.push(response.data);
      })
    }
  }, mounted() {
    this.obtenerDatosComision()
  }
}
</script>

<style>
.v-stepper__step__step {
  color: transparent !important;
  display: none !important;
}
</style>