<template>
  <div>
    <v-row dense>
      <v-col cols="6">
        <FiltrosCliente
          @filtrosModificados="updateFilters"
          :loading="loading"
        />
      </v-col>
      <v-col cols="12">
        <ResultadosCliente
          :loading="loading"
          :clientes="clientes"
          :totalRegistros="totalRegistros"
          @paginacionModificada="paginacionModificada"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logoSegurify from "@/assets/logo_segurify.png";
import FiltrosCliente from "./FiltrosCliente.vue";
import ResultadosCliente from "./ResultadosCliente.vue";

export default {
  components: {
    FiltrosCliente,
    ResultadosCliente,
  },
  data() {
    return {
      logoSegurify,
      loading: false,
      clientes: [],
      registrosPagina: 10,
      pagina: 1,
      totalRegistros: 0,
      nuevosFiltros: {},
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },
  methods: {
    async realizarBusqueda() {
      try {
        this.loading = true;
        const busquedaCompleta =
          !!this.nuevosFiltros.nombre &&
          !!this.nuevosFiltros.apellido_paterno &&
          !!this.nuevosFiltros.apellido_materno &&
          !!this.nuevosFiltros.rfc &&
          !!this.nuevosFiltros.fecha_nacimiento;
        if (busquedaCompleta && !this.fromComplete) {
          this.updateFilters(this.nuevosFiltros);
          return;
        }

        this.clientes = [];
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        var filtros = {
          ...this.nuevosFiltros,
          ...{
            pagina: this.pagina,
            elementosPagina: this.registrosPagina,
          },
        };
        // BUSQUEDA
        const res = await this.$axios.post(
          "/v1/clientes/filtro",
          filtros,
          config
        );
        this.loading = false;
        if (!res.data.data.clientes.length || res.data.error) return;
        res.data.data.clientes.forEach((cliente) => {
          this.clientes.push({
            id: cliente.id,
            nombre: `${cliente.nombre} ${cliente.apellidoPaterno ||
              ""} ${cliente.apellidoMaterno || ""}`,
            uuid: cliente.uuid || "No disponible",
            status: cliente.status || "No disponible",
            rfc: cliente.rfc || "No disponible",
            statusNombre: cliente.statusNombre || "No disponible",
            correo: cliente.correo || "No disponible",
            telefonoMovil: cliente.telefonoMovil || "No disponible",
          });
        });
        this.totalRegistros = res.data.data.total;
      } catch (error) {
        if (error.res && error.res.status === 400) {
          this.$toast.error(
            "No se encontraron resultados. Intente nuevamente."
          );
        } else {
          this.$toast.error(
            "Se ha producido un error inesperado. Intente nuevamente más tarde."
          );
        }
      } finally {
        this.loading = false;
      }
    },

    async realizarBusquedaCompleta(filtros) {
      await this.realizarBusqueda(filtros, true);
      if (!this.clientes.length)
        await this.realizarBusqueda({ rfc: filtros.rfc });
      if (!this.clientes.length)
        await this.realizarBusqueda({ rfc: filtros.rfc.substring(0, 10) });
      if (!this.clientes.length)
        await this.realizarBusqueda({
          nombre: filtros.nombre,
          apellido_paterno: filtros.apellido_paterno,
          apellido_materno: filtros.apellido_materno,
        });
      if (!this.clientes.length)
        await this.realizarBusqueda({
          nombre: `${filtros.nombre} ${filtros.apellido_paterno} ${filtros.apellido_materno}`,
        });
      if (!this.clientes.length)
        await this.realizarBusqueda({
          nombre: `${filtros.apellido_paterno} ${filtros.apellido_materno} ${filtros.nombre}`,
        });
    },
    paginacionModificada(e) {
      this.pagina = parseInt(e.pagina);
      this.registrosPagina = e.registrosPagina;
      this.realizarBusqueda();
    },
    updateFilters(e, fromComplete = false) {
      (this.nuevosFiltros = e), (this.fromComplete = fromComplete);
      this.realizarBusqueda();
    },
  },
};
</script>
