export const ListExpanded = {
    data: function(){
        return {
            IdBradescard:[
                'incapacidad Ap',
                'hogar'
                // 'SURA',
                // 'AIG',
                // 'ADT',
                // 'GNP'
            ],
            estatusLeads: [
                'Nuevo',
                'Asignado',
                'No Efectivo Seguimiento',
                'No Efectivo Finalizado',
                'Efectivo Seguimiento',
                'Efectivo Venta',
                'Efectivo No Venta'
            ],
            estadosCiviles: [
                'Soltero(a)',
                'Casado',
                'Unión libre'
            ],
            productos:[
                'BK BODEGA AURRERA',
                'BK C&A',
                'BK GCC',
                'BK PROMODA',
                'BK SHASA',
                'PLCC C&A',
            ],
            cortes:[5,10,15,25,25,30],
            etiquetas:['Nueva', 'Vieja', 'Remarcacion'],
            agentes:['A','B','C','D','E','F','G','H'],
            canalItems: ["Agentes", "Directo", "Masivos"],
            regimenItems: ["Física", "Moral"],
            tipoCedulaItems: ["A", "A1", "B", "B1", "C", "F"],
            nivelAgenteItems: ["Básico", "Intermedio", "Avanzado"],
            ultimasDisposiciones: [
                'No le interesa',
                'Ya cuenta con un seguro similar',
                'Telefono equivocado',
                'No desea ofrecimiento',
                'insatisfecho con cobertura',
                'Llamar más tarde',
                'No tiene tiempo',
                'No titular',
                'Agendar llamada',
                'Venta',
                'Telefono no existe',
                'Telefono fuera de servicio',
                'Linea solo para navegar en internet',
                'No contesta',
                'Buzon de voz',
                'Se corta llamada/colgo',
                'Telefono suspendido',
                'Telefono invalido',
                'Linea ocupada',
            ],
            generos: [
                { id: 1, sexo: "Masculino" },
                { id: 2, sexo: "Femenino" },
            ],
            generosBeneficiario: [
                { id: 'M', sexo: "Masculino" },
                { id: 'F', sexo: "Femenino" },
            ],
            estados: [
                { id: 1, estadoRepublica: "Aguascalientes" },
                { id: 2, estadoRepublica: "Baja California" },
                { id: 3, estadoRepublica: "Baja California Sur" },
                { id: 4, estadoRepublica: "Campeche" },
                { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
                { id: 6, estadoRepublica: "Colima" },
                { id: 7, estadoRepublica: "Chiapas" },
                { id: 8, estadoRepublica: "Chihuahua" },
                { id: 9, estadoRepublica: "Ciudad de México" },
                { id: 10, estadoRepublica: "Durango" },
                { id: 11, estadoRepublica: "Guanajuato" },
                { id: 12, estadoRepublica: "Guerrero" },
                { id: 13, estadoRepublica: "Hidalgo" },
                { id: 14, estadoRepublica: "Jalisco" },
                { id: 15, estadoRepublica: "Estado de México" },
                { id: 16, estadoRepublica: "Michoacán de Ocampo" },
                { id: 17, estadoRepublica: "Morelos" },
                { id: 18, estadoRepublica: "Nayarit" },
                { id: 19, estadoRepublica: "Nuevo León" },
                { id: 20, estadoRepublica: "Oaxaca" },
                { id: 21, estadoRepublica: "Puebla" },
                { id: 22, estadoRepublica: "Querétaro" },
                { id: 23, estadoRepublica: "Quintana Roo" },
                { id: 24, estadoRepublica: "San Luis Potosí" },
                { id: 25, estadoRepublica: "Sinaloa" },
                { id: 26, estadoRepublica: "Sonora" },
                { id: 27, estadoRepublica: "Tabasco" },
                { id: 28, estadoRepublica: "Tamaulipas" },
                { id: 29, estadoRepublica: "Tlaxcala" },
                { id: 30, estadoRepublica: "Veracruz" },
                { id: 31, estadoRepublica: "Yucatán" },
                { id: 32, estadoRepublica: "Zacatecas" },
                { id: 33, estadoRepublica: "Desconocio" },
            ],
            status: [
                { id: 0, name: "Nuevo" },
                { id: 1, name: "Activo" },
                { id: 2, name: "Inactivo" },
                { id: -1, name: "Baja" },
            ],
            motivoBajaItems: [
            "Encontró trabajo",
            "Falta de pago de comisiones",
            "Falta de producción",
            "Falta de seguimiento del área comercial",
            "Falta de seguimiento del área de operaciones",
            "Inconformidad con el proceso de capacitación",
            "Inconformidad con la oferta de valor",
            "Exceso de faltas",
            "Ingreso a escuela",
            "Temas de salud",
            "Otro",
            ],
            campanias: [
            { id: 1, name: "SURA - Incapacidad AP" },
            { id: 2, name: "AIG - Hogar" },
            { id: 3, name: "ZURICH - Seguro Celular" },
            { id: 4, name: "METLIFE - Hospitalización" },
            { id: 5, name: "Asistencias" },
            ],
            landings: [
            { id: 1, name: "AIG" },
            { id: 2, name: "ANA" },
            { id: 3, name: "AXA" },
            { id: 4, name: "COMPARADOR" },
            { id: 5, name: "COMPARADOR-ADT" },
            { id: 6, name: "COMPARADOR-AUTOCOSMOS" },
            { id: 7, name: "GNP" },
            { id: 8, name: "HDI" },
            { id: 9, name: "PROVEEDORES" },
            { id: 10, name: "QUÁLITAS" },
            { id: 11, name: "SGMM SEGURIFY" },
            { id: 12, name: "SURA" },
            { id: 13, name: "SURA MOVILIDAD" },
            { id: 14, name: "SURA SGMM" },
            { id: 15, name: "AUTOS RASTREATOR AIG" },
            { id: 16, name: "TUSEGURO.COM" },
            { id: 17, name: "CORU.COM" },
            ],
            fuenteIngresoItems: [
            "Referido",
            "LinkedIn",
            "Facebook",
            "RH",
            "IECA",
            "Base de Datos",
            "Simulador",
            "Eventos",
            ],
            asociadoPromotoriaItems: [
            { id: 1, name: "Afirme" },
            { id: 2, name: "ANA Seguros" },
            { id: 3, name: "GNP" },
            { id: 4, name: "Primero Seguros" },
            { id: 5, name: "Quálitas" },
            { id: 6, name: "Multiva" },
            { id: 7, name: "RSA Seguros" },
            { id: 8, name: "AIG Seguros México" },
            { id: 9, name: "HDI Seguros" },
            { id: 10, name: "Zurich" },
            { id: 11, name: "AXA" },
            { id: 12, name: "BANORTE" },
            { id: 13, name: "ATLAS" },
            { id: 14, name: "CHUBB" },
            { id: 15, name: "GMX" },
            { id: 16, name: "ALLIANZ" },
            { id: 17, name: "ARGOS" },
            { id: 18, name: "ASERTA" },
            { id: 19, name: "BX+" },
            { id: 20, name: "MUTUUS" },
            { id: 21, name: "PLAN SEGURO" },
            { id: 22, name: "PREVEM" },
            { id: 23, name: "SISNOVA" },
            { id: 24, name: "BUPA" },
            ],
            tipoAgenteItems: [
            "Agente",
            "Comisionista",
            "Agente Apoderado",
            "Agente Promotoria",
            ],
            parentesco:[
                {id:1, descripcion: 'Padre'},
                {id:2, descripcion: 'Madre'},
                {id:3, descripcion: 'Hermano(a)'},
                {id:4, descripcion: 'Conyuge'},
                {id:5, descripcion: 'Hijo (a)'},
                {id:9, descripcion: 'Otro'},
            ],
        }
    }, methods: {
        async getProductos(){
            try{
                let {data} = await this.$axios.get('v1/bradescard/productos',{
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                });
                this.productos = [];
                for(let key in data.status){
                    this.productos.push(
                        key,
                    );
                }
            }catch(exception){
                console.error(exception);
            }
        },
    }
}