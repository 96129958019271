<template>
    <div>
        <v-row class="mb-2 fill-height" >
            <v-col cols="12"> 
                <v-card>
                    <v-card-title primary-title>
                        Polizas Bradescard ( {{status}} )
                        <v-spacer></v-spacer>
                        <v-btn @click="$router.go(-1)" style="background-color: #ff0042; color: white;">
                            FINALIZAR
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense class="mt-5 mb-6">
            <v-col md="12" lg="12" sm="12" xl="12" >
                <v-card dense>
                    <center>
                        <v-card-text class="max-width: 90% !important; min-width: 50% !important;">
                            <div id="chartBar" class="chart"></div>
                        </v-card-text>
                    </center>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense class="mt-6 mb-12 " v-if="emisiones != null"> 
            <v-col cols="12">
                <div class="mt-6 mb-2"  style="text-align: end;" v-if="roles.rol != 'AGENTCC'">
                    <v-btn color="primary" style="height: 50px; weight: 50px" class="ml-16" dark max-height="40px"
                        max-width="100px" :disabled="emisiones.length == 0">
                        <download-csv :name="'Reporte de emisiones.csv'" :data="emisiones">
                            Descargar
                      </download-csv>
                    </v-btn>
                </div>
                
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar..."
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="columns"
                        :items="emisiones"
                        item-key="name"
                        class="elevation-1"
                        :search="search"
                        :loading='loading'
                        loading-text="Cargando..."
                        :server-items-length="totalRegistros"
                        @update:page="pagina = $event"
                        @update:items-per-page="elementosPagina = $event"
                    >
                        <template #no-data>
                            No se tienen datos para mostrar
                        </template>
                        <template #[`item.actions`]="{ item }">
                            <v-btn icon  @click="$router.push('/emision/' + item.id)">
                                <v-icon small class="mr-2" >mdi-eye</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
var moment = require("moment-timezone");
import { mainAxios } from "../../../../../mainAxios";
    
    export default {
        name:'Bradescard.emisiones.Dashboard',
        props: {
            status: {
                type: String,
                default: ""
            },
        },
        data:function(){
            return {
                columns:[
                    { text: "Póliza", value: "idMostrar" },
                    { text: "Cliente", value: "cliente" },
                    { text: "Agente", value: "agente" },
                    { text: "Estatus Póliza", value: "status" },
                    { text: "Prima Neta", value: "primaNeta" },
                    { text: "Inicio de Vigencia", value: "inicioVigencia" },
                    { text: "Fin de Vigencia", value: "finVigencia" },
                    { text: "Periodicidad", value: "periodicidad" },
                    { text: "Ramo", value: "ramo" },
                    { text: "Aseguradora", value: "aseguradora" },
                    { text: "Ejecutivo", value: "ejecutivo" },
                    { text: "Ver Emisión", value: "id" },
                    { text: 'Ver    ', value: 'actions'},
                ],
                agenteUuid: localStorage.getItem('agenteUuid'),
                emisiones: [],
                loading: false,
                search: "",
                productos: [],
                currentProductId: null,
                currentChart: null,
                roles: JSON.parse(localStorage.getItem('roles'))[0],
                currentAgentId:null,
                pagina: 1,
                elementosPagina: 10,
                totalRegistros: 0,
            }
        }, 

        watch: {
            pagina(v) {
                !!v && this.getEmisionesList();
            },

            elementosPagina(v) {
                !!v && this.getEmisionesList();
            },
        },

       async mounted(){
            this.loadchart();
            this.getEmisionesList();
            this.getAgentes();
            this.obtenerProductos();

            this.currentChart.on('click', (params) => {
                if(this.roles.rol == 'AGENTCC'){
                    this.currentProductId = this.obtenerIDProducto(params.name);
                    this.getEmisionesList();
                }else{
                    this.currentAgentId = this.obtenerIDAgente(params.name);
                    console.log(this.currentAgentId);
                    this.getEmisionesList();
                }
            });
        }, 
        methods: {
            getEmisionesList(){
                let statusId = ''

                console.log([])

                this.status == 'Nueva' ? statusId = 0  :
                this.status == 'Emitida Pendente de Pago' ? statusId = 1 :
                this.status == 'Pagada Parcial' ? statusId = 2:
                this.status == 'Pagada Total' ? statusId = 3:
                this.status == 'Renovada' ? statusId = 4:
                this.status == 'Finalizada' ? statusId = 5:
                this.status == 'Cancelada' ? statusId = 6:
                this.status == 'Emitido Certificado' ? statusId = 8: 
                this.status == 'Emitido certificado' ? statusId = 8: 7

                var query= {
                    status: statusId,
                    agente_id: this.currentAgentId,
                    producto: this.currentProductId,
                    pagina: this.pagina,
                    elementosPagina: this.elementosPagina,
                }
                const config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                }
                this.emisiones = []
                //Obtener Polizas
                this.$axios.post(`/v1/emision/list/status/${this.agenteUuid}`,query, config).then(resp => {
                    //Cargar datos en la tabla
                    this.totalRegistros = resp.data.data.totalRegistros;
                    resp.data.data.emisiones.map(element => {
                        this.emisiones.push({
                            idMostrar: element.poliza ? element.poliza : 'No Disponible',
                            cliente: element.clienteNombre ? element.clienteNombre : 'No Disponible',
                            agente: element.agenteNombre ? element.agenteNombre : 'No Disponible',
                            status: element.status == 0 ? 'Nueva' :
                            element.status == 1 ? 'Emitida Pendiente de Pago' :
                                element.status == 2 ? 'Pagada Parcial' :
                                element.status == 3 ? 'Pagada Total' :
                                    element.status == 4 ? 'Renovada' :
                                    element.status == 5 ? 'Finalizada' : 'Cancelada',
                            primaNeta: element.primaNeta ? element.primaNeta : 'No Disponible',
                            inicioVigencia: element.inicio ? moment(element.inicio, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'No Disponible',
                            finVigencia: element.fin ? moment(element.fin, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'No Disponible',
                            periodicidad: element.periodicidad ? element.periodicidad : 'No Disponible',
                            ramo: element.ramo ? element.ramo : 'No Disponible',
                            aseguradora: element.nombre ? element.nombre : 'No Disponible',
                            ejecutivo: element.ejecutivoNombre ? element.ejecutivoNombre : 'No Disponible',
                            id: element.id,
                        })
                    })
                    //Datos para Descargar reporte
                    resp.data.map(item => {
                    this.descargarEmisiones.push({
                        "Póliza": item.poliza,
                        "Cliente": item.clienteNombre ? item.clienteNombre : 'No Disponible',
                        "Agente": item.agenteNombre ? item.agenteNombre : 'No Disponible',
                        "Estatus Póliza": item.status == 0 ? 'Nueva' :
                        item.status == 1 ? 'Emitida Pendiente de Pago' :
                            item.status == 2 ? 'Pagada Parcial' :
                            item.status == 3 ? 'Pagada Total' :
                                item.status == 4 ? 'Renovada' :
                                item.status == 5 ? 'Finalizada' : 'Cancelada',
                        "Prima Neta": item.primaNeta ? item.primaNeta : 'No Disponible',
                        "Inicio de Vigencia": item.inicio ? moment(item.inicio, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'No Disponible',
                        "Fin de Vigencia": item.fin ? moment(item.fin, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'No Disponible',
                        "Periodicidad": item.periodicidad ? item.periodicidad : 'No Disponible',
                        "Ramo": item.ramo ? item.ramo : 'No Disponible',
                        "Aseguradora": item.nombre ? item.nombre : 'No Disponible',
                    })
                    })
                });
            },

            loadchart() {
                var options = {
                    title: {
                        text: `Polizas status: `+ this.status,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: []
                    },
                    series: [
                        {
                        name: 'Cantidad de polizas',
                        type: 'bar',
                        data: []
                        },
                    ]
                }
                this.currentChart = this.$chart.init(document.getElementById('chartBar'));
                this.currentChart.setOption(options);
                var names = [];
                var data = [];
                let statusId = ''

                this.status == 'Nueva' ? statusId = 0  :
                this.status == 'Emitida Pendente de Pago' ? statusId = 1 :
                this.status == 'Pagada Parcial' ? statusId = 2:
                this.status == 'Pagada Total' ? statusId = 3:
                this.status == 'Renovada' ? statusId = 4:
                this.status == 'Finalizada' ? statusId = 5:
                this.status == 'Cancelada' ? statusId = 6:
                this.status == 'Emitido certificado' ? statusId = 8: 7
                
                mainAxios.post(`v1/bradescard/data/emisiones/${this.agenteUuid}`, {status: statusId},{            
                headers: {
                    Authorization: `Bearer ${localStorage.agenteAccessToken}`,
                },
                }).then((response) => {
                    if(this.roles.rol == 'AGENTCC'){
                        Object.keys(response.data.productos).forEach(key => {
                            names.push(key);
                            data.push(response.data.productos[key]);
                        });
                    }else{
                        Object.keys(response.data.agentes).forEach(key => {
                            names.push(key);
                            data.push(response.data.agentes[key]);
                        });
                    }

                options.yAxis.data = names;
                options.series[0].data = data;          
                this.currentChart.setOption(options);
                });
            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            getAgentes() {
                let config = {
                    headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                };
                this.$axios
                    .get(`/v1/agente/list`, config)
                    .then((response) => {
                    this.agentes = response.data
                    });
            },
            obtenerIDAgente(name) {
                if(name == 'No Disponible'){
                    return name;
                }
                else{
                    let agente = this.agentes.find(element => element.nombreCompleto == name);
                    return agente.id;
                } 
            },
            obtenerIDProducto(name) {
                if(name == 'No Disponible'){
                    return name;
                }
                else{
                    let producto = this.productos.find(element => element.nombre == name);
                    console.log(producto);
                    return producto.id;
                } 
            },
            
            obtenerProductos() {
                let config = {
                    headers: {
                    Authorization: 'Bearer ' + localStorage.agenteAccessToken,
                    },
                };
                this.$axios
                    .get('/v1/productos/listaProductos', config)
                    .then((response) => {
                    response.data.map(element => {
                        this.productos.push({
                        id: element.id,
                        nombre: element.nombre,
                        titulo: `${element.ramo.toUpperCase()} / ${element.nombre.toUpperCase()} / ${element.nombreAseguradora.toUpperCase()}`,
                        ramo: element.ramo,
                        compania: element.nombreAseguradora.toUpperCase(),
                        fechaCreacion: element.fechaCreacion.slice(0, 10),
                        fechaModificacion: element.fechaModificacion ? element.fechaModificacion : 'No Disponible',
                        check: false
                        })
                    })
                    })
                },
        }
    }
</script>

<style>

</style>