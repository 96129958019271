import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../../services/mainService";

/**
 * Servicio para obtener un lead bradescard por id
 */
export const getLeadById = async (leadId) => {
  try {
    const response = await mainAxios.get(
      `/v1/lead/byId/${leadId}`,
      getDefaultHeaders()
    );

    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
