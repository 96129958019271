<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte Leads Rastreator</h1>
        </v-col>
      </v-row>
      <v-row class="mb-15" style="padding: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="generateReportClientes">Generar Reporte
          </v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px" dark>
            <download-csv :data="reportDownload" name="clientes.csv">
              {{ downloadText }}
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table v-if="!loading && reportType == 'lead'" :headers="columns" :items="report" item-key="name"
            class="elevation-1" :search="search" :custom-filter="filterOnlyCapsText" :loading="loading"
            loading-text="Cargando...">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item.telefono="{ item }">
              <td style="white-space: nowrap;">{{ item.telefono }}</td>
            </template>

            <template v-slot:item.version="{ item }">
              <td style="white-space: nowrap;">{{ item.version }}</td>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: [],
      downloadText: "Descargar Reporte",
      downloading: false,
      loading: false,
      reportType: "lead",
      report: [],
      reportDownload: [],

      search: "",
      columns: [
        {
          text: "Cotizacion",
          align: "start",
          sortable: false,
          value: "cotizacion",
        },
        { text: "Nombre", value: "nombre" },
        { text: "Teléfono", value: "telefono" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Correo Electronico", value: "correo" },
        { text: "Fecha", value: "fecha" },
        { text: "Versión", value: "version" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },
    generateReportClientes() {
      var vm = this;
      vm.reportType = "lead";
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }

      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      vm.loading = true;
      this.$axios
        .post("/v1/reporte/leads/rastreator/online", fechas, config)
        .then((response) => {
          vm.report = []
          vm.reportDownload = []
          response.data.map(element => {
            if (element.fechaEstimada) {
              this.report.push({
                "cotizacion": element.cotizacion,
                "nombre": element.nombre,
                "telefono": element.telefono,
                "aseguradora": element.aseguradora,
                "correo": element.correo,
                "fecha": moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                "version": element.version,
              })
            }
          })
          response.data.map(element => {
            if (element.fechaEstimada) {

              this.reportDownload.push({
                "Cotizacion": element.cotizacion,
                "Nombre": element.nombre,
                "Apellido": element.apellido,
                "Telefono": element.telefono,
                "Aseguradora": element.aseguradora,
                "FechaEstimada": element.fechaEstimada,
                "Correo": element.correo,
                "Genero": element.genero,
                "FechaNacimiento": element.fechaNacimiento,
                "Codigo Postal": element.codigoPostal,
                "Fecha": moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
                "Marca": element.marca,
                "Submarca": element.submarca,
                "Version": element.version,
                "Modelo": element.modelo,
                "Agente": element.agente,
                "Total": element.total,
                "Portal": element.portal,
              })
            }
          })
          vm.loading = false;
        });
    },

    downloadReportClientes() {
      var vm = this;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
        responseType: "blob",
      };
      vm.downloading = true;
      var fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      vm.downloadText = "Generando reporte...";
      this.$axios
        .post("/v1/reporte/leads/cliente", fechas, config)
        .then((response) => {
          vm.downloading = false;
          vm.downloadText = "Descargar";
          require("downloadjs")(
            response.data,
            "ReporteLeadsClientes.xls",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        });
    },

    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
