<template>
    <v-card>
        <v-row>
            <v-col cols="10"><v-subheader>EMAILS</v-subheader></v-col>
        </v-row>

        <v-divider></v-divider>

        <v-data-table
            :headers="columns"
            :items="[]"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
        >
            <template v-slot:item="row">
                <tr>
                    <td>{{row.item.asunto}}</td>
                    <td>{{row.item.fecha}}</td>
                    <td>{{row.item.remitente}}</td>
                    <td style="cursor:pointer;">
                        <v-icon light small class="mr-2">mdi-eye</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    export default {
        name: 'leads.detalles.Emails',
        data: function(){
            return{
                columns: [
                    { text: "Asunto", align: "start", sortable: false, value: "asunto"},
                    { text: "Fecha", value: "fecha" },
                    { text: "Para", value: "remitente" },
                ],
            }
        }, mounted(){
            this.getEmails()
        }, methods: {
            async getEmails(){

            }
        }
    }
</script>