<template>
  <v-app>
    <v-main class="mb-15">
      <v-container fluid> 
          <v-row>
              <v-col cols=12>
                  <div v-if="confirmando">
                      <p>Estamos confirmando tu emisión....</p>
                  </div>
                  <div v-if="!confirmando">
                    <v-card style="text-aling:center;">
                    <h2 style="text-align:center;padding:50px;">
                        ¡Felicidades has emitido tu póliza con número:{{poliza}} !
                    </h2>
                    <div style="text-align: center;">
                        <v-icon
                        large
                        color="green darken-2"
                        style="text-align:center;font-size:100px;"
                        >
                        mdi-book-check
                        </v-icon>
                    </div>

                    <h2 style="text-align:center;padding:50px;">
                        Código de confirmación Segurify: {{emision.codigoConfirmacionSegurify}}
                    </h2>
                    <div style="text-align: center;padding:50px;">
                    </div>

                    </v-card>
                  </div>
              </v-col>
          </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

export default {
  data() {
    return {
      confirmando: true,
      emision: {},
      cotizacion:"",
      poliza:""
    };
  },
  mounted() {
    
    var config = {
      headers: {
        Authorization: "Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5",
      },
    };
    this.cotizacion = this.$route.params.ciane;
    this.poliza = this.$route.query.Pol;
    var confirmaRequest = {
      cotizacion: this.cotizacion,
      poliza: this.poliza,
    };
    this.$axios
      .post("/v1/gnp/confirma", confirmaRequest, config)
      .then((value) => {
        this.confirmando = false;
        this.emision = value.data;
      });
      
  },
  
};
</script>
