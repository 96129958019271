<template>
  <div>
    <!-- HEAD TITLE -->
    <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
      <v-toolbar>
        <v-toolbar-title>
          <h2>Poliza</h2>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn @click="goBack()">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row class="mb-10">
      <v-col cols="8">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper v-model="stepper" non-linear>
                <v-stepper-header class="d-flex justify-space-around">
                  <v-stepper-step step="1" editable>
                    Datos Generales
                  </v-stepper-step>

                  <v-stepper-step step="2" editable>
                    Datos de la Poliza
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">
                    <v-row style="padding: 20px; margin-top: -35px">
                      <v-col cols="4">
                        <v-text-field label="No. Póliza" v-model="poliza.poliza" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Poliza ID Interna" v-model="poliza.id" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Tipo de Póliza" v-model="poliza.type" :items="typePolizaList"
                          placeholder="Requerido"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Estatus de la Póliza" v-model="poliza.status" :items="statusList"
                          item-text="name" item-value="id" :disabled="roles != 'ADMIN'" readonly></v-select>
                      </v-col>
                      <!-- FUNCIONALIDAD DE LISTA PENDIENTE -->
                      <v-col cols="4">
                        <v-autocomplete label="Póliza renovada por" v-model="poliza.anterior" readonly></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="No. Cotización / Lead ID" v-model="poliza.leadId" readonly></v-text-field>
                      </v-col>
                      <!-- INICIO DE CHECKS -->

                      <v-col cols="4">
                        <v-text-field label="Cliente" v-model="poliza.newCliente" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete label="Agente" :items="agentes" item-text="nombre" item-value="id"
                          v-model="poliza.agente" placeholder="Requerido" :rules="inputsRequeridos"
                          @change="esDirecto()"></v-autocomplete>
                      </v-col>
                      <v-col cols="4" v-if="esAgentDirecto">
                        <v-autocomplete label="Comercial Asignado" :items="comercialAsignadoList" item-text="nombre"
                          item-value="id" v-model="comercialAsignado" placeholder="Requerido" :rules="inputsRequeridos">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete label="Productos" v-model="poliza.productos" :items="productos"
                          item-text="nombre" item-value="id" placeholder="Requerido" :rules="inputsRequeridos"
                          @change="obtenerRamo(poliza.productos), obtenerAseguradoraId(poliza.productos)">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-menu v-model="menuInicioVigencia" :close-on-content-click="false"
                          transition="scale-transition" offset-y max-width="290px" min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateFormattedInicioVigencia" label="Fecha inicio de vigencia"
                              persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
                              @blur="comboInicioVigencia = parseDate(dateFormattedInicioVigencia)" v-on="on"
                              v-mask="'##/##/####'" :rules="inputsRequeridos"></v-text-field>
                          </template>
                          <v-date-picker @input="menuInicioVigencia = false" v-model="comboInicioVigencia" no-title>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-menu ref="menuFinVigencia" v-model="menuFinVigencia" :close-on-content-click="false"
                          transition="scale-transition" offset-y max-width="290px" min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateFormattedFinVigencia" label="Fecha fin de vigencia"
                              persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
                              @blur="comboFinVigencia = parseDate(dateFormattedFinVigencia)" v-on="on"
                              v-mask="'##/##/####'" :rules="inputsRequeridos"></v-text-field>
                          </template>
                          <v-date-picker v-model="comboFinVigencia" no-title @input="menuFinVigencia = false">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Periodicidad de pago" v-model="poliza.periodicidad" :items="periodicidadList"
                          item-text="name" item-value="id" :rules="inputsRequeridos"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Años" v-model="years" type="number"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Número de cuotas" v-model="poliza.numeroCuotas" type="number" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="ramo != 'AHORRO'">
                        <v-text-field label="Prima neta" v-model="poliza.primaNeta" :rules="inputsRequeridos">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="ramo != 'AHORRO'">
                        <v-text-field label="Prima total" v-model="poliza.primaTotal" :rules="inputsRequeridos">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Forma de Pago" v-model="poliza.formaPago" :items="formaPagoList"
                          item-text="name" item-value="id" :rules="inputsRequeridos"></v-select>
                      </v-col>
                      <v-col cols="4" v-if="ramo != 'AHORRO'">
                        <v-text-field label="Monto de Primer Pago" v-model="poliza.montoPrimerPago"
                          :rules="inputsRequeridos"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="(poliza.periodicidad == 1) && (ramo != 'AHORRO')">
                        <v-text-field label="Monto de Pago Subsecuente" v-model="poliza.montoSubsecuente">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
                          transition="scale-transition" offset-y max-width="290px" min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateFormattedPrimerPago" label="Fecha primer pago" persistent-hint
                              prepend-icon="mdi-calendar" v-bind="attrs"
                              @blur="comboPrimerPagoDate = parseDate(dateFormattedPrimerPago)" v-on="on"
                              v-mask="'##/##/####'" :rules="inputsRequeridos"></v-text-field>
                          </template>
                          <v-date-picker v-model="comboPrimerPagoDate" no-title @input="menu1 = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field label="Notas de la Póliza" v-model="poliza.notas"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="poliza.creadoPorDate" label="Fecha de Creación"
                          prepend-icon="mdi-calendar" v-mask="'##/##/####'" placeholder="Requerido"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Creado por" v-model="poliza.creadoPor"></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field v-model="poliza.ultimaModoficacionDate" label="Última Modificación"
                          prepend-icon="mdi-calendar" v-mask="'##/##/####'" placeholder="Requerido"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Modificado por" v-model="poliza.ultimaModoficacion"></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- ENDS HERE -->

                    <!-- <v-btn color="primary" @click="crearPoliza()" :disabled="!formIsValid">
                      Guardar
                    </v-btn> -->
                    <v-btn color="primary" style="margin-left: 15px" @click="stepper = 2">
                      Siguiente
                    </v-btn>
                  </v-stepper-content>

                  <!-- 2ND ITEM (2ND FORM) -->
                  <v-stepper-content step="2">
                    <v-row style="padding: 20px; margin-top: -35px; padding-bottom: 0px;">
                      <v-col cols="4">
                        <v-select label="Tipo de Persona (Régimen)" v-model="poliza.regimen" :items="regimentList"
                          :rules="inputsRequeridos"></v-select>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen == 'Moral' && poliza.regimen != null">
                        <v-text-field label="Razón Social" v-model="poliza.razonSocial" :rules="inputsRequeridos">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                        <v-text-field label="Nombre(s)" v-model="poliza.nombres" :rules="inputsRequeridos">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                        <v-text-field label="Apellido Paterno" v-model="poliza.apellidoPaterno"
                          :rules="inputsRequeridos"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                        <v-text-field label="Apellido Materno" v-model="poliza.apellidoMaterno"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                        <v-select label="Estado Civil" :items="['Soltero(a)', 'Casado(a)']" v-model="poliza.statusCivil"
                          :rules="inputsRequeridos"></v-select>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                        <v-menu ref="menuFechaNacimiento" v-model="menuFechaNacimiento" :close-on-content-click="false"
                          transition="scale-transition" offset-y max-width="290px" min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateFormattedFechaNacimiento" label="Fecha de Nacimiento"
                              persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
                              @blur="comboFechaNacimiento = parseDate(dateFormattedFechaNacimiento)" v-on="on"
                              v-mask="'##/##/####'"></v-text-field>
                          </template>
                          <v-date-picker v-model="comboFechaNacimiento" no-title @input="menuFechaNacimiento = false">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field v-model="poliza.rfc" label="RFC" placeholder="Requerido" :rules="rfcRules">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                        <v-select label="Género" v-model="poliza.selectedGenero" :items="generoList"
                          :rules="inputsRequeridos"></v-select>
                      </v-col>


                      <v-col cols="4">
                        <v-text-field label="Teléfono" v-model="poliza.celular" v-mask="'##########'"
                          :rules="inputsRequeridos"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Correo Electrónico" v-model="poliza.correoElectronico" :rules="emailRules">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="poliza.regimen != null">
                        <v-select label="Estado de la República" v-model="poliza.estadoRepublicaSelected"
                          :items="estados" item-text="name" item-value="id" :rules="inputsRequeridos"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Código Postal" v-model="poliza.codigoPostal" v-mask="'#####'"
                          :rules="inputsRequeridos"></v-text-field>
                      </v-col>
                      <v-col cols="8">
                        <v-textarea label="Dirección" v-model="poliza.direccion" rows="1" auto-grow></v-textarea>
                      </v-col>
                    </v-row>

                    <v-btn color="primary" @click="crearPoliza()" :disabled="!formIsValid">
                      Guardar
                    </v-btn>

                    <v-btn text @click="stepper = 1"> Regresar </v-btn>
                  </v-stepper-content>

                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <!-- ALERT MODAL -->
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="actualizadaExito">
      <template v-slot:default="actualizadaExito">
        <v-card>
          <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
          <v-card-text>
            <div class="text-h4" style="margin-top: 10px; ">
              Creación de Poliza exitoso!
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text
              @click="actualizadaExito.value = false, $router.push(`/seguimiento-solicitud/${this.$route.params.id}`)">
              Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- END OF ALERT MODAL -->
  </div>
</template>

<script>
var moment = require('moment-timezone');

export default {
  data() {
    return {
      emailRules: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Dirección de correo incorrecta'
        },
      ],
      rfcRules: [
        (v) => (v || '').length <= 13 || 'RFC No debe ser mayor de 13 caracteres',
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(v) ||
          "RFC no es valido recordar escribir en mayusculas",
      ],
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
      stepper: 1,
      poliza: {
        poliza: null,
        id: null,
        status: 0,
        type: null,
        productos: null,
        ejecutivoOperacion: null,
        cliente: null,
        newCliente: null,
        agente: null,
        landing: null,
        leadId: null,
        aseguradoraId: null,
        anterior: null,
        emitidaCheck: null,
        emitidDate: null,
        primerPagoCheck: null,
        primerPagoDate: null,
        canceladaCheck: null,
        canceladaDate: null,
        canceladaMotivo: null,
        finalizadaCheck: null,
        finalizadaDate: null,
        finalizadaMotivo: null,
        inicioDate: null,
        finDate: null,
        periodicidad: 4,
        primaNeta: null,
        primaTotal: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        formaPago: 1,
        numeroCuotas: null,
        noCuotasPagadas: null,
        creadoPorDate: null,
        creadoPor: null,
        ultimaModoficacionDate: null,
        ultimaModoficacion: null,
        observaciones: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        estadoRepublicaSelected: null,
        codigoPostal: null,
        statusCivil: null,
        direccion: null,
        regimen: null,
        razonSocial: null,
        notas: null,
        rfc: null,
        canceladaFinalizadaMotivo: null,
        checkRenovada: null,
        checkGenerarCuotas: null,
        checkReactivarPoliza: null
      },
      years: 1,
      yearsNumber: 0,
      actualizadaExito: false,
      emision: null,
      uuid: this.$route.params.id,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      estados: [
        { id: 1, name: 'Aguascalientes' },
        { id: 2, name: 'Baja California' },
        { id: 3, name: 'Baja California Sur' },
        { id: 4, name: 'Campeche' },
        { id: 5, name: 'Coahuila de Zaragoza' },
        { id: 6, name: 'Colima' },
        { id: 7, name: 'Chiapas' },
        { id: 8, name: 'Chihuahua' },
        { id: 9, name: 'Ciudad de México' },
        { id: 10, name: 'Durango' },
        { id: 11, name: 'Guanajuato' },
        { id: 12, name: 'Guerrero' },
        { id: 13, name: 'Hidalgo' },
        { id: 14, name: 'Jalisco' },
        { id: 15, name: 'Estado de México' },
        { id: 16, name: 'Michoacán de Ocampo' },
        { id: 17, name: 'Morelos' },
        { id: 18, name: 'Nayarit' },
        { id: 19, name: 'Nuevo León' },
        { id: 20, name: 'Oaxaca' },
        { id: 21, name: 'Puebla' },
        { id: 22, name: 'Querétaro' },
        { id: 23, name: 'Quintana Roo' },
        { id: 24, name: 'San Luis Potosí' },
        { id: 25, name: 'Sinaloa' },
        { id: 26, name: 'Sonora' },
        { id: 27, name: 'Tabasco' },
        { id: 28, name: 'Tamaulipas' },
        { id: 29, name: 'Tlaxcala' },
        { id: 30, name: 'Veracruz de Ignacio de la Llave' },
        { id: 31, name: 'Yucatán' },
        { id: 32, name: 'Zacatecas' },
      ],
      productos: [],
      agentes: [],
      statusList: [
        { id: 0, name: "Nueva" },
        { id: 1, name: "Emitida Pendente de Pago" },
        { id: 2, name: "Pagada Parcial" },
        { id: 3, name: "Pagada Total" },
        { id: 4, name: "Renovada" },
        { id: 5, name: "Finalizada" },
        { id: 6, name: "Cancelada" },
      ],
      cancelacionFinalizacionList: [
        { id: 0, name: "Falta de pago" },
        { id: 1, name: "Incremento en prima / Renovación" },
        { id: 2, name: "Mal servicio agente" },
        { id: 3, name: "Mal servicion compañía" },
        { id: 4, name: "Económicas" },
        { id: 5, name: "Inconformidad con el producto" },
        { id: 6, name: "Mejora en competencia" },
        { id: 7, name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente" },
      ],
      typePolizaList: [
        "Nueva",
        "Renovación",
      ],
      landList: [
        "AIG",
        "ANA",
        "AXA",
        "COMPARADOR",
        "COMPARADOR-ADT",
        "COMPARADOR-AUTOCOSMOS",
        "GNP",
        "HDI",
        "PROVEEDORES",
        "QUÁLITAS",
        "SGMM SEGURIFY",
        "SURA",
        "SURA MOVILIDAD",
        "SURA SGMM",
      ],
      aseguradorasList: [
        { id: 1, name: 'Afirme' },
        { id: 2, name: 'ANA' },
        { id: 3, name: 'GNP' },
        { id: 4, name: 'Primero' },
        { id: 5, name: 'Qualitas' },
        { id: 6, name: 'Multiva' },
        { id: 7, name: 'Sura' },
        { id: 8, name: 'AIG' },
        { id: 9, name: 'HDI' },
        { id: 10, name: 'Zurich' },
        { id: 11, name: 'AXA' },
        { id: 12, name: 'Banorte' },
      ],
      periodicidadList: [
        { id: 1, name: 'Anual' },
        { id: 2, name: 'Semestral' },
        { id: 3, name: 'Trimestral' },
        { id: 4, name: 'Mensual' },
      ],
      clienteList: [
        { id: 1, name: 'Cliente 1' },
        { id: 2, name: 'Cliente 2' },
        { id: 3, name: 'Cliente 3' },
        { id: 4, name: 'Cliente 4' },
      ],
      formaPagoList: [
        { id: 1, name: 'Tarjeta de Crédito' },
        { id: 2, name: 'Tarjeta de Débito' },
        { id: 3, name: 'Depósito en banco' },
        { id: 4, name: 'Transferencia' },
        { id: 5, name: 'Cheque' },
        { id: 6, name: 'Efectivo' },
        { id: 7, name: 'Domiciliación' },
        { id: 8, name: 'Cargo único' },
      ],
      generoList: ["Masculino", "Femenino"],
      regimentList: [
        "Física",
        "Moral",
      ],
      canal: [],
      comercialAsignadoList: [],
      comercialAsignado: null,
      esAgentDirecto: null,

      comboInicioVigencia: null,
      dateFormattedInicioVigencia: null,
      menuInicioVigencia: false,

      comboFinVigencia: null,
      dateFormattedFinVigencia: null,
      menuFinVigencia: false,

      comboPrimerPagoDate: null,
      dateFormattedPrimerPago: null,
      menu1: false,

      comboFechaNacimiento: null,
      dateFormattedFechaNacimiento: null,
      menuFechaNacimiento: null,

      ramo: null,
      aseguradoraId: null,

      /* RAMO AUTOS */
      modelo: null,
      marca: null,
      submarca: null,
      version: null,
      vehiculo: null,
      detallePoliza: null,
      plan: null,
      origenAuto: null,
      usoAuto: null,
      noMotor: null,
      noSerie: null,
      placas: null,

      /* RAMO DAÑOS */
      tipoInmueble: null,
      valorInmueble: null,
      nivelDepartamento: null,
      pisosTotales: null,
      numeroPisosAltos: null,
      sotanos: null,
      valorContenidos: null,
      valorEquipoElectronico: null,
      sumaAseguradaRC: null,
      sumaAseguradaCristales: null,
      sumaAseguradaRoboViolencia: null,
      sumaAseguradaDineroValores: null,
      sumaAseguradaObrasDeArte: null,

      /* RAMO SALUD */
      nivelHospitalario: null,
      sumaAsegurada: null,
      deducible: null,
      coaseguro: null,
      ceroDeducible: null,
      medicinaFueraHospital: null,
      emergenciaExtranjero: null,
      enfermedadesCatastroficas: null,
      coberturaExtranjero: null,
      preexistencias: null,
      proteccionDental: null,
      maternidadExtendida: null,
      coberturaNacional: null,
      reconocimientoAntiguedad: null,
      fechasParaCotizar: null,
      dependientes: null,

      /* RAMO VIDA */
      planVida: null,
      fumadorVida: null,
      sumaAseguradaVida: null,
      muerteAccidental: null,
      perdidasOrganicas: null,
      invalidezTotal: null,
      gastosFunerarios: null,

      /* RAMO AHORRO */
      tipoPortafolios: null,
      fumador: null,
      plazoComprometido: null,
      deducibilidad: null,
      revaluableInflacion: null,
      valorPlan: null,
      montoInversion: null,

      /* RAMO FIANZAS */
      contratoAdjunto: null,
    };
  },
  watch: {
    dialog(val) {
      if (!val) return

      setTimeout(() => (this.dialog = false), 4000)
    },
    years(value) {
      if (value.typeof != 'number') {
        this.years = parseInt(value)
        const meses = (this.years * 12);
        switch (this.poliza.periodicidad) {
          case 1:
            this.poliza.numeroCuotas = (meses / 12)
            break;
          case 2:
            this.poliza.numeroCuotas = (meses / 6)
            break;
          case 3:
            this.poliza.numeroCuotas = (meses / 3)
            break;
          case 4:
            this.poliza.numeroCuotas = (meses / 1)
            break;
          default:
            break;
        }
      }
    },
    comboInicioVigencia() {
      this.dateFormattedInicioVigencia = this.formatDate(this.comboInicioVigencia)
    },
    comboFinVigencia() {
      this.dateFormattedFinVigencia = this.formatDate(this.comboFinVigencia)
    },
    comboPrimerPagoDate() {
      this.dateFormattedPrimerPago = this.formatDate(this.comboPrimerPagoDate)
    },
    comboFechaNacimiento() {
      this.dateFormattedFechaNacimiento = this.formatDate(this.comboFechaNacimiento)
    },
    "poliza.productos": function (value) {
      this.obtenerRamo(value);
      this.obtenerAseguradoraId(value);
      this.ObtenerDatosPagos(value)
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/solicitud/agente/list', config).then(response => {
        response.data.map(element => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList
          })
        })
      });
    },
    esDirecto() {
      this.agentes.map(element => {
        let elemento = JSON.parse(JSON.stringify(element))
        let canalList = elemento.canalList
        if (element.id == this.poliza.agente) {
          canalList.map(elm => {
            if (elm.id == 3) {
              this.esAgentDirecto = true
            } else {
              this.esAgentDirecto = false
            }
          })
        }
      })
    },
    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/solicitud/agente/directo/list", config).then((response) => {
        response.data.map((element) => {
          this.comercialAsignadoList.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid
          });
        });
      });
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.post('/v1/producto/leadbradescard', {
        producto_lead: "BK C&A",
        producto_aseguradora: "Hogar"
      }, config).then(response => {
        response.data.map(element => {
          this.productos.push({
            nombre: `${element.ramo} / ${element.nombre} / ${element.nombreAseguradora}`,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId,
            primaNeta: element.primaNeta,
            primaTotal: element.total,
            primerPago: element.primerPago,
            Subsecuente: element.subsecuentePago,
          })
        })
      });
    },
    obtenerCliente(cliente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(`/v1/cliente/id/${cliente}`, config).then(resp => {
          this.poliza.newCliente = `${resp.data.nombre}` + " " + `${resp.data.apellidoPaterno}` + " " + `${resp.data.apellidoMaterno}`
        })
    },
    obtenerRamo(id) {
      let productoSelected = this.productos.find(element => element.id === id)
      this.ramo = productoSelected ? productoSelected.ramo : null
    },
    obtenerAseguradoraId(id) {
      let productoSelected = this.productos.find(element => element.id === id)
      this.aseguradoraId = productoSelected ? productoSelected.aseguradoraId : null
    },
    ObtenerDatosPagos(id) {
      let productoSelected = this.productos.find(element => element.id === id)
      this.poliza.primaNeta = productoSelected.primaNeta;
      this.poliza.primaTotal = productoSelected.primaTotal;
      this.poliza.montoPrimerPago = productoSelected.primerPago;
      this.poliza.montoSubsecuente = productoSelected.Subsecuente;
    },
    crearPoliza() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let request = {}

      request = {
        "id": this.poliza.id,
        "operador_id": '',
        "comercial_asignado": this.comercialAsignado ? this.comercialAsignado : null,
        "forma_pago": this.poliza.formaPago,
        "fecha": this.poliza.creadoPorDate ? moment(this.poliza.creadoPorDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        "poliza": `${this.poliza.poliza}`,
        "prima_neta": this.poliza.primaNeta,
        "prima_total": this.poliza.primaTotal,
        "primer_pago": this.poliza.montoPrimerPago,
        "subsecuente_pago": this.poliza.montoSubsecuente,
        "pago_id": this.poliza.periodicidad,
        "aseguradora_id": this.aseguradoraId,
        "agente": this.poliza.agente,
        "estado_id": this.poliza.estadoRepublicaSelected,
        "origen": '',
        "inicio": this.comboInicioVigencia ? this.comboInicioVigencia : null,
        "anios": this.years,
        "fin": this.comboFinVigencia ? this.comboFinVigencia : null,
        "campania": this.poliza.landing,
        "url": '',
        "status": this.poliza.status,
        "status_cobro": "",
        "telefono": this.poliza.celular,
        "correo_electronico": this.poliza.correoElectronico,
        "nombre_cliente": `${this.poliza.nombres} ${this.poliza.apellidoPaterno} ${this.poliza.apellidoMaterno}`,
        "producto_id": this.poliza.productos,
        "observaciones": this.poliza.observaciones,
        "modificado_por": parseInt(this.poliza.ultimaModoficacion),
        "nombre": this.poliza.nombres,
        "apellido_paterno": this.poliza.apellidoPaterno,
        "apellido_materno": this.poliza.apellidoMaterno,
        "codigo_postal": this.poliza.codigoPostal,
        "tipo_persona": this.poliza.regimen,
        "razon_social": this.poliza.razonSocial ? this.poliza.razonSocial : null,
        "estado_civil": this.poliza.statusCivil,
        "direccion": this.poliza.direccion,
        /* nuevos campos */
        "notas": this.poliza.notas,
        "tipo_poliza": this.poliza.type,
        "ejecutivo_de_operaciones": this.poliza.ejecutivoOperacion,
        "cliente_id": this.poliza.cliente ? this.poliza.cliente : null,
        "cotizacion": this.poliza.leadId,
        "poliza_anterior": this.poliza.anterior,
        "primer_pago_poliza": this.poliza.primerPagoCheck,
        "fechaPrimerPago": this.comboPrimerPagoDate ? this.comboPrimerPagoDate : null,
        "checkCancelada": this.poliza.canceladaCheck ? this.poliza.canceladaCheck : false,
        "fecha_cancelacion": this.poliza.canceladaDate ? moment(this.poliza.canceladaDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        "motivoCancelacion": this.poliza.canceladaFinalizadaMotivo ? this.canceladaFinalizadaMotivo : null,
        "checkPolizaFinalizada": this.poliza.finalizadaCheck ? this.poliza.finalizadaCheck : false,
        "fecha_finalizacion": this.poliza.finalizadaDate ? moment(this.poliza.finalizadaDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        "motivoFinalizacion": this.poliza.finalizadaMotivo,
        "checkPolizaEmitida": this.poliza.emitidaCheck ? this.poliza.emitidaCheck : false,
        "fecha_emision": this.poliza.emitidDate ? moment(this.poliza.emitidDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        "creado_por": parseInt(this.poliza.creadoPor),
        "ultima_modificacion": moment(new (Date)).format('YYYY-MM-DD'),
        "genero": this.poliza.selectedGenero,
        "fecha_nacimiento": this.comboFechaNacimiento ? this.comboFechaNacimiento : null,
        "rfc": this.poliza.rfc ? this.poliza.rfc : null,
        "checkGenerarCuotas": this.poliza.checkGenerarCuotas ? this.poliza.checkGenerarCuotas : false,
        "checkReactivarPoliza": this.poliza.checkReactivarPoliza ? this.poliza.checkReactivarPoliza : false
      };

      switch (this.ramo) {
        case 'AUTOS':
          request.plan_id = this.plan
          request.vehiculo = this.version,
            request.modelo = this.modelo,
            request.marca = this.marca,
            request.submarca = this.submarca,
            request.version = this.version,
            request.detalle_auto = this.detallePoliza,
            request.origen_auto = this.origenAuto,
            request.uso_auto = this.usoAuto,
            request.num_motor = this.noMotor,
            request.num_serie = this.noSerie,
            request.placas = this.placas
          break;
        case 'DAÑOS':
          request.tipoInmueble = this.tipoInmueble,
            request.valorInmueble = this.valorInmueble,
            request.nivelDepartamento = this.nivelDepartamento,
            request.pisosTotales = this.pisosTotales,
            request.numeroPisosAltos = this.numeroPisosAltos,
            request.sotanos = this.sotanos,
            request.valorContenidos = this.valorContenidos,
            request.valorEquipoElectronico = this.valorEquipoElectronico,
            request.sumaAseguradaRC = this.sumaAseguradaRC,
            request.sumaAseguradaCristales = this.sumaAseguradaCristales,
            request.sumaAseguradaRoboViolencia = this.sumaAseguradaRoboViolencia,
            request.sumaAseguradaDineroValores = this.sumaAseguradaDineroValores,
            request.sumaAseguradaObrasDeArte = this.sumaAseguradaObrasDeArte
          break;
        case 'SALUD':
          request.nivelHospitalario = this.nivelHospitalario
          request.sumaAsegurada = this.sumaAsegurada
          request.deducible = this.deducible
          request.coaseguro = this.coaseguro
          request.ceroDeducible = this.ceroDeducible
          request.medicinaFueraHospital = this.medicinaFueraHospital
          request.emergenciaExtranjero = this.emergenciaExtranjero
          request.enfermedadesCatastroficas = this.enfermedadesCatastroficas
          request.coberturaExtranjero = this.coberturaExtranjero
          request.preexistencias = this.preexistencias
          request.proteccionDental = this.proteccionDental
          request.maternidadExtendida = this.maternidadExtendida
          request.coberturaNacional = this.coberturaNacional
          request.reconocimientoAntiguedad = this.reconocimientoAntiguedad
          request.fechasParaCotizar = this.fechasParaCotizar
          request.dependientes = this.dependientes
          break;
        case 'VIDA':
          request.plan = this.planVida
          request.fumador = this.fumadorVida
          request.sumaAsegurada = this.sumaAseguradaVida
          request.muerteAccidental = this.muerteAccidental
          request.perdidasOrganicas = this.perdidasOrganicas
          request.invalidezTotal = this.invalidezTotal
          request.gastosFunerarios = this.gastosFunerarios
          break;
        case 'AHORRO':
          request.tipoPortafolios = this.tipoPortafolios
          request.fumador = this.fumador
          request.plazoComprometido = this.plazoComprometido
          request.deducibilidad = this.deducibilidad
          request.revaluableInflacion = this.revaluableInflacion
          request.valorPlan = this.valorPlan
          request.montoInversion = this.montoInversion
          break;
        case 'FIANZAS':
          request.contratoAdjunto = this.contratoAdjunto
          break;
        default:
          break;
      }
      this.$axios.post("/v1/emision/manual", request, config).then((value) => {
        console.log(value);
        this.enviandoPoliza = false;
        this.polizaEnviada = true;
        this.actualizadaExito = true
        setTimeout(() => {
          this.actualizadaExito = false
          this.$router.push(`/mis-emisiones`)
        }, 2000);
      })
    },
    // Datos para formulario
    onResultados(datos) {
      switch (this.ramo) {
        case 'AUTOS':
          this.modelo = datos.modelo
          this.marca = datos.marca
          this.submarca = datos.submarca
          this.version = datos.version
          this.vehiculo = datos.version
          this.detallePoliza = datos.detallePoliza
          this.plan = datos.plan
          this.origenAuto = datos.origenAuto
          this.usoAuto = datos.usoAuto
          this.noMotor = datos.noMotor
          this.noSerie = datos.noSerie
          this.placas = datos.placas
          this.formIsValid
          break;
        case 'DAÑOS':
          this.tipoInmueble = datos.tipoInmueble
          this.valorInmueble = datos.valorInmueble
          this.nivelDepartamento = datos.nivelDepartamento
          this.pisosTotales = datos.pisosTotales
          this.numeroPisosAltos = datos.numeroPisosAltos
          this.sotanos = datos.sotanos
          this.valorContenidos = datos.valorContenidos
          this.valorEquipoElectronico = datos.valorEquipoElectronico
          this.sumaAseguradaRC = datos.sumaAseguradaRC
          this.sumaAseguradaCristales = datos.sumaAseguradaCristales
          this.sumaAseguradaRoboViolencia = datos.sumaAseguradaRoboViolencia
          this.sumaAseguradaDineroValores = datos.sumaAseguradaDineroValores
          this.sumaAseguradaObrasDeArte = datos.sumaAseguradaObrasDeArte
          this.formIsValid
          break;
        case 'SALUD':
          this.nivelHospitalario = datos.nivelHospitalario
          this.sumaAsegurada = datos.sumaAsegurada
          this.deducible = datos.deducible
          this.coaseguro = datos.coaseguro
          this.ceroDeducible = datos.ceroDeducible
          this.medicinaFueraHospital = datos.medicinaFueraHospital
          this.emergenciaExtranjero = datos.emergenciaExtranjero
          this.enfermedadesCatastroficas = datos.enfermedadesCatastroficas
          this.coberturaExtranjero = datos.coberturaExtranjero
          this.preexistencias = datos.preexistencias
          this.proteccionDental = datos.proteccionDental
          this.maternidadExtendida = datos.maternidadExtendida
          this.coberturaNacional = datos.coberturaNacional
          this.reconocimientoAntiguedad = datos.reconocimientoAntiguedad
          this.fechasParaCotizar = datos.fechasParaCotizar
          this.dependientes = datos.dependientes
          this.formIsValid
          break;
        case 'VIDA':
          this.planVida = datos.plan
          this.fumadorVida = datos.fumador
          this.sumaAseguradaVida = datos.sumaAsegurada
          this.muerteAccidental = datos.muerteAccidental
          this.perdidasOrganicas = datos.perdidasOrganicas
          this.invalidezTotal = datos.invalidezTotal
          this.gastosFunerarios = datos.gastosFunerarios
          this.formIsValid
          break;
        case 'AHORRO':
          this.tipoPortafolios = datos.tipoPortafolios
          this.fumador = datos.fumador
          this.plazoComprometido = datos.plazoComprometido
          this.deducibilidad = datos.deducibilidad
          this.revaluableInflacion = datos.revaluableInflacion
          this.valorPlan = datos.valorPlan
          this.poliza.primaTotal = datos.valorPlan
          this.montoInversion = datos.montoInversion
          this.poliza.primaNeta = datos.montoInversion
          this.poliza.montoPrimerPago = datos.montoInversion
          this.poliza.montoSubsecuente = datos.montoInversion
          this.formIsValid
          break;
        case 'FIANZAS':
          this.contratoAdjunto = datos.contratoAdjunto
          break;
        default:
          break;
      }
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  computed: {
    formIsValid() {
      if (this.ramo == 'AUTOS') {
        return (
          this.poliza.agente != null &&
          this.poliza.productos != null &&
          this.poliza.regimen != null &&
          this.poliza.rfc != null &&
          this.poliza.celular != null &&
          this.poliza.correoElectronico != null &&
          this.poliza.codigoPostal != null &&
          this.poliza.direccion != null &&
          this.comboInicioVigencia != null &&
          this.comboFinVigencia != null &&
          this.poliza.periodicidad != null &&
          this.poliza.formaPago != null &&
          this.poliza.primaNeta != null &&
          this.poliza.primaTotal != null &&
          this.poliza.montoPrimerPago != null &&
          // this.poliza.montoSubsecuente != null &&
          this.comboPrimerPagoDate != null &&

          /* CAMPOS DEL RAMO */
          this.modelo != null &&
          this.version != null &&
          this.marca != null &&
          this.submarca != null &&
          this.detallePoliza != null &&
          this.plan != null &&
          this.origenAuto != null &&
          this.usoAuto != null &&
          this.noMotor != null &&
          this.noSerie != null &&
          this.placas != null
        )

      } else if (this.ramo == 'DAÑOS') {
        return (
          this.poliza.agente != null &&
          this.poliza.productos != null &&
          this.poliza.regimen != null &&
          this.poliza.rfc != null &&
          this.poliza.celular != null &&
          this.poliza.correoElectronico != null &&
          this.poliza.codigoPostal != null &&
          this.poliza.direccion != null &&
          this.comboInicioVigencia != null &&
          this.comboFinVigencia != null &&
          this.poliza.periodicidad != null &&
          this.poliza.formaPago != null &&
          this.poliza.primaNeta != null &&
          this.poliza.primaTotal != null &&
          this.poliza.montoPrimerPago != null &&
          // this.poliza.montoSubsecuente != null &&
          this.comboPrimerPagoDate != null &&

          /* CAMPOS DEL RAMO */
          this.tipoInmueble != null &&
          this.valorInmueble != null &&
          this.valorContenidos != null &&
          this.valorEquipoElectronico != null &&
          this.sumaAseguradaRC != null &&
          this.sumaAseguradaCristales != null &&
          this.sumaAseguradaRoboViolencia != null &&
          this.sumaAseguradaDineroValores != null &&
          this.sumaAseguradaObrasDeArte != null
        )
      } else if (this.ramo == 'SALUD') {
        return (
          this.poliza.agente != null &&
          this.poliza.productos != null &&
          this.poliza.regimen != null &&
          this.poliza.rfc != null &&
          this.poliza.celular != null &&
          this.poliza.correoElectronico != null &&
          this.poliza.codigoPostal != null &&
          this.poliza.direccion != null &&
          this.comboInicioVigencia != null &&
          this.comboFinVigencia != null &&
          this.poliza.periodicidad != null &&
          this.poliza.formaPago != null &&
          this.poliza.primaNeta != null &&
          this.poliza.primaTotal != null &&
          this.poliza.montoPrimerPago != null &&
          // this.poliza.montoSubsecuente != null &&
          this.comboPrimerPagoDate != null &&

          /* CAMPOS DEL RAMO */
          this.nivelHospitalario != null &&
          this.sumaAsegurada != null &&
          this.deducible != null &&
          this.coaseguro != null &&
          this.ceroDeducible != null &&
          this.medicinaFueraHospital != null &&
          this.emergenciaExtranjero != null &&
          this.enfermedadesCatastroficas != null &&
          this.coberturaExtranjero != null &&
          this.preexistencias != null &&
          this.proteccionDental != null &&
          this.maternidadExtendida != null &&
          this.coberturaNacional != null &&
          this.reconocimientoAntiguedad != null &&
          this.fechasParaCotizar != null
        )
      } else if (this.ramo == 'VIDA') {
        return (
          this.poliza.agente != null &&
          this.poliza.productos != null &&
          this.poliza.regimen != null &&
          this.poliza.rfc != null &&
          this.poliza.celular != null &&
          this.poliza.correoElectronico != null &&
          this.poliza.codigoPostal != null &&
          this.poliza.direccion != null &&
          this.comboInicioVigencia != null &&
          this.comboFinVigencia != null &&
          this.poliza.periodicidad != null &&
          this.poliza.formaPago != null &&
          this.poliza.primaNeta != null &&
          this.poliza.primaTotal != null &&
          this.poliza.montoPrimerPago != null &&
          // this.poliza.montoSubsecuente != null &&
          this.comboPrimerPagoDate != null &&

          /* CAMPOS DEL RAMO */
          this.planVida != null &&
          this.fumadorVida != null &&
          this.sumaAseguradaVida != null &&
          this.muerteAccidental != null &&
          this.perdidasOrganicas != null &&
          this.invalidezTotal != null &&
          this.gastosFunerarios != null
        )
      } else if (this.ramo == 'AHORRO') {
        return (
          this.poliza.agente != null &&
          this.poliza.productos != null &&
          this.poliza.regimen != null &&
          this.poliza.rfc != null &&
          this.poliza.celular != null &&
          this.poliza.correoElectronico != null &&
          this.poliza.codigoPostal != null &&
          this.poliza.direccion != null &&
          this.comboInicioVigencia != null &&
          this.comboFinVigencia != null &&
          this.poliza.periodicidad != null &&
          this.poliza.formaPago != null &&
          this.comboPrimerPagoDate != null &&

          /* CAMPOS DEL RAMO */
          this.tipoPortafolios != null &&
          this.fumador != null &&
          this.plazoComprometido != null &&
          this.deducibilidad != null &&
          this.revaluableInflacion != null &&
          this.valorPlan != null &&
          this.montoInversion != null
        )
      } else if (this.ramo == 'FIANZAS') {
        return (
          this.poliza.agente != null &&
          this.poliza.productos != null &&
          this.poliza.regimen != null &&
          this.poliza.rfc != null &&
          this.poliza.celular != null &&
          this.poliza.correoElectronico != null &&
          this.poliza.codigoPostal != null &&
          this.poliza.direccion != null &&
          this.comboInicioVigencia != null &&
          this.comboFinVigencia != null &&
          this.poliza.periodicidad != null &&
          this.poliza.formaPago != null &&
          this.comboPrimerPagoDate != null &&

          /* CAMPOS DEL RAMO */
          this.contratoAdjunto != null
        )
      } else return false
    },
  },

  mounted() {
    this.obtenerAgentes();
    this.obtenerProductos();
    this.obtenerUsersCanalDirecto();
    this.years = 12;
  },
};
</script>
