<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <FiltrosSolicitudes
          @filtrosModificados="updateFilters"
          :loading="loading"
        />
      </v-col>
      <v-col cols="12">
        <ResultadosSolicitudes :loading="loading" :solicitudes="solicitudes" @paginacionModificada="paginacionModificada" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logoSegurify from "@/assets/logo_segurify.png";
import FiltrosSolicitudes from "./FiltrosSolicitudes.vue";
import ResultadosSolicitudes from "./ResultadosSolicitudes.vue";
var moment = require('moment-timezone');

export default {
  components: {
    FiltrosSolicitudes,
    ResultadosSolicitudes,
  },
  data() {
    return {
      logoSegurify,
      loading: false,
      solicitudes: {
        solicitudes:[],
        total:0
      },
      currentFiltros:{},
      currentPage:{
        pagina:1,
        registrosPagina:10
      }
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },
  watch:{
    currentFiltros(){
      this.realizarBusqueda()
    },
    // currentPage(){
    //   this.realizarBusqueda()
    // }
  },
  methods: {
    realizarBusqueda() {
      var filtros = {...this.currentFiltros, ...this.currentPage}
      this.loading = true;
      this.solicitudes.solicitudes = [];
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .post("/v1/solicitud/filtros", filtros, config)
        .then((response) => {
          this.loading = false;
          if (!response.data.data.solicitudes.length) return;
          response.data.data.solicitudes.forEach((solicitud) => {
            this.solicitudes.solicitudes.push({
              id: solicitud.id,
              titulo: solicitud.titulo || "No disponible",
              origen: solicitud.origen || "No disponible",
              nombre: `${solicitud.nombre} ${solicitud.apellidoPaterno ||
                ""} ${solicitud.apellidoMaterno || ""}`,
              uuid: solicitud.uuid,
              status: solicitud.status || "No disponible",
              statusId: solicitud.statusId || "No disponible",
              tipo: solicitud.tipo || "No disponible",
              fechaCreacion: moment.utc(solicitud.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') || "No disponible",
            }); 
          });
          this.solicitudes.total = response.data.data.totalRegistros
        });
    },
    updateFilters(e){
      this.currentFiltros = e
    },
    paginacionModificada(e){
      this.currentPage.pagina = e.pagina
      this.currentPage.registrosPagina = e.registrosPagina
      this.realizarBusqueda()
      
    }
  },
};
</script>
