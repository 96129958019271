import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../../services/mainService";

/**
 * Este método trae un listado de todos los productos
 * bradescard
 */
export const getListaProductos = async () => {
  try {
    const response = await mainAxios.get(
      "/v1/productos/listaProductos",
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
