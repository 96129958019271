<template>
    <div class="mb-10">
        <v-row class="mb-10" dense align="center">
            <v-img :max-height="height_log1" :max-width="width_log1" :src="logo_1"></v-img>
            <h2 class="ml-5">Leads bradescard</h2>
            <v-spacer />
            <v-img :max-height="width_log2" :max-width="height_log2" :src="logo_2"></v-img>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card flat dense>
                    <v-row dense justify="space-around">
                        <v-card dense width="150" tile :to="{ name: 'LeadsBradescard', params: { status: 'Nuevo' } }"
                            color="#67ae99" dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">Sin asignar</span>
                            </v-card-subtitle>
                        </v-card>

                        <v-card dense width="150" tile :to="{ name: 'LeadsBradescard', params: { status: 'Asignado' } }"
                            color="#2e82a4" dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">Asignados</span>
                            </v-card-subtitle>
                        </v-card>

                        <v-card dense width="150" tile
                            :to="{ name: 'LeadsBradescard', params: { status: 'No Efectivo Seguimiento' } }"
                            color="#1f6ea6" dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">No Efectivo Seguimiento</span>
                            </v-card-subtitle>
                        </v-card>

                        <v-card dense width="150" tile
                            :to="{ name: 'LeadsBradescard', params: { status: 'No Efectivo Finalizado' } }"
                            color="#6f97a2" dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">No Efectivo Finalizado</span>
                            </v-card-subtitle>
                        </v-card>

                        <v-card dense width="150" tile
                            :to="{ name: 'LeadsBradescard', params: { status: 'Efectivo Seguimiento' } }"
                            color="#343242" dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">Efectivo Seguimiento</span>
                            </v-card-subtitle>
                        </v-card>

                        <v-card dense width="150" tile
                            :to="{ name: 'LeadsBradescard', params: { status: 'Efectivo Venta' } }" color="#4fa6af"
                            dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">Efectivo Venta</span>
                            </v-card-subtitle>
                        </v-card>

                        <v-card dense width="150" tile
                            :to="{ name: 'LeadsBradescard', params: { status: 'Efectivo No Venta' } }" color="#1b1b1b"
                            dark>
                            <v-card-subtitle class="text-subtitle-2 text-center">
                                <span class="white--text">Efectivo No Ventas</span>
                            </v-card-subtitle>
                        </v-card>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense class="mt-4 text-center" justify="center">
            <v-col cols="12" sm="12" md="6" lg="5" xl="5">
                <v-card dense>
                    <v-card-text v-if="renderGhapics">
                        <div id="pie" class="chart"></div>
                    </v-card-text>

                    <v-card-text v-else>
                        <v-progress-circular :size="100" indeterminate color="primary"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="5" xl="5">
                <v-card dense>
                    <v-card-text v-if="renderGhapics">
                        <div id="bar" class="chart"></div>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-progress-circular :size="100" indeterminate color="primary"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12">
                <v-card dense flat>
                    <v-card-title class="text-center">
                        Agendas De Llamadas
                    </v-card-title>

                    <v-card-text>
                        <v-data-table :headers="headAgenda" :items="agenda" :items-per-page="5" class="elevation-1"
                            dense>
                            <template v-slot:item.actions="{ item }">
                                <v-btn icon :to="{ name: 'DetalleLeadBradescard', params: { uuid: item.uuid } }">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <template #no-data>
                                No se encontro registros asignados
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mainAxios } from "@/mainAxios";
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-bradescard-horizontal.png";

export default {
    name: 'Bradescard.ViewBradescard',
    data: function () {
        return {
            logo_1: LogoSegurify,

            width_log1: 80,
            height_log1: 80,

            logo_2: LogoBradescard,
            width_log2: 80,
            height_log2: 250,

            renderGhapics: true,
            pieLeadsChart: {},
            barEmisionesChart: {},
            barChart: {},
            pieLeadsChartData: [],
            agenteUuid: localStorage.agenteUuid,
            agenteAccessToken: localStorage.getItem('agenteAccessToken'),
            agenda: [],
            headAgenda: [
                { text: 'Nombre', align: 'start', sortable: false, value: 'nombre' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
                { text: 'Fecha de llamada agendada', value: 'proximaLlamada' },
                { text: 'ver', value: 'actions', sortable: false, },
            ],
        }
    },
    mounted() {
        this.loadPiechart();
        this.loadBarChart();
        this.loadAgenda();
        this.pieLeadsChart.on('click', (params) => {
            if (params.name == 'Nuevo') {
                this.$router.push({ name: 'LeadsBradescard', params: { status: 'Nuevo' } });
            } else {
                this.$router.push(`/bradescard/leads-bradescard-dashboard/${params.name}`);
            }
        });

        this.barChart.on('click', (params) => {
            this.$router.push(`/bradescard/polizas-bradescard-dashboard/${params.name}`);
        });

    }, watch: {
        "$vuetify.breakpoint.xs": function (val) {
            // console.log('xs', val)

            if (val) {
                this.width_log1 = 50;
                this.height_log1 = 80;

                this.width_log2 = 90;
                this.height_log2 = 100;

                this.renderGhapics = false;
                this.$nextTick(() => {
                    this.renderGhapics = true;
                    setTimeout(() => {
                        this.loadPiechart();
                        this.loadBarChart();
                    }, 300)
                });
            }
        },
        "$vuetify.breakpoint.sm": function (val) {
            // console.log('sm', val)

            if (val) {
                this.width_log1 = 60;
                this.height_log1 = 80;

                this.width_log2 = 160;
                this.height_log2 = 200;

                this.renderGhapics = false;
                this.$nextTick(() => {
                    this.renderGhapics = true;
                    setTimeout(() => {
                        this.loadPiechart();
                        this.loadBarChart();
                    }, 300)
                });
            }
        },
        "$vuetify.breakpoint.md": function (val) {
            // console.log('md', val)
            if (val) {
                this.width_log1 = 80;
                this.height_log1 = 80;

                this.width_log2 = 90;
                this.height_log2 = 300;

                this.renderGhapics = false;
                this.$nextTick(() => {
                    this.renderGhapics = true;
                    setTimeout(() => {
                        this.loadPiechart();
                        this.loadBarChart();
                    }, 300)
                });
            }
        },
        "$vuetify.breakpoint.lg": function (val) {
            // console.log('lg', val)

            if (val) {
                this.width_log1 = 80;
                this.height_log1 = 80;

                this.width_log2 = 90;
                this.height_log2 = 300;

                this.renderGhapics = false;
                this.$nextTick(() => {
                    this.renderGhapics = true;
                    setTimeout(() => {
                        this.loadPiechart();
                        this.loadBarChart();
                    }, 300)
                });
            }
        },
        "$vuetify.breakpoint.xl": function (val) {
            // console.log('xl', val)
            if (val) {
                this.width_log1 = 80;
                this.height_log1 = 80;

                this.width_log2 = 90;
                this.height_log2 = 300;

                this.renderGhapics = false;
                this.$nextTick(() => {
                    this.renderGhapics = true;
                    setTimeout(() => {
                        this.loadPiechart();
                        this.loadBarChart();
                    }, 300)
                });
            }
        },
    },
    methods: {
        loadPiechart() {
            var options = {
                title: {
                    text: 'Estatus Leads'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    // orient: 'vertical',
                    top: 'bottom',
                    type: 'scroll',
                },
                series: [
                    {
                        name: 'Estatus Leads',
                        type: 'pie',
                        radius: '50%',
                        avoidLabelOverlap: false, // evita la superpósicon de etiquetas
                        // stillShowZeroSum: false,
                        data: [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ],
            }
            this.pieLeadsChart = this.$chart.init(document.getElementById('pie'));
            this.pieLeadsChart.setOption(options);

            var data = [];
            mainAxios.get(`v1/bradescard/dashboard/data/${this.agenteUuid}`, {
                headers: {
                    Authorization: `Bearer ${this.agenteAccessToken}`,
                }
            }).then((response) => {
                Object.keys(response.data.Leads).forEach(key => {
                    data.push({
                        name: key,
                        value: response.data.Leads[key]
                    });
                })
                options.series[0].data = data;
                this.pieLeadsChart.setOption(options);
            });
        },
        loadBarChart() {
            var options = {
                title: {
                    text: 'Estatus Polizas'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    // orient: 'vertical',
                    top: 'bottom',
                    type: 'scroll',

                },
                series: [
                    {
                        name: 'Estatus Polizas',
                        type: 'pie',
                        radius: '50%',
                        avoidLabelOverlap: false,
                        // stillShowZeroSum: false,
                        data: [
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            },
                        },
                        label: {
                            show: true,
                        },
                        labelLine: { // quita las lineas del pie
                            show: true
                        },
                    }
                ],
            }
            this.barChart = this.$chart.init(document.getElementById('bar'));
            this.barChart.setOption(options);
            var data = [];
            mainAxios.get(`v1/bradescard/dashboard/data/${this.agenteUuid}`, {
                headers: {
                    Authorization: `Bearer ${this.agenteAccessToken}`,
                }
            }).then((response) => {
                Object.keys(response.data.Emisiones).forEach(key => {
                    data.push({
                        name: key,
                        value: response.data.Emisiones[key]
                    });
                })
                options.series[0].data = data;
                this.barChart.setOption(options);
            });
        },
        async loadAgenda() {
            try {
                let { data } = await this.$axios.get(`v1/bradescard/agenda/${localStorage.agenteUuid}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                });
                // console.log(data);
                this.agenda = data;
                this.agenda = [];
            } catch (exception) {
                console.error(exception);
            }
        }
    },

}
</script>

<style>
.chart {
    width: 400px;
    height: 350px;
    /* width: 97%; */
}
</style>