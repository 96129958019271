import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";

export const getSolicitudesByIdEmision = async (idEmision) => {
  try {
    const serverResponse = await mainAxios.get(
      `/v1/solicitud/emision/${idEmision}`,
      getDefaultHeaders()
    );

    return serverResponse.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getSolicitudById = async (idSolicitud) => {
  try {
    const serverResponse = await mainAxios.get(
      `/v1/solicitud/id/${idSolicitud}`,
      getDefaultHeaders()
    );

    return serverResponse.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const updateSolicitud = async (body, idSolicitud) => {
  try {
    const serverResponse = await mainAxios.put(
      `/v1/solicitud/editar/${idSolicitud}`,
      body,
      getDefaultHeaders()
    );

    return serverResponse.data.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      throw new Error(getErrorMessage(error));
    }
  }
};