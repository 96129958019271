<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>
            {{
                manager
                  ? 'Reporte Cotizaciones asignados'
                  : 'Reporte Cotizaciones'
            }}
          </h1>
        </v-col>
      </v-row>
      <v-row class="mb-15" style="padding: 30px">
        <v-col cols="3" md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="generateReport">Generar Reporte</v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px" dark>
            <download-csv :data="reportDownload" name="cotizacion.csv">
              {{ downloadText }}
            </download-csv>
          </v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px" dark @click="downloadComparadorReport">
            Descagar Reporte Comparador</v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table v-if="!loading && reportType == 'lead'" :headers="columns" :items="report" item-key="name"
            class="elevation-1" :search="search" :custom-filter="filterOnlyCapsText" :loading="loading"
            loading-text="Cargando...">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item.telefono="{ item }">
              <td style="white-space: nowrap">{{ item.telefono }}</td>
            </template>


            <template v-slot:item.version="{ item }">
              <td style="white-space: nowrap">{{ item.version }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

var moment = require('moment-timezone');

export default {
  data() {
    return {
      roles: JSON.parse(localStorage.getItem('roles')),
      uuid: localStorage.getItem('agenteUuid'),
      dates: [],
      downloadText: 'Descargar Reporte En Excel',
      downloading: false,
      loading: false,
      reportType: 'lead',
      report: [],
      reportDownload: [],

      search: '',
      columns: [
        {
          text: 'Cotizacion',
          align: 'start',
          sortable: false,
          value: 'cotizacion',
        },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Aseguradora', value: 'aseguradora' },
        { text: 'Correo Electronico', value: 'correo' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Versión', value: 'version' },
      ],
    };
  },

  computed: {
    admin() {
      let isAdminCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'ADMIN') {
          isAdminCotiza = true;
        }
      });
      return isAdminCotiza;
    },
    operador() {
      let isOperCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'OPERADOR') {
          isOperCotiza = true;
        }
      });
      return isOperCotiza;
    },
    operaciones() {
      let isOperacionesCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'OPERACIONES') {
          isOperacionesCotiza = true;
        }
      });
      return isOperacionesCotiza;
    },
    adminagent() {
      let isAdminAgentCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'ADMINAGENT') {
          isAdminAgentCotiza = true;
        }
      });
      return isAdminAgentCotiza;
    },
    manager() {
      let isManagerCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGER') {
          isManagerCotiza = true;
        }
      });
      return isManagerCotiza;
    },
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz('UTC', true);
      return dateRes.tz('America/Mexico_City').format('DD-MM-YYYY HH:mm');
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    generateReport() {
      let vm = this;
      vm.reportType = 'lead';
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
        //restar 6 horas al reques
        //moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
      }

      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      vm.loading = true;
      if (this.manager) {
        this.$axios.get(`/v1/comercial/cotizaciones-agentes/${this.uuid}`,//fechas,
          config
        )
          .then((response) => {
            vm.report = response.data;
            vm.loading = false;
          });
      } else {
        this.$axios
          .post('/v1/reporte/leads/online', fechas, config)
          .then((response) => {
            vm.report = []
            vm.reportDownload = []
            response.data.map(element => {
              vm.report.push({
                "cotizacion": element.cotizacion,
                "nombre": element.nombre,
                "telefono": element.telefono,
                "aseguradora": element.aseguradora,
                "correo": element.correo,
                "fecha": element.fecha,
                "version": element.version,
              })
            })
            response.data.map(element => {
              vm.reportDownload.push({
                "cotizacion": element.cotizacion,
                "nombre": element.nombre,
                "telefono": element.telefono,
                "aseguradora": element.aseguradora,
                "correo": element.correo,
                "fecha": element.fecha,
                "version": element.version,
              })
            })
            vm.loading = false;
          });
      }
    },
    downloadReport() {
      let vm = this;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;
      let fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      vm.downloadText = 'Generando reporte...';
      this.$axios.post('/v1/reporte/leads/', fechas, config).then((response) => {
        vm.downloading = false;
        vm.downloadText = 'Descargar';
        require('downloadjs')(
          response.data,
          'ReporteLeads.xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        //download(response.data, "ReporteLeads.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      });
    },
    downloadComparadorReport() {
      let vm = this;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;

      vm.downloadText = 'Generando reporte...';
      this.$axios
        .get('/v1/reporte/cotizaciones/comparador', config)
        .then((response) => {
          vm.downloading = false;
          vm.downloadText = 'Descargar';
          require('downloadjs')(
            response.data,
            'ReporteComprador.xls',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
          //download(response.data, "ReporteComprador.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        });
    },

    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
