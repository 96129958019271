<template>
  <div>
    <div v-if="leadSinAsignar">
      <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
        <v-toolbar>
          <v-toolbar-title>
            <h3>
              Certificado:
              {{
                this.poliza.poliza != "null"
                  ? "#" + this.poliza.poliza
                  : "Aun sin certificado"
              }}
            </h3>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <h3>Plan: {{ getCurrentProduct() }}</h3>
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <h3>Mensualidad: {{ toCurrency(poliza.montoPrimerPago) }}</h3>
          <v-spacer></v-spacer>

          <v-btn v-if="canEdit" icon @click="verEmisionEditable()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            @click="goBack()"
            style="background-color: #ff0042; color: white;"
          >
            FINALIZAR
          </v-btn>
        </v-toolbar>
      </v-card>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="7">
          <v-form>
            <v-row>
              <v-col>
                <v-stepper non-linear>
                  <v-stepper-header class="d-flex justify-space-around">
                    <v-stepper-step step="1" editable>
                      Cotizacion / Poliza
                    </v-stepper-step>

                    <v-stepper-step step="2" editable>
                      Datos del cliente
                    </v-stepper-step>
                    <v-stepper-step step="3" editable v-if="hasEndoso">
                      Detalles de endosos
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row style="padding: 20px; margin-top: -35px">
                        <v-col cols="4">
                          <v-text-field
                            label="Estatus de la Póliza"
                            v-model="poliza.status"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Cliente"
                            v-model="poliza.newCliente"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            label="Tipo de contratante"
                            v-model="poliza.tipo_contratante"
                            :items="tipos_contratante"
                            v-if="productoAseguradora == 'Hogar'"
                            readonly
                            item-text="text"
                            item-value="value"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            label="Plan"
                            v-model="poliza.productos"
                            :items="productos"
                            item-text="nombre"
                            item-value="id"
                            placeholder="Requerido"
                            readonly
                            :rules="[$rules.required]"
                          >
                          </v-autocomplete>
                        </v-col>

                        <v-col
                          cols="4"
                          v-if="
                            poliza.status == 'Pagada Total' ||
                              poliza.status == 'Renovada'
                          "
                        >
                          <v-text-field
                            label="Póliza renovada por"
                            v-model="poliza.anterior"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" v-if="visible">
                          <v-text-field
                            label="No. Cotización / Lead ID"
                            v-model="poliza.leadId"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="6"
                          v-if="
                            poliza.status == 'Finalizada' ||
                              poliza.status == 'Cancelada'
                          "
                        >
                          <v-select
                            label="Motivo de Cancelación / Finalización"
                            v-model="poliza.canceladaFinalizadaMotivo"
                            :items="cancelacionFinalizacionList"
                            readonly
                            item-text="name"
                            item-value="name"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="6"
                          v-if="
                            poliza.polizaSustituida &&
                              poliza.status == 'Cancelada'
                          "
                        >
                          <v-text-field
                            label="Póliza de sustitución"
                            v-model="poliza.polizaSustituida"
                            readonly
                            append-icon="mdi-eye"
                            @click:append="
                              $router.push({
                                name: 'emision',
                                params: { uuid: poliza.polizaSustituida },
                              })
                            "
                          ></v-text-field>
                        </v-col>

                        <v-col cols="5" v-if="poliza.status == 'Cancelada'">
                          <v-text-field
                            label="Fecha de Cancelación"
                            prepend-icon="mdi-calendar"
                            v-model="poliza.canceladaDate"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.status == 'Finalizada'">
                          <v-text-field
                            label="Fecha de Finalización"
                            v-model="poliza.finalizadaDate"
                            prepend-icon="mdi-calendar"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            label="Inicio de Vigencia"
                            v-model="poliza.inicioDate"
                            prepend-icon="mdi-calendar"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Fin de Vigencia"
                            v-model="poliza.finDate"
                            prepend-icon="mdi-calendar"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="visible">
                          <v-text-field
                            label="Periodicidad de pago"
                            v-model="poliza.periodicidad"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="visible">
                          <v-text-field
                            label="Periodicidad de pago"
                            v-model="poliza.periodicidad"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="visible">
                          <v-text-field
                            label="Años"
                            v-model="years"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" v-if="visible">
                          <v-text-field
                            label="Número de cuotas"
                            v-model="poliza.numeroCuotas"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Prima neta"
                            v-model="poliza.primaNeta"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Prima total"
                            v-model="poliza.primaTotal"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" v-if="visible">
                          <v-text-field
                            label="Forma de Pago"
                            v-model="poliza.formaPago"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Monto de Primer Pago"
                            v-model="poliza.montoPrimerPago"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Fecha de primer pago"
                            v-model="poliza.fechaPrimerPago"
                            prepend-icon="mdi-calendar"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="4" v-if="visible">
                        <v-text-field label="Monto de Pago Subsecuente" v-model="poliza.montoSubsecuente" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                          <v-select label="Tipo de Persona (Régimen)" v-model="poliza.regimen"
                              :items="regimentList" :disabled=disable :rules="[$rules.required]"></v-select>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen == 'Moral' && poliza.regimen != null">
                          <v-text-field label="Razón Social" v-model="poliza.razonSocial"
                              :rules="[$rules.required]">
                          </v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                          <v-text-field label="Nombre(s)" v-model="poliza.nombres"
                              :rules="[$rules.required]">
                          </v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                          <v-text-field label="Apellido Paterno" v-model="poliza.apellidoPaterno"
                              :rules="[$rules.required]"></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                          <v-text-field label="Apellido Materno" :rules="[$rules.required]" v-model="poliza.apellidoMaterno">
                          </v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                          <v-select label="Estado Civil" :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
                              v-model="poliza.statusCivil" :rules="[$rules.required]"></v-select>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                          <v-menu ref="menuFechaNacimiento" v-model="menuFechaNacimiento"
                              :close-on-content-click="false" transition="scale-transition" offset-y
                              max-width="290px" min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="datepolizaattedFechaNacimiento"
                                      label="Fecha de Nacimiento" persistent-hint :rules="[$rules.required]"
                                      prepend-icon="mdi-calendar" v-bind="attrs"
                                      @blur="poliza.comboFechaNacimiento = parseDate(datepolizaattedFechaNacimiento)"
                                      v-on="on" v-mask="'##/##/####'"></v-text-field>
                              </template>
                              <v-date-picker v-model="poliza.comboFechaNacimiento" no-title
                                  @input="menuFechaNacimiento = false">
                              </v-date-picker>
                          </v-menu>
                      </v-col>

                      <v-col cols="4">
                          <v-text-field v-model="poliza.edad" label="EDAD" placeholder="Requerido" :disabled=disable >
                          </v-text-field>
                      </v-col>

                      <v-col cols="4">
                          <v-text-field v-model="poliza.rfc" label="RFC" placeholder="Requerido"
                              :rules="rfcRules">
                          </v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="poliza.regimen != 'Moral' && poliza.regimen != null">
                          <v-select label="Género" v-model="poliza.selectedGenero" :items="generoList"
                              :rules="[$rules.required]"></v-select>
                      </v-col>

                      <v-col cols="4">
                          <v-text-field label="Teléfono" v-model="poliza.celular"></v-text-field>
                      </v-col>

                      <v-col cols="4">
                          <v-text-field label="Correo Electrónico" v-model="poliza.correoElectronico"
                              :rules="emailRules">
                          </v-text-field>
                      </v-col>


                      <v-col cols="8" style="padding-buttom:5%">
                          <v-textarea label="Dirección" :rules="[$rules.required]" v-model="poliza.direccion" rows="1" auto-grow>
                          </v-textarea>
                      </v-col> -->

                        <v-col cols="12">
                          <v-textarea
                            label="Notas de la Póliza"
                            rows="1"
                            auto-grow
                            readonly
                            v-model="poliza.notas"
                          >
                          </v-textarea>
                        </v-col>

                        <v-col v-if="showBotonConfirmacion" cols="12">
                          <div class="d-flex justify-end">
                            <v-btn
                              color="primary"
                              @click="showConfirmationDialog = true"
                            >
                              <v-icon small class="mr-2">mdi-chat</v-icon>
                              <span>Enviar y Validar Código SMS</span>
                            </v-btn>
                          </div>
                        </v-col>

                        <!-- <v-col cols="6">
                        <v-text-field label="Fecha de Creación" v-model="poliza.creadoPorDate"
                          prepend-icon="mdi-calendar" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Creado por" v-model="poliza.creadoPor" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Última Modificación" v-model="poliza.ultimaModoficacionDate"
                          prepend-icon="mdi-calendar" readonly></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field label="Modificado por" v-model="poliza.ultimaModoficacion" readonly>
                        </v-text-field>
                      </v-col> -->

                        <!-- ENDS HERE -->
                      </v-row>
                      <v-row
                        v-if="emision && emision.ramo == 'ASISTENCIAS'"
                        dense
                        justify="end"
                      >
                        <v-col cols="12" md="8" lg="6">
                          <agregarTelefonoButton
                            :tarjeta="emision.tarjeta"
                            :telefono="emision.telefonoContacto"
                            :emisionId="emision.id"
                          ></agregarTelefonoButton>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-row style="padding: 20px; margin-top: -35px">
                        <v-col cols="4">
                          <v-select
                            label="Tipo de cliente"
                            v-model="poliza.tipo_cliente"
                            readonly
                            :items="tipos_cliente"
                            :rules="[$rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Tipo de Persona (Régimen)"
                            v-model="poliza.regimen"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen == 'Moral'">
                          <v-text-field
                            label="Razón Social"
                            v-model="poliza.razonSocial"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Nombre(s)"
                            v-model="poliza.nombres"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Apellido Paterno"
                            v-model="poliza.apellidoPaterno"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Apellido Materno"
                            v-model="poliza.apellidoMaterno"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="4"
                          v-if="
                            poliza.regimen != 'Moral' && poliza.regimen != null
                          "
                        >
                          <v-select
                            label="Estado Civil"
                            :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
                            readonly
                            v-model="poliza.statusCivil"
                            :rules="[$rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Fecha de Nacimiento"
                            v-model="poliza.fechaNacimiento"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="poliza.edad"
                            label="EDAD"
                            placeholder="Requerido"
                            :disabled="true"
                            readonly
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            label="RFC"
                            v-model="poliza.rfc"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="poliza.regimen != 'Moral'">
                          <v-text-field
                            label="Género"
                            v-model="poliza.selectedGenero"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Teléfono"
                            v-model="poliza.celular"
                            readonly
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            label="Correo Electrónico"
                            v-model="poliza.correoElectronico"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                        <v-col v-if="poliza.regimen == 'Física'" cols="4">
                          <v-select
                            v-model="poliza.facturar"
                            label="¿Desea facturar?"
                            :items="facturacionItems"
                            readonly
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="poliza.facturar && poliza.regimen == 'Física'"
                          cols="4"
                        >
                          <v-select
                            v-model="poliza['regimen-fiscal']"
                            label="Tipo de régimen"
                            :items="tipoRegimenItems"
                            readonly
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="poliza.facturar && poliza.regimen == 'Física'"
                          cols="4"
                        >
                          <v-select
                            v-model="poliza.cfdi"
                            label="Concepto CFDI"
                            :items="conceptoCFDIItems"
                            readonly
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Calle"
                            v-model="poliza.calle"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="No. Exterior"
                            v-model="poliza.num_exterior"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="No. Interior"
                            v-model="poliza.num_interior"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Colonia"
                            v-model="poliza.colonia"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            label="Código Postal"
                            v-model="poliza.codigoPostal"
                            v-mask="'#####'"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4" v-if="poliza.regimen != null">
                          <v-select
                            label="Estado de la República"
                            v-model="poliza.estadoRepublicaSelected"
                            readonly
                            :items="estados"
                            item-text="estadoRepublica"
                            item-value="id"
                            :rules="[$rules.required]"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Municipio"
                            v-model="poliza.municipio"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                          <v-text-field
                            label="Año en que se construyo"
                            v-model="poliza.años_construccion"
                            type="number"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                          <v-select
                            label="Tipo de construcción"
                            readonly
                            :items="['Maciza']"
                            v-model="poliza.tipo_construccion"
                            :rules="[$rules.required]"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                          <v-text-field
                            label="No. de Pisos"
                            v-model="poliza.num_pisos"
                            type="number"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="productoAseguradora == 'Hogar'">
                          <v-text-field
                            label="Piso en el que vive"
                            v-model="poliza.piso_vive"
                            type="number"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8" style="padding-buttom:5%">
                          <v-textarea
                            label="Dirección"
                            :rules="[$rules.required]"
                            v-model="poliza.direccion"
                            rows="1"
                            auto-grow
                            readonly
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="6" v-if="esCelular">
                          <v-text-field
                            label="Marca y Modelo Celular"
                            :value="emision.modeloMarcaCelular"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="esCelular">
                          <v-text-field
                            label="Precio Factura Celular"
                            :value="emision.precioFacturaCelular || 0"
                            readonly
                            :rules="[$rules.required]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <template>
                        <v-simple-table fixed-header>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-center">
                                  Tipo de endoso
                                </th>
                                <th class="text-center">
                                  Fecha
                                </th>
                                <th class="text-center">
                                  Detalle de endoso
                                </th>
                                <th class="text-center">
                                  Usuario
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in jsonEndosoInfo"
                                :key="item.tipoEndoso"
                              >
                                <td class="text-center">
                                  {{ item.tipoEndoso }}
                                </td>
                                <td class="text-center">
                                  {{ item.fechaEndoso }}
                                </td>
                                <td class="text-center">
                                  {{ item.detalleEndoso }}
                                </td>
                                <td class="text-center">{{ item.usuario }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="5">
          <v-card>
            <v-toolbar flat dense>
              <v-toolbar-title class="text-subtitle-1">CUOTAS</v-toolbar-title>
            </v-toolbar>

            <v-divider></v-divider>
            <v-data-table
              :headers="columnsCuotas"
              :items="cuotas"
              :items-per-page="3"
              dense
              item-key="name"
              class="elevation-1"
              :search="search"
              :custom-filter="filterOnlyCapsText"
              :loading="loading"
              loading-text="Cargando..."
            >
              <template v-slot:item.uuid="{ item }">
                <v-icon small class="mr-2" @click="verCuota(item.uuid)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="pt-1 mt-4">
            <v-toolbar flat dense>
              <v-toolbar-title class="text-subtitle-1"
                >NOTAS Y DOCUMENTOS</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="documentsDialog = true">
                Crear
              </v-btn>
            </v-toolbar>

            <v-divider></v-divider>
            <v-data-table
              :headers="columnsDocumentos"
              :items="documentos"
              item-key="name"
              class="elevation-1"
              :custom-filter="filterOnlyCapsText"
              :loading="loading"
              loading-text="Cargando..."
              dense
            >
              <template v-slot:item.url="{ item }">
                <v-icon small class="mr-2" @click="verDocumento(item)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mt-4">
            <v-toolbar flat dense>
              <v-toolbar-title class="text-subtitle-1">EMAILS</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-btn small color="primary" @click="newCorreoDialog = true">
              Crear
            </v-btn> -->
            </v-toolbar>

            <v-divider></v-divider>

            <v-data-table
              :headers="columnsEmails"
              :items="correos"
              item-key="name"
              class="elevation-1"
              :custom-filter="filterOnlyCapsText"
              :loading="loading"
              loading-text="Cargando..."
              dense
            >
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.asunto }}</td>
                  <td>{{ row.item.fecha }}</td>
                  <td>{{ row.item.remitente }}</td>
                  <td
                    style="cursor:pointer;"
                    @click="verDetalleCorreo(row.item)"
                  >
                    <v-icon light small class="mr-2">mdi-eye</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>

          <Beneficiarios
            v-if="load && !esCelular"
            :emisionId="emision.id"
            :emisionStatus="poliza.status"
            :rfcPoliza="poliza ? poliza.rfc : ''"
          />
          <SolicitudesEmision
            v-if="load"
            :emisionId="emision.id"
            :emision="emision"
          />
        </v-col>
      </v-row>

      <!-- MODALES -->

      <!-- MODAL VER DOCUMENTOS -->
      <v-dialog v-model="dialogDetalleDocumentos" max-width="750px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Detalle de Documento / Nota</span>
          </v-card-title>

          <template>
            <v-container style="padding: 30px">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Nombre"
                    v-model="detallesDocumento.nombre"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    label="¿Enviar por correo electrónico?"
                    readonly
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Tipo"
                    :items="tipoItems"
                    v-model="detallesDocumento.tipo"
                    required
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                  <v-select
                    label="Tipo de Doocumento*"
                    :items="tipoDocumentoItems"
                    v-model="detallesDocumento.tipoDocumento"
                    required
                    readonly
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Descripción"
                    v-model="detallesDocumento.descripcion"
                    required
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" v-if="detallesDocumento.url">
                  <template>
                    <v-file-input
                      v-model="documentos"
                      color="primary"
                      label="Adjuntos*"
                    >
                      <template v-slot:selection="{ index }">
                        <v-chip
                          v-if="index < 1"
                          color="primary"
                          dark
                          label
                          small
                          @click="detallesDocumento.url"
                        >
                          {{ detallesDocumento.nombre }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ documentos.length - 2 }} Archivos(s)
                        </span>
                      </template>
                    </v-file-input>
                  </template>
                </v-col>
                <v-col cols="2" v-if="detallesDocumento.url">
                  <v-btn
                    text
                    :href="detallesDocumento.url"
                    small
                    color="primary"
                    class="mt-4"
                  >
                    <v-icon>mdi-download</v-icon>
                    Descargar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Creado por"
                    v-model="detallesDocumento.creadoPor"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fecha de Creación"
                    v-mask="'##/##/####'"
                    v-model="detallesDocumento.fecha"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-card>
      </v-dialog>

      <!-- MODAL DE CORREO -->
      <v-dialog v-model="dialogCorreo" width="600px">
        <v-card style="padding: 30px">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Para"
                v-model="correoOpen.remitente"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="CC"
                v-model="correoOpen.cc"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="BCC"
                v-model="correoOpen.bcc"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Asunto"
                v-model="correoOpen.asunto"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="correoOpen.cuerpo"
                label="Mensaje"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row v-if="correoOpen.documentos">
            <v-col>
              <p>Descargar Adjuntos</p>
            </v-col>
          </v-row>
          <v-row v-if="correoOpen.documentos">
            <v-col>
              <v-chip
                v-for="(documento, index) in correoOpen.documentos"
                v-bind:key="index"
                color="primary"
                outlined
                :href="documento.url"
                style="cursor: pointer"
              >
                <v-icon left>
                  mdi-tray-arrow-down
                </v-icon>
                {{ documento.nombre }}
              </v-chip>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogCorreo = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- SEND EMAIL -->
      <v-dialog v-model="newCorreoDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Crear Correo</span>
          </v-card-title>
          <div style="padding:40px;">
            <EmailForm :poliza="poliza.id" :correoAgente="correoAgente" />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="newCorreoDialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- ADD DOCUMENTS -->
      <v-dialog v-model="documentsDialog" max-width="750px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Creación de Documento / Nota</span>
          </v-card-title>

          <div style="padding: 30px">
            <DocumentosNotasModal
              :accionGuardar="accionGuardar"
              :poliza="poliza.id"
            />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="documentsDialog = false">
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="(accionGuardar = true), (documentsDialog = false)"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            Ups parece que esta Venta no te corresponde" Por favor valida con tu
            supervisor.
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click="$router.go(-1)"
              elevation="2"
              style="background-color: white; color: black;"
            >
              FINALIZAR
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <policy-code-dialog
      v-if="showConfirmationDialog"
      :show="showConfirmationDialog"
      :policy="poliza"
      @close="showConfirmationDialog = false"
      @policySuccess="policySuccess"
    ></policy-code-dialog>
  </div>
</template>

<script>
import moment from "moment";
import EmailForm from "../../components/EmailForm";
import DocumentosNotasModal from "../../components/DocumentosNotasModal.vue";
import Beneficiarios from "./components/Beneficiarios.vue";
import SolicitudesEmision from "./components/SolicitudesEmision.vue";
import agregarTelefonoButton from "../../../components/telefonoContacto/agregarTelefonoButton.vue";

export default {
  components: {
    EmailForm,
    DocumentosNotasModal,
    Beneficiarios,
    SolicitudesEmision,
    agregarTelefonoButton,
    "policy-code-dialog": () =>
      import("@/components/emision/validatePolicyCode.vue"),
  },
  computed: {
    canEdit() {
      var canView = false;
      var roles = [];
      var isAgent = false;

      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "ADMINCC",
        "MANAGERCC",
        "AGENTCC",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      roles.forEach((element) => {
        if ("AGENTCC" == element) {
          isAgent = true;
        }
      });
      if (this.poliza.status != "Nueva" && isAgent) {
        canView = false;
      }

      return canView;
    },

    esCelular() {
      if (!this.poliza || !this.poliza.productos) return false;
      const productoFound = this.productos.find(
        (producto) => producto.id == this.poliza.productos
      );
      return !!productoFound && productoFound.nombre.includes("Seguro Celular");
    },

    showBotonConfirmacion() {
      return this.poliza && this.poliza.status == "Emitido el certificado";
    },
  },

  data() {
    return {
      tipos_cliente: [
        { text: "Titular", value: "titular" },
        { text: "Asegurado", value: "asegurado" },
      ],
      generoList: ["Masculino", "Femenino"],
      regimentList: ["Física", "Moral"],
      visible: false,
      loading: false,
      load: false,
      search: "",
      roles: JSON.parse(localStorage.getItem("roles")),
      newCorreoDialog: false,
      dialogCorreo: false,
      correoOpen: {},
      documentsDialog: false,
      dialogDetalleDocumentos: false,
      detallesDocumento: {},
      accionGuardar: false,
      poliza: {
        poliza: null,
        id: null,
        status: null,
        type: null,
        productos: null,
        ejecutivoOperacion: null,
        cliente: null,
        newCliente: null,
        agente: null,
        newAgente: null,
        landing: null,
        leadId: null,
        anterior: null,
        emitidaCheck: null,
        emitidDate: null,
        primerPagoCheck: null,
        primerPagoDate: null,
        canceladaCheck: null,
        canceladaDate: null,
        canceladaMotivo: null,
        finalizadaCheck: null,
        finalizadaDate: null,
        finalizadaMotivo: null,
        inicioDate: null,
        finDate: null,
        periodicidad: null,
        periodicidadNumber: null,
        primaNeta: null,
        primaTotal: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        formaPago: null,
        numeroCuotas: null,
        primerPagoPolizaDate: null,
        noCuotasPagadas: null,
        creadoPorDate: null,
        creadoPor: null,
        ultimaModoficacionDate: null,
        ultimaModoficacion: null,
        observaciones: null,
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        fechaNacimiento: null,
        selectedGenero: null,
        celular: null,
        correoElectronico: null,
        estadoRepublicaSelected: null,
        codigoPostal: null,
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalleAuto: null,
        plan: null,
        razonSocial: null,
        regimen: null,
        origenAuto: null,
        usoAuto: null,
        noMotor: null,
        noSerie: null,
        placas: null,
        notas: null,
        canceladaFinalizadaMotivo: null,
        rfc: null,
        tipo_cliente: null,
        tipo_contratante: null,
        lead_id: null,
        años_construccion: null,
        tipo_construccion: null,
        num_pisos: null,
        piso_vive: null,
      },
      correoAgente: null,
      years: null,
      productos: [],
      cuotas: [],
      nombreCliente: "",
      documentos: [],
      correos: [],
      columnsEmails: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      columnsCuotas: [
        {
          text: "Estado de Cuota",
          align: "start",
          sortable: false,
          value: "status",
        },
        { text: "Valor a Pagar", value: "valorPagar" },
        { text: "Fecha de Pago Acordada", value: "fechaPagoAcordada" },
        { text: "Ver Cuota", value: "uuid" },
      ],
      ejecutivos: [],
      clienteList: [
        { id: 1, name: "Cliente 1" },
        { id: 2, name: "Cliente 2" },
        { id: 3, name: "Cliente 3" },
        { id: 4, name: "Cliente 4" },
      ],
      tipos_contratante: [
        { text: "Propietario", value: "propietario" },
        { text: "Inquilino", value: "inquilino" },
      ],
      emision: null,
      uuid: this.$route.params.uuid,
      productoAseguradora: null,
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      cancelacionFinalizacionList: [
        { id: 0, name: "Falta de pago" },
        { id: 1, name: "Incremento en prima / Renovación" },
        { id: 2, name: "Mal servicio agente" },
        { id: 3, name: "Mal servicion compañía" },
        { id: 4, name: "Económicas" },
        { id: 5, name: "Inconformidad con el producto" },
        { id: 6, name: "Mejora en competencia" },
        {
          id: 7,
          name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente",
        },
        { id: 8, name: "Cancelará tarjeta Bradescard" },
        { id: 9, name: "Contrató una póliza similiar" },
        { id: 10, name: "Es muy caro" },
        { id: 11, name: "Fallecimiento de asegurado" },
        { id: 12, name: "Inconformidad con el producto" },
        { id: 13, name: "Llegó al límite de morosidad de su cuenta" },
        { id: 14, name: "Mal servicio con la compañía de seguros" },
        { id: 15, name: "No le interesa" },
        { id: 16, name: "No tiene dinero" },
        { id: 17, name: "No tiene el saldo suficiente" },
        { id: 18, name: "Siniestro no cubierto" },
        { id: 19, name: "Cambio de plan" },
        { id: 20, name: "Cambio de contratante" },
        { id: 21, name: "Cambio de plan y contratante" },
        { id: 22, name: "Cambio de dirección" },
      ],
      tipoItems: ["Nota", "Documento"],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Contrato firmado del Agente",
        "Cotización",
        "Cédula del Agente",
        "Identificación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      leadSinAsignar: true,
      jsonEndosoInfo: null,
      hasEndoso: false,
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
        },
        {
          name: "Eclair",
          calories: 262,
        },
        {
          name: "Cupcake",
          calories: 305,
        },
        {
          name: "Gingerbread",
          calories: 356,
        },
        {
          name: "Jelly bean",
          calories: 375,
        },
        {
          name: "Lollipop",
          calories: 392,
        },
        {
          name: "Honeycomb",
          calories: 408,
        },
        {
          name: "Donut",
          calories: 452,
        },
        {
          name: "KitKat",
          calories: 518,
        },
      ],
      comercialAsignado: "",
      facturacionItems: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      tipoRegimenItems: [
        "Sueldos y Salarios e Ingresos Asimilados a Salarios",
        "Arrendamiento",
        "Régimen de Enajenación o Adquisición de Bienes",
        "Demás ingresos",
        "Residentes en el Extranjero sin Establecimiento Permanente en México",
        "Ingresos por Dividendos (socios y accionistas)",
        "Personas Físicas con Actividades Empresariales y Profesionales",
        "Ingresos por intereses",
        "Régimen de los ingresos por obtención de premios",
        "Sin obligaciones fiscales",
        "Incorporación Fiscal",
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        "Régimen Simplificado de Confía",
        "",
      ],
      conceptoCFDIItems: [
        "Gastos en general",
        "Primas por seguros de gastos médicos",
        "Sin efectos fiscales",
      ],
      showConfirmationDialog: false,
    };
  },
  methods: {
    policySuccess() {
      window.location.reload();
    },

    getCurrentProduct() {
      var producto = this.productos.find(
        (element) => element.id == this.poliza.productos
      );
      if (producto) {
        return producto.nombre;
      } else {
        return "No tiene";
      }
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    goBack() {
      return this.$router.go(-1);
    },

    verEmisionEditable() {
      this.$router.push(`/actualizar-emision/${this.uuid}`);
    },
    verDetalleCorreo(correo) {
      console.log("Correo open:", correo);
      this.dialogCorreo = true;
      this.correoOpen = correo;
    },
    verDocumento(documento) {
      this.dialogDetalleDocumentos = true;
      this.detallesDocumento = documento;
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/producto/list", config).then((response) => {
        // console.log('Productos: ', response.data);
        response.data.map((element) => {
          this.productos.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
          });
        });
      });
    },
    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/agentes/operador", config).then((response) => {
        // console.log("Ejecutivos: ", response.data);
        response.data.map((element) => {
          this.ejecutivos.push({
            nombre:
              `${element.nombre}` +
              " " +
              `${element.apellidoPaterno}` +
              " " +
              `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
            id: element.id,
            uuid: element.uuid,
          });
        });
      });
    },
    cargarEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/emision/id/" + this.uuid, config).then((value) => {
        if (!value) {
          this.leadSinAsignar = false;
        }
        console.log("respuesta: ", value);
        this.emision = { ...JSON.parse(value.data.requestJson), ...value.data };
        if (typeof value.data.endosoJson != "undefined") {
          this.jsonEndosoInfo = JSON.parse(value.data.endosoJson);
          if (this.jsonEndosoInfo.length > 0) {
            this.hasEndoso = true;
          }
        }

        this.load = true;
        if (this.emision.aseguradoraId == 3) {
          this.$axios
            .get("/v1/cotizacion/id/" + this.emision.cotizacionId, config)
            .then((value2) => {
              this.cotizacion = value2.data.cotizacion;
            });
        }
        //Obtener Documentos
        value.data.documentos
          ? value.data.documentos.map((element) => {
              this.documentos.push({
                nombre: element.nombre ? element.nombre : "No Disponible",
                fecha: element.fecha
                  ? moment
                      .utc(element.fecha)
                      .subtract(6, "hours")
                      .format("DD/MM/YYYY HH:mm")
                  : "No Disponible",
                tipo: element.tipo ? element.tipo : "No Disponible",
                tipoDocumento: element.tipoDocumento
                  ? element.tipoDocumento
                  : "No Disponible",
                descripcion: element.nota ? element.nota : "No Disponible",
                creadoPor: element.creadoPor
                  ? element.creadoPor
                  : "No Disponible",
                url: element.url,
              });
            })
          : "";
        //Obtener Emails
        value.data.correos
          ? value.data.correos.map((element) => {
              this.correos.push({
                asunto: element.subject,
                fecha: element.fecha
                  ? moment
                      .utc(element.fecha)
                      .subtract(6, "hours")
                      .format("DD/MM/YYYY HH:mm")
                  : null,
                cuerpo: element.cuerpo,
                remitente: element.remitente,
              });
            })
          : "";
        this.nombreCliente =
          this.emision.nombre +
          " " +
          this.emision.apellidoPaterno +
          " " +
          this.emision.apellidoMaterno;
        this.poliza.poliza = this.emision.poliza
          ? this.emision.poliza
          : this.emision.id;
        this.poliza.id = this.emision.id;
        this.poliza.status =
          this.emision.status == 0
            ? "Nueva"
            : this.emision.status == 1
            ? "Emitida Pendente de Pago"
            : this.emision.status == 2
            ? "Pagada Parcial"
            : this.emision.status == 3
            ? "Pagada Total"
            : this.emision.status == 4
            ? "Renovada"
            : this.emision.status == 5
            ? "Finalizada"
            : this.emision.status == 6
            ? "Cancelada"
            : this.emision.status == 8
            ? "Emitido el certificado"
            : "Cancelada";

        this.poliza.type = this.emision.tipoPoliza;
        this.poliza.ejecutivoOperacion = parseInt(this.emision.operadorId);
        this.poliza.agente = this.emision.agenteId;
        this.correoAgente = this.emision.correoAgente
          ? this.emision.correoAgente
          : null;
        this.poliza.landing = this.emision.campania;
        this.poliza.leadId = null;
        this.poliza.anterior = this.emision.polizaAnterior;
        this.poliza.emitidaCheck = null;
        this.poliza.polizaSustituida = value.data.polizaSustituida;
        this.poliza.emitidDate = null;
        this.poliza.primerPagoCheck = null;
        this.poliza.primerPagoDate = null;
        this.poliza.canceladaCheck = null;
        this.poliza.canceladaDate = this.emision.fechaCancelacion
          ? moment(this.emision.fechaCancelacion, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.canceladaMotivo = null;
        this.poliza.finalizadaCheck = null;
        this.poliza.finalizadaDate = this.emision.fechaFinalizacion
          ? moment(this.emision.fechaFinalizacion, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.finalizadaMotivo = null;
        this.poliza.inicioDate = this.emision.inicio
          ? moment(this.emision.inicio, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.poliza.fechaPrimerPago = this.emision.fechaPrimerPago
          ? moment(this.emision.fechaPrimerPago, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.finDate = this.emision.fin
          ? moment(this.emision.fin, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null;
        this.poliza.periodicidad =
          this.emision.pagoId == 1
            ? "Anual"
            : this.emision.pagoId == 2
            ? "Semestral"
            : this.emision.pagoId == 3
            ? "Trimestral"
            : "Mensual";
        this.poliza.periodicidadNumber = this.emision.pagoId;
        this.years = this.emision.anios ? this.emision.anios : 1;
        this.poliza.primaNeta = this.emision.primaNeta;
        this.poliza.primaTotal = this.emision.total;
        this.poliza.montoPrimerPago = this.emision.primerPago;
        this.poliza.montoSubsecuente = this.emision.subsecuentePago;
        this.poliza.formaPago =
          this.emision.formaPago == 1
            ? "Tarjeta de Crédito"
            : this.emision.formaPago == 2
            ? "Tarjeta de Débito"
            : this.emision.formaPago == 3
            ? "Depósito en Banco"
            : this.emision.formaPago == 4
            ? "Transferencia"
            : this.emision.formaPago == 5
            ? "Cheque"
            : this.emision.formaPago == 6
            ? "Efectivo"
            : this.emision.formaPago == 7
            ? "Domiciliación"
            : this.emision.formaPago == 8
            ? "Cargo Único"
            : "";

        // this.poliza.numeroCuotas = this.emision.pagoId == 1 ? 1
        //                           :  this.emision.pagoId == 2 ? 2
        //                           :  this.emision.pagoId == 3 ? 4
        //                           : 6
        this.poliza.primerPagoPolizaDate = null;
        this.poliza.noCuotasPagadas = null;
        this.poliza.creadoPorDate = moment
          .utc(this.emision.fecha)
          .subtract(6, "hours")
          .format("DD/MM/YYYY HH:mm");
        this.poliza.creadoPor = this.emision.creadoPor
          ? this.emision.creadoPor
          : "";
        this.poliza.cliente = this.emision.cliente;
        this.poliza.ultimaModoficacionDate = moment
          .utc(this.emision.ultimaModificacion)
          .subtract(6, "hours")
          .format("DD/MM/YYYY HH:mm");
        this.poliza.ultimaModoficacion = this.emision.modificadoPor
          ? this.emision.modificadoPor
          : "No Disponible";
        this.poliza.observaciones = this.emision.observaciones;
        this.poliza.nombres = this.emision.nombre;
        this.poliza.apellidoPaterno = this.emision.apellido_paterno;
        this.poliza.apellidoMaterno = this.emision.apellido_materno;
        this.poliza.selectedGenero = this.emision.genero;
        this.poliza.correoElectronico =
          this.emision.status != 0
            ? `${this.emision.correoContacto.slice(0, 3)}${"*".repeat(
                this.emision.correoContacto.split("@")[0].length - 3
              )}@${this.emision.correoContacto.split("@")[1]}`
            : this.emision.correoContacto;
        (this.poliza.estadoRepublicaSelected =
          this.emision.estadoId == 1
            ? "Aguascalientes"
            : this.emision.estadoId == 2
            ? "Baja California"
            : this.emision.estadoId == 3
            ? "Baja California Sur"
            : this.emision.estadoId == 4
            ? "Campeche"
            : this.emision.estadoId == 5
            ? "Coahuila de Zaragoza"
            : this.emision.estadoId == 6
            ? "Colima"
            : this.emision.estadoId == 7
            ? "Chiapas"
            : this.emision.estadoId == 8
            ? "Chihuahua"
            : this.emision.estadoId == 9
            ? "Ciudad de México"
            : this.emision.estadoId == 10
            ? "Durango"
            : this.emision.estadoId == 11
            ? "Guanajuato"
            : this.emision.estadoId == 12
            ? "Guerrero"
            : this.emision.estadoId == 13
            ? "Hidalgo"
            : this.emision.estadoId == 14
            ? "Jalisco"
            : this.emision.estadoId == 15
            ? "Estado de México"
            : this.emision.estadoId == 16
            ? "Michoacán de Ocampo"
            : this.emision.estadoId == 17
            ? "Morelos"
            : this.emision.estadoId == 18
            ? "Nayarit"
            : this.emision.estadoId == 19
            ? "Nuevo León"
            : this.emision.estadoId == 20
            ? "Oaxaca"
            : this.emision.estadoId == 21
            ? "Puebla"
            : this.emision.estadoId == 22
            ? "Querétaro"
            : this.emision.estadoId == 23
            ? "Quintana Roo"
            : this.emision.estadoId == 24
            ? "San Luis Potosí"
            : this.emision.estadoId == 25
            ? "Sinaloa"
            : this.emision.estadoId == 26
            ? "Sonora"
            : this.emision.estadoId == 27
            ? "Tabasco"
            : this.emision.estadoId == 28
            ? "Tamaulipas"
            : this.emision.estadoId == 29
            ? "Tlaxcala"
            : this.emision.estadoId == 30
            ? "Veracruz"
            : this.emision.estadoId == 31
            ? "Yucatán"
            : this.emision.estadoId == 32
            ? "Zacatecas"
            : this.emision.estadoId == 33
            ? "Desconocio"
            : ""),
          (this.poliza.codigoPostal = this.emision.codigoPostal);
        this.poliza.modelo = this.emision.modelo;
        this.poliza.marca = this.emision.marca;
        this.poliza.submarca = this.emision.submarca;
        this.poliza.version = this.emision.nombreVehiculo;
        this.poliza.detalleAuto = this.emision.detalleAuto;
        this.poliza.plan =
          this.emision.planId == 1
            ? "Amplia"
            : this.emision.planId == 14
            ? "Amplia Plus"
            : this.emision.planId == 3
            ? "Limitada"
            : "RC_reglamentaria";
        this.poliza.razonSocial = this.emision.razonSocial
          ? this.emision.razonSocial
          : null;
        this.poliza.origenAuto = this.emision.origenAuto;
        this.poliza.usoAuto = this.emision.usoAuto;
        this.poliza.noMotor = this.emision.numMotor;
        this.poliza.noSerie = this.emision.numSerie;
        this.poliza.placas = this.emision.placas;
        this.poliza.notas = this.emision.notas;
        this.poliza.rfc = this.emision.rfc;
        this.poliza.canceladaFinalizadaMotivo = this.emision.motivoCancelacion;
        this.poliza.productos = this.emision.productoId;
        this.poliza.newCliente = this.emision.nombre;
        this.poliza.estadoRepublicaSelected = this.emision.estadoId;
        let jsonFormat = JSON.parse(this.emision.requestJson);
        this.poliza.statusCivil = jsonFormat.estado_civil;
        this.poliza.calle = jsonFormat.calle;
        this.poliza.num_exterior = jsonFormat.num_exterior;
        this.poliza.num_interior = jsonFormat.num_interior;
        this.poliza.colonia = jsonFormat.colonia;
        this.poliza.municipio = jsonFormat.municipio;
        this.poliza.id = this.emision.id;
        this.poliza.uuid = this.emision.uuid;
        this.poliza.checkgetCertificado = jsonFormat.checkgetCertificado;
        this.poliza.regimen = jsonFormat.tipo_persona;
        this.poliza.genero = jsonFormat.genero;
        this.poliza.direccion = jsonFormat.direccion;
        this.poliza.fechaNacimiento = jsonFormat.fecha_nacimiento
          ? moment(jsonFormat.fecha_nacimiento, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : null;
        this.poliza.celular =
          this.emision.status != 0
            ? `******${this.emision.telefonoContacto.slice(6, 10)}`
            : this.emision.telefonoContacto;
        this.poliza.tipo_cliente = jsonFormat.tipo_cliente
          ? jsonFormat.tipo_cliente
          : "";

        (this.poliza.tipo_contratante = jsonFormat.tipo_contratante
          ? jsonFormat.tipo_contratante
          : null),
          (this.poliza.años_construccion = jsonFormat.años_construccion
            ? jsonFormat.años_construccion
            : null);
        this.poliza.tipo_construccion = jsonFormat.tipo_construccion
          ? jsonFormat.tipo_construccion
          : null;
        this.poliza.num_pisos = jsonFormat.num_pisos
          ? jsonFormat.num_pisos
          : null;
        this.poliza.piso_vive = jsonFormat.piso_vive
          ? jsonFormat.piso_vive
          : null;
        this.comercialAsignado = this.emision.comercialAsignado
          ? this.emision.comercialAsignado
          : null;
        this.poliza.facturar = jsonFormat.facturar || false;
        this.poliza["regimen-fiscal"] = jsonFormat["regimen-fiscal"] || null;
        this.poliza.cfdi = jsonFormat.cfdi || null;

        if (
          this.poliza.tipo_contratante != null &&
          this.poliza.años_construccion != null &&
          this.poliza.tipo_construccion != null &&
          this.poliza.num_pisos != null &&
          this.poliza.piso_vive != null
        ) {
          this.productoAseguradora = "Hogar";
        }
        this.poliza.globalInteractionId = this.emision.globalInteractionId
          ? this.emision.globalInteractionId
          : "";

        if (this.poliza.globalInteractionId == "0000") {
          this.actualizarGlobalInteractionId();
        }

        if (typeof this.poliza.cliente != "undefined") {
          this.obtenerCliente(this.poliza.cliente);
        }
        if (typeof this.poliza.agente != "undefined") {
          this.obtenerAgente(this.poliza.agente);
        }
      });
    },
    cargarCuotasDePoliza() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(`/v1/cuota/cuotaByEmisionId/${this.$route.params.uuid}`, config)
        .then((resp) => {
          // console.log("Cuotas: ", resp);
          resp.data.map((element) => {
            this.cuotas.push({
              status: element.status,
              valorPagar: "$" + element.valorAPagar,
              fechaPagoAcordada: element.fechaPagoAcordada
                ? moment(element.fechaPagoAcordada, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )
                : "No Disponible",
              uuid: element.uuid,
            });
          });
        });
    },
    verCuota(uuid) {
      this.$router.push(`/visualizacion-comisiones/${uuid}`);
    },
    obtenerCliente(cliente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/cliente/id/${cliente}`, config).then((resp) => {
        // console.log("Cliente: ", resp.data);
        this.poliza.newCliente =
          `${resp.data.nombre}` +
          " " +
          `${resp.data.apellidoPaterno}` +
          " " +
          `${resp.data.apellidoMaterno}`;
      });
    },
    obtenerAgente(agente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/agente/id/${agente}`, config).then((response) => {
        console.log("Agente: ", response.data);
        this.poliza.newAgente =
          `${response.data.nombre}` +
          " " +
          `${response.data.apellidoPaterno}` +
          " " +
          `${response.data.apellidoMaterno}`;
      });
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
    actualizarGlobalInteractionId() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let request = {
        id: this.poliza.id,
        inbound: true,
        agente: "707",
      };
      this.actualStatus = request.status;
      this.$axios
        .post("/v1/emision/manual/actualizar", request, config)
        .then(() => {
          console.log("listo");
        });
    },
  },
  watch: {
    "poliza.fechaNacimiento"(newValue) {
      var years = moment(newValue, "DD/MM/YYYY");
      var today = moment().format("DD/MM/YYYY");
      var diference = moment(today, "DD/MM/YYYY").diff(
        moment(years, "DD/MM/YYYY"),
        "years"
      );
      this.poliza.edad = diference;
    },
    years(value) {
      if (value.typeof != "number") {
        // console.log("No es number")
        this.years = parseInt(value);
        const meses = this.years * 12;
        console.log("Calculando cuotas: ", meses);
        switch (this.poliza.periodicidadNumber) {
          case 1:
            this.poliza.numeroCuotas = meses / 12;
            break;
          case 2:
            this.poliza.numeroCuotas = meses / 6;
            break;
          case 3:
            this.poliza.numeroCuotas = meses / 3;
            break;
          case 4:
            this.poliza.numeroCuotas = meses / 1;
            break;
          default:
            break;
        }
      }
    },

    "$route.path"() {
      location.reload();
    },
  },
  mounted() {
    this.obtenerProductos(),
      this.obtenerEjecutivos(),
      this.cargarEmision(),
      this.cargarCuotasDePoliza();
  },
};
</script>

<style scoped>
.v-stepper__step__step {
  background-color: white;
}
</style>
