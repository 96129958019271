<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
        label="Destinatario"
        v-model="to"
        hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
        label="CC"
        v-model="cc"
        hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>  
    <v-row>
      <v-col cols="12">
        <v-text-field
        label="BCC"
        v-model="bcc"
        hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
        label="Asunto"
        v-model="subject"
        hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row> 
    <v-row>
      <v-col cols="12">
        <v-textarea
        v-model="content"
        label="Mensaje"
        value=""
        hint="Escribe aquí tu mensaje"
        ></v-textarea>
      </v-col>
    </v-row> 
    <v-row>
      <v-col cols="12">
        <v-file-input
        counter
        multiple
        small-chips
        v-model="files"
        truncate-length="15"
        ></v-file-input> 
      </v-col>
    </v-row> 
    <v-row>
      <v-col cols="12">
        <v-btn @click="enviarCorreo()">Enviar</v-btn>
      </v-col>
    </v-row>

    <!-- ALERT MODAL -->
    <v-col cols="auto">
      <v-dialog 
        transition="dialog-bottom-transition" 
        max-width="400" 
        v-model="dialog"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="success" dark
              >Confirmación de envío</v-toolbar
            >
            <v-card-text>
              <div class="text-h4" style="margin-top: 10px; ">
                Envio de email exitoso!
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog = false, 
                                  solicitud ? $router.push(`/seguimiento-solicitud/${solicitud}`) : 
                                  poliza ? $router.push(`/emision/${poliza}`) : 
                                  cuota ? $router.push(`/visualizacion-comisiones/${cuota}`) : ''"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <!-- END ALERT MODAL -->
  </div>
</template>
<script>
export default {
  props: {
    solicitud: Number,
    poliza: Number,
    cuota: Number,
    cliente: Number,
    usuario: Number,
    correoAgente: String,
  },
  data(){
    return{
      bcc:"",
      subject:"",
      to: this.correoAgente,
      content:"",
      cc:"",
      files:[],
      dialog: false,
    }
  },
  watch: {
    dialog(val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 2500)
    },
  },
  methods:{
    enviarCorreo(){
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          'Content-Type': 'multipart/form-data'
        },
      };
      if (this.solicitud) {
        const formData = new FormData();
        for( var i = 0; i < this.files.length; i++ ){
          let file = this.files[i];
          formData.append('files', file);
        }
        formData.append("to", this.to);
        formData.append("content",this.content);
        formData.append("subject",this.subject);
        formData.append("bcc",this.bcc);
        formData.append("cc",this.cc);
        formData.append("solicitud", `${this.solicitud}`);
        this.$axios.post("/v1/portal/correo/enviar", formData,config)
        .then((result) => {
            console.log(result);
            if (result.status == 200) {
              this.dialog = true;
            }
            setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.solicitud}`)), 2500);
        }, function (error) {
            console.log(error);
        });
      } else if (this.poliza) {
        const formData = new FormData();
        for( var j = 0; j < this.files.length; j++ ){
          let file = this.files[j];
          formData.append('files', file);
        }
        formData.append("to", this.to);
        formData.append("content",this.content);
        formData.append("subject",this.subject);
        formData.append("bcc",this.bcc);
        formData.append("cc",this.cc);
        formData.append("emision", `${this.poliza}`);
        this.$axios.post("/v1/portal/emision/correo/enviar", formData,config)
        .then((result) => {
            console.log(result);
            if (result.status == 200) {
              this.dialog = true;
            }
            setTimeout(() => (this.$router.push(`/emision/${this.poliza}`)), 2500);
        }, function (error) {
            console.log(error);
        });
      } else if (this.cuota) {
        const formData = new FormData();
        for( var k = 0; k < this.files.length; k++ ){
          let file = this.files[k];
          formData.append('files', file);
        }
        formData.append("to", this.to);
        formData.append("content",this.content);
        formData.append("subject",this.subject);
        formData.append("bcc",this.bcc);
        formData.append("cc",this.cc);
        formData.append("cuota", `${this.cuota}`);
        this.$axios.post("/v1/portal/cuota/correo/enviar", formData,config)
        .then((result) => {
            console.log(result);
            if (result.status == 200) {
              this.dialog = true;
            }
            setTimeout(() => (this.$router.push(`/visualizacion-comisiones/${this.cuota}`)), 2500);
        }, function (error) {
            console.log(error);
        });
      } else if (this.cliente) {
        const formData = new FormData();
        for( var l = 0; l < this.files.length; l++ ){
          let file = this.files[l];
          formData.append('files', file);
        }
        formData.append("to", this.to);
        formData.append("content",this.content);
        formData.append("subject",this.subject);
        formData.append("bcc",this.bcc);
        formData.append("cc",this.cc);
        formData.append("cliente", `${this.cliente}`);
        this.$axios.post("/v1/portal/cliente/correo/enviar", formData,config)
        .then((result) => {
            console.log(result);
            if (result.status == 200) {
              this.dialog = true;
            }
            setTimeout(() => (this.$router.push(`/cliente-detalle/${this.cliente}`)), 2500);
        }, function (error) {
            console.log(error);
        });
      } else if (this.usuario) {
        const formData = new FormData();
        for( var m = 0; m < this.files.length; m++ ){
          let file = this.files[m];
          formData.append('files', file);
        }
        formData.append("to", this.to);
        formData.append("content",this.content);
        formData.append("subject",this.subject);
        formData.append("bcc",this.bcc);
        formData.append("cc",this.cc);
        formData.append("agente_id", `${this.usuario}`);
        this.$axios.post("/v1/portal/agente/correo/enviar", formData,config)
        .then((result) => {
          console.log(result);
          if (result.status == 200) {
            this.dialog = true;
          }
          setTimeout(() => (this.$router.push(`/inicio`)), 2500);
        }, function (error) {
          console.log(error);
        });
      }
    }
  }
    
}
</script>