<template>
    <div>
        <div v-if="leadSinAsignar">
        <v-row class="mb-14 fill-height">
            <v-col cols="12">
                <v-card>
                    <v-card-title primary-title>
                        Leads #{{ lead.id }}
                        <v-spacer></v-spacer>
                        <v-btn @click="$router.go(-1)" elevation="2" style="background-color: #ff0042; color: white;">
                            FINALIZAR
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" sm="12" md="7" lg="7" xl="7">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="4">
                                        <v-autocomplete label="Estatus Lead" :items="estatusLeads" v-model="lead.status"
                                            readonly placeholder="Requerido"></v-autocomplete>
                                    </v-col>
                                    <!-- <v-col cols="4">
                                        <v-text-field label="Campaña" v-model="lead.campania" placeholder="Requerido">
                                        </v-text-field>
                                    </v-col> -->
                                    <v-col cols="4">
                                        <v-autocomplete label=" Tipo tarjeta" :items="productos" v-model="lead.producto"
                                            readonly placeholder="Requerido"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Seguro a vender" v-model="lead.productoAseguradora"
                                            placeholder="Requerido" readonly>
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field label="* Nombre" v-model="lead.nombre" placeholder="Requerido"
                                            readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="* Apellido Paterno" v-model="lead.apellido_Paterno" readonly
                                            placeholder="Requerido"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="* Apellido Materno" v-model="lead.apellido_Materno" readonly
                                            placeholder="Requerido"></v-text-field>
                                    </v-col>

                                    <template v-if="lead.regime == 'Física'">
                                        <!-- para regimen fiscal fisico -->
                                        <v-col cols="3">
                                            <v-autocomplete label="Género" :items="generos" v-model="lead.sexo" readonly
                                                placeholder="Requerido"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field label="Fecha De Nacimiento" v-model="lead.fecha" readonly
                                                placeholder="Requerido"></v-text-field>
                                        </v-col>
                                    </template>

                                    <template v-if="lead.regime == 'Moral'">
                                        <!-- para regimen fiscal Moral -->
                                        <v-col cols="3">
                                            <v-text-field label="Razón Social" v-model="lead.razonSocial" readonly
                                                placeholder="Requerido"></v-text-field>
                                        </v-col>
                                    </template>

                                    <v-col cols="4">
                                        <v-text-field label="Correo electrónico" placeholder="Requerido" readonly
                                            :value="formatEmail"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Terminación de tarjeta" v-model="lead.fourUD" readonly
                                            placeholder="Requerido"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Día de corte de tarjeta" v-model="lead.corte" readonly
                                            placeholder="Requerido"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12">
                                        <v-text-field label="Etiqueta" v-model="lead.etiqueta" outlined dense readonly
                                            placeholder="Requerido"></v-text-field>
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <div style="padding-top:5%; height: 100%;">
                            <v-btn color="primary" style="margin-left: 15px" @click="changeView(1)">
                                Planes
                            </v-btn>
                            <v-btn color="primary" style="margin-left: 15px" @click="changeView(2)">
                                Script
                            </v-btn>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="this.loadView == 1">
                                <v-img :aspect-ratio="16 / 9" :src="imagenScript" contain />
                            </v-col>
                            <v-row style="padding-top:5%; height: 100%; padding-button:2%;" v-if="this.loadView == 2">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <object :data="linkPdfProducto" type="application/pdf" width="100%" height='100%' />
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                        <v-row>
                            <v-col cols="12">
                                <Llamadas />
                            </v-col>
                        </v-row>

                        <v-row class="mt-1">
                            <v-col cols="12">
                                <Documentos v-if="load" opcion="lead" :identificador="lead.id" :list="ListDocuments" />
                            </v-col>
                        </v-row>

                        <v-row class="mt-1">
                            <v-col cols="12">
                                <Polizas v-if="load" :identificador="lead.id" :producto="lead.producto"
                                    :productoAseguradora="lead.productoAseguradora" :restringirVenta="!puedeCrearVenta" :corte="lead.corte" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <Embedded v-if="loadEmbedded" :identificador="lead.id" :producto="lead.producto" :restringirVenta="!puedeCrearVenta"
            :productoAseguradora="lead.productoAseguradora" :lead="lead" :corte="lead.corte" @failEmbedded="failEmbedded" />
        </div>
        <div v-else>
            <v-alert
            prominent
            type="error"
            >
            <v-row align="center">
                <v-col class="grow">
                    Ups parece que esta Venta no te corresponde" Por favor valida con tu supervisor.
                </v-col>
                <v-col class="shrink">
                    <v-btn @click="$router.go(-1)" elevation="2" style="background-color: white; color: black;">
                        FINALIZAR
                    </v-btn>
                </v-col>
            </v-row>
            </v-alert>
        </div>
    </div>
</template>

<script>


import { ListExpanded } from "@/plugins/mixins";
import Llamadas from "./../../components/Llamadas.vue";
import Embedded from "../../../../components/Embedded.vue";
import Documentos from "./../../components/Documentos.vue";
import Polizas from "./../../components/Poliza.vue";
import moment from 'moment';
import { mapState, mapActions } from 'vuex'

export default {
    name: 'Bradescard.DetallesLeads',
    components: {
        Llamadas,
        Embedded,
        Documentos,
        Polizas
    },
    props: {
        isInbound: {
            type: Boolean,
            required: false,
            default: false
        },
        
    },
    mixins: [ListExpanded],
    data: function () {
        return {
            loadEmbedded: false,
            load: false,
            ListDocuments: [],
            loadView: 0,
            linkPdfProducto: '',
            imagenScript: '',
            leadSinAsignar:true,
        }
    }, computed: {
        ...mapState('leads', ['lead']),
        ...mapState('polizas', ['polizas']),
        formatEmail() {
            if (this.lead.correo == null) {
                return null
            }
            if (this.lead.correo == "") {
                return null
            }

            let emailBody = this.lead.correo.split("@")
            return `${emailBody[0].slice(0, 3)}******@${emailBody[1]}`;
        },

        puedeCrearVenta() {
            if (!this.polizas.length) return true;
            return this.polizas.filter(e => e.statusNumber == 6 && e.motivoCancelacion == "Llegó al límite de morosidad de su cuenta").length < 1;
        }
    }, async mounted() {
        console.log("Esto que es : ", this.isInbound )
        this.getProductos();
        await this.LoadDetalleLead(this.$route.params.uuid);
        this.viewScript();
    }, watch: {
        "lead": function (val) {
            if(val == ''){
                this.leadSinAsignar = false
            }
            else{
                val.documentos.map(element => {
                    this.ListDocuments.push({
                        nombre: element.nombre ? element.nombre : 'No Disponible',
                        fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
                        tipo: element.tipo ? element.tipo : 'No Disponible',
                        tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
                        descripcion: element.nota ? element.nota : 'No Disponible',
                        creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
                        url: element.url
                    });
                });
                this.load = true;
                this.loadEmbedded = true;
            }
        }
    }, methods: {
        ...mapActions("leads", ['LoadDetalleLead']),
        // async LoadDetalleLead() {
        //     try {
        //         this.ListDocuments = [];
        //         data.documentos.map(element => {
        //             this.ListDocuments.push({
        //                 nombre: element.nombre ? element.nombre : 'No Disponible',
        //                 fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
        //                 tipo: element.tipo ? element.tipo : 'No Disponible',
        //                 tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
        //                 descripcion: element.nota ? element.nota : 'No Disponible',
        //                 creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
        //                 url: element.url
        //             });
        //         });
        //         this.form = { ...data };
        //         this.load = true;
        //         this.loadEmbedded = true;
        //     } catch (exception) {
        //         console.error(exception)
        //         this.loadEmbedded = false;
        //     }
        // },
        changeView(tab) {
            this.loadView = tab;
        },
        async viewScript() {
            try {
                let { data } = await this.$axios.post(`/v1/producto/leadbradescard`, {
                    producto_lead: this.lead.producto,
                    producto_aseguradora: this.lead.productoAseguradora
                }, {
                    headers: {
                        Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                });

                if (data) {
                    var script_link = data[0].url_producto;
                    var imagen_script = data[0].url_imagen
                    this.imagenScript = imagen_script
                    this.linkPdfProducto = script_link
                } else {
                    if(this.lead != []){
                         this.$toast.error("No se encontro el script del producto ");
                    }
                }
            } catch (exception) {
                if(this.lead != []){
                    this.$toast.error("No se encontro el script del producto");
                }
            }
        }, 
        failEmbedded(){
            this.loadEmbedded = false;
        }
    }
}
</script>

<style>
.v-label {
    font-size: 19px !important;
}
</style>
