<template>
    <div>
        <v-row class="mb-2 fill-height" >
            <v-col cols="12"> 
                <v-card>
                    <v-card-title primary-title>
                        Leads Bradescard ( {{status}} )
                        <v-spacer></v-spacer>
                        <v-btn @click="$router.go(-1)" style="background-color: #ff0042; color: white;">
                            FINALIZAR
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense class="mt-5 mb-6">
            <v-col md="12" lg="12" sm="12" xl="12" >
                <v-card dense>
                    <center>
                        <v-card-text class="max-width: 90% !important; min-width: 50% !important;">
                            <div id="chartBar" class="chart"></div>
                        </v-card-text>
                    </center>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense class="mt-6 mb-12 " v-if="leads != null"> 
            <v-col cols="12">
                <!-- <div class="mt-6 mb-2"  style="text-align: end;" v-if="roles.rol != 'AGENTCC'">
                    <v-btn color="primary" style="height: 50px; weight: 50px" class="ml-16" dark max-height="40px"
                        max-width="100px" :disabled="leads.length == 0">
                        <download-csv :name="'Reporte_de_leads.csv'" :data="leads">
                            Descargar
                      </download-csv>
                    </v-btn>
                </div> -->
                
                <v-card>
                    <!-- <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar..."
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title> -->
                    <v-data-table
                        :headers="columns"
                        :items="leads"
                        item-key="name"
                        class="elevation-1"
                        :search="search"
                        :loading='loading'
                        loading-text="Cargando..."
                        @update:page="getInfoPage"
                        @update:items-per-page="getInfoItems"
                        :items-per-page="registrosPagina"
                        :server-items-length="totalLeads"
                        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
                    >
                        <template #no-data>
                            No se tienen datos para mostrar
                        </template>
                        
                        <template #[`item.operador`]="{ item }">
                            {{obtenerNombreOperador(item.operador)}}
                        </template>
                        <template #[`item.agenteId`]="{ item }">
                            {{obtenerNombreAgente(item.agenteId)}}
                        </template>
                        <template #[`item.actions`]="{ item }">
                            <v-btn icon :to="{name: 'DetalleLeadBradescard', params:{uuid:item.uuid}}">
                                    <v-icon small class="mr-2">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { mainAxios } from "../../../../../mainAxios";
import moment from "moment";
    
    export default {
        name:'Bradescard.Leads.Dashboard',
        props: {
            status: {
                type: String,
                default: ""
            },
        },
        data:function(){
            return {
                columns:[
                    { text: 'ID', value: 'id'},
                    { text: 'Status', value: 'status'},
                    { text: 'Nombre', value: 'nombre'},
                    { text: 'Producto', value: 'producto'},
                    { text: 'No. De Llamadas Realizadas', value: 'intentos', align: 'center' },
                    { text: 'Producto Aseguradora', value: 'productoAseguradora'},
                    { text: 'Corte', value: 'corte'},
                    { text: 'Fecha Asignación', value: 'fechaAsignacion'},
                    { text: 'Fecha Última Llamada', value: 'fechaUltimaLlamada'},
                    { text: 'Ultima disposicion', value: 'ultima_disposicion'},
                    { text: 'Operador', value: 'operador'}, 
                    { text: 'Ver    ', value: 'actions'},
                ],
                leads: [],
                loading: false,
                search: "",
                currentAgentId: null,
                currentUltimaDisposicion: null,
                currentChart: null,
                agenteUuid: localStorage.agenteUuid,
                agenteAccessToken:localStorage.getItem('agenteAccessToken'),
                agentes: [],
                operadores: [],
                roles: JSON.parse(localStorage.getItem('roles'))[0],
                registrosPagina:10,
                pagina:1,
                totalLeads:0
            }
        }, 
        async mounted(){
            this.getOperadores(1);
            this.getOperadores(0);
            this.loadchart();
            this.LoadLeads();
            this.getAgentes();
            
            this.currentChart.on('click', (params) => {
                if(this.roles.rol == 'AGENTCC'){
                    if(params.name  == 'Sin Disposiciones'){
                        this.currentUltimaDisposicion = null;
                    }else{
                        this.currentUltimaDisposicion = params.name;
                    }
                    this.LoadLeads();
                }else{
                    this.currentAgentId = this.obtenerIDAgente(params.name);
                    this.LoadLeads();
                }
            });
        }, 
        methods: {
            loadchart() {
                var options = {
                    title: {
                        text: `Leads Bradescard status: `+ this.status,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: []
                    },
                    series: [
                        {
                        name: 'Cantidad de leads',
                        type: 'bar',
                        data: []
                        },
                    ]
                }
                this.currentChart = this.$chart.init(document.getElementById('chartBar'));
                this.currentChart.setOption(options);
                var names = [];
                var data = [];
                mainAxios.post(`v1/bradescard/data/${this.agenteUuid}`, {status: this.status},{            
                headers: {
                    Authorization: `Bearer ${this.agenteAccessToken}`,
                },
                }).then((response) => {
                    if(this.roles.rol == 'AGENTCC'){
                        Object.keys(response.data.disposiciones).forEach(key => {
                            names.push(key);
                            data.push(response.data.disposiciones[key]);
                        });
                    }else{
                        Object.keys(response.data.agentes).forEach(key => {
                            names.push(key);
                            data.push(response.data.agentes[key]);
                        });
                    }

                options.yAxis.data = names;
                options.series[0].data = data;          
                this.currentChart.setOption(options);
                });
            },
            LoadLeads(){
                try{
                    var query= {
                        status: this.status,
                        agente_id: this.currentAgentId,
                        ultima_disposicion: this.currentUltimaDisposicion,
                        pagina: this.pagina,
                        registrosPagina: this.registrosPagina,
                    }
                    this.loading = true;
                    mainAxios.post(`v1/bradescard/list/status/${localStorage.agenteUuid}`,query,{            
                        headers: {
                            Authorization: `Bearer ${this.agenteAccessToken}`,
                        },
                    }).then((response) => {
                        this.leads = [];
                        response.data.data.leads.map(item => {
                            this.leads.push({
                            "uuid": item.uuid,
                            "id": item.id,
                            "status": item.status ? item.status : 'No Disponible',
                            "nombre": item.nombre ? item.nombre : 'No Disponible',
                            "agenteId": item.agenteId ? item.agenteId : 'No Disponible',
                            "producto": item.producto ? item.producto : 'No Disponible',
                            "productoAseguradora": item.productoAseguradora ? item.productoAseguradora : 'No Disponible',
                            "fourUD": item.fourUD ? item.fourUD : 'No Disponible',
                            "corte": item.corte ? item.corte : 'No Disponible',
                            "ultima_disposicion": item.ultimaDisposicion ? item.ultimaDisposicion : 'No tiene disposicion',
                            "operador": item.operadorId ? item.operadorId : 'No Disponible',
                            fechaAsignacion: item.fecha_asignacion ? moment(item.fecha_asignacion).format('DD/MM/YYYY') : 'No disponible',
                            fechaUltimaLlamada: item.fecha_ultima_llamada ? moment(item.fecha_ultima_llamada).format('DD/MM/YYYY') : 'No disponible',
                            intentos: item.intentos
                        })
                        this.loading = false;
                        this.totalLeads = response.data.data.totalRegistros
                        })
                    });
                }catch(exception){
                    this.loading = false;
                    console.error(exception);
                }
            },
            getOperadores(status){
                let data = [];
                try{
                    mainAxios.get(`v1/agente/operadores/${status}`,{            
                        headers: {
                            Authorization: `Bearer ${this.agenteAccessToken}`,
                        },
                    }).then((response) => {
                        data = response.data;
                        if(status == 0){
                            this.operadores = [...data];
                        }else if(status == 1){
                            this.operadores = [...data]
                        }
                    });
                }catch(exception){
                    console.error(exception);
                }
            },
            obtenerNombreOperador(id) {
                if(id == 'No Disponible'){
                    return id;
                }
                else{
                    let agente = this.operadores.find(element => element.id == id);
                    return agente.nombreCompleto;
                } 
            },
            obtenerNombreAgente(id) {
                if(id == 'No Disponible'){
                    return id;
                }
                else{
                    let agente = this.agentes.find(element => element.id == id);
                    return agente.nombreCompleto;
                } 
            },
            getAgentes() {
            let config = {
                headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            this.$axios
                .get(`/v1/agente/list`, config)
                .then((response) => {
                this.agentes = response.data
                });
            },
            obtenerIDAgente(name) {
                if(name == 'No Disponible'){
                    return name;
                }
                else{
                    let agente = this.agentes.find(element => element.nombreCompleto == name);
                    return agente.id;
                } 
            },
            getInfoPage(v){
                this.pagina = v
                this.LoadLeads()
            },
            getInfoItems(v){
                this.registrosPagina = v > 0 ? v : this.totalLeads
                console.log(this.registrosPagina)
                this.LoadLeads()
            }
        },
    }
</script>

<style>

</style>