<template>
  <v-bottom-sheet
    v-model="sheet"
    hide-overlay
    width="350px"
    persistent
    scrollable
    round
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" dark v-bind="attrs" v-on="on" class="embedded">
        Call center
      </v-btn>
    </template>

    <v-sheet
      height="420px"
      color="#133B7D"
      class="pl-2 pr-2"
      style="border-radius: 6px;"
      flat
    >
      <v-card height="410" class="" round flat>
        <v-toolbar flat color="#133B7D" dark>
          <v-toolbar-title class="text-center">Call it Once</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="sheet = !sheet">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row class="mt-1 mr-5" justify="space-around">
            <v-col cols="1">
              <v-btn
                fab
                small
                color="success"
                @click="callNumber"
                :disabled="llamar || this.selectPhone == null"
              >
                <v-icon dark>mdi-phone</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1">
              <v-btn
                fab
                small
                color="red"
                @click="stopCall"
                :disabled="!colgar"
              >
                <v-icon color="white">mdi-phone-hangup</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="black"
                    @click="ConferenceCall"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!isConference"
                  >
                    <v-img :src="icon" color="white" width="20" />
                  </v-btn>
                </template>
                <span>Conferencia</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <!-- <v-row class="mt-2">
                        <v-col cols="12">
                            <v-select label="Telefonos" :items="ListNumber" v-model="selectPhone" item-text="name"
                                item-value="cel" hide-details dense :disabled="llamar"></v-select>
                        </v-col>
                    </v-row> -->

          <v-form ref="formDisposicion">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Tipos de contacto"
                  clearable
                  :items="TiposContactos"
                  hide-details
                  dense
                  v-model="tipo_contacto"
                  :rules="[$rules.required]"
                  :disabled="!llamar"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Disposición"
                  clearable
                  :items="disposicionesAux"
                  hide-details
                  dense
                  v-model="disposicion"
                  :disabled="tipo_contacto == null"
                  item-text="disposicion"
                  item-value="id"
                  :rules="[$rules.required]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="mt-2 mr-4" justify="space-around" align="center">
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="addAgenda"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!llamar"
                    >
                      <v-icon dark x-large>mdi-calendar-month</v-icon>
                    </v-btn>
                  </template>
                  <span>Agendar llamada</span>
                </v-tooltip>
              </v-col>

              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="loadScriptAgente"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark x-large>mdi-script-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Script</span>
                </v-tooltip>
              </v-col>

              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      @click="sendCode"
                      :disabled="!sendMessage"
                    >
                      <!-- <v-btn icon rounded v-bind="attrs" v-on="on" @click="sendCode" :loading="loadingBotton"> -->
                      <v-icon large>mdi-cellphone-message</v-icon>
                    </v-btn>
                  </template>
                  <span>Enviar código</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row v-if="registrarAgenda">
              <v-col cols="12">
                <v-text-field
                  v-model="dateAgenda"
                  label="Fecha de agenda"
                  type="datetime-local"
                  hide-details
                  :rules="[$rules.required]"
                  :max="nextMonth()" 
                  :min="today()"
                >
                  <template #prepend>
                    <v-btn icon @click="cancelAgenda">
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <template v-if="isVenta">
          <v-card-text v-if="nuevasPolizas.length > 0">
            <v-row>
              <v-col cols="1" v-if="poliza != null">
                <v-btn icon @click="open()">
                  <v-icon small class="mr-2">mdi-eye</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="11">
                <v-autocomplete
                  label="Seleccionar Pólizas"
                  clearable
                  :items="nuevasPolizas"
                  hide-details
                  dense
                  v-model="poliza"
                  :rules="[$rules.required]"
                  item-text="descripcion"
                  return-object
                  :loading="loading"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions
            class="ml-2 dd-flex justify-center text-center"
            v-else
          >
            <v-btn
              dark
              color="primary"
              :disabled="restringirVenta"
              @click="open"
              small
            >
              Crear Venta
            </v-btn>
            <v-alert v-if="restringirVenta" type="error" text dense>
              <span>Límite de morosidad alcanzado</span>
            </v-alert>
          </v-card-actions>
        </template>
      </v-card>
    </v-sheet>
    <FormPolizaDialog
      v-if="dialog"
      @getCurrentEmision="openDialogNewEmision"
      :isEmbbeded="2"
      :identificador="identificador"
      :producto="producto"
      :productoAseguradora="productoAseguradora"
      :active.sync="dialog"
      :poliza="poliza"
      :global_interaction_id.sync="global_id"
      :corte="corte"
    />
  </v-bottom-sheet>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import FormPolizaDialog from "./../pages/bradescard/components/documentos/FormPolizaDialog.vue";
import Incoming from "~/assets/phone-incoming-outgoing.svg";
import { mapState, mapActions } from "vuex";

export default {
  name: "Embedded",
  components: {
    FormPolizaDialog,
  },
  props: {
    lead: {
      type: Object,
      require: true,
    },
    identificador: {
      type: Number,
      required: true,
    },
    producto: {
      type: String,
      required: true,
    },
    productoAseguradora: {
      type: String,
      required: true,
    },
    corte: {
      type: String,
      required: true,
    },
    restringirVenta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      isVenta: false, // verifico si la disposicion es venta
      registrarAgenda: false, // registra la llamada para una agenda
      isConference: false,
      loading: false,
      loadingBotton: false,
      sendMessage: false, // active if send global_id from poliza

      // icon
      icon: Incoming,

      // listas variable
      TiposContactos: [],
      disposiciones: [],
      disposicionesAux: [],
      polizas: [], // lista de las polizas

      // config dialog embedded // pasar en otro archivo secundario
      sheet: false,
      llamar: false,
      colgar: false,
      mute: false,
      callInfo: null,

      // var user
      calendar: false,
      dateFormatted: null,

      // var to agend call
      dateAgenda: null,
      ListNumber: [],
      selectPhone: null,

      // disposicion
      tipo_contacto: null,
      disposicion: null,
      poliza: null,
      global_id: null,
      is_emitido: false,
      globalId: "",
    };
  },
  computed: {
    ...mapState("polizas", ["nuevasPolizas"]),
  },
  async mounted() {
    this.getPolizas(1);
    this.selectPhone = this.lead.celular;
    loadScript(
      "https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js"
    )
      .then(() => {
        window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");
        window.bpspat.api.getState(this.checkStatus);
      })
      .catch(() => {
        console.error(
          "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
        );
        location.reload(true);
        this.$emit("failEmbedded");
      });

    await this.getdisposicion();

    setTimeout(() => this.checkStatus(), 2000);
  },
  watch: {
    dateAgenda() {
      this.dateFormatted = this.formatDate(this.dateAgenda);
    },
    tipo_contacto(val) {
      if (val == null) {
        this.colgar = false;
        this.disposicion = null;
      }
      this.filteDisposicion();
    },
    disposicion(val) {
      if (val == null) {
        this.isVenta = false;
        this.registrarAgenda = false;
        this.colgar = false;
        return;
      }

      if (val == 6) {
        this.registrarAgenda = true;
        this.isVenta = false;
        this.colgar = false;
      } else if (val == 10) {
        window.bpspat.api.getState(async ({ data }) => {
          let interaction = null;
          interaction = data.interactions.find(
            (item) => item.phone_number == this.selectPhone
          );
          this.global_id = interaction.global_id;
        });
        this.registrarAgenda = false;
        this.isVenta = true;
        this.colgar = true;
      } else {
        this.registrarAgenda = false;
        this.isVenta = false;
        this.colgar = true;
      }
    },
    poliza(val) {
      this.actualizarPoliza(val);
    },
  },
  methods: {
    ...mapActions("polizas", ["getNuevasPolizas"]),
    ...mapActions("leads", ["LoadDetalleLead"]),
    ...mapActions("llamadas", ["getLlamadas"]),
    openDialogNewEmision(emision) {
      this.poliza = emision;
      setTimeout(() => {
        this.dialog = true;
      }, 500);
    },
    open() {
      this.dialog = true;
    },
    formatDate(date) {
      if (!date) {
        this.colgar = false;
        return null;
      }
      this.colgar = true;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    filteDisposicion() {
      this.disposicionesAux = this.disposiciones.filter(
        (item) => item.tipo_contacto == this.tipo_contacto
      );
    },
    /**
     * consulta que obtiene la lista de disposiciones para la llamada
     * @function void
     */
    async getdisposicion() {
      let { data } = await this.$axios.get(`/v1/bradescard/disposicion/list`, {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      });
      this.disposiciones = [...data];
      let tiposContacto = new Set();
      data.forEach((item) => {
        tiposContacto.add(item.tipo_contacto);
      });
      this.TiposContactos = [...tiposContacto];
    },
    /**
     * Consulta para obtener las polizas con estados nuevas
     * @params uuid agente
     * @function void
     * @return void
     *
     */
    async getPolizas(e) {
      try {
        this.getNuevasPolizas(this.lead.id);
        if (e != 1) {
          this.poliza = e;
          this.open();
        }
      } catch (exception) {
        console.error(exception);
      }
    },
    addAgenda() {
      this.dateAgenda = null;
      this.tipo_contacto = "Efectivo";
      this.disposicion = 6;
      this.registrarAgenda = true;
    },
    cancelAgenda() {
      this.tipo_contacto = null;
      this.disposicion = null;
      this.registrarAgenda = false;
    },
    async loadScriptAgente() {
      let { data } = await this.$axios.post(
        `/v1/producto/leadbradescard`,
        {
          producto_lead: this.lead.producto,
          producto_aseguradora: this.lead.productoAseguradora,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        }
      );

      if (data) {
        var script_link = data[0].url_producto;
        window.open(script_link);
      } else {
        this.$toast.error("No se encontro el script del producto");
      }
    },

    openPoliza() {
      window.open(`/emision/${this.poliza}`);
    },
    actualizarPoliza(val) {
      try {
        let interaction = null;
        this.sendMessage = false;

        if (val == null) {
          return false;
        }

        window.bpspat.api.getState(async ({ data }) => {
          this.loading = true;
          interaction = data.interactions.find(
            (item) => item.phone_number == this.selectPhone
          );
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          await this.$axios.post(
            `v1/bradescard/emisiones/actualizarPolizaEmbebido/${val.id}`,
            {
              global_id: interaction.global_id,
            },
            config
          );
          this.loading = false;
          if (val.status == "Emitido el certificado") {
            this.sendMessage = true;
          } else {
            this.sendMessage = false;
          }
        });
        // this.sendMessage = true;
      } catch (exception) {
        console.error(exception);
        this.loading = false;
        this.sendMessage = false;
      }
    },
    async saveDisposition() {
      try {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let disposition = this.disposiciones.find(
          (item) => item.id == this.disposicion
        );
        let response = await this.$axios.get(
          `v1/agente/uuid/${localStorage.agenteUuid}`,
          config
        );
        await this.$axios.post(
          `v1/bradescard/accion/lead`,
          {
            lead_uuid: this.$route.params.uuid,
            code_disposicion: this.disposicion,
            descripcion: disposition.disposicion,
            operador_id: 707,
            agente_id: response.data.id,
            fecha_agenda: this.dateAgenda,
            tipo_llamada: "Outbound",
            globalInteractionId: this.globalId,
            callback: 0,
            marcacion_automatica: this.$route.query.marcacion == "automatica" ? 'true' : 'false'
          },
          config
        );

        this.getLlamadas(this.$route.params.uuid);
        await this.LoadDetalleLead(this.$route.params.uuid);
      } catch (exception) {
        console.error(exception);
      }
    },
    /**
     * Realiza la funcion de llamada, pasando como parametro el numero de telefono y cambiando los estados a llamando
     * @params selectPhone | numero de telefono seleccionado
     */
    callNumber() {
      this.llamar = true;
      //this.selectPhone = "4492344656";
      console.log("Realizando llamada al número: ", this.selectPhone);
      window.bpspat.api.selectService("CC_Bradescard_Leads_CC");
      window.bpspat.api.dialNumber(this.selectPhone);

      // window.bpspat.api.getState(this.logs);
      // window.bpspat.api.onStatusChange(this.logs);
      window.bpspat.api.addInteractionRenderedHandler(this.logs);
      window.bpspat.api.addInteractionCompletedHandler(this.logs);
    },
    onMute() {
      this.mute = true;
    },
    offMute() {
      this.mute = false;
    },
    async sendCode() {
      try {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };

        this.loadingBotton = true;
        await this.$axios.get(`v1/bradescard/sms/${this.poliza.uuid}`, config);
        // console.log(data);
        this.sendMessage = false;

        this.$toast.success("Se ha enviando el código de verificación");

        this.loadingBotton = false;
        this.isConference = true;
      } catch (exception) {
        this.$toast.error("Error al enviar el código de verificación");
        this.loadingBotton = false;
        this.isConference = false;
        console.error(exception);
      }
    },
    /**
     * Se realiza una conferencia con un numero o extension
     */
    ConferenceCall() {
      // window.bpspat.api.singleStepConference("5588770558");

      window.bpspat.api.selectService("CC_Bradescard_Leads_CC");
      window.bpspat.api.singleStepConference(process.env.VUE_APP_NUMERO_IVR);
    },
    /**
     * Detiene la llamada en curso
     */
    async stopCall() {
      let value = this.$refs.formDisposicion.validate();
      if (!value) {
        return false;
      }

      window.bpspat.api.getState(({ data }) => {
        let ivr = data.interactions.find((item) => item.phone_number == 4545);
        if (ivr) {
          window.bpspat.api.completeInteractionWithDisp(
            ivr.item_id,
            this.disposicion
          );
        }

        let interaccion = data.interactions.find(
          (item) => item.phone_number == this.selectPhone
        );
        window.bpspat.api.completeInteractionWithDisp(
          interaccion.item_id,
          String(this.disposicion),
          ""
        ); // se termina la llamada con el envio de la disposicion
      });

      await this.saveDisposition();

      this.tipo_contacto = null;
      this.disposicion = null;
      this.llamar = false;
      this.isConference = false;
      this.poliza = null;
      this.isVenta = false;
    },
    logs(state) {
      this.globalId = state.data.global_id;
      console.log("Aqui esta el globla id: ", this.globalId);
      console.log("aqui esta el estado: ", state);
    },

    checkStatus() {
      window.bpspat.api.getState(this.statusChecked);
    },

    statusChecked() {
      this.$emit("successEmbedded");
      if (this.$route.query.marcacion == "automatica") {
        const btn_embedded = document.getElementsByClassName(
          "embedded v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default success"
        );
        btn_embedded[0].click();
        this.callNumber();
      }
    },
    nextMonth() {
        const now = new Date();
        const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
        return nextMonth.toISOString().slice(0, 16); // Formato necesario para datetime-local
    },
    today() {
        const now = new Date();
        return now.toISOString().slice(0, 16); // Formato necesario para datetime-local
    },
  },
};
</script>

<style scoped>
.embedded {
  position: fixed;
  bottom: 60px;
  right: 0;
}

.v-dialog__content {
  justify-content: end !important;
}
</style>
