var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-15 fill-height"},[_c('v-col',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Reporte Emisiones Offline")])])],1),_c('v-row',{staticClass:"mb-15",staticStyle:{"padding":"30px"}},[_c('v-col',{attrs:{"md":"3"}},[_c('h3',[_vm._v("Selecciona el rango de fechas")]),_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.consultar}},[_vm._v("Consultar")]),(_vm.roles != 'AGENTCC')?_c('v-btn',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.newEmision}},[_vm._v("Crear nueva emision")]):_vm._e(),_c('v-btn',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"rounded":"","color":"primary","disabled":_vm.bloquear}},[_c('download-csv',{attrs:{"data":_vm.reportDownload,"name":"reporteEmisiones.csv"}},[_vm._v(" Descargar Información ")])],1)],1),_c('v-col',{attrs:{"cols":"9","md":"9"}},[_c('div',[(_vm.loading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('h2',{attrs:{"clas":"pb-4"}},[_vm._v("Cargando Reporte....")]),_c('br'),_c('br'),_c('md-progress-spinner',{attrs:{"md-diameter":100,"md-stroke":10,"md-mode":"indeterminate"}})],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.columns,"items":_vm.emisiones,"item-key":"name","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 0)?_c('td',[_vm._v("Nueva")]):_vm._e(),(item.status == 1)?_c('td',[_vm._v("Emitida Pendente de Pago")]):_vm._e(),(item.status == 2)?_c('td',[_vm._v("Pagada Parcial")]):_vm._e(),(item.status == 3)?_c('td',[_vm._v("Pagada Total")]):_vm._e(),(item.status == 4)?_c('td',[_vm._v("Renovada")]):_vm._e(),(item.status == 5)?_c('td',[_vm._v("Finalizada")]):_vm._e(),(item.status == 6)?_c('td',[_vm._v("Cancelada")]):_vm._e()]}},{key:"item.aseguradoraId",fn:function(ref){
var item = ref.item;
return [(item.aseguradoraId == 1)?_c('td',[_vm._v("Afirme")]):_vm._e(),(item.aseguradoraId == 2)?_c('td',[_vm._v("ANA")]):_vm._e(),(item.aseguradoraId == 3)?_c('td',[_vm._v("GNP")]):_vm._e(),(item.aseguradoraId == 4)?_c('td',[_vm._v("Primero")]):_vm._e(),(item.aseguradoraId == 5)?_c('td',[_vm._v("Quálitas")]):_vm._e(),(item.aseguradoraId == 6)?_c('td',[_vm._v("Multiva")]):_vm._e(),(item.aseguradoraId == 7)?_c('td',[_vm._v("Sura")]):_vm._e(),(item.aseguradoraId == 8)?_c('td',[_vm._v("AIG")]):_vm._e(),(item.aseguradoraId == 9)?_c('td',[_vm._v("HDI")]):_vm._e(),(item.aseguradoraId == 10)?_c('td',[_vm._v("Zurich")]):_vm._e(),(item.aseguradoraId == 11)?_c('td',[_vm._v("AXXA")]):_vm._e(),(item.aseguradoraId == 12)?_c('td',[_vm._v("Banorte")]):_vm._e()]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.$router.push('/administrador/editar-emision/' + item.uuid)}}},[_vm._v(" Ver ")])]}},{key:"item.urlPoliza",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza}},[_vm._v("Descargar")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }