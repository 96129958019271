<template>
  <v-data-table :headers="headers" :items="cotizaciones" item-key="numeroCotizacion" class="elevation-1"
    :search="search" :loading="cargando" loading-text="Cargando cotizaciones... Por favor espera">
    <template v-slot:item.id="{ item }">
      <v-btn color="primary" v-on:click="$router.push('/cotizacion/' + item.numeroCotizacion)">Ver Cotización</v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      cotizaciones: [],
      search: '',
      calories: '',
      cargando: true,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Codigo',
          align: 'start',
          sortable: false,
          value: 'numeroCotizacion',
        },
        {
          text: 'Vehiculo',
          align: 'start',
          sortable: false,
          value: 'vehiculo',
        },
        {
          text: 'Cliente',
          align: 'start',
          sortable: false,
          value: 'cliente',
        },
        {
          text: 'Aseguradora',
          align: 'start',
          sortable: false,
          value: 'aseguradora',
        },
        {
          text: 'Acciones',
          align: 'start',
          sortable: false,
          value: 'id',
        },
      ]
    },
  },
  mounted() {
    this.getCotizaciones();
  },
  methods: {
    getCotizaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get("/v1/agente/cotizaciones/guardadas/" + localStorage.agenteUuid, config)
        .then((value) => {
          this.cotizaciones = value.data;
          this.cargando = false;
        });
    },
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
