<template>
  <div>
    <v-card outlined>
      <v-card-title >Polizas por Ramo</v-card-title>
      <div id="ramo" class="chart"></div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
const eventBus = new Vue();
import { mainAxios } from "../../mainAxios";

export default {
  props: {
    solicitudes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        type: "pie",
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#385083",
                "#20407b",
                "#a74e18",
                "#f37926",
                "#a1a1a1",
                "#f9c302",
                "#589cdb",
                "#67a93b",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          legend: {
            position: "bottom",
          },
          interaction: {
            mode: "index",
            axis: "y",
          },
        },
        plugins: [
          {
            id: "myEventCatcher",
            beforeEvent(chart, args) {
              if (args.type == "click")
                eventBus.$emit(
                  "clicked",
                  chart.getElementAtEvent(args)[0]._model.label
                );
            },
          },
        ],
      },
      agenteUuid: localStorage.agenteUuid,
      agenteAccessToken: localStorage.getItem('agenteAccessToken'),
      payload:{
        ramo: ''
      }
    };
  },
  mounted() {
    this.loadPiechart()
    this.barChart.on('click', (params) => {
        this.getResultData(params.name);
    });
  },
  methods: {
    loadPiechart() {
      var options = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            // orient: 'vertical',
            top: 'bottom',
            type: 'scroll',

        },
        series: [
            {
                name: 'Estatus Polizas',
                type: 'pie',
                radius: '50%',
                avoidLabelOverlap: false,
                // stillShowZeroSum: false,
                data: [
                    { value: 1048, name: 'Nueva' },
                    { value: 735, name: 'Emitida Pendente de Pago' },
                    { value: 580, name: 'Pagada Parcial' },
                    { value: 484, name: 'Pagada Total' },
                    { value: 300, name: 'Renovada' },
                    { value: 300, name: 'Finalizada' }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                },
                label: {
                    show: true,
                },
                labelLine: { // quita las lineas del pie
                    show: true
                },
            }
        ],
      }
      this.barChart = this.$chart.init(document.getElementById('ramo'));
      this.barChart.setOption(options);
      var data = [];
      mainAxios.get(`v1/bradescard/dashboard/data/${this.agenteUuid}`, {
          headers: {
              Authorization: `Bearer ${this.agenteAccessToken}`,
          }
      }).then((response) => {
          Object.keys(response.data['Emisiones-ramo']).forEach(key => {
           if(key != 'AHORRO' ){
              data.push({
                    name: key,
                    value: response.data['Emisiones-ramo'][key]
                });
             }
          })
          options.series[0].data = data;
          this.barChart.setOption(options);
      });
    },
    getResultData(name){
      this.payload.ramo = name;
      this.$emit('getPolizas', this.payload)
    }
  },
};
</script>