<template>
    <v-form>
        <v-row style="margin-top: 10px">
            <v-col cols="6">
                <h2>Información Requerida</h2>
            </v-col>

            <v-col cols="6">
                <v-btn @click="goBack()" icon elevation="2" style="margin-left: 527px">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-card style="margin-top: 15px" elevation="5">

            <v-row style="padding: 20px">
                <v-col cols="6">
                    <v-text-field outlined v-model="modelo" label="Modelo"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined v-model="version" label="Version"></v-text-field>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col cols="6">
                    <v-text-field label="Marca" outlined v-model="marca"></v-text-field>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col cols="12">
                    <v-textarea label="Detalle del auto" outlined v-model="detalleAuto"></v-textarea>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col cols="6">
                    <v-text-field outlined v-model="submarca" label="Submarca"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined v-model="cobertura" label="Cobertura/Plan"></v-text-field>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col cols="6">
                    <v-text-field outlined v-model="inicioVigencia" label="Inicio de Vigencia"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined v-model="finVigencia" label="Fin de Vigencia"></v-text-field>
                </v-col>
            </v-row>

            <div v-if="bandera == true">
                <v-row style="padding: 20px; margin-top: -70px">
                    <v-col cols="6">
                        <v-text-field outlined v-model="placas" label="Placas"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined v-model="numSerie" label="No. De Serie"></v-text-field>
                    </v-col>
                </v-row>

                <v-row style="padding: 20px; margin-top: -70px">
                    <v-col cols="6">
                        <v-text-field outlined v-model="numMotor" label="No. De Motor"></v-text-field>
                    </v-col>
                </v-row>
            </div>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col>
                    <h3>Información del Usuario</h3>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -50px">
                <v-col cols="6">
                    <v-text-field outlined v-model="celular" label="Celular"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-select outlined :items="genero" v-model="selectedGenero" label="Género"></v-select>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col cols="6">
                    <v-text-field outlined v-model="correoElectronico" label="Correo Electrónico"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined v-model="direccion" label="Dirección"></v-text-field>
                </v-col>
            </v-row>

            <v-row style="padding: 20px; margin-top: -70px">
                <v-col cols="6">
                    <v-text-field outlined v-model="edad" label="Edad"></v-text-field>
                </v-col>
            </v-row>

            <v-btn outline color="primary" dark style="margin-bottom: 30px; margin-left: 20px; margin-top: -20px">
                Enviar solicitud
            </v-btn>

        </v-card>

    </v-form>
</template>

<script>

export default {
    props: {
        origenSolicitudSelected: String,
        estadoSelected: String,
        tipoSelected: String,
        categoriaSelected: String,
        agente: String,
        productos: String,
        ramo: String,
        ejecutivo: String,
        seguimientoSolicitud: String,
        polizaRelacionada: String,
        oportunidades: String,
        nombres: String,
        apellidoPaterno: String,
        apellidoMaterno: String,
        fechaNacimiento: String
    },
    data() {
        return {
            bandera: false,
            genero: ['Masculino', 'Femenino'],
            modelo: null,
            marca: null,
            submarca: null,
            detalleAuto: null,
            version: null,
            inicioVigencia: null,
            finVigencia: null,
            cobertura: null,
            placas: null,
            numSerie: null,
            numMotor: null,
            celular: null,
            correoElectronico: null,
            edad: null,
            direccion: null,

            selectedGenero: null,
        };
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
    },
    created() {
        console.log(this.origenSolicitudSelected, this.estadoSelected);
    }
}
</script>