<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte gerencial Segurify</h1>
        </v-col>
      </v-row>
      <v-card elevation="2" class="mt-10">
        <v-card-text>
          <p class="text-h5 text--primary">
            Filtros
          </p>
        </v-card-text>
        <v-row
          class="pt-2"
          style="padding-left: 30px; padding-right: 30px; "
          v-if="currentMonth == '' || currentYear == ''"
        >
          <v-col>
            <v-alert shaped dense dark type="warning">
              Recuerde seleccionar fecha y año
            </v-alert>
          </v-col>
          <hr />
        </v-row>
        <v-row class="pt-6" style="padding-left: 30px">
          <v-col class="d-flex" cols="5" sm="5">
            <v-select
              :items="años"
              item-text="name"
              item-value="id"
              v-model="currentYear"
              label="Seleccione el año"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="5" sm="5">
            <v-select
              :items="meses"
              item-text="name"
              item-value="id"
              v-model="currentMonth"
              label="Seleccione el mes"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="2" sm="2">
            <div class="my-2">
              <v-btn color="primary" dark @click="clearFilters">
                Limpiar filtros
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col md="4">
          <v-card elevation="2" class="mt-10">
            <v-card-text>
              <p class="text-h5 text--primary">
                Lista de reportes
              </p>
              <hr />
            </v-card-text>
            <v-row>
              <v-col cols="12">
                <div style="margin: 1%;">
                  <v-btn
                    :disabled="desabilitarBoton"
                    color="primary"
                    block
                    @click="consultarInfo(1)"
                  >
                    Reporte de marcacion y Asignacion
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <div style="margin: 1%;">
                  <v-btn
                    :disabled="desabilitarBoton"
                    color="primary"
                    block
                    @click="consultarInfo(2)"
                  >
                    Reporte de ventas
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <div style="margin: 1%;">
                  <v-btn
                    :disabled="desabilitarBoton"
                    color="primary"
                    block
                    @click="consultarInfo(3)"
                  >
                    Reporte leads mailling
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col md="8">
          <v-card elevation="2" class="mt-10">
            <v-row>
              <v-col cols="6">
                <v-card-text>
                  <p class="text-h5 text--primary">
                    Informacion {{ currentReporte }}
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="6" style="text-align: end; padding-right: 2%;">
                <v-btn
                  v-if="currentReporte == 'Reporte de marcacion y Asignacion'"
                  :disabled="desabilitarBoton"
                  color="primary"
                  @click="consultarInfo(1, true)"
                >
                  Descargar
                </v-btn>
                <v-btn v-else :disabled="desabilitarBoton" color="primary">
                  <download-csv :data="report" name="Informe_bradescard.csv">
                    Descargar
                  </download-csv>
                </v-btn>
              </v-col>
            </v-row>
            <hr />
            <v-row>
              <v-col cols="12" v-if="loader" style="text-align: center;">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  v-if="currentReporte == 'Reporte de marcacion y Asignacion'"
                  :headers="columns"
                  :items="report"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                  loading-text="Cargando..."
                  :server-items-length="totalRegistros"
                  :items-per-page="elementosPagina"
                  @update:page="pagina = $event"
                  @update:items-per-page="elementosPagina = $event"
                >
                </v-data-table>

                <v-data-table
                  v-else
                  :headers="columns"
                  :items="report"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                  :custom-filter="filterOnlyCapsText"
                  loading-text="Cargando..."
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");
import download from "downloadjs";

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      currentYear: "",
      currentMonth: "",
      loader: false,
      reportDownload: [],
      solicitudes: [],
      block: true,
      meses: [
        { id: 1, name: "Enero" },
        { id: 2, name: "Febrero" },
        { id: 3, name: "Marzo" },
        { id: 4, name: "Abril" },
        { id: 5, name: "Mayo" },
        { id: 6, name: "Junio" },
        { id: 7, name: "Julio" },
        { id: 8, name: "Agosto" },
        { id: 9, name: "Septiembre" },
        { id: 10, name: "Octubre" },
        { id: 11, name: "Noviembre" },
        { id: 12, name: "Diciembre" },
      ],
      columns: [],
      report: [],
      currentReporte: "",
      desabilitado: true,
      operadores: [],

      totalRegistros: 0,
      pagina: 1,
      elementosPagina: 10,
    };
  },

  computed: {
    desabilitarBoton() {
      var valor = false;
      if (this.currentYear == "" || this.currentMonth == "") {
        valor = true;
      } else {
        valor = false;
      }
      return valor;
    },
    años() {
      const startYear = 2022;
      const endYear = new Date().getFullYear();
      const yearList = [];
      for (let year = startYear; year <= endYear; year++) {
        yearList.push(year);
      }
      return yearList;
    },
  },

  watch: {
    pagina() {
      this.consultarInfo(1);
    },

    elementosPagina() {
      this.consultarInfo(1);
    },
  },

  mounted() {
    this.obtenerAgentes();
  },

  methods: {
    clearFilters() {
      this.currentYear = "";
      this.currentMonth = "";
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    consultarInfo(peticion, file = false) {
      this.loader = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      var firstDay = new moment(
        this.currentYear + "-" + this.currentMonth + "-" + "01" + " 00:00:00"
      ).format("YYYY-MM-DD HH:mm:ss");
      var endDate = moment(firstDay).endOf("month");

      firstDay = moment(firstDay).format("YYYY-MM-DD HH:mm:ss");
      endDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
      console.log(firstDay, endDate);

      let fechas = {
        fechaInicio: firstDay,
        fechaFin: endDate,
      };

      if (peticion == 1) {
        let url = "/v1/bradescard/reporte/leadsGerencial";
        if (file) url += "/file";
        this.$axios
          .post(
            url,
            {
              ...fechas,
              pagina: this.pagina,
              elementosPagina: this.elementosPagina,
            },
            config
          )
          .then((value) => {
            if (file) return this.manageFileResponse(value.data);
            this.reportDownload = [];
            this.solicitudes = [];
            if (value.data.data?.length === 0) {
              alert("No existen registros!");
            } else {
              this.report = [];
              this.totalRegistros = value.data.data.totalRegistros;
              value.data.data.leads.map((element) => {
                this.report.push({
                  "ID Bradescard": element.id_bradescard
                    ? element.id_bradescard
                    : "No disponible",
                  Producto: element.producto
                    ? element.producto
                    : "No disponible",
                  "Producto Aseguradora": element.productoAseguradora
                    ? element.productoAseguradora
                    : "No disponible",
                  Etiqueta: element.etiqueta
                    ? element.etiqueta
                    : "No disponible",
                  Corte: element.corte ? element.corte : "No disponible",
                  Edad: element.edad ? element.edad : "No disponible",
                  Sexo: element.sexo ? element.sexo : "No disponible",
                  Status: element.status ? element.status : "No disponible",
                  "Ultima Disposicion": element.ultimaDisposicion
                    ? element.ultimaDisposicion
                    : "No disponible",
                  "Fecha Ultima Llamada": moment
                    .utc(element.fecha_ultima_llamada)
                    .subtract(5, "hours")
                    .format("DD/MM/YYYY HH:mm:ss"),
                  Ejecutivo: element.operadorId || "No disponible",
                  "Fecha Asignacion": moment
                    .utc(element.fecha_asignacion)
                    .subtract(5, "hours")
                    .format("DD/MM/YYYY HH:mm:ss"),
                  Intentos: element.intentos
                    ? element.intentos
                    : "No disponible",
                  "Fecha Creacion": element.fecha_creacion || "No disponible",
                  Base: element.base || "No disponible",
                  "Tipo Contacto": element.tipoContacto
                    ? element.tipoContacto
                    : "No disponible",
                  Poliza: element.poliza || "No disponible",
                  "Fecha Venta": element.fecha_emision || "No disponible",
                  Plan: element.plan || "No disponible",
                  Inicio: element.inicio || "No disponible",
                  Fin: element.fin || "No disponible",
                  "Prima Neta": element.primaNeta || "No disponible",
                  "Prima Total": element.total || "No disponible",
                  Mensualidad: element.primerPago || "No disponible",
                });
              });
              this.columns = [
                { text: "ID Bradecard", value: "ID Bradescard" },
                { text: "Producto", value: "Producto" },
                { text: "Producto Aseguradora", value: "Producto Aseguradora" },
                { text: "Etiqueta", value: "Etiqueta" },
                { text: "Corte", value: "Corte" },
                { text: "Edad", value: "Edad" },
                { text: "Sexo", value: "Sexo" },
                { text: "Estatus", value: "Status" },
                { text: "Ultima Disposicion", value: "Ultima Disposicion" },
                { text: "Fecha Ultima Llamada", value: "Fecha Ultima Llamada" },
                { text: "Ejecutivo", value: "Ejecutivo" },
                { text: "Fecha Asignacion", value: "Fecha Asignacion" },
                { text: "Intentos", value: "Intentos" },
                { text: "Fecha Creacion", value: "Fecha Creacion" },
                { text: "Base", value: "Base" },
                { text: "Tipo contacto", value: "Tipo Contacto" },
                { text: "Poliza", value: "Poliza" },
                { text: "Fecha Venta", value: "Fecha Venta" },
                { text: "Plan", value: "Plan" },
                { text: "Inicio", value: "Inicio" },
                { text: "Fin", value: "Fin" },
                { text: "Prima Neta", value: "Prima Neta" },
                { text: "Prima Total", value: "Prima Total" },
                { text: "Mensualidad", value: "Mensualidad" },
              ];
            }
            this.currentReporte = "Reporte de marcacion y Asignacion";
            this.loader = false;
          });
      } else if (peticion == 2) {
        this.$axios
          .post("/v1/bradescard/emisiones/emisiones-asignacion", fechas, config)
          .then((value) => {
            this.report = [];
            if (value.data.length === 0) {
              alert("No existen registros!");
            } else {
              this.report = [];
              value.data.map((element) => {
                this.report.push({
                  id: element.id ? element.id : "No disponible",
                  "No. Póliza": element.poliza
                    ? element.poliza
                    : "No disponible",
                  "Fecha Emisión": moment
                    .utc(element.fechaEmision)
                    .format("DD/MM/YYYY HH:mm:ss"),
                  Estatus: element.status
                    ? element.status == 0
                      ? "Nueva"
                      : element.status == 1
                      ? "Emitida Pendente de Pago"
                      : element.status == 2
                      ? "Pagada Parcial"
                      : element.status == 3
                      ? "Pagada Total"
                      : element.status == 4
                      ? "Renovada"
                      : element.status == 5
                      ? "Finalizada"
                      : element.status == 6
                      ? "Cancelada"
                      : element.status == 8
                      ? "Emitido el certificado"
                      : "No disponible"
                    : "No disponible",
                  Producto: element.producto
                    ? element.producto
                    : "No disponible",
                  "Producto Aseguradora": element.productoAseguradora
                    ? element.productoAseguradora
                    : "No disponible",
                  Plan: element.nombre || "No disponible",
                  Aseguradora: element.aseguradoraId
                    ? element.aseguradoraId == 1
                      ? "Afirme"
                      : element.aseguradoraId == 2
                      ? "ANA Seguros"
                      : element.aseguradoraId == 3
                      ? "Gnp"
                      : element.aseguradoraId == 4
                      ? "Primero Seguros"
                      : element.aseguradoraId == 5
                      ? "Quálitas"
                      : element.aseguradoraId == 6
                      ? "Multiva"
                      : element.aseguradoraId == 7
                      ? "SURA"
                      : element.aseguradoraId == 8
                      ? "AIG"
                      : element.aseguradoraId == 9
                      ? "HDI"
                      : element.aseguradoraId == 10
                      ? "Zurich"
                      : element.aseguradoraId == 11
                      ? "AXA"
                      : element.aseguradoraId == 12
                      ? "BANORTE"
                      : element.aseguradoraId == 13
                      ? "ATLAS"
                      : element.aseguradoraId == 14
                      ? "CHUBB"
                      : element.aseguradoraId == 15
                      ? "GMX"
                      : element.aseguradoraId == 16
                      ? "ALLIANZ"
                      : element.aseguradoraId == 17
                      ? "ARGOS"
                      : element.aseguradoraId == 18
                      ? "BX+"
                      : element.aseguradoraId == 19
                      ? "MUTUUS"
                      : element.aseguradoraId == 20
                      ? "PLAN SEGURO"
                      : element.aseguradoraId == 21
                      ? "PREVEM"
                      : element.aseguradoraId == 22
                      ? "BUPA"
                      : element.aseguradoraId == 23
                      ? "METLIFE"
                      : element.aseguradoraId == 24
                      ? "INSIGNIA"
                      : element.aseguradoraId == 25
                      ? "MAPFRE"
                      : "No disponible"
                    : "No disponible",
                  Inicio: moment
                    .utc(element.inicio)
                    .format("DD/MM/YYYY HH:mm:ss"),
                  Fin: moment.utc(element.fin).format("DD/MM/YYYY HH:mm:ss"),
                  Periodicidad: element.pagoId
                    ? element.pagoId == 1
                      ? "Anual"
                      : element.pagoId == 2
                      ? "Semestral"
                      : element.pagoId == 3
                      ? "Trimestral"
                      : element.pagoId == 4
                      ? "Mensual"
                      : "No disponible"
                    : "No disponible",
                  "ID Bradescard": element.id_bradescard
                    ? element.id_bradescard
                    : "No disponible",
                  Etiqueta: element.etiqueta || "No disponible",
                  "Prima Neta": element.primaNeta
                    ? this.toCurrency(element.primaNeta)
                    : "No disponible",
                  "Prima Total": element.total
                    ? this.toCurrency(element.total)
                    : "No disponible",
                  Mensualidad: element.primerPago
                    ? this.toCurrency(element.primerPago)
                    : "No disponible",
                  "Forma de Pago": element.formaPago
                    ? element.formaPago == 1
                      ? "TC Bradescard"
                      : element.formaPago == 2
                      ? "Tarjeta de Débito"
                      : element.formaPago == 3
                      ? "Depósito en banco"
                      : element.formaPago == 4
                      ? "Transferencia"
                      : element.formaPago == 5
                      ? "Cheque"
                      : element.formaPago == 6
                      ? "Efectivo"
                      : element.formaPago == 7
                      ? "Domiciliación"
                      : element.formaPago == 8
                      ? "Cargo único"
                      : "No disponible"
                    : "No disponible",
                  Ejecutivo: element.operadorNombre || "No disponible",
                  "Fecha de cancelación": element.fechaCancelacion
                    ? moment
                        .utc(element.fechaCancelacion)
                        .subtract(6, "hours")
                        .format("DD/MM/YYYY")
                    : "No disponible",
                  "Fecha de Venta": element.fecha_venta
                    ? moment
                        .utc(element.fecha_venta)
                        .format("DD/MM/YYYY HH:mm:ss")
                    : "No Disponible",
                });
              });
              this.columns = [
                { text: "id", value: "id" },
                { text: "Póliza", value: "No. Póliza" },
                { text: "Fecha Emisión", value: "Fecha Emisión" },
                { text: "Estatus", value: "Estatus" },
                { text: "Producto", value: "Producto" },
                { text: "Producto Aseguradora", value: "Producto Aseguradora" },
                { text: "Plan", value: "Plan" },
                { text: "Aseguradora", value: "Aseguradora" },
                { text: "Fecha Inicio", value: "Inicio" },
                { text: "fecha Fin", value: "Fin" },
                { text: "Periodicidad", value: "Periodicidad" },
                { text: "ID Bradescard", value: "ID Bradescard" },
                { text: "Etiqueta", value: "Etiqueta" },
                { text: "Prima Neta", value: "Prima Neta" },
                { text: "Prima Total", value: "Prima Total" },
                { text: "Mensualidad", value: "Mensualidad" },
                { text: "Forma de Pago", value: "Forma de Pago" },
                { text: "Ejecutivo", value: "Ejecutivo" },
              ];
            }
            this.loader = false;
            this.currentReporte = "Reporte de ventas";
          });
      } else if (peticion == 3) {
        this.$axios
          .post("/v1/bradescard/reporte/leadsGerencial", fechas, config)
          .then((value) => {
            this.report = [];
            if (value.data.length === 0) {
              alert("No existen registros!");
            } else {
              this.report = [];
              value.data.map((element) => {
                this.report.push({
                  "ID Bradescard": element.id_bradescard
                    ? element.id_bradescard
                    : "No disponible",
                  Producto: element.producto
                    ? element.producto
                    : "No disponible",
                  Etiqueta: element.etiqueta || "No disponible",
                  Nombre: element.nombre ?? "No disponible",
                  "Apellido Paterno":
                    element.apellido_Paterno ?? "No disponible",
                  "Apellido Materno":
                    element.apellido_Materno ?? "No disponible",
                  Estado: element.estado ?? "No disponible",
                  Corte: element.corte ?? "No disponible",
                  Edad: element.edad ?? "No disponible",
                  Sexo: element.sexo ?? "No disponible",
                  "Estado civil": element.estadoCivil ?? "No disponible",
                  Email: element.correo ?? "No disponible",
                  Seguro: element.productoAseguradora ?? "No disponible",
                });
              });
              this.columns = [
                { text: "ID Bradescard", value: "ID Bradescard" },
                { text: "Producto", value: "Producto" },
                { text: "Etiqueta", value: "Etiqueta" },
                { text: "Nombre", value: "Nombre" },
                { text: "Apellido Paterno", value: "Apellido Paterno" },
                { text: "Apellido Materno", value: "Apellido Materno" },
                { text: "Estado", value: "Estado" },
                { text: "Corte", value: "Corte" },
                { text: "Edad", value: "Edad" },
                { text: "Sexo", value: "Sexo" },
                { text: "Estado civil", value: "Estado civil" },
                { text: "Email", value: "Email" },
                { text: "Seguro", value: "Seguro" },
              ];
            }
            this.loader = false;
            this.currentReporte = "Reporte de ventas";
          });
      }
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
    getOperadores(status) {
      let data = [];
      try {
        this.$axios
          .get(`v1/agente/operadores/${status}`, {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          })
          .then((response) => {
            data = response.data;
            if (status == 0) {
              this.operadores = [...data];
            } else if (status == 1) {
              this.operadores = [...data];
            }
          });
      } catch (exception) {
        console.error(exception);
      }
    },
    obtenerNombreOperador(id) {
      if (id == "No Disponible") {
        return id;
      } else {
        let agente = this.operadores.find((element) => element.id == id);
        var valor = agente ? agente.nombreCompleto : "No disponible";
        return valor;
      }
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      // /v1/agente/operadores/1
      this.$axios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
          });
        });
      });
    },

    manageFileResponse(responseData) {
      download(responseData, `reporte_gerencial.csv`, "application/csv");

      this.loader = false;
    },
  },
};
</script>

<style scoped>
.v-application .my-2 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.container .container--fluid {
  background: #f6f9ff !important;
}

.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}
</style>
