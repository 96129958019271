<template>
  <v-main class="mb-15">
    <v-dialog v-model="dialog" max-width="500px" light>
      <v-card>
        <v-card-text style="padding: 20px" v-if="ayudaEnviada">
          <h3>En breve te contactaremos para brindarte ayuda</h3>
        </v-card-text>
        <v-card-text style="padding: 20px" v-if="!ayudaEnviada">
          <h3>¿Tienes algún problema al realizar tu cotización?</h3>
          <v-textarea style="margin-top: 20px" outlined name="input-7-1" v-model="detalle"
            label="¿Cómo podemos ayudarte?" hint="Describe tu problema brevemente"></v-textarea>
          <p>
            El teléfono que tenemos registrado para contactarte es: <br /><b>** ** **
              {{
                  telefonoAgente.substring(
                    telefonoAgente.length - 4,
                    telefonoAgente.length
                  )
              }}</b>
          </p>
          <p>
            Si deseas ser contactado en otro teléfono actualiza tu perfil o
            ingresa el teléfono a continuación
          </p>
          <v-text-field name="name" label="Teléfono" v-model="telefonoContacto" single-line></v-text-field>
          <p>
            O puedes comunicarte al teléfono <b>5588770545</b> o bien, al correo electrónico:
            <b>operaciones@segurify.com</b>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="!ayudaEnviada" :disabled="enviandoAyuda" @click="sendAyuda">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn elevation="2" fab dark color="blue" large v-bind="attrs" v-on="on"
          style="position: fixed; bottom: 30px; right: 10px; z-index: 5" @click="dialog = !dialog">
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>
      <span>¿Necesitas Ayuda?</span>
    </v-tooltip>

    <v-banner :color="'blue'" dark>
      Las cotizaciones hechas en este portal son para emisiones nuevas, para
      renovaciones por favor contacta a nuestro equipo de operaciones.<br />
      Con GNP, AIG, Ana y HDI seguros el pago de tu póliza deberá ser
      realizado al momento de tu cotizacion<br />
      Con Quálitas podrás emitir tu póliza y realizar el págo o descargar los
      recibos para pago en ventanilla bancaria.<br />
      *Tu cotización contará con una vigencia de 5 días.
      <template v-slot:actions> </template>
    </v-banner>

    <v-container fluid>
      <v-row>
        <v-col>
          <v-row class="mb-15" align="center" justify="center">
            <v-col cols="12">
              <v-card style="padding: 30px">
                <v-card-title primary-title>
                  <div>
                    <img :src="getAseguradoraImage(cotizacionAgente.cotizacionAgente.aseguradora)"
                      style="width: 150px" />
                    <h3 class="headline mb-0" style="margin-bottom: 30px !important">
                      Tu vehículo cotizado
                      {{ cotizacionAgente.cotizacionAgente.vehiculo }}
                    </h3>
                    <div>
                      <p>
                        Cliente:
                        {{ cotizacionAgente.cotizacionAgente.cliente }}
                      </p>
                    </div>
                  </div>
                </v-card-title>
                <v-card-actions> </v-card-actions>
              </v-card>
              <div style="margin-top: 30px">
                <v-btn class="mr-2" v-if="cotizaciones.length > 0" text color="primary" @click="downloadCotizacion">
                  Descargar PDF cotización</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" v-for="(cotizacion, index) in cotizacionesOrdenadas" v-bind:key="cotizacion.id">
              <v-card style="opacity: 1" :loading="cotizacion.loading">
                <v-card-title primary-title v-if="cotizacion.loading">
                  <p>Cotizando...</p>
                </v-card-title>
                <v-card-title primary-title v-if="
                  !cotizacion.loading &&
                  cotizacion.cotizacion.status == 'FAILED'
                ">
                  <div style="width: 100%; text-align: center">
                    <img style="height: 100px" :src="getAseguradoraImage(cotizacion.aseguradora)" />

                    <h3 class="headline mb-0" style="
                          font-size: 30px !important;
                          margin-bottom: 20px !important;
                        ">
                      No Disponible
                    </h3>
                  </div>
                </v-card-title>
                <v-card-title primary-title v-if="
                  !cotizacion.loading &&
                  cotizacion.cotizacion.status != 'FAILED'
                ">
                  <div style="width: 100%; text-align: center">
                    <div class="ribbon ribbon-top-right" v-if="index == 0">
                      <span>Recomendado</span>
                    </div>
                    <img style="height: 100px" :src="getAseguradoraImage(cotizacion.aseguradora)" />
                    <h3 class="headline mb-0" style="
                          font-size: 30px !important;
                          margin-bottom: 20px !important;
                        ">
                      {{ toCurrency(cotizacion.cotizacion.total) }}
                    </h3>
                    <div>
                      {{
                          getAseguradoraName(
                            cotizacion.cotizacion.aseguradoraId
                          )
                      }}
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <coberturas-amplia :aseguradora="cotizacion.aseguradora" :plan="cotizacion.cotizacion.plan">
                        </coberturas-amplia>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-title>
                <v-card-actions v-if="!cotizacion.loading">
                  <v-btn color="primary" style="width: 100%" @click="
                    emitir(
                      getAseguradoraName(
                        cotizacion.cotizacion.aseguradoraId
                      ),
                      cotizacion.cotizacion
                    )
                  ">Emitir</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>


<script>
import CoberturasAmplia from '../../../components/coberturas/coberturasAmplia';
import { axiosLogin } from '../../../axiosLogin';

export default {
  components: {
    CoberturasAmplia,
  },
  data() {
    return {
      ayudaEnviada: false,
      detalle: '',
      telefonoContacto: null,
      enviandoAyuda: false,
      request: {},
      cotizacionAgente: null,
      aseguradora: null,
      dialog: false,
      cotizarClicked: false,
      cotizaciones: [],
      submarcas: [],
      vehiculos: [],
      vehiculo: null,
      marca: null,
      submarca: null,
      marcas: [],
      modelo: null,
      modelos: [],
      e6: 1,
      icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      items: [
        {
          title: 'Mis Cotizaciones',
          text: 'Podrás consultar tus cotizaciones',
        },
        {
          title: 'Mis Emisiones',
          text: 'Podrás consultar tus emisiones',
        },
        {
          title: 'Cotizar',
          text: 'Podrás consultar tus emisiones',
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
      // data costumer
      customer: {
        name: null,
        lastname: null,
        lastname2: null,
        email: null,
        phone: null,
        birthDay: null,
        birthMonth: null,
        birthYear: null,
        cp: null,
        civilStatus: null,
        sexo: null,
      },
      //validate form
      valid: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'E-mail must be valid',
      ],
      date: null,
      menu: false,
      itemsCivilStatus: ['Soltero', 'Casado'],
      itemsSexo: ['Masculino', 'Femenino'],
      sexo: null,
      //Respuesta Aseguradoras
      respuestaQualitas: null,
      respuestaAig: null,
      respuestaHDI: null,
      respuestaAna: null,
      respuestaGNP: null,

      telefonoAgente: '',
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  computed: {
    cotizacionesOrdenadas() {
      return this.cotizaciones.slice().sort(function (a, b) {
        if (a.loading && b.loading) {
          return 0;
        }
        if (a.loading && !b.loading) {
          return -1;
        }
        if (!a.loading && b.loading) {
          return 1;
        }
        if (!a.loading && !b.loading) {
          if (a.cotizacion.total > b.cotizacion.total) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    },
  },
  methods: {
    getAseguradoraImage(aseguradora) {
      if (aseguradora == 'segurify') {
        return require('../../../assets/logo_segurify.png');
      }
      if (aseguradora == 'qualitas') {
        return require('../../../assets/qualitas_new.svg');
      }
      if (aseguradora == 'gnp') {
        return require('../../../assets/gnp_new.svg');
      }
      if (aseguradora == 'aig') {
        return require('../../../assets/aig_new.svg');
      }
      if (aseguradora == 'ana') {
        return require('../../../assets/ana.png');
      }
      if (aseguradora == 'hdi') {
        return require('../../../assets/hdi_new.svg');
      }
    },
    downloadCotizacion() {
      var vm = this;
      let newCotizaciones = [];
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
        responseType: 'blob',
      };
      vm.downloading = true;
      this.cotizaciones.map((element) => {
        newCotizaciones.push(element.cotizacion);
      });
      if (this.cotizacionAgente.cotizacionAgente.aseguradora == 'segurify') {
        this.$axios
          .post('/v1/cotizacion/pdf', newCotizaciones, config)
          .then((response) => {
            vm.downloading = false;
            require('downloadjs')(
              response.data,
              vm.customer.name +
              '_' +
              vm.customer.lastname +
              '_CotizacionSegurify.pdf',
              'application/pdf'
            );
            //download(response.data, "cotizacion.pdf", "application/pdf");
          });
      } else {
        this.$axios
          .post(
            '/v1/cotizacion/pdf/aseguradora/' +
            this.cotizacionAgente.cotizacionAgente.aseguradora,
            newCotizaciones,
            config
          )
          .then((response) => {
            vm.downloading = false;
            require('downloadjs')(
              response.data,
              vm.customer.name +
              '_' +
              vm.customer.lastname +
              '_CotizacionSegurify.pdf',
              'application/pdf'
            );
            //download(response.data, "cotizacion.pdf", "application/pdf");
          });
      }
    },
    getAseguradoraName(id) {
      if (id == 5) {
        return 'Qualitas';
      }
      if (id == 3) {
        return 'gnp';
      }
      if (id == 8) {
        return 'aig';
      }
      if (id == 2) {
        return 'ana';
      }
      if (id == 9) {
        return 'hdi';
      }
      return 'N/A';
    },
    cargarCotizacion() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get('/v1/agente/cotizacion/' + this.$route.params.uuid, config)
        .then((value) => {
          this.cotizacionAgente = value.data;
          this.cotizacionAgente.cotizaciones.forEach((element) => {
            var cotizacion = {
              loading: false,
              aseguradora: this.getAseguradoraName(
                element.aseguradoraId
              ).toLowerCase(),
              cotizacion: element,
            };
            this.cotizaciones.push(cotizacion);
          });
        });
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number); /* $2,500.00 */
    },
    guardarCotizacion() {
      var request = {
        agente: localStorage.agenteUuid,
        vehiculo:
          this.modelo + ' ' + this.marca.marca + ' ' + this.vehiculo.nombre,
        cliente: this.customer.name + ' ' + this.customer.lastname,
        aseguradora: this.aseguradora,
        cotizaciones: this.cotizaciones,
      };
      console.log(request);
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .post('/v1/agente/cotizacion/guardar', request, config)
        .then((value) => {
          console.log(value);
        });
    },
    cargarMarcas() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(
          '/v1/vehiculo/' + this.aseguradora + '/marca/' + this.modelo,
          config
        )
        .then((value) => {
          this.marcas = value.data;
        });
    },
    cargarSubmarcas() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(
          '/v1/vehiculo/' +
          this.aseguradora +
          '/submarca/' +
          this.marca.id +
          '/' +
          this.modelo,
          config
        )
        .then((value) => {
          this.submarcas = value.data;
        });
    },
    cargarVersiones() {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(
          '/v1/vehiculo/' +
          this.aseguradora +
          '/vehiculo/' +
          this.marca.id +
          '/' +
          this.submarca.id +
          '/' +
          this.modelo,
          config
        )
        .then((value) => {
          this.vehiculos = value.data;
        });
    },
    seleccionarModelo(modelo) {
      this.modelo = modelo;
      this.cargarMarcas();
    },
    seleccionarMarca(marca) {
      this.marca = marca;
      this.cargarSubmarcas();
    },
    seleccionarSubmarca(submarca) {
      this.submarca = submarca;
      this.cargarVersiones();
    },
    seleccionarVehiculo(vehiculo) {
      this.vehiculo = vehiculo;
    },
    //calculadora de edad
    getYearsOld(birthDateDay) {
      let tempDate = birthDateDay.split('/');
      let birthDay = tempDate[0];
      let birthMonth = tempDate[1];
      let birthYear = tempDate[2];
      let todayDate = new Date();
      let todayYear = todayDate.getFullYear();
      let todayMonth = todayDate.getMonth();
      let todayDay = todayDate.getDate();
      let age = todayYear - birthYear;

      if (todayMonth < birthMonth - 1) {
        age--;
      }
      if (birthMonth - 1 == todayMonth && todayDay < birthDay) {
        age--;
      }
      return age;
    },

    watch: {
      cotizaciones() {
        return this.cotizaciones;
      },
    },
    // Funciones para cotizar
    cotizar() {
      this.e6 = 6;
      //identificador()
      var cotizacion1 = {};
      var cotizacion2 = {};
      var cotizacion3 = {};
      var cotizacion4 = {};
      console.log('Vehiculo id: ' + this.vehiculo.id);
      console.log(this.vehiculo);
      var res = this.vehiculo.id.split('-');
      var idVehiculo = this.vehiculo.id;
      if (res.length == 1) {
        idVehiculo = this.vehiculo.id + '-' + this.modelo;
      }
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get('/v1/vehiculo/identificador/' + idVehiculo, config)
        .then((value) => {
          var vehiculoHomologado = value.data;

          if (this.$route.params.aseguradora != 'segurify') {
            cotizacion1 = { plan: 'amplia', loading: true };
            cotizacion2 = { plan: 'amplia-plus', loading: true };
            cotizacion3 = { plan: 'limitada', loading: true };
            cotizacion4 = { plan: 'rc', loading: true };
            this.cotizaciones.push(cotizacion1);
            this.cotizaciones.push(cotizacion2);
            this.cotizaciones.push(cotizacion3);
            this.cotizaciones.push(cotizacion4);
            this.cotiza(this.aseguradora, 'amplia', 1, this.vehiculo.id);
            this.cotiza(this.aseguradora, 'amplia-plus', 0, this.vehiculo.id);
            this.cotiza(this.aseguradora, 'limitada', 2, this.vehiculo.id);
            this.cotiza(this.aseguradora, 'rc', 3, this.vehiculo.id);
          } else {
            cotizacion1 = { plan: 'amplia', loading: true };
            cotizacion2 = { plan: 'amplia', loading: true };
            cotizacion3 = { plan: 'amplia', loading: true };
            cotizacion4 = { plan: 'amplia', loading: true };
            this.cotizaciones.push(cotizacion1);
            this.cotizaciones.push(cotizacion2);
            this.cotizaciones.push(cotizacion3);
            this.cotizaciones.push(cotizacion4);
            this.cotiza('qualitas', 'amplia', 1, this.vehiculo.id);
            this.cotiza(
              'aig',
              'amplia',
              0,
              vehiculoHomologado.identificadorAig
            );
            this.cotiza(
              'gnp',
              'amplia',
              2,
              vehiculoHomologado.identificadorGnp
            );
            this.cotiza(
              'ana',
              'amplia',
              3,
              vehiculoHomologado.identificadorAna.split('-')[0]
            );
          }
        });
    },

    //data piker
    save(date) {
      this.$refs.menu.save(date);
    },
    emitir: function (aseguradora, cotizacion) {
      var deComparador = false;
      if (this.aseguradora == 'segurify') {
        deComparador = true;
      }
      this.$router.push({
        name: 'emite',
        params: {
          aseguradora: aseguradora.toLowerCase(),
          cotizacion: cotizacion,
          deComparador: deComparador,
        },
      });
    },
    sendAyuda() {
      this.enviandoAyuda = true;
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let request = {
        detalle: this.detalle,
        paso: this.e6,
      };
      if (this.telefonoContacto != null) {
        request.phone = this.telefonoContacto;
      }

      this.$axios
        .post(
          'v1/lead/ayuda/agente/' + localStorage.agenteUuid,
          request,
          config
        )
        .then((value) => {
          console.log(value);
          this.ayudaEnviada = true;
        });
    },
  },
  mounted() {
    this.cargarCotizacion();

    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.agenteAccessToken,
      },
    };
    axiosLogin.get('/user', config).then((value) => {
      this.telefonoAgente = value.data.principal.celular;
    });
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

/* .v-card:not(.on-hover) {
  opacity: 0.6;
} */

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
  font-size: 12px;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  font-size: 12px;
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}

.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}

.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}

.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
</style>
