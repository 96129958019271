import { mainAxios } from "@/mainAxios";

export default {
    namespaced: true,
    state: {
        productos: [],
        polizas: [],
        nuevasPolizas: [],
        beneficiarios: [],
        porcentaje: 0
    }, mutations: {
        setPolizas(state, payload) {
            state.polizas = payload
        }, setNuevasPolizas(state, payload) {
            state.nuevasPolizas = payload
        }, setProducto(state, payload) {
            state.productos = payload
        }, setBeneficiarios(state, payload) {
            state.beneficiarios = payload
        }, setPorcentaje(state, payload) {
            state.porcentaje = payload
        }
    }, actions: {
        async obtenerProducto({ commit }) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken
                }
            }
            let { data } = await mainAxios.get('/v1/productos/listaProductos', config);
            let listProductos = []
            data.map(element => {
                listProductos.push({
                    id: element.id,
                    nombre: element.nombre,
                    titulo: `${element.ramo.toUpperCase()} / ${element.nombre.toUpperCase()} / ${element.nombreAseguradora.toUpperCase()}`,
                    ramo: element.ramo,
                    compania: element.nombreAseguradora.toUpperCase(),
                    fechaCreacion: element.fechaCreacion ? element.fechaCreacion.slice(0, 10) : null,
                    fechaModificacion: element.fechaModificacion ? element.fechaModificacion : 'No Disponible',
                    check: false
                })
            });
            commit("setProducto", listProductos)
        },
        async getPolizas({ commit }, payload) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken
                }
            }
            let { data } = await mainAxios.get(`v1/bradescard/emisiones/${payload}/0`, config)
            let listPolizas = []
            data.map(item => {
                listPolizas.push({
                    "uuid": item.uuid,
                    "motivoCancelacion": item.motivoCancelacion,
                    "statusNumber": item.status,
                    "id": item.id,
                    "cliente": item.nombre,
                    "poliza": item.poliza,
                    "status": (item.status == 0
                        ? "Nueva" : item.status == 1
                            ? "Emitida Pendente de Pago" : item.status == 2
                                ? "Pagada Parcial" : item.status == 3
                                    ? "Pagada Total" : item.status == 4
                                        ? "Canceladas" : item.status == 5
                                            ? "Finalizada" : item.status == 6
                                                ? "Renovada" : item.status == 8
                                                    ? "Emitido el certificado" : ""

                    ),
                    "agenteId": item.agenteId ? item.agenteId : 'No Disponible',
                    "productoId": item.productoId ? item.productoId : 'No Disponible',
                });
            });
            commit('setPolizas', listPolizas);
        },
        async getNuevasPolizas({ commit, state }, payload) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken
                }
            }
            let { data } = await mainAxios.get(`v1/bradescard/emisiones/${payload}/1`, config)
            let listPolizas = []
            data.map(item => {
                let nombre = state.productos.find(element => element.id == item.productoId) ? state.productos.find(element => element.id == item.productoId) : 'No disponible' ;
                
                listPolizas.push({
                    'descripcion': `${item.id} - ${item.productoId == 'No Disponible' ? 'No Disponible' : (nombre.nombre ? nombre.nombre : 'No disponible')} `,
                    "uuid": item.uuid,
                    "id": item.id,
                    "cliente": item.nombre,
                    "poliza": item.poliza,
                    "status": (item.status == 0
                        ? "Nueva" : item.status == 1
                            ? "Emitida Pendente de Pago" : item.status == 2
                                ? "Pagada Parcial" : item.status == 3
                                    ? "Pagada Total" : item.status == 4
                                        ? "Canceladas" : item.status == 5
                                            ? "Finalizada" : item.status == 6
                                                ? "Renovada" : item.status == 8
                                                    ? "Emitido el certificado" : ""
                    ),
                    "agenteId": item.agenteId ? item.agenteId : 'No Disponible',
                    "productoId": item.productoId ? item.productoId : 'No Disponible',
                });
            });
            commit('setNuevasPolizas', listPolizas);
        },
        async getBeneficiarios({ commit }, payload) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken
                }
            }
            let porcentaje = 0;
            let { data } = await mainAxios.get(`v1/beneficiario/list/${payload}`, config);
            commit('setBeneficiarios', data)

            data.forEach(item => {
                porcentaje += parseFloat(item.porcentaje);
            });
            commit('setPorcentaje', porcentaje)
        }
    }
}