import { loadScript } from "vue-plugin-load-script";
import { mainAxios } from "@/mainAxios";

export default {
  data() {
    return {
      leadsMarcacion: [],
      intervalo: null,
      isChecking: false,
      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      roles: JSON.parse(localStorage.getItem("roles")),
    };
  },

  computed: {
    tieneRol() {
      return function(rol) {
        return !!this.roles.find((r) => r.rol == rol);
      };
    },
  },

  mounted() {
    console.log("Se checa status")
    loadScript(
      "https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js"
    )
      .then(() => {
        window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");
      })
      .catch(() => {
        console.error(
          "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
        );
        location.reload();
      });
    setTimeout(() => this.checkStatus(), 30000);
    this.intervalo = window.setInterval(() => {
      this.checkStatus();
    }, 15000);
  },
  
  beforeDestroy() {
    clearInterval(this.intervalo);
  },

  methods: {
    checkStatus() {
      if (
        (this.tieneRol("AGENTCC") || this.tieneRol("AGENTCCSPONSORS")) &&
        !this.isChecking
      ) {
        console.log("Checando status")
        window.bpspat.api.getState(this.statusChecked);
      }
    },

    statusChecked(state) {
      if (state.data.status == "ready") {
        this.getLeadsMarcacion();
      }
    },

    getLeadsMarcacion() {
      mainAxios
        .get("/v1/bradescard/asignacion", this.axiosConfig)
        .then((res) => {
          this.leadsMarcacion = res.data;
          if(this.leadsMarcacion.length > 0 ) 
            this.validarDisponibilidadLeads(this.leadsMarcacion)
        });
    },

    validarDisponibilidadLeads(listaLeads) {
      if (!listaLeads || !listaLeads.length) return;
      const leadDisponible = listaLeads.find((lead) => !!lead);
      this.verificarDisponibilidadLead(leadDisponible);
    },

    verificarDisponibilidadLead(lead) {
      this.$toast("Consultando disponibilidad del lead", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });

      const duracionAleatoria = Math.floor(Math.random() * 4000) + 1000;
      setTimeout(() => {
        this.isChecking = true;
        mainAxios
          .get(`/v1/bradescard/uuid/${lead.uuid}`, this.axiosConfig)
          .then(() => {
            //if (data.operadorId != null) this.avisoLeadEnMarcacion();
            this.cargarMarcacionLead(lead);
          });
      }, duracionAleatoria);
    },

    avisoLeadEnMarcacion() {
      this.$toast.warning("Lead en marcacion", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.isChecking = false;
    },

    cargarMarcacionLead(lead) {
      this.$router.push({
        name: "DetalleLeadBradescard",
        params: { uuid: lead.uuid },
        query: {
          marcacion: "automatica",
        },
      });
      location.reload()
    },
  },
};
