<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Solicitudes Pendientes de aprobación</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px">
      </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <v-select label="Canal" outlined style="max-width: 420px" class="mt-5" :items="canales" item-text="name"
            item-value="id" v-model="canal" v-show="canShow"></v-select>
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="consultar">Consultar</v-btn>
          <v-btn rounded color="primary" style="width:100%;margin-top:20px;" :disabled="bloquear">
            <download-csv :data="reportDownload" name="Reporte_Solicitudes_Pendientes_Aprobacion.csv">
              Descargar Información
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table :headers="columns" :items="solicitudes" item-key="name" class="elevation-1" :search="search"
            :custom-filter="filterOnlyCapsText">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>

            <template v-slot:item.uuid="{ item }">
              <v-btn @click="$router.push('/administrador/editar-emision/' + item.uuid)">
                Ver
              </v-btn>
            </template>

            <template v-slot:item.urlPoliza="{ item }">
              <v-btn :href="'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza">Descargar</v-btn>
            </template>
          </v-data-table>


        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem('roles')),
      agenteUuid: localStorage.getItem('agenteUuid'),
      loading: false,
      reportType: "lead",
      solicitudes: [],
      search: "",
      reportDownload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: 'AGENTES' },
        { id: 2, name: 'MASIVOS' },
        { id: 3, name: 'DIRECTOS' }
      ],

      columns: [
        { text: "No. Solicitud", value: "idSolicitud" },
        { text: "Nombre de la Solicitud", value: "nombreSolicitud" },
        { text: "Tipo", value: "tipo", },
        { text: "Estado", value: "status" },
        { text: "Proceso de Cotización", value: "procesoCotizacion" },
        { text: "Ramo", value: "ramo" },
        { text: "Producto", value: "producto" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Cliente", value: "cliente" },
        { text: "Agente", value: "agente" },
        { text: "Canal", value: "canal" },
        { text: "Ejecutivo", value: "ejecutivo" },
        { text: "Origen de la Solicitud", value: "origenSolicitud" },
        { text: "Fecha de Creación", value: "fechaCreacion" },
        { text: "SLA General", value: "slaGeneral" },
        { text: "Valor de la Oportunidad", value: "valorOportunidad" },
      ],
    };
  }, methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      this.$axios.post(`/v1/solicitud/${this.canal ? this.canal : 0}/3/${this.agenteUuid}`, fechas, config).then((value) => {
        this.reportDownload = []
        this.solicitudes = []
        console.log(value)
        if (value.data.length === 0) {
          alert('No existen registros!');
        }
        value.data.map(element => {
          this.solicitudes.push({
            idSolicitud: element.idSolicitud,
            nombreSolicitud: element.nombreSolicitud,
            // tipo: moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            tipo: element.tipo,
            status: element.status,
            procesoCotizacion: element.procesoCotizacion,
            ramo: element.ramo,
            producto: element.producto,
            aseguradora: element.nombreAseguradora,
            cliente: element.nombreCLiente,
            agente: element.nombreAgente,
            canal: element.canal,
            ejecutivo: element.nombreEjecutivo,
            origenSolicitud: element.origen,
            fechaCreacion: moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            slaGeneral: element.cumpleSLA == 0 ? 'No Cumple' : 'Cumple',
            valorOportunidad: element.valorOportunidad ? "$" + element.valorOportunidad : 'No Disponible',
          })

          this.reportDownload.push({
            "No. Solicitud": element.idSolicitud,
            "Nombre de la Solicitud": element.nombreSolicitud,
            "Tipo": element.tipo,
            "Estado": element.status,
            "Proceso de Cotización": element.procesoCotizacion,
            "Ramo": element.ramo,
            "Producto": element.producto,
            "Aseguradora": element.nombreAseguradora,
            "Cliente": element.nombreCLiente,
            "Agente": element.nombreAgente,
            "Canal": element.canal,
            "Ejecutivo": element.nombreEjecutivo,
            "Origen de la Solicitud": element.origen,
            "Detalle de la Solicitud": element.detalleSolicitud,
            "Fecha de Creación": moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            "Fecha de Información Completa": element.fechaInfoCompleta ? moment.utc(element.fechaInfoCompleta).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de Pendiente de Información": element.fechaPInfo ? moment.utc(element.fechaPInfo).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de En Trámite": element.fechaEnTramite ? moment.utc(element.fechaEnTramite).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de Cotización Tramitada": element.fechaCotizacion ? moment.utc(element.fechaCotizacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de Recotización": element.fechaRecotizacion ? moment.utc(element.fechaRecotizacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de Emisión en Proceso": element.fechaEmisionEnProceso ? moment.utc(element.fechaEmisionEnProceso).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de Finalización": element.fechaFinalizacion ? moment.utc(element.fechaFinalizacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Fecha de Cancelación": element.fechaCancelacion ? moment.utc(element.fechaCancelacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Valor de la Oportunidad": element.valorOportunidad ? "$" + element.valorOportunidad : 'No Disponible',
          })
          this.bloquear = false
        })
        this.block = false
      });
    },
    newEmision() {
      this.$router.push('/administrador/carga-emisiones')
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  }, computed: {
    blockBoton() {
      return (
        this.canal != null &&
        this.dates != ["", ""]
      )
    },
    canShow() {
      var canView = false;
      var roles = []
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = [
        "ADMIN",
        "DIRECTOR",
        "FINANZAS",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      })
      return canView;
    },
  }
};
</script>
