<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="10" style="padding-left: 30px">
          <h1>
            Usuarios
            {{ rol == "ADMINCC" || rol == "MANAGERCC" ? "Call Center" : "" }}
          </h1>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            @click="$router.push('/usuario/crear')"
            style="align-items: right"
            class="ml-11 mt-2"
            v-show="canViewCreate"
          >
            Crear
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="mb-10" style="padding: 30px">
      <v-data-table
        :headers="agentesTable"
        :items="report"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading="loading"
        loading-text="Cargando..."
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.sexo="{ item }">
          <div v-if="item.sexo == 1">Masculino</div>
          <div v-if="item.sexo == 2">Femenino</div>
        </template>

        <template v-slot:item.status="{ item }">
          <div v-if="item.status == -1">
            <v-chip color="red" dark>Baja</v-chip>
          </div>
          <div v-if="item.status == 0">
            <v-chip color="blue" dark>Nuevo</v-chip>
          </div>
          <div v-if="item.status == 1">
            <v-chip color="green" dark>Activo</v-chip>
          </div>
          <div v-if="item.status == 2">
            <v-chip color="orange" dark>Inactivo</v-chip>
          </div>
        </template>
        <template v-slot:item.uuid="{ item }">
          <v-icon small class="mr-2" @click="editItem(item.uuid)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: true,
      search: "",
      agentesTable: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombreCompleto",
        },
        { text: "Correo Electronico", value: "correo" },
        { text: "Fecha de ingreso", value: "fechaRegistro" },
        { text: "Rol", value: "roles" },
        { text: "Estatus", value: "status" },
        { text: "Ver Agente", value: "uuid" },
      ],
      report: [],
    };
  },

  mounted() {
    this.getAgent();
  },

  computed: {
    canViewCreate() {
      let canView = false;
      let roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "ADMINCC"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentRol() {
      let roles = [];
      this.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },

  methods: {
    getAgent() {
      const rolesVistaAdminCC = [
        "AGENTCC",
        "MANAGERCC",
        "MESACONTROLASIST",
        "OPERACIONES",
        "OPERACIONESASIST",
        "OPERADOR",
        "OPERADORASIST",
      ];
      const existeEnVistaAdminCC = (rolId) =>
        rolesVistaAdminCC.some((r) => r == rolId);
      console.log(this.currentRol);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/agente/list`, config).then((response) => {
        this.loading = false;
        response.data.map((item) => {
          if (this.currentRol == "ADMIN") {
            this.pushItem(item);
          } else if (
            this.currentRol == "ADMINCC" &&
            item.roles.length &&
            existeEnVistaAdminCC(item.roles[0].rol)
          ) {
            this.pushItem(item);
          }
        });
        this.report = this.agentes;
      });
    },

    currentItemRol(item) {
      let roles = [];
      item.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },

    pushItem(item) {
      this.agentes.push({
        uuid: item.uuid,
        id: item.id,
        nombreCompleto: item.nombreCompleto
          ? item.nombreCompleto
          : "No Disponible",
        correo: item.correo ? item.correo : "No Disponible",
        fechaRegistro: item.fechaRegistro
          ? item.fechaRegistro
          : "No Disponible",
        status: item.status,
        roles: item.rol ? item.rol : "No Disponible",
      });
    },

    editItem(item) {
      this.$router.push(`/usuario/ver-micuenta/${item}`);
    },

    getCurrentRole(roles) {
      let currentRole = "";
      roles.forEach((element) => {
        currentRole += element.rol + " ";
      });
      return currentRole;
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
  },
};
</script>
<style scoped></style>
