<template>
  <div>
    <v-card>
      <v-toolbar dense dark flat color="#133B7D">
        <v-toolbar-title>
          <span>Resultados de búsqueda</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :items="solicitudes.solicitudes"
          :headers="headers"
          :server-items-length="solicitudes.total"
          no-data-text="Sin solicitudes para mostrar"
          loading-text="Buscando solicitudes..."
          :loading="loading"
          :footer-props="{
            'items-per-page-all-text': 'Todos',
            'items-per-page-text': 'Elementos por página',
          }"
          @update:page="pagina = $event"
          @update:items-per-page="elementosPagina = $event"
          :items-per-page="elementosPagina"

        >
          <template v-slot:item.status="{ item }">
            <v-chip
              close-icon="mdi-delete"
              filter
              :color="getColorChip(item.statusId)"
            >
              <span style="color: white;">{{ item.status }} </span>
            </v-chip>
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item, false)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
    <solicitudDialogForm
      :show="showCreateModal"
      @close="showCreateModal = false"
      :emisionId="emisionId"
      :onlyRead="onlyRead"
      :route="route"
    ></solicitudDialogForm>
  </div>
</template>

<script>
import solicitudDialogForm from "@/components/solicitudes/solicitudDialogForm.vue";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    solicitudes: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    solicitudDialogForm,
  },
  data() {
    return {
      headers: [
        {
          text: "No. Solicitud",
          align: "center",
          value: "id",
        },
        {
          text: "TItulo",
          align: "center",
          value: "titulo",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Fecha de Creación",
          align: "center",
          value: "fechaCreacion",
        },
        {
          text: "Tipo",
          align: "center",
          value: "tipo",
        },
        {
          text: "Estatus",
          align: "center",
          value: "status",
        },
        {
          text: "Origen",
          align: "center",
          value: "origen",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
      ],
      showCreateModal:false,
      route:'',
      emisionId:'',
      onlyRead:false,
      elementosPagina:10,
      pagina:1,

    };
  },
  watch: {
    pagina(v) {
      if(v)
        this.$emit('paginacionModificada', {
          'pagina': v,
          'registrosPagina': this.elementosPagina > 0 ? this.elementosPagina : this.solicitudes.total,
        })
    },

    elementosPagina(v) {
      if(v)
        this.$emit('paginacionModificada', {
          'pagina': this.pagina,
          'registrosPagina': v > 0 ? v : this.solicitudes.total,
        })
    },
  },
  methods: {
    changeRoute(item) {
      this.$router.push(`/solicitud/${item.id}`);
    },

    getColorChip(statusId) {
      switch (statusId) {
        case 1:
          return "green";
        case 3:
          return "blue";
        default:
          return "orange";
      }
    },
    fetchData2({ page, itemsPerPage }) {
      this.$emit('paginacionModificada', {
        'pagina': page,
        'registrosPagina': itemsPerPage > 0 ? itemsPerPage : this.solicitudes.total,
      })
    },
    perPage(e){
      console.log("pagina", e)
    }
  }
};
</script>
