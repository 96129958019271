<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte de Emisiones vida</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker
            style="width: 100%"
            v-model="dates"
            range
          ></v-date-picker>
          <v-btn
            rounded
            color="primary"
            style="width: 100%"
            dark
            @click="consultar"
            >Consultar</v-btn
          >
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
          >
            <download-csv
              :data="reportCompleteDowload"
              name="Reporte_Emision_vida.csv"
            >
              Descargar Información
            </download-csv>
          </v-btn>
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
            v-show="
              currentRol == 'ADMIN' ||
                currentRol == 'AUDITOR' ||
                currentRol == 'MANAGERCC' ||
                currentRol == 'ADMINCC'
            "
          >
            <download-csv
              :data="reportDownloadIt"
              name="Reporte_Emision_vida_ti.csv"
            >
              Descargar Informe TI
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner
                :md-diameter="100"
                :md-stroke="10"
                md-mode="indeterminate"
              />
            </div>
          </div>

          <v-data-table
            :headers="columns"
            :items="leads"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
              ></v-text-field>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      leads: [],
      search: "",
      reportDownloadIt: [],
      reportCompleteDowload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: "AGENTES" },
        { id: 2, name: "MASIVOS" },
        { id: 3, name: "DIRECTOS" },
      ],

      columns: [
        { text: "Poliza", value: "polizaInterna" },
        { text: "Cliente", value: "cliente" },
        { text: "Fecha inicio vigencia", value: "fecha_inicio_vigencia" },
        { text: "Fecha fin vigencia", value: "fecha_fin_vigencia" },
        { text: "Tipo de contratante", value: "tipo_contratante" },
        { text: "Producto", value: "producto" },
        { text: "Plan", value: "plan" },
        { text: "Prima neta", value: "prima_neta" },
        { text: "Ejecutivo CC", value: "ejecutivo" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    async consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      var inicioUTC = null;
      var finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      let query = {
        fechas: [inicioUTC, finUTC],
        aseguradora_id: 7,
        producto_aseg: "Vida Sura",
      };

      this.reportDownloadIt = [];
      this.leads = [];

      let { data } = await this.$axios.post(
        "v1/bradescard/emisiones/productoAseg",
        query,
        config
      );
      data.map((element) => {
        let jsonFormat = JSON.parse(element.request_json);
        this.leads.push({
          uuid: element.uuid,
          polizaInterna: element.polizaInterna
            ? element.polizaInterna
            : "No disponible",
          cliente:
            (element.nombre ? element.nombre : " ") +
            " " +
            (element.apellido_paterno ? element.apellido_paterno : " ") +
            " " +
            (element.apellido_materno ? element.apellido_materno : " "),
          fecha_inicio_vigencia: element.inicio
            ? moment
                .utc(element.inicio)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          fecha_fin_vigencia: element.fin
            ? moment
                .utc(element.fin)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          tipo_contratante: element.tipo_contratanteE
            ? element.tipo_contratanteE
            : "No disponible",
          producto: element.producto ? element.producto : "No disponible",
          plan: element.etiqueta_aseguradora
            ? element.etiqueta_aseguradora
            : "No disponible",
          prima_neta: element.prima_neta
            ? this.toCurrency(element.prima_neta)
            : "No disponible",
          ejecutivo: element.ejecutivo ? element.ejecutivo : "No disponible",
        });

        this.reportCompleteDowload.push({
          Certificado: element.certificado
            ? element.certificado
            : "No disponible",
          "Póliza Aseguradora": element.poliza_aseguradora
            ? element.poliza_aseguradora
            : "No disponible",
          "Póliza ID Interna": element.polizaInterna
            ? element.polizaInterna
            : "No disponible",
          "Estatus de la Póliza": element.status
            ? element.status == 0
              ? "Nueva"
              : element.status == 1
              ? "Emitida Pendente de Pago"
              : element.status == 2
              ? "Pagada Parcial"
              : element.status == 3
              ? "Pagada Total"
              : element.status == 4
              ? "Renovada"
              : element.status == 5
              ? "Finalizada"
              : element.status == 6
              ? "Cancelada"
              : element.status == 8
              ? "Emitido el certificado"
              : "Cancelada"
            : "No disponible",
          Cliente:
            (element.nombre ? element.nombre : " ") +
            " " +
            (element.apellido_paterno ? element.apellido_paterno : " ") +
            " " +
            (element.apellido_materno ? element.apellido_materno : " "),
          "Fecha de cancelacion": element.fechaCancelacion
            ? moment.utc(element.fechaCancelacion).format("DD/MM/YYYY")
            : "No Disponible",
          "Motivo cancelacion": element.motivoCancelacion
            ? element.motivoCancelacion
            : "No Disponible",
          "Nombre de Producto": element.producto
            ? element.producto
            : "No disponible",
          Ramo: element.ramo ? element.ramo : "No disponible",
          Aseguradora: element.aseguradora
            ? element.aseguradora
            : "No disponible",
          "Nueva/Renovacion": element.tipo_poliza
            ? element.tipo_poliza
            : "No disponible",
          "Inicio de Vigencia": element.inicio
            ? moment
                .utc(element.inicio)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          "Fin de Vigencia": element.fin
            ? moment
                .utc(element.fin)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          "Periodicidad de Pago": "Mensual",
          "Número de Cuotas": element.anios ? element.anios * 12 : "No tiene",
          "Monto de la Póliza": element.montoPoliza
            ? element.montoPoliza
            : "No tiene",
          Años: element.anios ? element.anios : "No tiene",
          "Forma de Pago": element.forma_Pago
            ? element.forma_Pago == 1
              ? "TC Bradescard"
              : element.forma_Pago == 2
              ? "Tarjeta de Débito"
              : element.forma_Pago == 3
              ? "Depósito en banco"
              : element.forma_Pago == 4
              ? "Transferencia"
              : element.forma_Pago == 5
              ? "Cheque"
              : element.forma_Pago == 6
              ? "Efectivo"
              : element.forma_Pago == 7
              ? "Domiciliación"
              : element.forma_Pago == 8
              ? "Cargo único"
              : "Cancelada"
            : "No disponible",
          "Fecha primer pago": element.fecha_primer_pago
            ? moment
                .utc(element.fecha_primer_pago)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          Ejecutivo: element.ejecutivo ? element.ejecutivo : "No disponible",
          "Tipo de Validacion": element.tipoValidacion || "No disponible",
        });

        this.reportDownloadIt.push({
          "Apellido Paterno": element.apellido_paterno
            ? element.apellido_paterno
            : "",
          "Apellido Manerno": element.apellido_materno
            ? element.apellido_materno
            : "",
          "Nombre 1": element.nombre ? element.nombre : "",
          "Nombre 2": element.nombre2 ? element.nombre2 : "",
          RFC: element.rfc ? element.rfc : "",
          Edad: element.edad ? element.edad : "",
          Corte: element.corte ? element.corte : "",
          //Termina campos del lead

          //Comienza campos de poliza
          "ID Registro": element.polizaInterna ? element.polizaInterna : "",
          Producto: element.producto ? element.producto : "",
          Plan: element.etiqueta_aseguradora
            ? element.etiqueta_aseguradora
            : "",
          "Prima Mensual": element.primer_pago ? element.primer_pago : "",
          Telefono: element.telefono_contacto ? element.telefono_contacto : "",
          "Estatus de la Póliza": element.status
            ? element.status == 0
              ? "Nueva"
              : element.status == 1
              ? "Alta"
              : element.status == 2
              ? "Alta"
              : element.status == 3
              ? "Alta"
              : element.status == 4
              ? "Baja"
              : element.status == 5
              ? "Baja"
              : element.status == 6
              ? "Baja"
              : element.status == 7
              ? "Baja"
              : element.status == 8
              ? "Emitido el certificado"
              : ""
            : "Cancelada",
          "ID emisión (Certificado)": element.certificado
            ? element.certificado
            : "",
          Póliza: element.poliza_aseguradora ? element.poliza_aseguradora : "",
          "Nueva/Renovacion": element.tipo_poliza
            ? element.tipo_poliza
            : "No disponible",
          "Fecha de venta": element.fecha_emision ? element.fecha_emision : "",
          "Tipo de Endoso": "",
          "Nota de Endoso": "",
          "Forma de Pago": element.forma_Pago
            ? element.forma_Pago == 1
              ? "TC Bradescard"
              : element.forma_Pago == 2
              ? "Tarjeta de Débito"
              : element.forma_Pago == 3
              ? "Depósito en banco"
              : element.forma_Pago == 4
              ? "Transferencia"
              : element.forma_Pago == 5
              ? "Cheque"
              : element.forma_Pago == 6
              ? "Efectivo"
              : element.forma_Pago == 7
              ? "Domiciliación"
              : element.forma_Pago == 8
              ? "Cargo único"
              : "Cancelada"
            : "No disponible",
          "Fecha de inicio de vigencia": element.inicio
            ? moment.utc(element.inicio).format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          "Apellido Paterno poliza": element.apellido_paternoE
            ? element.apellido_paternoE
            : "",
          "Apellido Materno poliza": element.apellido_maternoE
            ? element.apellido_maternoE
            : "",
          "Nombre 1 poliza": element.nombreE ? element.nombreE : "",
          "Nombre 2 poliza": element.nombreE2 ? element.nombreE2 : "",
          "Fecha de nacimiento": element.fecha_nacimiento
            ? moment.utc(element.fecha_nacimiento).format("DD/MM/YYYY HH:mm:ss")
            : "No Disponible",
          "RFC poliza": element.rfcE ? element.rfcE : "",
          "Sexo poliza": element.genero ? element.genero : "",
          "Estado Civil": jsonFormat.estado_civil
            ? jsonFormat.estado_civil
            : "",
          Email: element.correo_contacto ? element.correo_contacto : "",
          "Tipo de Cliente": element.tipo_cliente ? element.tipo_cliente : "",
          //Termina campos de poliza
          //Comienza logica para asignar los campos de beneficiario
          "Tipo de Cliente Beneficiario 1": "Beneficiario",
          "Nombre del Beneficiario Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].nombre +
                " " +
                element.beneficiarios[0].apellidoPaterno +
                " " +
                element.beneficiarios[0].apellidoMaterno
              : ""
            : "",
          "Porcentaje Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].porcentaje
              : ""
            : "",
          "Parentesco Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].parentesco
              : ""
            : "",
          "RFC Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].rfc
              : ""
            : "",
          "Sexo Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].genero
              : ""
            : "",
          "Estado Civil Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].estadoCivil
              : ""
            : "",
          "Email Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].correo
              : ""
            : "",
          "Teléfono Beneficiario 1": element.beneficiarios
            ? element.beneficiarios[0]
              ? element.beneficiarios[0].telefono
              : ""
            : "",

          "Tipo de Cliente Beneficiario 2": "Beneficiario",
          "Nombre del Beneficiario Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].nombre +
                " " +
                element.beneficiarios[1].apellidoPaterno +
                " " +
                element.beneficiarios[1].apellidoMaterno
              : ""
            : "",
          "Porcentaje Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].porcentaje
              : ""
            : "",
          "Parentesco Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].parentesco
              : ""
            : "",
          "RFC Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].rfc
              : ""
            : "",
          "Sexo Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].genero
              : ""
            : "",
          "Estado Civil Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].estadoCivil
              : ""
            : "",
          "Email Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].correo
              : ""
            : "",
          "Teléfono Beneficiario 2": element.beneficiarios
            ? element.beneficiarios[1]
              ? element.beneficiarios[1].telefono
              : ""
            : "",

          "Tipo de Cliente Beneficiario 3": "Beneficiario",
          "Nombre del Beneficiario Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].nombre +
                " " +
                element.beneficiarios[2].apellidoPaterno +
                " " +
                element.beneficiarios[2].apellidoMaterno
              : ""
            : "",
          "Porcentaje Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].porcentaje
              : ""
            : "",
          "Parentesco Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].parentesco
              : ""
            : "",
          "RFC Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].rfc
              : ""
            : "",
          "Sexo Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].genero
              : ""
            : "",
          "Estado Civil Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].estadoCivil
              : ""
            : "",
          "Email Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].correo
              : ""
            : "",
          "Teléfono Beneficiario 3": element.beneficiarios
            ? element.beneficiarios[2]
              ? element.beneficiarios[2].telefono
              : ""
            : "",

          "Tipo de Cliente Beneficiario 4": "Beneficiario",
          "Nombre del Beneficiario Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].nombre +
                " " +
                element.beneficiarios[3].apellidoPaterno +
                " " +
                element.beneficiarios[3].apellidoMaterno
              : ""
            : "",
          "Porcentaje Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].porcentaje
              : ""
            : "",
          "Parentesco Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].parentesco
              : ""
            : "",
          "RFC Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].rfc
              : ""
            : "",
          "Sexo Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].genero
              : ""
            : "",
          "Estado Civil Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].estadoCivil
              : ""
            : "",
          "Email Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].correo
              : ""
            : "",
          "Teléfono Beneficiario 4": element.beneficiarios
            ? element.beneficiarios[3]
              ? element.beneficiarios[3].telefono
              : ""
            : "",

          "Tipo de Cliente Beneficiario 5": "Beneficiario",
          "Nombre del Beneficiario Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].nombre +
                " " +
                element.beneficiarios[4].apellidoPaterno +
                " " +
                element.beneficiarios[4].apellidoMaterno
              : ""
            : "",
          "Porcentaje Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].porcentaje
              : ""
            : "",
          "Parentesco Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].parentesco
              : ""
            : "",
          "RFC Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].rfc
              : ""
            : "",
          "Sexo Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].genero
              : ""
            : "",
          "Estado Civil Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].estadoCivil
              : ""
            : "",
          "Email Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].correo
              : ""
            : "",
          "Teléfono Beneficiario 5": element.beneficiarios
            ? element.beneficiarios[4]
              ? element.beneficiarios[4].telefono
              : ""
            : "",
        });
        this.bloquear = false;
      });
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
    canShow() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "DIRECTOR", "FINANZAS"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentRol() {
      let roles = [];
      this.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },
};
</script>
