<template>
    <v-dialog v-model="dialog" max-width="750px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="text-h5">Creación de Documento / Nota</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Nombre" v-model="nombre"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-checkbox label="¿Enviar por correo electrónico?" v-model="enviarPorCorreo"></v-checkbox>
                        </v-col>

                        <v-col cols="6">
                            <v-select label="Tipo" :items="tipoItems" v-model="tipoSelected" required></v-select>
                        </v-col>

                        <v-col cols="6" v-if="tipoSelected == 'Documento'">
                            <v-select label="Tipo de Doocumento*" :items="tipoDocumentoItems"
                                v-model="tipoDocumentoSelected" required></v-select>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Descripción" v-model="descripcion" required></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-file-input v-model="archivos" color="primary" counter label="Adjuntos*" multiple
                                prepend-icon="mdi-paperclip" :show-size="1000">
                                <template v-slot:selection="{ index, text }">
                                    <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                                        {{ text }}
                                    </v-chip>

                                    <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                                        +{{ archivos.length - 2 }} File(s)
                                    </span>
                                </template>
                            </v-file-input>
                        </v-col>
                        <v-col cols="2" v-if="form.url">
                            <v-btn
                                text
                                :href="form.url"
                                small
                                color="primary"
                                class="mt-4"
                            >
                            <v-icon>mdi-download</v-icon>
                                Descargar
                            </v-btn>
                        </v-col>


                        <v-row>
                            <v-col cols="6">
                                <v-text-field label="Creado por" v-model="creadoPor"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Fecha de Creación" v-mask="'##/##/####'" v-model="fechaCreacion">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-row>

                    <small>*campo requerido</small>

                    <!-- ALERT MODAL -->
                    <v-col cols="auto">
                        <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="send">
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar color="success" dark>
                                        Confirmación de envío
                                    </v-toolbar>

                                    <v-card-text>
                                        <div class="text-h4" style="margin-top: 10px; ">
                                            Envio de documento exitoso!
                                        </div>
                                    </v-card-text>

                                    <v-card-actions class="justify-end">
                                        <v-btn text
                                            @click="dialog = false, $router.push(`/seguimiento-solicitud/${solicitud}`)">
                                            Cerrar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-col>
                    <!-- END ALERT MODAL -->
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    Cerrar
                </v-btn>
                <v-btn color="blue darken-1" text @click="enviarDocumentos">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'leads.detalles.DocumentosDialog',
    props: {
        active: {
            type:Boolean,
            default: false
        },
        opcion: {
            type: String,
            required: true
        },
        identificador: {
            type: Number,
            required: true
        },
        form: {
            default: null
        }
        
    },
    data() {
        return {
            send: false,
            dialog: false,
            tipoItems: [
                "Nota",
                "Documento"
            ],
            tipoDocumentoItems: [
                "Acta constitutiva",
                "Comprobante de Domicilio",
                "Comprobante Médico",
                "Contrato firmado del Agente",
                "Cotización",
                "Cédula del Agente",
                "Identificación",
                "Poder Notarial",
                "Póliza",
                "RC de Agente",
                "Tarjeta de Circulación",
                "Otro",
            ],
            nombre: null,
            enviarPorCorreo: null,
            tipoSelected: null,
            tipoDocumentoSelected: null,
            descripcion: null,
            archivos: [],
            creadoPor: null,
            fechaCreacion: null,
            modificadoPor: null,
            fechaUltimaModificacion: null
        }
    },
    watch: {
        dialog(val) {
            this.$emit('update:active', val)
        },
    },
    mounted(){
        this.dialog = this.active;
        if(this.form != null){
            this.nombre = this.form.nombre;
            this.tipoSelected = this.form.tipo;
            this.tipoDocumentoSelected = this.form.tipoDocumento;
            this.descripcion = this.form.descripcion;
            this.creadoPor = this.form.creadoPor;
            this.fechaCreacion = this.form.fecha;
            
        }
    },
    methods: {
        async enviarDocumentos() {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.agenteAccessToken}`,
                        'Content-Type': 'multipart/form-data'
                    },
                };
                const formData = new FormData();
                for (var i = 0; i < this.archivos.length; i++) {
                    let file = this.archivos[i];
                    formData.append('file', file);
                }
                formData.append('nota', this.descripcion ? this.descripcion : null);
                formData.append('tipo', this.tipoSelected ? this.tipoSelected : null);
                formData.append('nombre', this.nombre ? this.nombre : null);
                if (this.tipoDocumentoSelected) {
                    formData.append('tipo_documento', this.tipoDocumentoSelected);
                }

                let url = null;
                if(this.opcion == "lead"){
                    url = `v1/bradescard/documento/upload/${this.identificador}`;
                }else{
                    url = `v1/${this.opcion}/documento/upload/${this.identificador}`;
                }

                let data = await this.$axios.post(url, formData, config);
                if (data.status == 200) {
                    this.send = true;
                    setTimeout(() => (this.$router.back()), 2500);
                }
            } catch (exception) {
                console.error(exception);
            }
        }
    }
}
</script>