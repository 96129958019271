<template>
  <div>
    <v-card>
      <v-toolbar dense flat dark color="#133B7D">
        <v-toolbar-title>
          <span>Buscar por</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="formularioValido" lazy-validation>
          <v-row dense>
            <v-col v-if="error" cols="12">
              <v-alert type="error" dense>{{ error }}</v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="id"
                outlined
                dense
                label="No. Solicitud"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="tipo"
                label="Tipo solicitud"
                :items="tipoList"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="status"
                label="Estatus"
                :items="statuslist"
                item-value="id"
                item-text="nombre"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="operador_id"
                label="Operador"
                :items="operadores"
                item-text="nombreCompleto"
                item-value="id"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                v-model="menuFecha"
                :close-on-content-click="false"
                :max-width="300"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="datesSelectedString"
                    label="Fecha"
                    outlined
                    dense
                    v-on="on"
                    v-bind="attrs"
                    readonly
                    prepend-inner-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-card :width="300">
                  <v-date-picker
                    v-model="rango_fechas"
                    range
                    locale="es"
                    selected-items-text="2 seleccionadas"
                    :width="300"
                  ></v-date-picker>
                  <v-card-actions>
                    <v-btn small text @click="rango_fechas = []">Limpiar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn small text color="primary" @click="menuFecha = false"
                      >Listo</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="nombre"
                outlined
                dense
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="apellidoPaterno"
                outlined
                dense
                label="Apellido Paterno"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="apellidoMaterno"
                outlined
                dense
                label="Apellido Materno"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="rfc"
                outlined
                dense
                label="RFC"
                :rules="[$rules.rfc]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="tarjeta"
                label="Tarjeta"
                outlined
                dense
                type="number"
                :rules="[$rules.required]"
                counter="16"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="celular"
                outlined
                dense
                label="Teléfono"
                prepend-inner-icon="mdi-phone"
                counter="10"
                :rules="[$rules.numeric, longDigitValidation(10)]"
                :maxLength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="email"
                outlined
                dense
                label="Correo electrónico"
                prepend-inner-icon="mdi-email"
                :rules="[$rules.email]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="6">
            <v-btn color="primary" :loading="loading" @click="limpiarFiltros()">
              <v-icon small class="mr-1">mdi-close-circle</v-icon>
              <span>Limpiar filtros</span>
            </v-btn>
          </v-col>
          <v-col cols="6" style="text-align: end;">
            <v-btn
              color="primary"
              :disabled="!formularioValido"
              :loading="loading"
              @click="aplicarFiltros()"
            >
              <v-icon small class="mr-1">mdi-magnify</v-icon>
              <span>Aplicar filtros</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios";
import moment from "moment";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      celular: "",
      email: "",
      rfc: "",
      operador_id: "",
      status: "",
      id: "",
      formularioValido: true,
      error: "",
      longDigitValidation(number) {
        return (v) =>
          !v ||
          v.length == number ||
          "Debe proporcionar " + number + " digitos";
      },
      productos_aseguradora: [],
      statuslist: [],
      usuarios: [],
      rango_fechas: [],
      tipoList: ["Redención"],
      menuFecha: false,
      tipo: "",
      tarjeta:""
    };
  },
  computed: {
    operadores() {
      if (this.usuarios.length <= 0) return [];
      else
        return this.usuarios.filter(
          (usuario) => usuario.rol == "OPERADOR" || usuario.rol == "OPERADORASIST"
        );
    },
    datesSelectedString() {
      if (this.rango_fechas.length > 1)
        return `${this.formatDate(this.rango_fechas[0])} a ${this.formatDate(
          this.rango_fechas[1]
        )}`;
      return "";
    },
  },
  methods: {
    aplicarFiltros() {
      this.error = "";
      const filtros = {
        ...(this.id && { id: this.id }),
        ...(this.tipo && { tipo: this.tipo }),
        ...(this.status && { status: this.status }),
        ...(this.celular && { celular: this.celular }),
        ...(this.rfc && { rfc: this.rfc }),
        ...(this.apellidoPaterno && { apellido_paterno: this.apellidoPaterno }),
        ...(this.apellidoMaterno && { apellido_materno: this.apellidoMaterno }),
        ...(this.nombre && { nombre: this.nombre }),
        ...(this.correo_electronico && { correo_electronico: this.correo_electronico }),
        ...(this.rango_fechas && { fecha_creacion: this.formatedDates() }),
        ...(this.tarjeta && { tarjeta: this.tarjeta }),
      };
      if(filtros.fecha_creacion == null)
        delete filtros.fecha_creacion

      if (Object.keys(filtros).length < 1) {
        this.error = "Debes ingresar al menos un filtro";
        this.formularioValido = false;
        return;
      } else this.$emit("filtrosModificados", filtros);
    },
    getProductosAseguradora() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("v1/producto/productosAseguradora", config)
        .then((response) => {
          this.productos_aseguradora = response.data.data.productos;
        });
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.usuarios = []
      mainAxios.get("/v1/agente/list", config).then(({ data }) => {
        this.usuarios = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
          rol: e.rol,
        }));
      });
    },
    getStatus() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/status/lista", config).then((resp) => {
        this.statuslist = resp.data.data.statusSolicitud;
      });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    limpiarFiltros() {
      this.nombre = "";
      this.apellidoPaterno = "";
      this.apellidoMaterno = "";
      this.celular = "";
      this.email = "";
      this.rfc = "";
      this.operador_id = "";
      this.status = "";
      this.id = "";
      this.tipo = "";
      this.rango_fechas = [];
    },
    formatedDates() {
      let inicioUTC = null;
      let finUTC = null;
      if (this.rango_fechas.length > 0) {
        if (this.rango_fechas[1]) {
          inicioUTC = moment
            .utc(this.rango_fechas[0])
            .subtract(6, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.rango_fechas[1])
            .subtract(6, "hours")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment
            .utc(this.rango_fechas[0])
            .subtract(6, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.rango_fechas[0])
            .subtract(6, "hours")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
        return [
          inicioUTC,
          finUTC
        ]
      }
      return null
    },
  },
  mounted() {
    this.getProductosAseguradora();
    this.getAgentes();
    this.getStatus();
  },
};
</script>
