import { mainAxios } from "@/mainAxios";

export default {
    namespaced: true,
    state: {
        lead: {
            id: null,
            uuid: null,
            id_bradescard: null,
            medio: null,
            status: null,
            nombre: null,
            apellido_Paterno: null,
            apellido_Materno: null,
            rfc: null,
            edad: null,
            sexo: null, // se repitio
            estadoCivil: null,
            telefono: null,
            celular: null,
            correo: null,
            direccion: null,
            estado: null,
            ciudad: null,
            codigoPostal: null,
            producto: null,
            etiqueta: null,
            fourUD: null,
            corte: null,
            agenteId: null,
            operadorId: null,
            ultimaDisposicion: null,
            numLlamadas: null,
            campania: null,
            fecha: null,
            fechaModificacion: null,
            modificadoPor: null,
            creadoPor: null,
        },
    },
     mutations: {
        setLeads(state, payload) {
            state.lead = payload
        }
    }, actions: {
        async LoadDetalleLead({ commit, state }, payload) {
            console.log(state)
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken
                },
            }
            let { data } = await mainAxios.get(`v1/bradescard/uuid/${payload}`, config)
            commit('setLeads', data);
        }
    }
}