<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte Marcación Diario_Leads</h1>
        </v-col>
      </v-row>

      <v-row style="padding-left: 30px; margin-top: 25px"></v-row>

      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="consultar">Consultar</v-btn>
          <v-btn rounded color="primary" style="width:100%;margin-top:20px;" :disabled="bloquear">
            <download-csv :data="communReportDownload" name="Reporte_marcacion_diaria.csv">
              Descargar Información
            </download-csv>
          </v-btn>
          <v-btn rounded color="primary" style="width:100%;margin-top:20px;" :disabled="bloquear"
            v-show="currentRol == 'ADMIN'">
            <download-csv :data="reportDownload" name="Reporte_marcacion_diaria_TI.csv">
              Descargar Informe TI
            </download-csv>
          </v-btn>
        </v-col>

        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table :headers="columns" :items="leads" item-key="name" class="elevation-1" :search="search"
            :custom-filter="filterOnlyCapsText">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>

            <template v-slot:item.uuid="{ item }">
              <v-btn icon :to="{name: 'DetalleLeadBradescard', params:{uuid:item.uuid}}">
                <v-icon small class="mr-2">mdi-eye</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.urlPoliza="{ item }">
              <v-btn :href="'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza">Descargar</v-btn>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem('roles')),
      agenteUuid: localStorage.getItem('agenteUuid'),
      loading: false,
      reportType: "lead",
      leads: [],
      search: "",
      reportDownload: [],
      communReportDownload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: 'AGENTES' },
        { id: 2, name: 'MASIVOS' },
        { id: 3, name: 'DIRECTOS' }
      ],

      columns: [
        { text: "Id Bradescard", value: "id_bradescard" },
        { text: "Producto", value: "producto" },
        { text: "Producto Aseguradora", value: "productoAseguradora", },
        { text: "Etiqueta", value: "etiqueta" },
        { text: "4UD", value: "fourUD" },
        { text: "Corte", value: "corte" },
        { text: "Fecha asignacion", value: "fecha_asignacion" },
        { text: "Ejecutivo CC", value: "nombreAgente" },
        { text: "Ver", value: "uuid" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      }
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      this.$axios.post('v1/bradescard/reporte/basecalificada', fechas, config).then((value) => {
        this.reportDownload = []
        this.leads = []
        this.communReportDownload = []
        value.data.map(element => {
          this.leads.push({
            uuid: element.uuid,
            id_bradescard: element.id_bradescard,
            producto: element.producto,
            productoAseguradora: element.productoAseguradora,
            etiqueta: element.etiqueta,
            fourUD: element.fourUD ? element.fourUD : 'No disponible',
            corte: element.corte ? element.corte : 'No disponible',
            nombreAgente: element.nombreAgente ? element.nombreAgente : 'No disponible',
            fecha_asignacion: element.fecha_asignacion ? moment.utc(element.fecha_asignacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
          })

          this.communReportDownload.push({
            id_bradescard: element.id_bradescard ? element.id_bradescard : '',
            Medio: element.medio ? element.medio : 'No disponible',
            Producto: element.producto ? element.producto : 'No disponible',
            productoAseguradora: element.productoAseguradora ? element.productoAseguradora : 'No disponible',
            regimen: element.regimen ? element.regimen : 'No disponible',
            nombre: element.nombre ? element.nombre : 'No disponible',
            razon_social: 'null',
            RFC: element.rfc ? element.rfc : 'No disponible',
            Fecha_Nacimiento: element.fecha_nacimiento ? element.fecha_nacimiento : 'No disponible',
            Edad: element.edad ? element.edad : 'No disponible',
            Sexo: element.sexo ? element.sexo : 'No disponible',
            Estado_civil: element.estadoCivil ? element.estadoCivil : 'No disponible',
            Telefono: element.telefono ? element.telefono : 'No disponible',
            Celular: element.celular ? element.celular : 'No disponible',
            Correo: element.correo ? element.correo : 'No disponible',
            Direccion: element.direccion ? element.direccion : 'No disponible',
            Colonia: element.colonia ? element.colonia : 'No disponible',
            Estado: element.estado ? element.estado : 'No disponible',
            codigo_postal: element.codigoPostal ? element.codigoPostal : 'No disponible',
            Etiqueta: element.etiqueta ? element.etiqueta : 'No disponible',
            FourUD: element.fourUD ? element.fourUD : 'No disponible',
            corte: element.corte ? element.corte : 'No disponible',
            Fecha_asignacion: element.fecha_asignacion ? moment.utc(element.fecha_asignacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            Operador: element.nombreAgente ? element.nombreAgente : 'No disponible',
            Estatus: element.status ? element.status : 'No disponible',
            Ultima_disposicion: element.ultimaDisposicion ? element.ultimaDisposicion : 'No disponible',
            Fecha_ultima_llamada: element.fecha_ultima_llamada ? moment.utc(element.fecha_ultima_llamada).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            Intentos: element.intentos ? element.intentos : 'No disponible',
          })

          this.reportDownload.push({
            id_bradescard: element.id_bradescard,
            Estatus: element.status,
            Intentos: element.intentos,
            Calificación_llamada: element.ultimaDisposicion ? element.ultimaDisposicion : 'No disponible',
            fecha_asignacion: element.fecha_asignacion ? moment.utc(element.fecha_asignacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
          })
          this.bloquear = false
        })
      });
    },
    newEmision() {
      this.$router.push('/administrador/carga-emisiones')
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    blockBoton() {
      return (
        this.canal != null &&
        this.dates != ["", ""]
      )
    },
    canShow() {
      var canView = false;
      var roles = []
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = [
        "ADMIN",
        "DIRECTOR",
        "FINANZAS",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      })
      return canView;
    },
    currentRol() {
      let roles = []
      this.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol)
        }
      });

      if (roles.length > 0) {
        return roles[0]
      } else {
        return ""
      }
    }
  }
};
</script>
