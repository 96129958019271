<template>
  <div>
    <v-card outlined>
      <v-card-title >Polizas por Estatus</v-card-title>
      <div id="pie" class="chart"></div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
const eventBus = new Vue();
import { mainAxios } from "../../mainAxios";

export default {
  props: {
    solicitudes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        type: "pie",
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#385083",
                "#20407b",
                "#a74e18",
                "#f37926",
                "#a1a1a1",
                "#f9c302",
                "#589cdb",
                "#67a93b",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          legend: {
            position: "bottom",
          },
          interaction: {
            mode: "index",
            axis: "y",
          },
        },
        plugins: [
          {
            id: "myEventCatcher",
            beforeEvent(chart, args) {
              if (args.type == "click")
                eventBus.$emit(
                  "clicked",
                  chart.getElementAtEvent(args)[0]._model.label
                );
            },
          },
        ],
      },
      agenteUuid: localStorage.agenteUuid,
      agenteAccessToken: localStorage.getItem('agenteAccessToken'),
      payload:{
        status: ''
      }
    };
  },
  mounted() {
    this.loadPiechart()
    this.barChart.on('click', (params) => {
        this.getResultData(params.name);
    });
  },
  methods: {
    loadPiechart() {
      var options = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    // orient: 'vertical',
                    top: 'bottom',
                    type: 'scroll',

                },
                series: [
                    {
                        name: 'Estatus Polizas',
                        type: 'pie',
                        radius: '50%',
                        avoidLabelOverlap: false,
                        // stillShowZeroSum: false,
                        data: [
                            { value: 1048, name: 'Nueva' },
                            { value: 735, name: 'Emitida Pendente de Pago' },
                            { value: 580, name: 'Pagada Parcial' },
                            { value: 484, name: 'Pagada Total' },
                            { value: 300, name: 'Renovada' },
                            { value: 300, name: 'Finalizada' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            },
                        },
                        label: {
                            show: true,
                        },
                        labelLine: { // quita las lineas del pie
                            show: true
                        },
                    }
                ],
            }
            this.barChart = this.$chart.init(document.getElementById('pie'));
            this.barChart.setOption(options);
            var data = [];
            mainAxios.get(`v1/bradescard/dashboard/data/${this.agenteUuid}`, {
                headers: {
                    Authorization: `Bearer ${this.agenteAccessToken}`,
                }
            }).then((response) => {
                Object.keys(response.data.Emisiones).forEach(key => {
                    data.push({
                        name: key,
                        value: response.data.Emisiones[key]
                    });
                })
                options.series[0].data = data;
                this.barChart.setOption(options);
          });
    },
    getResultData(name){
      switch(name){
        case 'Nueva':
          this.payload.status = 1
        break;
        case 'Emitida Pendiente de Pago':
          this.payload.status = 2
        break;
        case 'Pagada Parcial':
          this.payload.status = 3
        break;
        case 'Pagada Total':
          this.payload.status = 4
        break;
        case 'Cancelada':
          this.payload.status = 5
        break;
        case 'Finalizada':
          this.payload.status = 6
        break;
        case 'Renovada':
          this.payload.status = 7
        break;
        case 'Emitido certificado':
          this.payload.status = 8
        break;
        default:
          this.payload.status = 0
        break; 
      }
      this.$emit('getPolizas', this.payload)
    }
  },
};
</script>