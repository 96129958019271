<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>
            {{
                'Reporte Abandono Leads TuSeguro'
            }}
          </h1>
        </v-col>
      </v-row>
      <v-row class="mb-15" style="padding: 30px">
        <v-col cols="12" lg="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <!--span v-if="this.dates.length == 0" class="mb-3">Selecciona una fecha o un rango de fechas</span -->
          <v-btn rounded color="primary" style="width: 100%" @click="generateReportLandigs"
            :disabled="this.dates.length == 0">Generar Reporte Landing</v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px;margin-bottom: 20px"
            :disabled="this.dates.length == 0">
            <download-csv :data="report" name="abandonoLanding.csv">
              {{ downloadText }}
            </download-csv>
          </v-btn>
          <v-btn rounded color="primary" style="width: 100%" :disabled="this.dates.length == 0"
            @click="generateReportGlobal">Generar Reporte Global</v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px" :disabled="this.dates.length == 0">
            <download-csv :data="reportDownloadGlobal" name="abandonoGlobal.csv">
              {{ downloadTextGlobal }}
            </download-csv>
          </v-btn>
        </v-col>

        <v-col v-if="reporteLandings" cols="12" lg="9">
          <div>
            <div v-if="loading == false" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="loading == null" style="text-align: center">
              <h2 clas="pb-4">Selecciona el rango de fechas</h2>
            </div>
          </div>

          <v-data-table v-if="loading" :headers="columns" :items="report" item-key="name" class="elevation-1"
            :search="search" :custom-filter="filterOnlyCapsText">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>
            <template v-if="loading" slot="body.append">
              <tr>
                <th>Total</th>
                <th>{{ totalAnio }}</th>
                <th>{{ totalMarca }}</th>
                <th>{{ totalSub }}</th>
                <th>{{ totalVersion }}</th>
                <th>{{ totalGenero }}</th>
                <th>{{ totalEstadoCivil }}</th>
                <th>{{ totalCp }}</th>
                <th>{{ totalDatosContacto }}</th>
                <th>{{ totalFecha }}</th>
                <th>{{ totalLead }}</th>
              </tr>
            </template>
          </v-data-table>

        </v-col>

        <v-col v-else cols="12" lg="9">
          <div>
            <div v-if="loadingGlobal == false" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="loadingGlobal == null" style="text-align: center">
              <h2 clas="pb-4">Selecciona el rango de fechas</h2>
            </div>
          </div>

          <v-data-table v-if="loadingGlobal" :headers="columnsGlobal" :items="reportGlobal" item-key="name"
            class="elevation-1" :search="searchGlobal" :custom-filter="filterOnlyCapsText">
            <template v-slot:top>
              <v-text-field v-model="searchGlobal" label="Buscar" class="mx-4"></v-text-field>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require('moment-timezone');

export default {
  data() {
    return {
      roles: JSON.parse(localStorage.getItem('roles')),
      uuid: localStorage.getItem('agenteUuid'),
      reporteLandings: null,
      dates: [],
      downloadText: 'Descargar Reporte landing',
      downloading: false,
      loading: null,
      reportType: 'lead',
      report: [],
      reportDownload: [],

      search: '',
      columns: [
        {
          text: 'Landing',
          align: 'start',
          sortable: false,
          value: 'landing',
        },
        { text: 'Año', value: 'anio' },
        { text: 'Marca', value: 'marca' },
        { text: 'Submarca', value: 'submarca' },
        { text: 'Version', value: 'version' },
        { text: 'Genero', value: 'genero' },
        { text: 'Estado Civil', value: 'estadoCivil' },
        { text: 'Codigo postal', value: 'cp' },
        { text: 'Datos de contacto', value: 'datosContacto' },
        { text: 'Fecha contratacion', value: 'fecha' },
        { text: 'Lead', value: 'lead' },
      ],
      totalAnio: 0,
      totalMarca: 0,
      totalSub: 0,
      totalVersion: 0,
      totalNacimiento: 0,
      totalGenero: 0,
      totalEstadoCivil: 0,
      totalCp: 0,
      totalDatosContacto: 0,
      totalFecha: 0,
      totalLead: 0,

      downloadTextGlobal: 'Descargar Reporte Global',
      downloadingGlobal: false,
      loadingGlobal: null,
      reportGlobal: [],
      reportDownloadGlobal: [],

      searchGlobal: '',
      columnsGlobal: [
        { text: 'ID', value: 'id' },
        {
          text: 'UUID',
          align: 'start',
          sortable: false,
          value: 'uuid',
        },
        { text: 'Landing', value: 'landing' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Hora', value: 'hora' },
        { text: 'Año', value: 'anio' },
        { text: 'Marca', value: 'marca' },
        { text: 'Submarca', value: 'submarca' },
        { text: 'Vehiculo', value: 'vehiculo' },
        { text: 'Datos personales', value: 'antesContacto' },
        { text: 'Datos de contacto', value: 'contacto' },
        { text: 'Lead', value: 'lead' },
      ],
    };
  },

  computed: {
    admin() {
      let isAdminCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'ADMIN') {
          isAdminCotiza = true;
        }
      });
      return isAdminCotiza;
    },
    operador() {
      let isOperCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'OPERADOR') {
          isOperCotiza = true;
        }
      });
      return isOperCotiza;
    },
    operaciones() {
      let isOperacionesCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'OPERACIONES') {
          isOperacionesCotiza = true;
        }
      });
      return isOperacionesCotiza;
    },
    adminagent() {
      let isAdminAgentCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'ADMINAGENT') {
          isAdminAgentCotiza = true;
        }
      });
      return isAdminAgentCotiza;
    },
    manager() {
      let isManagerCotiza = false;
      console.log(localStorage.roles);
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGER') {
          isManagerCotiza = true;
        }
      });
      return isManagerCotiza;
    },
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz('UTC', true);
      return dateRes.tz('America/Mexico_City').format('DD-MM-YYYY HH:mm');
    },
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    generateReportLandigs() {
      this.reporteLandings = true
      let vm = this;
      vm.reportType = 'lead';
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      this.$axios
        .post('/v1/reportes/abandonoleads/tuseguro/lead', fechas, config)

        .then(response => {
          vm.loading = true;
          console.log('leads: ', response.data);
          vm.report = []
          vm.totalAnio = response.data[0].totalAnio
          vm.totalMarca = response.data[0].totalMarca
          vm.totalSub = response.data[0].totalSub
          vm.totalVersion = response.data[0].totalVersion
          vm.totalNacimiento = response.data[0].totalNacimiento
          vm.totalGenero = response.data[0].totalGenero
          vm.totalEstadoCivil = response.data[0].totalEstadoCivil
          vm.totalCp = response.data[0].totalCp
          vm.totalDatosContacto = response.data[0].totalDatosContacto
          vm.totalFecha = response.data[0].totalFecha
          vm.totalLead = response.data[0].totalLead

          response.data.map(element => {
            vm.report.push({
              'landing': element.landing,
              'anio': element.anio,
              'marca': element.marca,
              'submarca': element.submarca,
              'version': element.version,
              'genero': element.genero,
              'estadoCivil': element.estadoCivil,
              'cp': element.cp,
              'datosContacto': element.datosContacto,
              'fecha': element.fecha,
              'lead': element.lead,
            })
          })
          vm.reportDownload = this.report
        })
      vm.loading = false;
    },
    generateReportGlobal() {
      this.reporteLandings = false
      let vm = this;
      vm.reportType = 'lead';
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      this.$axios
        .post('/v1/reportes/abandonoleads', fechas, config)
        .then(response => {
          vm.loadingGlobal = true;
          vm.reportGlobal = []
          vm.reportDownloadGlobal = []
          response.data.map(element => {
            vm.reportGlobal.push({
              'id': element.id,
              'uuid': element.uuid,
              'landing': element.landing,
              'fecha': moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY'),
              'hora': moment.utc(element.fecha).subtract(6, 'hours').format('HH:mm:ss'),
              'anio': element.anio,
              'marca': element.marca,
              'submarca': element.submarca,
              'vehiculo': element.vehiculo,
              'antesContacto': element.antesContacto,
              'contacto': element.contacto,
              'lead': element.lead,
              'valores': element.valores
            })
          })
          response.data.map(element => {
            vm.reportDownloadGlobal.push({
              'Id': element.id,
              'Uuid': element.uuid,
              'Landing': element.landing,
              'Fecha': moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY'),
              'Hora': moment.utc(element.fecha).subtract(6, 'hours').format('HH:mm:ss'),
              'Año': element.anio,
              'Marca': element.marca,
              'Submarca': element.submarca,
              'Vehiculo': element.vehiculo,
              'Fecha Nacimiento': element.antesContacto,
              'Genero': element.antesContacto,
              'Estado Civil': element.antesContacto,
              'Codigo Postal': element.antesContacto,
              'Nombre': element.contacto,
              'Correo Electronico': element.contacto,
              'Número télefonico': element.contacto,
              'Lead': element.lead,
              'valores': element.valores
            })
          })
        })
      vm.loadingGlobal = false;
    },


    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
