import { mainAxios } from "@/mainAxios";
import { getDefaultHeaders } from "../../services/mainService";

export const crearTelefonoContacto = async (payload) => {
  try {
    const response = await mainAxios.post(
      "/v1/telefonoContacto/nuevo",
      payload,
      getDefaultHeaders()
    );

    return response.data.data;
  } catch (error) {
    if (error.response) throw new Error(error.response.data.message);
    else {
      console.error(error);
      throw new Error("Error inesperado");
    }
  }
};

export const obtenerTelefonosContacto = async (payload) => {
  try {
    const response = await mainAxios.post(
      "/v1/telefonoContacto/filtros",
      payload,
      getDefaultHeaders()
    );

    return response.data.data;
  } catch (error) {
    if (error.response) throw new Error(error.response.data.message);
    else {
      console.error(error);
      throw new Error("Error inesperado");
    }
  }
};
