import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../../services/mainService";

/**
 * Servicio para listar todos los club beneficios
 */
export const getBeneficiosList = async () => {
  try {
    const response = await mainAxios.get(
      `/v1/clubBeneficios/lista`,
      getDefaultHeaders()
    );

    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
