<template>
  <component v-if="view != null" :is="rolComponent[view].component"></component>
</template>

<script>
import ViewBradescard from "./admin/leads/index.vue";
import ViewAgenteBradescard from "./agentes/leads/index.vue";

export default {
  name: "bradescard.leads",
  data() {
    return {
      roles: JSON.parse(localStorage.getItem("roles")),
      view: null,
      rolComponent: {
        admin: {
          component: ViewBradescard,
        },
        agente: {
          component: ViewAgenteBradescard,
        },
        auditor: {
          component: () => import("./leads/DashboardLeads.vue"),
        },
      },
    };
  },
  beforeMount() {
    this.getRole();
  },
  methods: {
    getRole() {
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });

      const rolesAdmin = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "DIRECTOR",
        "FINANZAS",
        "BACKOFFICE"
      ];
      const rolesAgents = ["AGENTCC"];
      const rolesOperador = ["OPERADOR", "OPERACIONES"];
      roles.forEach((element) => {
        if (rolesAdmin.includes(element)) {
          this.view = "admin";
        }
        if (rolesAgents.includes(element)) {
          this.view = "agente";
        }
        if (rolesOperador.includes(element)) {
          this.$router.push("/bradescard/leads/dashboard");
        }
        if (element == "AUDITOR") {
          this.view = "auditor";
        }
      });
    },
  },
};
</script>

<style></style>
