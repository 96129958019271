<template>
    <v-card>
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">LLAMADAS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="open" v-if="false">
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-data-table
            :headers="columns"
            :items="llamadas"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
            :items-per-page="5"
        >
            <template v-slot:[`item.fecha`]="{ item }">
                    {{ formatDate(item) }}
            </template>
            <template v-slot:[`item.accion`]="{ item }">
                <v-icon small class="mr-2" @click="verLlamada(item)">
                    mdi-eye
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import { mapState } from 'vuex'
    import moment from 'moment';

    export default {
        name: 'leads.detalles.llamadas',
        data: function(){
            return{
    
                columns: [
                    { text: "Disposición", align: "start", sortable: false, value: "disposicion"},
                    { text: "Fecha y hora", value: "fecha" },
                    { text: "Agente", value: "nombreAgente" },
                    { text: "Ver", value: "accion" },
                ],
            }
        }, mounted(){
            this.getllamadas()
        }, computed: {
        ...mapState('llamadas', ['llamadas'])
    }, methods: {
            async getllamadas(){
                try{
                    this.$store.dispatch('llamadas/getLlamadas', this.$route.params.uuid);
                }catch(exception){
                    console.error(exception)
                }
            },
            formatDate(date){
                var hora = moment(date.fecha, 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hours')
                return hora.format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
</script>