<template>
  <v-row class="mb-15">
    <v-col cols="12">
      <div class="home mt-5">
        <div v-if="mostarListaLead">
          <lista-lead :editarLead="editLead" />
        </div>

        <div v-if="mostarListaLead == false">
          <v-container v-if="leadCompleto === true">
            <v-row align="center" justify="space-around">
              <v-col md="11">
                <h1>Lead: {{ uuid }}</h1>

                <span style="font-size:medium; font-family:Trebuchet MS;"></span>
              </v-col>
              <v-col md="1">
                <v-btn variant="primary" class="my-3" @click="regresarDetalleLead()">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="my-4">
              <v-col md="6">
                <div>
                  <h2 class="pb-4">Detalles del lead</h2>
                  <p>Lead: <b>{{ uuid }}</b></p>
                  <p>Nombre: <b>{{ otrolead ? otrolead.nombre : '' }}</b></p>
                  <p>Apellido Paterno: <b>{{ otrolead ? otrolead.apellidoPaterno : '' }}</b></p>
                  <p>Apellido Materno: <b>{{ otrolead ? otrolead.apellidoMaterno : '' }}</b></p>
                  <p>Medio: <b>{{ otrolead ? otrolead.campania : '' }}</b></p>
                  <p>Correo: <b>{{ otrolead ? otrolead.correo : '' }}</b></p>
                  <p>Teléfono: <b>{{ otrolead ? otrolead.telefono : '' }}</b></p>
                </div>
              </v-col>

              <v-col md="6">

                <v-card>
                  <v-card-title>
                    Registro de acciones
                  </v-card-title>
                  <v-data-table :headers="headersAcciones" :items="itemsAcciones">
                  </v-data-table>
                </v-card>
              </v-col>

            </v-row>

            <v-row>
              <v-col class="my-4">
                <h2>Actualización de datos</h2>
              </v-col>

              <ValidationObserver ref="observer">
                <v-form ref="forms" lazy-validation class="row" @submit="onSubmit" @reset="onReset">
                  <v-col md="4" v-for="(campo, index) in campos" :key="index + 'id'">

                    <ValidationProvider immediate rules="required" v-if="campo.requerido">
                      <div v-if="
                        campo.tipo == 'Currency' ||
                        campo.tipo == 'Calculated' ||
                        campo.tipo == 'Number' ||
                        campo.tipo == 'Percent'
                      " :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'" :label-for="campo.nombre"
                        slot-scope="{ valid, errors }">
                        <v-text-field :id="campo.nombre" :label="campo.etiqueta + ': *'"
                          v-model="$data.formulario[index + 1]" type="text"
                          :state="errors[0] ? false : (valid ? true : null)" required :placeholder="campo.etiqueta"
                          :rules="generalRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="
                        campo.tipo == 'Currency' ||
                        campo.tipo == 'Calculated' ||
                        campo.tipo == 'Number' ||
                        campo.tipo == 'Percent'
                      " :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'" :label-for="campo.nombre">
                        <v-text-field :id="campo.nombre" :label="campo.etiqueta + ':'"
                          v-model="$data.formulario[index + 1]" type="text" required :placeholder="campo.etiqueta">
                        </v-text-field>
                      </div>
                    </div>

                    <ValidationProvider immediate rules="required" v-if="campo.requerido">
                      <div v-if="campo.tipo == 'CodePostal'" :id="campo.nombre + 'Group'"
                        :label="campo.etiqueta + ': *'" :label-for="campo.nombre" slot-scope="{ valid, errors }">
                        <v-text-field :id="campo.nombre" :label="campo.etiqueta + ': *'"
                          v-model="$data.formulario[index + 1]" type="text"
                          :state="errors[0] ? false : (valid ? true : null)" required :placeholder="campo.etiqueta"
                          :rules="generalRules" v-mask="'#####'"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="campo.tipo == 'CodePostal'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-text-field :id="campo.nombre" :label="campo.etiqueta + ':'"
                          v-model="$data.formulario[index + 1]" type="text" required :placeholder="campo.etiqueta"
                          v-mask="'#####'"></v-text-field>
                      </div>
                    </div>

                    <ValidationProvider immediate rules="required" v-if="campo.requerido">
                      <div v-if="
                      campo.tipo == 'Text' ||
                      campo.tipo == 'Name'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                        :label-for="campo.nombre" slot-scope="{ valid, errors }">
                        <v-text-field v-model="$data.formulario[index + 1]" type="text" :label="campo.etiqueta + ': *'"
                          :state="errors[0] ? false : (valid ? true : null)" required :id="campo.nombre"
                          :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="
                      campo.tipo == 'Text' ||
                      campo.tipo == 'Name'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-text-field v-model="$data.formulario[index + 1]" type="text" required
                          :label="campo.etiqueta + ':'" :id="campo.nombre" :placeholder="campo.etiqueta"></v-text-field>
                      </div>
                    </div>

                    <ValidationProvider immediate rules="required" v-if="campo.requerido">
                      <div v-if="campo.tipo == 'Auto Number'" :id="campo.nombre + 'Group'"
                        :label="campo.etiqueta + ': *'" :label-for="campo.nombre" slot-scope="{ valid, errors }">
                        <v-text-field v-model="$data.formulario[index + 1]" type="text"
                          :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                          :id="campo.nombre" :placeholder="campo.etiqueta" :maxlength="maxCP"
                          @keypress="isNumber($event)" :rules="generalRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="campo.tipo == 'Auto Number'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-text-field v-model="$data.formulario[index + 1]" type="text" required
                          :label="campo.etiqueta + ':'" :id="campo.nombre" :placeholder="campo.etiqueta"
                          :maxlength="maxCP" @keypress="isNumber($event)"></v-text-field>
                      </div>
                    </div>

                    <ValidationProvider immediate rules="required" v-if="campo.tipo == 'Email' && campo.requerido">
                      <div :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'" :label-for="campo.nombre"
                        slot-scope="{ valid, errors }">
                        <v-text-field v-model="$data.formulario[index + 1]"
                          :state="errors[0] ? false : (valid ? true : null)" type="text" required
                          :label="campo.etiqueta + ': *'" :id="campo.nombre" :placeholder="campo.etiqueta"
                          :rules="emailRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider immediate rules="required"
                      v-if="campo.tipo == 'Email' && campo.requerido == false">
                      <div :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'" :label-for="campo.nombre"
                        slot-scope="{ valid, errors }">
                        <v-text-field v-model="$data.formulario[index + 1]"
                          :state="errors[0] ? false : (valid ? true : null)" type="text" required
                          :label="campo.etiqueta + ': *'" :id="campo.nombre" :placeholder="campo.etiqueta"
                          :rules="emailRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider immediate rules="required" v-if="campo.requerido">
                      <div v-if="campo.tipo == 'Phone'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                        :label-for="campo.nombre" slot-scope="{ valid, errors }">
                        <v-text-field v-model="$data.formulario[index + 1]" type="text"
                          :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                          :id="campo.nombre" :placeholder="campo.etiqueta" :maxlength="max" @keypress="isNumber($event)"
                          :rules="generalRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="campo.tipo == 'Phone'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-text-field v-model="$data.formulario[index + 1]" type="text" required
                          :label="campo.etiqueta + ':'" :id="campo.nombre" :placeholder="campo.etiqueta"
                          :maxlength="max" @keypress="isNumber($event)"></v-text-field>
                      </div>
                    </div>

                    <ValidationProvider immediate rules="required" v-if="campo.requerido">
                      <div v-if="campo.tipo == 'Date/Time' || campo.tipo == 'Date'" :id="campo.nombre + 'Group'"
                        :label="campo.etiqueta + ': *'" :label-for="campo.nombre" slot-scope="{ valid, errors }">
                        <v-text-field :id="campo.nombre" v-model="$data.formulario[index + 1]" type="text"
                          :label="campo.etiqueta + ': *'" :state="errors[0] ? false : (valid ? true : null)"
                          placeholder="YYYY-MM-DD" autocomplete="off" v-mask="'####-##-##'" required
                          :rules="generalRules"></v-text-field>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="campo.tipo == 'Date/Time' || campo.tipo == 'Date'" :id="campo.nombre + 'Group'"
                        :label="campo.etiqueta + ':'" :label-for="campo.nombre">
                        <v-text-field :id="campo.nombre" v-model="$data.formulario[index + 1]" type="text"
                          :label="campo.etiqueta + ':'" placeholder="YYYY-MM-DD" autocomplete="off"
                          v-mask="'####-##-##'"></v-text-field>
                      </div>
                    </div>

                    <div v-if="index + 1 == 3">
                      <div v-if="
                      campo.tipo == 'Picklist' ||
                      campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-select v-if="campo.catalogo" v-model="$data.formulario[index + 1]"
                          :items="campo.catalogo.valores" :label="campo.etiqueta" required item-text="valor"
                          item-value="valor"></v-select>

                      </div>
                    </div>

                    <div v-if="index + 1 == 13">
                      <div v-if="
                      campo.tipo == 'Picklist' ||
                      campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-select v-if="campo.catalogo" v-model="$data.formulario[index + 1]"
                          :items="campo.catalogo.valores" :label="campo.etiqueta" required item-text="valor"
                          item-value="valor"></v-select>

                      </div>
                    </div>

                    <ValidationProvider immediate rules="required" v-if="campo.requerido" v-slot="{ errors }">
                      <div v-if="index + 1 != 3 && index + 1 != 13">
                        <div v-if="
                        campo.tipo == 'Picklist' ||
                        campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                          :label-for="campo.nombre">
                          <v-select v-model="$data.formulario[index + 1]" :items="campo.catalogo.valores"
                            :label="campo.etiqueta" required item-text="valor" item-value="valor" :rules="generalRules">
                          </v-select>
                          <span v-if="errors[0]" class="noValidoTex">
                            El valor es obligatorio
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>

                    <div v-if="campo.requerido == false">
                      <div v-if="index + 1 != 3 && index + 1 != 13">
                        <div v-if="
                        campo.tipo == 'Picklist' ||
                        campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                          :label-for="campo.nombre">
                          <v-select v-model="$data.formulario[index + 1]" :items="campo.catalogo.valores"
                            :label="campo.etiqueta" required item-text="valor" item-value="valor"></v-select>
                        </div>
                      </div>
                    </div>

                    <div v-if="campo.tipo == 'Text Area'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-textarea :name="campo.nombre" :label="campo.etiqueta" :value="$data.formulario[index + 1]"
                        hint="Hint text"></v-textarea>
                    </div>

                    <div v-if="campo.tipo == 'Checkbox'" :id="campo.nombre + 'Group'" class="d-flex align-items-center">
                      <v-checkbox v-model="$data.formulario[index + 1]" :label="campo.etiqueta"></v-checkbox>

                    </div>

                  </v-col>
                </v-form>

                <v-col md="12" class="d-flex justify-content-end mt-4">
                  <v-btn class="mx-2" type="submit" variant="primary" @click="updateValor" :disabled="invalidLead()">
                    Guardar</v-btn>

                </v-col>
                <v-col md="12" class="d-flex justify-content-end mt-1">
                  <p style="color: red; font-size:12px">* Los campos son requeridos para activar el boton de guardado.
                  </p>
                </v-col>

              </ValidationObserver>
            </v-row>

            <h3 v-if="items.length < 1" class="mt-3 ">No hay oportunidades creadas para este lead</h3>

            <v-row>
              <v-col md="6" class="d-flex justify-content-start align-items-center my-3">
                <v-btn class="my-2 my-sm-0" variant="primary" @click="addUuid">Agregar oportunidad</v-btn>
              </v-col>
            </v-row>

            <v-card>
              <v-card-title>
                Tabla de Oportunidades
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table :headers="headers" :items="items">
                <template v-slot:item.details="{ item }">
                  <v-btn size="sm" @click="details(item.uuid, item.userCallCenter)" class="mr-2">Seleccionar</v-btn>
                </template>
              </v-data-table>
            </v-card>

          </v-container>

          <v-container v-else>
            <v-row>
              <v-col md="12" class="p-0">
                <h1>Oportunidad: {{ uuidOportunidades }}</h1>
              </v-col>
              <v-col class="p-0">
                <v-btn variant="primary" class="my-3" @click="regresarOportunidades()">
                  <v-icon>mdi-arrow-left</v-icon> Regresar
                </v-btn>
              </v-col>
            </v-row>

            <ValidationObserver ref="observer2" v-if="esVent == false">
              <v-form class="row" @submit="onSubmitOportunidades" @reset="onResetOportunidades" ref="form2">
                <v-col md="4" v-for="(campo, index) in camposOportunidades" :key="index + 'id'">

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="
                      campo.tipo == 'Currency' ||
                      campo.tipo == 'Calculated' ||
                      campo.tipo == 'Number' ||
                      campo.tipo == 'Percent'
                    " :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'" :label-for="campo.nombre"
                      slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="number"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="
                      campo.tipo == 'Percent'
                    " :id="campo.nombre + 'Group'"
                      :label="campo.etiqueta + `: <span style='color:red'>*</span>`" :label-for="campo.nombre"
                      slot-scope="{ valid, errors }" description="porcentaje">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :maxlength="maxPorcentaje" @keypress="isNumber($event)"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="
                      campo.tipo == 'Currency' ||
                      campo.tipo == 'Calculated' ||
                      campo.tipo == 'Number' ||
                      campo.tipo == 'Percent'
                    " :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'" :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="number"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta"></v-text-field>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Text'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                      :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Text'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta"></v-text-field>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Date/Time' || campo.tipo == 'Date'" :id="campo.nombre + 'Group'"
                      :label="campo.etiqueta + ': *'" :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :label="campo.etiqueta + ': *'" :state="errors[0] ? false : (valid ? true : null)"
                        placeholder="YYYY-MM-DD" autocomplete="off" v-mask="'####-##-##'" required:rules="generalRules">
                      </v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Date/Time' || campo.tipo == 'Date'" :id="campo.nombre + 'Group'"
                      :label="campo.etiqueta + ':'" :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :label="campo.etiqueta + ':'" placeholder="YYYY-MM-DD" autocomplete="off" v-mask="'####-##-##'">
                      </v-text-field>
                    </div>
                  </div>

                  <div v-if="index == 13">
                    <div v-if="
                    campo.tipo == 'Picklist' ||
                    campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-select v-if="campo.catalogo" v-model="$data.formularioOportunidades[index]"
                        :items="campo.catalogo.valores" :label="campo.etiqueta" required item-text="valor"
                        item-value="valor" :disabled="formulario[1] != 'Cerrado Perdido' && forzoso(index)"></v-select>
                      <!-- formulario[1] != 'Cerrado Perdido' || ($data.formularioOportunidades[index ] == $data.formularioOportunidade[2]) && $data.formularioOportunidades[17]+'@segurify.com' != userCallCenter -->
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido" v-slot="{ errors }">
                    <div v-if="index != 13">
                      <div v-if="
                      campo.tipo == 'Picklist' ||
                      campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                        :label-for="campo.nombre">
                        <v-select v-if="campo.catalogo" v-model="$data.formularioOportunidades[index]"
                          :items="campo.catalogo.valores" :label="campo.etiqueta" required
                          @change="$data.formularioOportunidades[0] ? ventaOportunidad() : ''" item-text="valor"
                          item-value="valor" :rules="generalRules"></v-select>

                        <span v-if="errors[0]" class="noValidoTex">
                          El valor es obligatorio
                        </span>
                      </div>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="index != 13">
                      <div v-if="
                      campo.tipo == 'Picklist' ||
                      campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-select v-if="campo.catalogo" v-model="$data.formularioOportunidades[index]"
                          :items="campo.catalogo.valores" :label="campo.etiqueta" required item-text="valor"
                          item-value="valor"></v-select>
                      </div>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Creado'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                      :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules" readonly></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Creado'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta" readonly></v-text-field>
                    </div>
                  </div>
                  <!-- formulario[1] != 'Cerrado Perdido' ||  -->
                  <div v-if="campo.tipo == 'Text Area'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                    :label-for="campo.nombre">
                    <v-textarea :name="campo.nombre" :label="campo.etiqueta"
                      :value="$data.formularioOportunidades[index]" hint="Hint text"></v-textarea>
                  </div>

                  <div v-if="campo.tipo == 'Checkbox'" :id="campo.nombre + 'Group'" class="d-flex align-items-center">
                    <v-checkbox v-model="$data.formularioOportunidades[index]" :label="campo.etiqueta"></v-checkbox>
                  </div>

                </v-col>
              </v-form>

              <v-col md="12" class="d-flex justify-content-end mt-4">
                <v-btn class="mx-2" type="submit" variant="primary" @click="updateValorOportunidades()">Guardar</v-btn>
                <v-btn class="mx-2" type="submit" variant="primary" @click="regresarOportunidades()">Regresar</v-btn>
              </v-col>
              <v-col md="12" class="d-flex justify-content-end mt-1">
                <p style="color: red; font-size:12px">* Los campos son requeridos para activar el boton de guardado.</p>
              </v-col>

            </ValidationObserver>

            <ValidationObserver ref="observer2" v-else>
              <v-form class="row" @submit="onSubmitOportunidades" @reset="onResetOportunidades" ref="form2">
                <v-col md="4" v-for="(campo, index) in camposOportunidades" :key="index + 'id'">

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="
                      campo.tipo == 'Currency' ||
                      campo.tipo == 'Calculated' ||
                      campo.tipo == 'Number' ||
                      campo.tipo == 'Percent'
                    " :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'" :label-for="campo.nombre"
                      slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="number"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="
                      campo.tipo == 'Percent'
                    " :id="campo.nombre + 'Group'"
                      :label="campo.etiqueta + `: <span style='color:red'>*</span>`" :label-for="campo.nombre"
                      slot-scope="{ valid, errors }" description="porcentaje">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :maxlength="maxPorcentaje" @keypress="isNumber($event)"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="
                      campo.tipo == 'Currency' ||
                      campo.tipo == 'Calculated' ||
                      campo.tipo == 'Number' ||
                      campo.tipo == 'Percent'
                    " :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'" :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="number"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta"></v-text-field>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Creado'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                      :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules" readonly></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Creado'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta" readonly></v-text-field>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Text'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                      :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules"></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Text'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta"></v-text-field>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Creado'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                      :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :state="errors[0] ? false : (valid ? true : null)" required :label="campo.etiqueta + ': *'"
                        :placeholder="campo.etiqueta" :rules="generalRules" readonly></v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Creado'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        required :label="campo.etiqueta + ':'" :placeholder="campo.etiqueta" readonly></v-text-field>
                    </div>
                  </div>

                  <ValidationProvider immediate rules="required" v-if="campo.requerido">
                    <div v-if="campo.tipo == 'Date/Time' || campo.tipo == 'Date'" :id="campo.nombre + 'Group'"
                      :label="campo.etiqueta + ': *'" :label-for="campo.nombre" slot-scope="{ valid, errors }">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :label="campo.etiqueta + ': *'" :state="errors[0] ? false : (valid ? true : null)"
                        placeholder="YYYY-MM-DD" autocomplete="off" v-mask="'####-##-##'" required:rules="generalRules">
                      </v-text-field>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="campo.tipo == 'Date/Time' || campo.tipo == 'Date'" :id="campo.nombre + 'Group'"
                      :label="campo.etiqueta + ':'" :label-for="campo.nombre">
                      <v-text-field :id="campo.nombre" v-model="$data.formularioOportunidades[index]" type="text"
                        :label="campo.etiqueta + ':'" placeholder="YYYY-MM-DD" autocomplete="off" v-mask="'####-##-##'">
                      </v-text-field>
                    </div>
                  </div>

                  <div v-if="index == 13">
                    <div v-if="
                    campo.tipo == 'Picklist' ||
                    campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                      :label-for="campo.nombre">
                      <v-select v-if="campo.catalogo" v-model="$data.formularioOportunidades[index]"
                        :items="campo.catalogo.valores" :label="campo.etiqueta" required item-text="valor"
                        item-value="valor" :disabled="formulario[1] != 'Cerrado Perdido' && forzoso(index)"></v-select>
                      <!-- formulario[1] != 'Cerrado Perdido' || ($data.formularioOportunidades[index ] == $data.formularioOportunidade[2]) && $data.formularioOportunidades[17]+'@segurify.com' != userCallCenter -->
                    </div>
                  </div>


                  <ValidationProvider immediate rules="required" v-if="campo.requerido" v-slot="{ errors }">
                    <div v-if="index != 13">
                      <div v-if="
                      campo.tipo == 'Picklist' ||
                      campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ': *'"
                        :label-for="campo.nombre">
                        <v-select v-if="campo.catalogo" v-model="$data.formularioOportunidades[index]"
                          :items="campo.catalogo.valores" :label="campo.etiqueta" required
                          @change="$data.formularioOportunidades[0] ? ventaOportunidad() : ''" item-text="valor"
                          item-value="valor" :rules="generalRules"></v-select>

                        <span v-if="errors[0]" class="noValidoTex">
                          El valor es obligatorio
                        </span>
                      </div>
                    </div>
                  </ValidationProvider>

                  <div v-if="campo.requerido == false">
                    <div v-if="index != 13">
                      <div v-if="
                      campo.tipo == 'Picklist' ||
                      campo.tipo == 'Select'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                        :label-for="campo.nombre">
                        <v-select v-if="campo.catalogo" v-model="$data.formularioOportunidades[index]"
                          :items="campo.catalogo.valores" :label="campo.etiqueta" required item-text="valor"
                          item-value="valor"></v-select>
                      </div>
                    </div>
                  </div>
                  <!-- formulario[1] != 'Cerrado Perdido' ||  -->
                  <div v-if="campo.tipo == 'Text Area'" :id="campo.nombre + 'Group'" :label="campo.etiqueta + ':'"
                    :label-for="campo.nombre">
                    <v-textarea :name="campo.nombre" :label="campo.etiqueta"
                      :value="$data.formularioOportunidades[index]" hint="Hint text"></v-textarea>
                  </div>

                  <div v-if="campo.tipo == 'Checkbox'" :id="campo.nombre + 'Group'" class="d-flex align-items-center">
                    <v-checkbox v-model="$data.formularioOportunidades[index]" :label="campo.etiqueta"></v-checkbox>
                  </div>

                </v-col>


                <!-- arcchivo pdf -->
                <v-col cols="12" md="4" v-if="urlPoliza == null">
                  <ValidationProvider immediate rules="required" v-slot="{ errors }">
                    <v-file-input counter required :rules="generalRules" label="Selecciona el archivo PDF" accept=".pdf"
                      v-model="files" truncate-length="23"></v-file-input>
                    <span v-if="errors[0]" class="noValidoTex">
                      El valor es obligatorio
                    </span>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="4" v-else>
                  <v-btn color="primary" :href="'https://segurify-data.s3.amazonaws.com/' + urlPoliza">
                    Descargar Poliza
                  </v-btn>
                </v-col>

              </v-form>

              <v-col md="12" class="d-flex justify-content-end mt-4">
                <v-btn class="mx-2" type="submit" variant="primary" :disabled="urlPoliza != null"
                  @click="updateValorOportunidades()">Guardar</v-btn>
                <v-btn class="mx-2" type="submit" variant="primary" @click="regresarOportunidades()">Regresar</v-btn>
                <p v-if="cargandoPoliza">Cargando poliza...</p>
                <p v-if="enviandoPoliza">Enviando Poliza...</p>
                <p v-if="polizaEnviada">La poliza se ha cargado exitosamente</p>
              </v-col>


              <v-col md="12" class="d-flex justify-content-end mt-1">
                <p style="color: red; font-size:12px">* Los campos son requeridos para activar el boton de guardado.</p>
              </v-col>

            </ValidationObserver>
          </v-container>
        </div>

        <v-dialog v-model="modal" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Actualización
            </v-card-title>

            <v-card-text>
              Información de Lead actualizada correctamente
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="modal = false">
                aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="modalError" width="500">
          <v-card>
            <v-card-title class="headline yellow lighten-2">
              Error
            </v-card-title>

            <v-card-text>
              Faltan campos obligatorios
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="modalError = false">
                aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="modalOportunidad" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Actualizacion
            </v-card-title>

            <v-card-text>
              Información de la oportunidad se ha actualizada correctamente
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="modalOportunidad = false">
                aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment-timezone';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ListaLead from './SearchLead.vue'

export default {
  name: 'OportunidadesSin',
  components: {
    ValidationObserver,
    ValidationProvider,
    'lista-lead': ListaLead,
  },

  data() {
    return {
      files: [],
      uuid: localStorage.getItem('agenteUuid'),
      usuarioMailPortal: localStorage.getItem('agenteMail'),
      esVent: false,
      roles: JSON.parse(localStorage.getItem('roles'))[0].rol,
      modal: false,
      modalOportunidad: false,
      modalError: false,
      leadCompleto: true,
      lead_uuid: null,
      lead_uuidAS:
        this.$route.params.origen == 'autos'
          ? this.$route.params.cotizacion
          : null,
      tabla: null,
      origen: null,
      otroLeadRes: null,
      usuario: null,
      userCallCenter: null,
      lastModificationUser: null,
      item: this.$route.params.interactionStepId,
      tipoCalifSeleccionado: '',
      lead: '',
      otrolead: null,
      leadInfo: '',
      acciones: '',
      disposition: null,
      reschedule: {
        hour: '',
        date: this.formatDate(new Date().toISOString().substr(0, 10)),
      },
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      menu2: false,

      notes: '',
      formulario: [],
      campos: '',
      addOportunity: '',
      max: 10,
      maxCP: 5,
      maxPorcentaje: 3,

      //search: '',
      headers: [
        {
          text: 'Uuid',
          align: 'start',
          sortable: false,
          value: 'uuid',
        },
        { text: 'Lead Uuid', value: 'leadUuid' },
        { text: 'Usuario Call Center', value: 'userCallCenter' },
        { text: 'Detalles', value: 'details' },
      ],
      items: [],

      headersAcciones: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'date',
        },
        { text: 'Accion', value: 'action' },
        { text: 'Disposicion', value: 'disposition' },
      ],
      itemsAcciones: [],

      emailRules: [
        v => !!v || 'E-mail es necesario',
        v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
      ],
      generalRules: [
        v => !!v || 'El valor es obligatorio',
      ],

      disposiciones: [
        // {
        //   disposicion: 'Agenda con cita',
        //   codigo: '8000',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        // {
        //   disposicion: 'Agenda con cita de validacion',
        //   codigo: '8001',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        // {
        //   disposicion: 'Agenda menor de edad (15 a 17 años)',
        //   codigo: '8002',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        {
          disposicion: 'Asegurado Proximo a Vencer',
          codigo: '8003',
          tipo: 'Contacto efectivo',
          requiereAgenda: true,
        },
        {
          disposicion: 'Aun No Compra El Auto',
          codigo: '8004',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Buzon de voz',
          codigo: '8005',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Contrato en Segurify',
          codigo: '8006',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        // {
        //   disposicion: 'Contrato Otro curso',
        //   codigo: '8007',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        {
          disposicion: 'Contrato Otro Seguro',
          codigo: '8008',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Cotizacion Agenda/Seguimiento',
          codigo: '8009',
          tipo: 'Contacto efectivo',
          requiereAgenda: true,
        },
        {
          disposicion: 'Desea producto diferente al ofertado',
          codigo: '8010',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        // {
        //   disposicion: 'Edad no permitida',
        //   codigo: '8011',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        {
          disposicion: 'Es Agente',
          codigo: '8012',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Es muy caro',
          codigo: '8013',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Insatisfecho Con Cobertura / Beneficios / Producto',
          codigo: '8014',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Lead Repetido',
          codigo: '8015',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Linea Ocupada',
          codigo: '8016',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Mala experiencia con la aseguradora',
          codigo: '8017',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        // {
        //   disposicion: 'No acepta por  maternidad',
        //   codigo: '8018',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        // {
        //   disposicion: 'No asegurable por limite de edad',
        //   codigo: '8019',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        // {
        //   disposicion: 'No asegurable por preexistencia',
        //   codigo: '8020',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        {
          disposicion: 'No contesta',
          codigo: '8021',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'No Desea Ofrecimiento',
          codigo: '8022',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'No ingreso sus datos',
          codigo: '8023',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'No se encuentra',
          codigo: '8024',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'No tiene dinero',
          codigo: '8025',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'No Tiene Tiempo',
          codigo: '8026',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Pensara Opciones',
          codigo: '8027',
          tipo: 'Contacto efectivo',
          requiereAgenda: true,
        },
        // {
        //   disposicion: 'Penso era un producto más barato no puede pagar',
        //   codigo: '8028',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: true,
        // },
        {
          disposicion: 'Prueba',
          codigo: '8029',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'SA muy baja',
          codigo: '8030',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Se corta llamada',
          codigo: '8031',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Se le hace caro el curso',
          codigo: '8032',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Solo Cotiza',
          codigo: '8033',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Telefono Invalido',
          codigo: '8034',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Venta',
          codigo: '8035',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Venta Allianz',
          codigo: '8036',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        // {
        //   disposicion: 'Venta BUPA',
        //   codigo: '8037',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        {
          disposicion: 'Venta Cruzada / Ahorro',
          codigo: '8038',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Venta Cruzada / Auto',
          codigo: '8039',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Venta Cruzada / GMM',
          codigo: '8040',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        // {
        //   disposicion: 'Venta GNP',
        //   codigo: '8041',
        //   tipo: 'Contacto efectivo',
        //   requiereAgenda: false,
        // },
        {
          disposicion: 'Telefono No Existe',
          codigo: '8042',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Telefono Equivocado',
          codigo: '8043',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Telefono Suspendido',
          codigo: '8044',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Telefono Fuera de Servicio',
          codigo: '8045',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Telefono Sin Extension',
          codigo: '8046',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'Linea solo para navegar en internet',
          codigo: '8047',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },

        {
          disposicion: 'AIG - REUS/RPP- Bloqueado en telefonia',
          codigo: '8049',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG y SURA - Auto de salvamento',
          codigo: '8050',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG y SURA - Auto legalizado',
          codigo: '8051',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG - BIN Invalido',
          codigo: '8052',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG - Estado no vendible',
          codigo: '8053',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG - Edad del conductor',
          codigo: '8054',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG y SURA - Automovil no vendible (año)',
          codigo: '8055',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG y SURA - No tiene tdc o tdd',
          codigo: '8056',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG – Uso Comercial',
          codigo: '8057',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG - ya contrato con aig',
          codigo: '8058',
          tipo: 'Contacto efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG - TT corta la llamada',
          codigo: '8059',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
        {
          disposicion: 'AIG - Sin datos para emision',
          codigo: '8060',
          tipo: 'Contacto no efectivo',
          requiereAgenda: false,
        },
      ],
      //oportunidades
      oportunidadesCompleto: false,
      uuidOportunidades: null,
      oportunidad: [],
      formularioOportunidades: [],
      camposOportunidades: '',
      show: true,
      dataLeads: [],
      catForecastCategoryName: [],
      nuevoCampos: [],

      listaDeLead: '',
      mostarFormulario: false,
      mostarListaLead: true,

      // formulario si no hay cotización
      formularioCotizacion: {},
      estado_id: '',
      estados: [
        { id: 1, estadoRepublica: 'Aguascalientes' },
        { id: 2, estadoRepublica: 'Baja California' },
        { id: 3, estadoRepublica: 'Baja California Sur' },
        { id: 4, estadoRepublica: 'Campeche' },
        { id: 5, estadoRepublica: 'Coahuila de Zaragoza' },
        { id: 6, estadoRepublica: 'Colima' },
        { id: 7, estadoRepublica: 'Chiapas' },
        { id: 8, estadoRepublica: 'Chihuahua' },
        { id: 9, estadoRepublica: 'Ciudad de México' },
        { id: 10, estadoRepublica: 'Durango' },
        { id: 11, estadoRepublica: 'Guanajuato' },
        { id: 12, estadoRepublica: 'Guerrero' },
        { id: 13, estadoRepublica: 'Hidalgo' },
        { id: 14, estadoRepublica: 'Jalisco' },
        { id: 15, estadoRepublica: 'Estado de México' },
        { id: 16, estadoRepublica: 'Michoacán de Ocampo' },
        { id: 17, estadoRepublica: 'Morelos' },
        { id: 18, estadoRepublica: 'Nayarit' },
        { id: 19, estadoRepublica: 'Nuevo León' },
        { id: 20, estadoRepublica: 'Oaxaca' },
        { id: 21, estadoRepublica: 'Puebla' },
        { id: 22, estadoRepublica: 'Querétaro' },
        { id: 23, estadoRepublica: 'Quintana Roo' },
        { id: 24, estadoRepublica: 'San Luis Potosí' },
        { id: 25, estadoRepublica: 'Sinaloa' },
        { id: 26, estadoRepublica: 'Sonora' },
        { id: 27, estadoRepublica: 'Tabasco' },
        { id: 28, estadoRepublica: 'Tamaulipas' },
        { id: 29, estadoRepublica: 'Tlaxcala' },
        { id: 30, estadoRepublica: 'Veracruz' },
        { id: 31, estadoRepublica: 'Yucatán' },
        { id: 32, estadoRepublica: 'Zacatecas' },
        { id: 33, estadoRepublica: 'Desconocio' },
      ],
      urlPoliza: null,
      // plan_id: '',
      // planes: [
      //   {id: 1, plan: 'Amplia'},
      //   {id: 14, plan: 'Amplia plus'},
      //   {id: 3, plan: 'Limitada'},
      //   {id: 4, plan: 'RC'},
      // ],
      polizaEnviada: false,
      enviandoPoliza: false,
      cargandoPoliza: false,
      productList: [],
      emisionUuid: null,
    };
  },

  watch: {
    date(val) {
      console.log(val);
      this.reschedule.date = this.formatDate(this.date)
    },
  },

  mounted() {
    let externalScript = document.createElement('script');
    externalScript.setAttribute(
      'src',
      'https://segurify-oportunidades.netlify.app/js/servicepattern-sdk-v1.js'
    );
    document.head.appendChild(externalScript);
    window.addEventListener('load', () => {
      window.bpspat.api.init('https://aligev55.zw-callitonce.alestra.net.mx/');
    });
    this.setProductList()

  },

  methods: {
    editLead(uuid, tabla, origen, cotizacion) {
      this.tabla = tabla
      this.origen = origen
      if (cotizacion) {
        this.$axios
          .get(`/v1/oportunidades/cotizacion/${cotizacion}`, {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          })
          .then((response) => {
            this.otroLeadRes = response.data[0]
          })
      }
      this.loadLead(uuid);
      this.loadLeadtabla(uuid);
    },

    ventaOportunidad() {
      if (this.formularioOportunidades[0] == 'Venta') {
        this.esVent = null
        this.nuevoCampos = []
        this.camposOportunidades.map(element => {
          this.nuevoCampos.push({
            catalogo: element.catalogo,
            catalogoId: element.catalogoId,
            etiqueta: element.etiqueta,
            etiquetaIngles: element.etiquetaIngles,
            id: element.id,
            nombre: element.nombre,
            orden: element.orden,
            requerido: true,
            tipo: element.tipo,
            uuid: element.uuid,
          })
        })
        this.esVent = true
      } else {
        this.esVent = false
      }
    },

    forzoso(indice) {
      if (indice == 3) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      if (indice == 4) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      // if (indice == 8) {
      //   if (this.lastModificationUser != localStorage.agenteMail) {
      //     return true
      //   }
      // }
      if (indice == 10) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      if (indice == 12) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      if (indice == 14) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      if (indice == 16) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      if (indice == 17) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      if (indice == 18) {
        if (this.lastModificationUser != localStorage.agenteMail) {
          return true
        }
      }
      // if (indice == 19) {
      //   if (this.lastModificationUser != localStorage.agenteMail) {
      //     return true
      //   }
      // }
      // if (indice == 20) {
      //   if (this.lastModificationUser != localStorage.agenteMail) {
      //     return true
      //   }
      // }
      return false
    },

    myCompare(itemA, itemB, key) {
      if (key !== 'date') {
        // If field is not `date` we let b-table handle the sorting
        return false;
      } else {
        // Convert the string formatted date to a number that can be compared
        // Get the values being compared from the items
        let a = itemA[key];
        let b = itemB[key];
        // Split them into an array of parts (dd, mm, and yyyy)
        a = a.split('/');
        b = b.split('/');
        // convert string parts to numbers
        a =
          parseInt(a[2], 10) * 10000 +
          parseInt(a[1], 10) * 100 +
          parseInt(a[0]);
        b =
          parseInt(b[2], 10) * 10000 +
          parseInt(b[1], 10) * 100 +
          parseInt(b[0]);
        // Return the comparison result
        return a - b;
      }
    },
    parseJson(json) {
      return JSON.parse(json);
    },
    parseDate(date) {
      let fecha = moment(date);
      moment;
      fecha = fecha.tz('UTC', true);
      return fecha.format('DD/MM/YYYY, HH:mm:ss ');
    },

    // fechas
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDatePicker(date) {
      if (!date) return null

      const [month, day, year] = date.split('-')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    },

    sendDisp() {
      let urlLeadDetails = `/v1/accion/lead/crear/${this.tabla}`;
      let body = {
        leadUuid: this.uuid,
        disposicion: this.disposition.disposicion,
        codigo_disposicion: this.disposition.codigo,
      };
      let newreschedule = this.reschedule.date + ' ' + this.reschedule.hour;
      let fechaLeadGeneral =
        this.lead.leadDetalles.campania == 'autos'
          ? this.otrolead.fecha.split(' ')
          : this.otroLeadRes.fecha.split(' ');
      let fechaLead = fechaLeadGeneral[0];
      let fechaHora = fechaLeadGeneral[1].substr(0, 8);
      let aseguradora = null;
      if (this.lead.leadDetalles.campania != 'autos') {
        switch (this.otroLeadRes.aseguradoraId) {
          case 1:
            aseguradora = 'afirme';
            break;
          case 2:
            aseguradora = 'ana';
            break;
          case 3:
            aseguradora = 'gnp';
            break;
          case 4:
            aseguradora = 'primero';
            break;
          case 5:
            aseguradora = 'qualitas';
            break;
          case 6:
            aseguradora = 'multiva';
            break;
          case 7:
            aseguradora = 'rsa';
            break;
          case 8:
            aseguradora = 'aig';
            break;
          case 9:
            aseguradora = 'hdi';
            break;
          case 10:
            aseguradora = 'zurich';
            break;
          case 11:
            aseguradora = 'axa';
            break;
          default:
            aseguradora = 'segurify';
            break;
        }
      }

      this.$axios
        .post(urlLeadDetails, body, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        })
        .then((value) => {
          console.log(value);
          window.bpspat.api.completeInteractionWithDisp(
            this.item,
            this.disposition.codigo,
            this.notes
          );
        });

      if (this.disposition.requiereAgenda == true) {
        let urlReschedule = null;
        let reschedule = null;
        switch (this.lead.leadDetalles.campania) {
          case 'comparador':
            urlReschedule = `/v1/lead-comparador/agenda`;
            reschedule = {
              cotizacion: this.otroLeadRes.cotizacionGeneral,
              nombre: this.parseJson(this.otroLeadRes.usuarioJson).name,
              apellido: this.parseJson(this.otroLeadRes.usuarioJson).lastname,
              telefono: this.parseJson(this.otroLeadRes.usuarioJson).phone,
              aseguradora: aseguradora,
              fechaEstimadaContratacion: 'Inmediato',
              correo: this.parseJson(this.otroLeadRes.usuarioJson).email,
              genero: this.parseJson(this.otroLeadRes.vehiculoJson).generoConductor,
              fechaNacimiento:
                this.parseJson(this.otroLeadRes.usuarioJson)
                  .birthYear +
                '-' +
                this.parseJson(this.otroLeadRes.usuarioJson)
                  .birthMonth +
                '-' +
                this.parseJson(this.otroLeadRes.usuarioJson).birthDay,
              codigoPostal: this.parseJson(this.otroLeadRes.usuarioJson).cp,
              marca: this.parseJson(this.otroLeadRes.vehiculoJson).marca.marca,
              submarca: this.parseJson(this.otroLeadRes.vehiculoJson).submarca.id,
              version: this.otroLeadRes.identificadorVehiculo,
              modelo: this.parseJson(this.otroLeadRes.vehiculoJson).modelo.id,
              plan: this.otroLeadRes.plan,
              partner: this.otroLeadRes.partner,
              fecha_lead: fechaLead,
              hora_lead: fechaHora,
              rellamado: moment(newreschedule).format('DD.MM.YYYY HH:mm'),
              prioridad: 1,
              agente: this.usuario,
            };
            this.$axios
              .post(urlReschedule, reschedule, {
                headers: {
                  Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
              })
              .then((response) => {
                console.log(response);
              });
            break;
          case 'autocosmos':
            urlReschedule = `/v1/lead-autocosmos/agenda`;
            reschedule = {
              cotizacion: this.otroLeadRes.cotizacionGeneral,
              nombre: this.parseJson(this.otroLeadRes.usuarioJson).name,
              apellido: this.parseJson(this.otroLeadRes.usuarioJson).lastname,
              telefono: this.parseJson(this.otroLeadRes.usuarioJson).phone,
              aseguradora: aseguradora,
              fechaEstimadaContratacion: 'Inmediato',
              correo: this.parseJson(this.otroLeadRes.usuarioJson).email,
              genero: this.parseJson(this.otroLeadRes.vehiculoJson).generoConductor,
              fechaNacimiento:
                this.parseJson(this.otroLeadRes.usuarioJson)
                  .birthYear +
                '-' +
                this.parseJson(this.otroLeadRes.usuarioJson)
                  .birthMonth +
                '-' +
                this.parseJson(this.otroLeadRes.usuarioJson).birthDay,
              codigoPostal: this.parseJson(this.otroLeadRes.usuarioJson).cp,
              marca: this.parseJson(this.otroLeadRes.vehiculoJson).marca.marca,
              submarca: this.parseJson(this.otroLeadRes.vehiculoJson).submarca.id,
              version: this.otroLeadRes.identificadorVehiculo,
              modelo: this.parseJson(this.otroLeadRes.vehiculoJson).modelo.id,
              plan: this.otroLeadRes.plan,
              partner: this.otroLeadRes.partner,
              fecha_lead: fechaLead,
              hora_lead: fechaHora,
              rellamado: moment(newreschedule).format('DD.MM.YYYY HH:mm'),
              prioridad: 1,
              agente: this.usuario,
            };
            this.$axios
              .post(urlReschedule, reschedule, {
                headers: {
                  Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
              })
              .then((response) => {
                console.log(response);
              });
            break;
          case 'landing':
            urlReschedule = `/v1/lead-landing/agenda`;
            reschedule = {
              cotizacion: this.otroLeadRes.cotizacionGeneral,
              nombre: this.parseJson(this.otroLeadRes.usuarioJson).name,
              apellido: this.parseJson(this.otroLeadRes.usuarioJson).lastname,
              telefono: this.parseJson(this.otroLeadRes.usuarioJson).phone,
              aseguradora: aseguradora,
              fechaEstimadaContratacion: 'Inmediato',
              correo: this.parseJson(this.otroLeadRes.usuarioJson).email,
              genero: this.parseJson(this.otroLeadRes.vehiculoJson).generoConductor,
              fechaNacimiento:
                this.parseJson(this.otroLeadRes.usuarioJson)
                  .birthYear +
                '-' +
                this.parseJson(this.otroLeadRes.usuarioJson)
                  .birthMonth +
                '-' +
                this.parseJson(this.otroLeadRes.usuarioJson).birthDay,
              codigoPostal: this.parseJson(this.otroLeadRes.usuarioJson).cp,
              marca: this.parseJson(this.otroLeadRes.vehiculoJson).marca.marca,
              submarca: this.parseJson(this.otroLeadRes.vehiculoJson).submarca.id,
              version: this.otroLeadRes.identificadorVehiculo,
              modelo: this.parseJson(this.otroLeadRes.vehiculoJson).modelo.id,
              fecha_lead: fechaLead,
              hora_lead: fechaHora,
              rellamado: moment(newreschedule).format('DD.MM.YYYY HH:mm'),
              prioridad: 1,
              agente: this.usuario,
            };
            this.$axios
              .post(urlReschedule, reschedule, {
                headers: {
                  Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
              })
              .then((response) => {
                console.log(response);
              });
            break;
          case 'autos':
            urlReschedule = `/v1/lead-actual-sales/agenda`;
            reschedule = {
              uuid: this.uuid,
              medio: this.otrolead.medio,
              nombre: this.otrolead.nombre,
              apellidoPaterno: this.otrolead.apellidoPaterno,
              aperllidoMaterno: this.otrolead.aperllidoMaterno,
              fecha_nacimiento: this.otrolead.fechaNacimiento,
              sexo: this.otrolead.sexo,
              telefono: this.otrolead.telefono,
              correo: this.otrolead.correo,
              cp: this.otrolead.cp,
              modelo: this.otrolead.modelo,
              marca: this.otrolead.marca,
              submarca: this.otrolead.submarca,
              version: this.otrolead.version,
              fecha_contratacion: 'Inmediato',
              fecha_lead: fechaLead,
              hora_lead: fechaHora,
              rellamado: moment(newreschedule).format('DD.MM.YYYY HH:mm'),
              prioridad: 1,
              agente: this.usuario,
            };
            this.$axios
              .post(urlReschedule, reschedule, {
                headers: {
                  Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
              })
              .then((response) => {
                console.log(response);
              });
            break;
          default:
            break;
        }
      }
    },

    details(uuid, userCallCenter) {

      this.lastModificationUser = userCallCenter
      this.uuidOportunidades = uuid;
      this.leadCompleto = false;
      this.oportunidadesCompleto = true;
      this.loadInfoOportunidades();
    },

    loadLeadtabla(uuid) {
      let urlLead = `/v1/oportunidades/lead/${uuid}`;
      this.$axios
        .get(urlLead, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        })
        .then((response) => {
          this.items = [];
          response.data.map((element) => {
            this.items.push({
              uuid: element.uuid,
              leadUuid: element.uuidLead,
              userCallCenter: element.userCallCenter,
              detalles: '',
            });
          });
        });
    },

    cotizar() {
      let url = `https://segurify.com/operador/${this.$route.params.usuario}/${this.$route.params.cotizacion}/${this.$route.params.interactionStepId}`;
      window.open(url);
    },
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onFilteredAcciones(filteredItemsAcciones) {
      this.totalRowsAcciones = filteredItemsAcciones.length;
      this.currentPageAcciones = 1;
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = '';
      this.form.name = '';
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },

    onSubmitOportunidades(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onResetOportunidades(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = '';
      this.form.name = '';
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    regresarOportunidades() {
      this.leadCompleto = true;
      this.oportunidadesCompleto = false;
      this.loadLeadtabla(this.uuid);
    },
    regresarDetalleLead() {
      this.mostarListaLead = true;
    },
    onContextOportunidades(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      ctx.this.selected
        ?
        this.formatted = ctx.this.selected = ctx.selectedYMD
        :
        '' // The following will be an empty string until a valid date is entered
    },



    // ******* Lista de productos
    setProductList() {
      let productList = `/v1/producto/list`;
      console.log('productos dentro de endpoint');
      this.$axios
        .get(productList, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        })
        .then((response) => {
          response.data.map(element => {
            if (element.nombre == "MASIVO") {
              this.productList.push({
                id: element.id,
                aseguradoraId: element.aseguradoraId,
                ramo: element.ramo
              })
            }
          })
        })
    },

    //******** Cargar lead, validarlo y subirlo lead detalle
    loadLead(uuid) {
      this.mostarListaLead = false
      this.mostarFormulario = true
      let urlLeadDetails = `/v1/oportunidades/lead-detalles/${uuid}`;
      this.$axios
        .get(urlLeadDetails, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        })
        .then((response) => {
          this.mostarFormulario = true
          this.lead = response.data;
          this.otrolead = response.data.leadDetalles;
          this.uuid = response.data.leadDetalles.uuid;
          this.uuidCotizacion = response.data.leadDetalles.cotizacion
          this.leadInfo = response.data.lead;
          this.acciones = response.data.acciones;
          this.campos = response.data.campos;
          let valores = response.data.valores;
          this.userCallCenter = response.data.leadDetalles.userCallCenter ? response.data.leadDetalles.userCallCenter : this.$route.params.usuario + '@segurify.com'
          this.formulario[1] = "Autos"
          this.campos = this.campos.slice(2)
          if (valores.length > 0) {
            valores.map((element) => {
              element.campoLeadId == 62 ? this.formulario[1] = "Autos" : "Autos"
              element.campoLeadId == 78 ? this.formulario[2] = element.valor : ''
              element.campoLeadId == 79 ? this.formulario[3] = element.valor : ''
              element.campoLeadId == 63 ? this.formulario[4] = element.valor : ''
              element.campoLeadId == 73 ? this.formulario[5] = element.valor : ''
              element.campoLeadId == 77 ? this.formulario[6] = element.valor : ''
              element.campoLeadId == 64 ? this.formulario[7] = element.valor : ''
              element.campoLeadId == 65 ? this.formulario[8] = element.valor : ''
              element.campoLeadId == 67 ? this.formulario[9] = element.valor : ''
              element.campoLeadId == 69 ? this.formulario[10] = element.valor : ''
              element.campoLeadId == 70 ? this.formulario[11] = element.valor : ''
              element.campoLeadId == 68 ? this.formulario[12] = element.valor : ''
              element.campoLeadId == 71 ? this.formulario[13] = element.valor : ''
              element.campoLeadId == 72 ? this.formulario[14] = element.valor : ''
              element.campoLeadId == 74 ? this.formulario[15] = element.valor : ''
              element.campoLeadId == 75 ? this.formulario[16] = element.valor : ''
              element.campoLeadId == 61 ? this.formulario[17] = element.valor : ''
            });
          } else {
            this.campos.map((element) => {
              if (element.orden > 0) {
                element.campoLeadId == 62 ? this.formulario[1] = element.valor : "Autos"
                element.campoLeadId == 78 ? this.formulario[2] = element.valor : ''
                element.campoLeadId == 79 ? this.formulario[3] = element.valor : ''
                element.campoLeadId == 63 ? this.formulario[4] = element.valor : ''
                element.campoLeadId == 73 ? this.formulario[5] = element.valor : ''
                element.campoLeadId == 77 ? this.formulario[6] = element.valor : ''
                element.campoLeadId == 64 ? this.formulario[7] = element.valor : ''
                element.campoLeadId == 65 ? this.formulario[8] = element.valor : ''
                element.campoLeadId == 67 ? this.formulario[9] = element.valor : ''
                element.campoLeadId == 69 ? this.formulario[10] = element.valor : ''
                element.campoLeadId == 70 ? this.formulario[11] = element.valor : ''
                element.campoLeadId == 68 ? this.formulario[12] = element.valor : ''
                element.campoLeadId == 71 ? this.formulario[13] = element.valor : ''
                element.campoLeadId == 72 ? this.formulario[14] = element.valor : ''
                element.campoLeadId == 74 ? this.formulario[15] = element.valor : ''
                element.campoLeadId == 75 ? this.formulario[16] = element.valor : ''
                element.campoLeadId == 61 ? this.formulario[17] = element.valor : ''
              }
            });
          }
          this.acciones.map((element) => {
            this.itemsAcciones.push({
              date: this.parseDate(element.fecha),
              action: element.descripcion,
              disposition:
                element.disposicion == null ? 'N/A' : element.disposicion,
            });
          });
        });


      let urlLead = `/v1/oportunidades/lead/${uuid}`;
      this.$axios
        .get(urlLead, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        })
        .then((response) => {
          this.items = [];
          response.data.map((element) => {
            this.items.push({
              uuid: element.uuid,
              leadUuid: element.uuidLead,
              userCallCenter: element.userCallCenter,
              detalles: '',
            });
          });
        });
    },
    invalidLead() {
      let validado = 0
      this.formulario[1] ? this.formulario[1].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[2] ? this.formulario[2].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[4] ? this.formulario[4].length > 0 ? validado = validado + 1 : '' : ''
      //this.formulario[5] ? this.formulario[5].length > 0  ? validado = validado + 1 : '' : '' 
      this.formulario[6] ? this.formulario[6].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[7] ? this.formulario[7].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[8] ? this.formulario[8].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[9] ? this.formulario[9].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[10] ? this.formulario[10].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[11] ? this.formulario[11].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[13] ? this.formulario[13].length > 0 ? validado = validado + 1 : '' : ''
      this.formulario[17] ? this.formulario[17].length > 0 ? validado = validado + 1 : '' : ''
      if (validado == 11) {
        return false
      } else {
        return true
      }
    },
    updateValor() {
      let urlUpdateV = `/v1/lead/actualizar/${this.tabla}`;
      let valores = [];
      this.formulario.map((element, idx) => {
        valores.push({
          lead_uuid: this.uuid,
          campo_lead_id:
            idx == 1 ? 62 :
              idx == 2 ? 78 :
                idx == 3 ? 79 :
                  idx == 4 ? 63 :
                    idx == 5 ? 73 :
                      idx == 6 ? 77 :
                        idx == 7 ? 64 :
                          idx == 8 ? 65 :
                            idx == 9 ? 67 :
                              idx == 10 ? 69 :
                                idx == 11 ? 70 :
                                  idx == 12 ? 68 :
                                    idx == 13 ? 71 :
                                      idx == 14 ? 72 :
                                        idx == 15 ? 74 :
                                          idx == 16 ? 75 :
                                            idx == 17 ? 61 : '',
          valor: element,
          campania: this.lead.leadDetalles.campania,
          userCallCenter: this.userCallCenter,
        });
      });
      this.$axios
        .post(
          urlUpdateV,
          { valores: valores },
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        )
        .then((response) => {
          response.status == 200 ? this.modal = true : '';
          setTimeout(() => {
            this.modal = false
            //this.$router.push('/administrador/reporte-emisiones-offline')
          }, 2000);
        });
    },
    addUuid() {
      this.lastModificationUser = localStorage.agenteMail
      let urlLead = `/v1/oportunidad/crear`;
      this.$axios
        .post(
          urlLead,
          {
            lead_uuid: this.uuid,
            tabla: this.$route.params.tabla,
            userCallCenter: this.lastModificationUser,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        )
        .then((response) => {
          this.items = [];
          this.loadLead(this.uuid);
          this.uuidOportunidades = response.data.uuid;
          this.loadInfoOportunidades();
          this.leadCompleto = false;
          this.oportunidadesCompleto = true;
        });
    },
    //******** FIN Cargar lead, validarlo y subirlo lead detalle

    //******** Cargar Oportunidad, validarlo y subirlo 
    loadInfoOportunidades() {
      let urlForm = `/v1/oportunidad/${this.tabla}/${this.uuidOportunidades}`;
      this.$axios
        .get(urlForm, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        })
        .then((response) => {
          let valores = response.data.valores ? response.data.valores : '';
          this.formularioOportunidades = [];
          this.camposOportunidades = response.data.campos;
          this.oportunidad = response.data.oportunidad;
          this.formularioOportunidades[0] = 'En seguimiento'
          this.formularioOportunidades[3] = 'Autos'
          this.formularioOportunidades[3] = 'Autos'
          this.formularioOportunidades[13] = 'Nueva'
          this.formularioOportunidades[30] = this.usuarioMailPortal
          // ocultar para local mostrar para master
          //this.camposOportunidades =this.camposOportunidades.slice(2)
          valores.map((element) => {
            element.campoId == 25 ? this.formularioOportunidades[0] = element.valor : ''
            element.campoId == 71 ? this.formularioOportunidades[1] = element.valor : ''
            element.campoId == 4 ? this.formularioOportunidades[2] = element.valor : ''
            element.campoId == 27 ? this.formularioOportunidades[3] = element.valor : ''
            element.campoId == 45 ? this.formularioOportunidades[4] = element.valor : ''
            element.campoId == 46 ? this.formularioOportunidades[5] = element.valor : ''
            element.campoId == 26 ? this.formularioOportunidades[6] = element.valor : ''
            element.campoId == 33 ? this.formularioOportunidades[7] = element.valor : ''
            element.campoId == 34 ? this.formularioOportunidades[8] = element.valor : ''
            element.campoId == 40 ? this.formularioOportunidades[9] = element.valor : ''
            element.campoId == 75 ? this.formularioOportunidades[10] = element.valor : ''
            element.campoId == 76 ? this.formularioOportunidades[11] = element.valor : ''
            element.campoId == 28 ? this.formularioOportunidades[12] = element.valor : ''
            //element.campoId == 78 ? this.formularioOportunidades[13] = element.valor : 'Nueva'
            element.campoId == 49 ? this.formularioOportunidades[14] = element.valor : ''
            element.campoId == 47 ? this.formularioOportunidades[15] = element.valor : ''
            element.campoId == 48 ? this.formularioOportunidades[16] = element.valor : ''
            element.campoId == 50 ? this.formularioOportunidades[17] = element.valor : ''
            element.campoId == 74 ? this.formularioOportunidades[18] = element.valor : ''
            element.campoId == 41 ? this.formularioOportunidades[19] = element.valor : ''
            element.campoId == 42 ? this.formularioOportunidades[20] = element.valor : ''
            element.campoId == 35 ? this.formularioOportunidades[21] = element.valor : ''
            element.campoId == 36 ? this.formularioOportunidades[22] = element.valor : ''
            element.campoId == 39 ? this.formularioOportunidades[23] = element.valor : ''
            element.campoId == 43 ? this.formularioOportunidades[24] = element.valor : ''
            element.campoId == 30 ? this.formularioOportunidades[25] = element.valor : ''
            element.campoId == 31 ? this.formularioOportunidades[26] = element.valor : ''
            element.campoId == 29 ? this.formularioOportunidades[27] = element.valor : ''
            element.campoId == 37 ? this.formularioOportunidades[28] = element.valor : ''
            element.campoId == 38 ? this.formularioOportunidades[29] = element.valor : ''
            element.campoId == 5 ? this.formularioOportunidades[30] = this.usuarioMailPortal : this.usuarioMailPortal
            element.campoId == 77 ? this.formularioOportunidades[31] = element.valor : ''
            element.campoId == 3 ? this.formularioOportunidades[32] = element.valor : ''
            element.campoId == 79 ? this.formularioOportunidades[33] = element.valor : ''
            element.campoId == 80 ? this.formularioOportunidades[34] = element.valor : ''
            element.campoId == 81 ? this.formularioOportunidades[35] = element.valor : ''
          });

          this.ventaOportunidad()
          const config = {
            headers: {
              Authorization: "Bearer " + 'cba3bb78-144b-434f-bd04-77ec68f03d05',
            },
          };
          if (this.oportunidad.emisionUuid) {
            this.$axios.get(`/v1/agente/emision/${this.oportunidad.emisionUuid}`, config).then((response) => {
              this.urlPoliza = response.data.urlPoliza
            })
          }
          // const fecha = new Date();
          // const year = fecha.getFullYear();
          // const day = fecha.getDate();
          // const month = fecha.getMonth() + 1;
        });
    },
    updateValorOportunidades() {
      let urlUpdateV = '/v1/oportunidad/actualizar';
      let valor = [];

      if (this.formularioOportunidades[0] == 'Venta') {
        //let newDate = this.date.split('-')
        let vm = this;
        let fd = new FormData();
        // Emision offline

        let aseguradoraID = this.formularioOportunidades[6] == 'AFIRME' ? 1
          : this.formularioOportunidades[6] == 'ANA' ? 2
            : this.formularioOportunidades[6] == 'GNP' ? 3
              : this.formularioOportunidades[6] == 'PRIMERO' ? 4
                : this.formularioOportunidades[6] == 'QUALITAS' ? 5
                  : this.formularioOportunidades[6] == 'MULTIVA' ? 6
                    : this.formularioOportunidades[6] == 'SURA' ? 7
                      : this.formularioOportunidades[6] == 'AIG' ? 8
                        : this.formularioOportunidades[6] == 'HDI' ? 9
                          : this.formularioOportunidades[6] == 'ZURICH' ? 10
                            : this.formularioOportunidades[6] == 'AXA' ? 11
                              : this.formularioOportunidades[6] == 'BANORTE' ? 12
                                : ''

        let productoId = null
        this.productList.map(element => {
          if (aseguradoraID == element.aseguradoraId) {
            productoId = element.id
          }
        })

        let plan = this.formularioOportunidades[21] == 'Amplia' ? 1
          : this.formularioOportunidades[21] == 'Amplia Plus' ? 14
            : this.formularioOportunidades[21] == 'Limitada' ? 3
              : this.formularioOportunidades[21] == 'RC_reglamentaria' ? 4
                : ''

        let planesPago = this.formularioOportunidades[22] == 'Anual' ? 1
          : this.formularioOportunidades[22] == 'Semestral' ? 2
            : this.formularioOportunidades[22] == 'Trimestral' ? 3
              : 4

        let formasPago = this.formularioOportunidades[23] == 'Tarjeta de Crédito' ? 1
          : this.formularioOportunidades[23] == 'Tarjeta de Débito' ? 2
            : this.formularioOportunidades[23] == 'Deposito Bancario' ? 3
              : this.formularioOportunidades[23] == 'Transferencia' ? 4
                : this.formularioOportunidades[23] == 'Cheque' ? 5
                  : this.formularioOportunidades[23] == 'Efectivo' ? 6
                    : this.formularioOportunidades[23] == 'Domiciliación' ? 7 : ''

        console.log(this.formularioOportunidades[23]);
        console.log(formasPago);

        // Estatus Poliza
        let status = this.formularioOportunidades[24] == 'Nueva' ? 0
          : this.formularioOportunidades[24] == 'Emitida Pendente de Pago' ? 1
            : this.formularioOportunidades[24] == 'Pagada Parcial' ? 2
              : this.formularioOportunidades[24] == 'Pagada Total' ? 3
                : this.formularioOportunidades[24] == 'Renovada' ? 4
                  : this.formularioOportunidades[24] == 'Finalizada' ? 5
                    : 'Cancelada'
        // Estatus Cobro
        //let statusCobro = this.formularioOportunidades[15] == 'Pagada' ? 1  : 2

        let estadoId = this.formularioOportunidades[18] == 'Aguascalientes' ? 1
          : this.formularioOportunidades[18] == 'Baja California' ? 2
            : this.formularioOportunidades[18] == 'Baja California Sur' ? 3
              : this.formularioOportunidades[18] == 'Campeche' ? 4
                : this.formularioOportunidades[18] == 'Coahuila de Zaragoza' ? 5
                  : this.formularioOportunidades[18] == 'Colima' ? 6
                    : this.formularioOportunidades[18] == 'Chiapas' ? 7
                      : this.formularioOportunidades[18] == 'Chihuahua' ? 8
                        : this.formularioOportunidades[18] == 'Ciudad de México' ? 9
                          : this.formularioOportunidades[18] == 'Durango' ? 10
                            : this.formularioOportunidades[18] == 'Guanajuato' ? 11
                              : this.formularioOportunidades[18] == 'Guerrero' ? 12
                                : this.formularioOportunidades[18] == 'Hidalgo' ? 13
                                  : this.formularioOportunidades[18] == 'Jalisco' ? 14
                                    : this.formularioOportunidades[18] == 'Estado de México' ? 15
                                      : this.formularioOportunidades[18] == 'Michoacán de Ocampo' ? 16
                                        : this.formularioOportunidades[18] == 'Morelos' ? 17
                                          : this.formularioOportunidades[18] == 'Nayarit' ? 18
                                            : this.formularioOportunidades[18] == 'Nuevo León' ? 19
                                              : this.formularioOportunidades[18] == 'Oaxaca' ? 20
                                                : this.formularioOportunidades[18] == 'Puebla' ? 21
                                                  : this.formularioOportunidades[18] == 'Querétaro' ? 22
                                                    : this.formularioOportunidades[18] == 'Quintana Roo' ? 23
                                                      : this.formularioOportunidades[18] == 'San Luis Potosí' ? 24
                                                        : this.formularioOportunidades[18] == 'Sinaloa' ? 25
                                                          : this.formularioOportunidades[18] == 'Sonora' ? 26
                                                            : this.formularioOportunidades[18] == 'Tabasco' ? 27
                                                              : this.formularioOportunidades[18] == 'Tamaulipas' ? 28
                                                                : this.formularioOportunidades[18] == 'Tlaxcala' ? 29
                                                                  : this.formularioOportunidades[18] == 'Veracruz de Ignacio de la Llave' ? 30
                                                                    : this.formularioOportunidades[18] == 'Yucatán' ? 31
                                                                      : this.formularioOportunidades[18] == 'Zacatecas' ? 32
                                                                        : this.formularioOportunidades[18] == 'Desconocio' ? 33
                                                                          : ''


        let newRequest = {
          "campania": this.formularioOportunidades[4],
          "origen": this.formularioOportunidades[5],
          "aseguradora_id": aseguradoraID,

          "origen_auto": this.formularioOportunidades[7],
          "uso_auto": this.formularioOportunidades[8],

          "nombre": this.formularioOportunidades[9],
          "nombre_cliente": this.formularioOportunidades[9],
          "apellido_paterno": this.formularioOportunidades[10],
          "apellido_materno": this.formularioOportunidades[11],
          "tipo_persona": this.formularioOportunidades[12],
          "tipo_poliza": this.formularioOportunidades[13],

          "modelo": this.formularioOportunidades[14],
          "marca": this.formularioOportunidades[15],
          "submarca": this.formularioOportunidades[16],
          "version": this.formularioOportunidades[17],
          "vehiculo": `${this.formularioOportunidades[16]} ${this.formularioOportunidades[15]} ${this.formularioOportunidades[17]} ${this.formularioOportunidades[18]}`,

          "estado_id": parseInt(estadoId),
          "inicio": this.formularioOportunidades[19],
          "fin": this.formularioOportunidades[20],
          "plan_id": parseInt(plan),

          "pago_id": parseInt(planesPago),
          "forma_pago": parseInt(formasPago),
          "status": parseInt(status),
          "prima_neta": this.formularioOportunidades[25],
          "prima_total": this.formularioOportunidades[26],
          "poliza": this.formularioOportunidades[27],
          "primer_pago": this.formularioOportunidades[28],
          "primer_pago_poliza": this.formularioOportunidades[28],
          "subsecuente_pago": this.formularioOportunidades[29],
          "creado_por": this.formularioOportunidades[30],
          "rfc": this.formularioOportunidades[31],
          "fecha": this.formularioOportunidades[32],
          "placas": this.formularioOportunidades[33],
          "num_serie": this.formularioOportunidades[34],
          "num_motor": this.formularioOportunidades[35],

          "fecha_nacimiento": this.formulario[8],
          "genero": this.formulario[9],
          "telefono": this.formulario[10],
          "correo_electronico": this.formulario[11],
          "codigo_postal": this.formulario[13],

          "notas": "Registro desde portal segurify",
          "observaciones": "Registro desde portal segurify",
          "operador_id": `${localStorage.agenteMail}`,
          "agente_id": this.formularioOportunidades[4] == "TUSEGURO.COM" ? 641 : 700,
          "producto_id": parseInt(productoId),
          "status_cobro": parseInt(status),

          "cliente": null,
          "cotizacion": null,
          "detalle_auto": "",
          "ejecutivo_de_operaciones": `${localStorage.agenteMail}`,
          "fecha_cancelacion": null,
          "fecha_emision": null,
          "fecha_finalizacion": null,
          "fecha_primer_pago": null,
          "motivo_cancelacion": null,
          "motivo_finalizacion": null,
          "poliza_anterior": null,
          "poliza_cancelada": null,
          "poliza_finalizada": null,
          "ultima_modificacion": null,
          "url": "",
        }

        const configFile = {
          headers: {
            Authorization: "Bearer " + 'cba3bb78-144b-434f-bd04-77ec68f03d05',
            'Content-Type': 'multipart/form-data'
          }
        }
        fd.append('file', this.files);
        this.cargandoPoliza = true;
        this.$axios.post("/v1/emision/upload-file", fd, configFile).then((value) => {
          vm.cargandoPoliza = false;
          newRequest.url = value.data.url;
          // emision manual
          vm.enviandoPoliza = true;
          const config = {
            headers: {
              Authorization: "Bearer " + 'cba3bb78-144b-434f-bd04-77ec68f03d05',
            },
          };
          console.log('New request: ', newRequest);
          this.$axios.post("/v1/emision/manual", newRequest, config)
            .then((value) => {
              vm.enviandoPoliza = false;
              vm.polizaEnviada = true;
              this.emisionUuid = value.data.uuid
              this.formularioOportunidades.map((element, idx) => {
                let campoId = 0;
                idx == 0 ? campoId = 25 :
                  idx == 1 ? campoId = 71 :
                    idx == 2 ? campoId = 4 :
                      idx == 3 ? campoId = 27 :
                        idx == 4 ? campoId = 45 :
                          idx == 5 ? campoId = 46 :
                            idx == 6 ? campoId = 26 :
                              idx == 7 ? campoId = 33 :
                                idx == 8 ? campoId = 34 :
                                  idx == 9 ? campoId = 40 :
                                    idx == 10 ? campoId = 75 :
                                      idx == 11 ? campoId = 76 :
                                        idx == 12 ? campoId = 28 :
                                          idx == 13 ? campoId = 78 :
                                            idx == 14 ? campoId = 49 :
                                              idx == 15 ? campoId = 47 :
                                                idx == 16 ? campoId = 48 :
                                                  idx == 17 ? campoId = 50 :
                                                    idx == 18 ? campoId = 74 :
                                                      idx == 19 ? campoId = 41 :
                                                        idx == 20 ? campoId = 42 :
                                                          idx == 21 ? campoId = 35 :
                                                            idx == 22 ? campoId = 36 :
                                                              idx == 23 ? campoId = 39 :
                                                                idx == 24 ? campoId = 43 :
                                                                  idx == 25 ? campoId = 30 :
                                                                    idx == 26 ? campoId = 31 :
                                                                      idx == 27 ? campoId = 29 :
                                                                        idx == 28 ? campoId = 37 :
                                                                          idx == 29 ? campoId = 38 :
                                                                            idx == 30 ? campoId = 5 :
                                                                              idx == 31 ? campoId = 77 :
                                                                                idx == 32 ? campoId = 3 :
                                                                                  idx == 33 ? campoId = 79 :
                                                                                    idx == 34 ? campoId = 80 :
                                                                                      campoId = 81
                //console.log(`${idx}  ${campoId}  ${element}`);
                valor.push({
                  oportunidad_uuid: this.uuidOportunidades,
                  campo_id: campoId,
                  valor: element,
                  campania: this.lead.leadDetalles.campania,
                  userCallCenter: this.userCallCenter,
                });
              });
              this.$axios
                .post(
                  urlUpdateV,
                  { valores: valor, emisionUuid: this.emisionUuid, oportunidad_uuid: this.uuidOportunidades },
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.agenteAccessToken,
                    },
                  }
                )
                .then((response) => {
                  if (response.status == 200) {
                    this.modalOportunidad = true
                    this.leadCompleto = true;
                    this.oportunidadesCompleto = false;
                    this.loadLeadtabla(this.uuid);
                  }
                });
            })

        })
      }
      else {
        this.formularioOportunidades.map((element, idx) => {
          let campoId = 0;
          idx == 0 ? campoId = 25 :
            idx == 1 ? campoId = 71 :
              idx == 2 ? campoId = 4 :
                idx == 3 ? campoId = 27 :
                  idx == 4 ? campoId = 45 :
                    idx == 5 ? campoId = 46 :
                      idx == 6 ? campoId = 26 :
                        idx == 7 ? campoId = 33 :
                          idx == 8 ? campoId = 34 :
                            idx == 9 ? campoId = 40 :
                              idx == 10 ? campoId = 75 :
                                idx == 11 ? campoId = 76 :
                                  idx == 12 ? campoId = 28 :
                                    idx == 13 ? campoId = 78 :
                                      idx == 14 ? campoId = 49 :
                                        idx == 15 ? campoId = 47 :
                                          idx == 16 ? campoId = 48 :
                                            idx == 17 ? campoId = 50 :
                                              idx == 18 ? campoId = 74 :
                                                idx == 19 ? campoId = 41 :
                                                  idx == 20 ? campoId = 42 :
                                                    idx == 21 ? campoId = 35 :
                                                      idx == 22 ? campoId = 36 :
                                                        idx == 23 ? campoId = 39 :
                                                          idx == 24 ? campoId = 43 :
                                                            idx == 25 ? campoId = 30 :
                                                              idx == 26 ? campoId = 31 :
                                                                idx == 27 ? campoId = 29 :
                                                                  idx == 28 ? campoId = 37 :
                                                                    idx == 29 ? campoId = 38 :
                                                                      idx == 30 ? campoId = 5 :
                                                                        idx == 31 ? campoId = 77 :
                                                                          idx == 32 ? campoId = 3 :
                                                                            idx == 33 ? campoId = 79 :
                                                                              idx == 34 ? campoId = 80 :
                                                                                campoId = 81
          //console.log(`${idx}  ${campoId}  ${element}`);
          valor.push({
            oportunidad_uuid: this.uuidOportunidades,
            campo_id: campoId,
            valor: element,
            campania: this.lead.leadDetalles.campania,
            userCallCenter: this.userCallCenter,
          });
        });
        this.$axios
          .post(
            urlUpdateV,
            { valores: valor },
            {
              headers: {
                Authorization: "Bearer " + localStorage.agenteAccessToken,
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.modalOportunidad = true
              this.leadCompleto = true;
              this.oportunidadesCompleto = false;
              this.loadLeadtabla(this.uuid);
            }
          });
      }
    },
    //******** FIN Cargar Oportunidad, validarlo y subirlo 
  },

  computed: {
    disposicionesCalculadas() {
      let result = [];
      this.disposiciones.forEach((element) => {
        if (element.tipo == this.tipoCalifSeleccionado) {
          result.push(element);
        }
      });
      return result;
    },
    disposicionesSelect() {
      let result = [];
      this.disposiciones.forEach((element) => {
        if (this.disposition == null) {
          result = false;
        } else {
          if (element.tipo == this.tipoCalifSeleccionado) {
            if (element.tipo == 'Contacto efectivo') {
              result = true;
            } else {
              result = false;
            }
          }
        }
      });
      return result;
    },
    tiposContacto() {
      let result = [];
      this.disposiciones.forEach((element) => {
        if (result.includes(element.tipo) == false) {
          result.push(element.tipo);
        }
      });
      return result;
    },

    // Fechas
    computedDateFormatted() {
      return this.formatDate(this.date)
    },


    //Validador de leds y oportunidades
    validadorLead() {
      if (
        this.disposition.codigo == '8003' ||
        this.disposition.codigo == '8004' ||
        this.disposition.codigo == '8009' ||
        this.disposition.codigo == '8027' ||
        this.disposition.codigo == '8035' ||
        this.disposition.codigo == '8036' ||
        this.disposition.codigo == '8037' ||
        this.disposition.codigo == '8038' ||
        this.disposition.codigo == '8039' ||
        this.disposition.codigo == '8040' ||
        this.disposition.codigo == '8041'
      ) {
        if (
          this.formulario[1] && this.formulario[1].length > 1 &&
          this.formulario[2] && this.formulario[2].length > 1 &&
          this.formulario[4] && this.formulario[4].length > 1 &&
          this.formulario[10] && this.formulario[10].length > 1
        ) {
          if (this.items[0]) {
            if (
              this.formularioOportunidades[2] &&
              this.formularioOportunidades[3] &&
              this.formularioOportunidades[4] &&
              this.formularioOportunidades[9] &&
              this.formularioOportunidades[10] &&
              this.formularioOportunidades[11] &&
              this.formularioOportunidades[15] &&
              this.formularioOportunidades[20]
            ) {
              if (
                this.formularioOportunidades[2] && this.formularioOportunidades[2].length > 1 &&
                this.formularioOportunidades[3] && this.formularioOportunidades[3].length > 1 &&
                this.formularioOportunidades[4] && this.formularioOportunidades[4].length > 1 &&
                this.formularioOportunidades[9] && this.formularioOportunidades[9].length > 1 &&
                this.formularioOportunidades[10] && this.formularioOportunidades[10].length > 1 &&
                this.formularioOportunidades[11] && this.formularioOportunidades[11].length > 1 &&
                this.formularioOportunidades[15] && this.formularioOportunidades[15].length > 1 &&
                this.formularioOportunidades[20] && this.formularioOportunidades[20].length > 1
              ) {
                return 3;
              }
            } else {
              return 5;
            }
            return 4;
          } else {
            return 2;
          }
        } else {
          return 1;
        }
      } else {
        if (
          this.formulario[1] && this.formulario[1].length > 1 &&
          this.formulario[2] && this.formulario[2].length > 1 &&
          this.formulario[4] && this.formulario[4].length > 1 &&
          this.formulario[10] && this.formulario[10].length > 1
        ) {
          return 0;
        } else {
          return 1;
        }
      }
    },

    admin() {
      var isAdmin = false;
      this.roles.forEach((element) => {
        if (element.rol == 'ADMIN') {
          isAdmin = true;
        }
      });
      return isAdmin;
    },

    adminagent() {
      var isAdminAgent = false;

      this.roles.forEach((element) => {
        if (element.rol == 'ADMINAGENT') {
          isAdminAgent = true;
        }
      });
      return isAdminAgent;
    },

    admincc() {
      var isAdmincc = false;

      this.roles.forEach((element) => {
        if (element.rol == 'ADMINCC') {
          isAdmincc = true;
        }
      });
      return isAdmincc;
    },

    manager() {
      var isManager = false;
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGER') {
          isManager = true;
        }
      });
      return isManager;
    },
    managercc() {
      var isManagercc = false;
      this.roles.forEach((element) => {
        if (element.rol == 'MANAGERCC') {
          isManagercc = true;
        }
      });
      return isManagercc;
    },

    operador() {
      var isOper = false;

      this.roles.forEach((element) => {
        if (element.rol == 'OPERADOR') {
          isOper = true;
        }
      });
      return isOper;
    },
    operaciones() {
      var isOperaciones = false;

      this.roles.forEach((element) => {
        if (element.rol == 'OPERACIONES') {
          isOperaciones = true;
        }
      });
      return isOperaciones;
    },

    agentcc() {
      var isAgentcc = false;

      this.roles.forEach((element) => {
        if (element.rol == 'AGENTCC') {
          isAgentcc = true;
        }
      });
      return isAgentcc;
    },
  },
};
</script>

<style sass>
.radioButtons .bv-no-focus-ring {
  display: flex;
}

.radioButtons .bv-no-focus-ring .custom-radio {
  padding-right: 10px;
}

.noValido {
  border: 1px solid #dc3545 !important;
}

.noValidoTex {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.valido {
  border: 1px solid #28a745 !important;
}
</style>




