<template>
    <div>
        <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
            <v-toolbar>
                <v-toolbar-title>
                    Registro de Producto
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn @click="goBack()">
                    <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
                </v-btn>

            </v-toolbar>
            <p style="margin-top: 15px; padding-bottom: 10px">{{ titulo }}</p>
        </v-card>

        <v-divider></v-divider>

        <v-row>
            <v-col cols="7">
                <v-row style="padding: 20px">
                    <v-col cols="6">
                        <v-text-field label="Nombre del Producto" v-model="nombreProducto"></v-text-field>
                    </v-col>
                    <!-- End 2nd Col -->
                </v-row>

                <v-row style="padding: 20px; margin-top: -50px">
                    <v-col cols="6">
                        <v-select label="Compañia" :items="companiaItems" v-model="compania"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select label="Ramo" :items="ramoItems" item-text="ramo" item-value="ramo" v-model="ramo">
                        </v-select>
                    </v-col>
                    <!-- End 4th Col -->
                </v-row>

                <v-row style="padding: 20px; margin-top: -50px">
                    <v-col cols="6">
                        <v-text-field label="% Comisión Agente" v-model="comisionAgente"></v-text-field>
                    </v-col>
                    <!-- End 7th Col -->
                </v-row>

                <v-row style="padding: 20px; margin-top: -50px">
                    <v-col cols="6">
                        <v-text-field label="% Bono" v-model="bono"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="% Comisión pagada por la aseguradora" v-model="comisionPayAseguradora">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row style="padding: 20px; margin-top: -50px">
                    <v-col cols="6">
                        <v-text-field label="% Comisión Total" v-model="comisionTotal"></v-text-field>
                    </v-col>
                </v-row>

                <v-row style="padding: 20px; margin-top: -50px">
                    <v-col cols="6">
                        <v-btn color="primary" @click="insertarProducto()">
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nombreProducto: null,
            compania: null,
            ramo: null,
            comisionAgente: null,
            bono: null,
            comisionPayAseguradora: null,
            comisionTotal: null,
            companiaItems: ['GNP', 'QUALITAS', 'AIG SEGUROS MEXICO', 'HDI SEGUROS', 'ZURICH', 'AXA', 'AFIRME', 'ANA SEGUROS', 'RSA SEGUROS'],
            ramoItems: []
        }
    },
    methods: {
        goBack() {
            return this.$router.go(-1);
        },
        insertarProducto() {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + localStorage.agenteAccessToken,
                },
            };
            const request = {
                nombre: this.nombreProducto ? this.nombreProducto : '',
                aseguradora: this.compania ? this.compania : '',
                ramo: this.ramo ? this.ramo : '',
                status: 0,
                comisionAgente: this.comisionAgente ? this.comisionAgente : '',
                bono: this.bono ? this.bono : '',
                comisionPayAseguradora: this.comisionPayAseguradora ? this.comisionPayAseguradora : '',
                comisionTotal: this.comisionTotal ? this.comisionTotal : '',
            }
            this.$axios
                .post('/v1/producto/insert', request, config).then(response => {
                    if (response.status == 200) {
                        alert('Se registró el producto!');
                    }
                    setTimeout(() => (this.$router.push('/lista-productos')), 2000);
                })
        },
        obtenerRamoProductos() {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            this.$axios.get('/v1/ramo/list', config).then(response => {
                // console.log("Ramo: ", response.data);
                response.data.map(element => {
                    this.ramoItems.push({
                        id: element.id,
                        ramo: element.ramo
                    })
                })
            })
        },
    },
    mounted() {
        this.obtenerRamoProductos()
    },
    computed: {
        titulo() {
            return (this.ramo ? this.ramo : '')
                + " / " +
                (this.nombreProducto ? this.nombreProducto : '')
                + " / " +
                (this.compania ? this.compania : '');
        },
    },
}
</script>