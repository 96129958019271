<template>
  <v-row>
    <v-col md="12">
      <v-row>
        <v-col cols="10">
          <h1>Solicitudes</h1>
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" style="align-items: right" class="ml-11 mt-2"
            @click="$router.push('/tarjeta-comercial')">
            Crear
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="4">
          <v-card outlined class="card" @click="cargarSolicitudesPorCards(1)">
            <v-row>
              <p>{{ solicitudesAbiertas }}</p>
              <h3>Abiertas</h3>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="card" @click="cargarSolicitudesPorCards(2)">
            <v-row>
              <p>{{ solicitudesPendientesAprobacion }}</p>
              <h3>Pendientes de Aprobación</h3>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="card" @click="cargarSolicitudesPorCards(3)">
            <v-row>
              <p>{{ solicitudesPendientesInformacion }}</p>
              <h3>Pendiente de Información</h3>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card outlined class="card" @click="cargarSolicitudesPorCards(4)">
            <v-row>
              <p>{{ solicitudesRecotizar }}</p>
              <h3>Recotizar</h3>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="card" @click="cargarSolicitudesPorCards(5)">
            <v-row>
              <p>{{ solicitudesAtendidas }}</p>
              <h3>Atendidas</h3>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="card" @click="cargarSolicitudesPorCards(6)">
            <v-row>
              <p>{{ solicitudesCanceladas }}</p>
              <h3>Canceladas</h3>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card style="padding: 30px">
        <v-card-title primary-title>
          <v-row>
            <v-col cols="10">
              <span class="text-h3">Solicitudes {{ titulo }}</span>
            </v-col>
            <v-col cols="2">
              <v-btn color="primary" style="height: 50px; weight: 50px" class="ml-16" dark max-height="40px"
                max-width="100px" v-if="canCreate">
                <download-csv :name="downloadText" :data="descargarSolicitudes">
                  Descargar
                </download-csv>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- TABLE -->
        <v-data-table :headers="columns" :items="solicitudes" item-key="name" class="elevation-1" :search="search"
          :loading='loading' loading-text="Cargando...">
          <template v-slot:top>
            <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
          </template>
          <template v-slot:item.uuid="{ item }">
            <v-icon small class="mr-2" @click="verSolicitud(item.id)">
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';
export default {
  computed: {
    canCreate() {
      var canView = false;
      var roles = []
      this.roles.forEach((element) => {
        roles.push(element.rol)
      });
      const rolesToView = ["ADMIN", "ADMINAGENT", "ADMINCC", "AGENT", "AGENTCC", "DIRECTO", "FINANZAS", "DIRECTOR", "MANAGER", "MANAGERCC", "MESADECONTROL", "OPERACIONES", "OPERADOR"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      })
      return canView;
    }
  },
  data() {
    return {
      roles: JSON.parse(localStorage.getItem('roles')),
      loading: false,
      solicitudes: [],
      search: '',
      descargarSolicitudes: [],
      downloadText: "Solicitudes.csv",
      banner: 0,
      banners: [
        { id: 1, url: 'BANNER.png' },
        { id: 1, url: 'BANNER.png' },
        { id: 1, url: 'BANNER.png' },
      ],
      columns: [
        {
          text: 'Número',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nombre de la Solicitud', value: 'nombreSolicitud' },
        { text: 'Estado', value: 'estado' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Categoria', value: 'categoria' },
        { text: 'Ejecutivo', value: 'ejecutivo' },
        { text: 'Fecha de Creación', value: 'fechaCreacion' },
        { text: 'SLA', value: 'sla' },
        { text: 'Ver', value: 'uuid' },
      ],
      solicitudesAbiertas: 0,
      solicitudesAtendidas: 0,
      solicitudesPendientesAprobacion: 0,
      solicitudesPendientesInformacion: 0,
      solicitudesCanceladas: 0,
      solicitudesCerradas: 0,
      solicitudesRecotizar: 0,
      agenteUuid: localStorage.getItem('agenteUuid'),
      titulo: '',
    };
  },
  methods: {
    obtenerSolicitudes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/solicitud/list", config).then((response) => {
        //Cargar en el montaje del componente datos a la tabla
        response.data.map(element => {
          this.solicitudes.push({
            id: element.id,
            nombreSolicitud: element.nombreSolicitud,
            // nombreSolicitud: `${element.categoria ? element.categoria : ''}` + ' / ' + `${element.tipo ? element.tipo : ''}` + ' / ' + `${element.nombreAgente ? element.nombreAgente : ''}`,
            fechaCreacion: element.fechaCreacion ? moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            estado: element.status,
            tipo: element.tipo,
            categoria: element.categoria,
            ejecutivo: element.nombreEjecutivo ? element.nombreEjecutivo : 'No Disponible',
            sla: element.slaGeneral ? element.slaGeneral : 'No Disponible'
          });
        });
        //Cargar en el montaje del componente datos para descargar
        response.data.map(item => {
          this.descargarSolicitudes.push({
            "No. Solicitud": item.id,
            "Titulo": item.nombreSolicitud,
            "Estatus de la Solicitud": item.status,
            "Origen": item.origen,
            "Tipo": item.tipo,
            "Fecha de Creación": item.fechaCreacion ? moment.utc(item.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Ramo": item.categoria ? item.categoria : 'No Disponible',
            "Agente": item.nombreAgente,
            "Nombre del Cliente": `${item.nombre ? item.nombre : ''}` + " " + `${item.apellidoPaterno ? item.apellidoPaterno : ''}` + " " + `${item.apellidoMaterno ? item.apellidoMaterno : ''}`
          })
        })
      });
    },
    cargarSolicitudesPorCards(status) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let reporte = ''
      status == 1 ? reporte = 'solicitudesAbiertas' :
        status == 2 ? reporte = 'solicitudesPendientesAprobacion' :
          status == 3 ? reporte = 'solicitudesPendientesInformacion' :
            status == 4 ? reporte = 'solicitudesParaRecotizacion' :
              status == 5 ? reporte = 'solicitudesAtendidas' :
                reporte = 'solicitudesCanceladas'

      status == 1 ? this.downloadText = 'Solicitudes_Abiertas.csv' :
        status == 2 ? this.downloadText = 'Solicitudes_Pendientes_de_Aprobacion.csv' :
          status == 3 ? this.downloadText = 'Solicitudes_Pendientes_de_Informacion.csv' :
            status == 4 ? this.downloadText = 'Solicitudes_Para_Recotizar.csv' :
              status == 5 ? this.downloadText = 'Solicitudes_Atendidas.csv' :
                this.downloadText = 'Solicitudes_Canceladas.csv'

      status == 1 ? this.titulo = 'Abiertas' :
        status == 2 ? this.titulo = 'Pendientes de Aprobación' :
          status == 3 ? this.titulo = 'Pendientes de Información' :
            status == 4 ? this.titulo = 'Para Recotizar' :
              status == 5 ? this.titulo = 'Atendidas' :
                this.titulo = 'Canceladas'

      this.solicitudes = []
      this.descargarSolicitudes = []
      this.$axios.get(`/v1/solicitud/${reporte}/agente/` + `${this.agenteUuid}`, config).then(resp => {
        console.log("Solicitudes: ", resp.data)
        if (resp.status == 200) {
          console.log("Entró!!!!!")
          this.loading = true
        }
        //Cargar datos en la tabla
        resp.data.map(element => {
          this.solicitudes.push({
            id: element.id,
            nombreSolicitud: element.nombreSolicitud,
            // nombreSolicitud: `${element.categoria ? element.categoria : ''}` + ' / ' + `${element.tipo ? element.tipo : ''}` + ' / ' + `${element.nombreAgente ? element.nombreAgente : ''}`,
            fechaCreacion: element.fechaCreacion ? moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            estado: element.status,
            tipo: element.tipo,
            categoria: element.categoria,
            ejecutivo: element.nombreEjecutivo ? element.nombreEjecutivo : 'No Disponible',
            sla: element.slaGeneral ? element.slaGeneral : 'No Disponible'
          });
        })
        //Cargar datos para descargar
        resp.data.map(item => {
          this.descargarSolicitudes.push({
            "No. Solicitud": item.id,
            "Titulo": item.nombreSolicitud,
            "Estatus de la Solicitud": item.status,
            "Origen": item.origen,
            "Tipo": item.tipo,
            "Fecha de Creación": item.fechaCreacion ? moment.utc(item.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible',
            "Ramo": item.categoria ? item.categoria : 'No Disponible',
            "Agente": item.nombreAgente,
            "Nombre del Cliente": `${item.nombre ? item.nombre : ''}` + " " + `${item.apellidoPaterno ? item.apellidoPaterno : ''}` + " " + `${item.apellidoMaterno ? item.apellidoMaterno : ''}`
          })
        })
        this.loading = false
      });
    },
    obtenerAgente(agente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          if (agente.includes(element.id)) {
            return element.nombre
          }
        });
      });
    },
    obtenerCliente(cliente) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(`/v1/cliente/id/${cliente}`, config).then(resp => {
          // console.log("Cliente: ", resp.data);
          return `${resp.data.nombre}` + " " + `${resp.data.apellidoPaterno}` + " " + `${resp.data.apellidoMaterno}`
        })
    },
    obtenerSolicitudesGeneral() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      //Solicitudes Abiertas
      this.$axios.get('/v1/solicitud/solicitudesAbiertas/agente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.solicitudesAbiertas++;
        })
      });
      //Solicitudes Atendidas
      this.$axios.get('/v1/solicitud/solicitudesAtendidas/agente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.solicitudesAtendidas++;
        })
      });
      //Solicitudes Pendientes de Aprobacion
      this.$axios.get('/v1/solicitud/solicitudesPendientesAprobacion/agente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.solicitudesPendientesAprobacion++;
        })
      });
      //Solicitudes Pendientes de Información
      this.$axios.get('/v1/solicitud/solicitudesPendientesInformacion/agente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.solicitudesPendientesInformacion++;
        })
      });
      //Solicitudes Canceladas
      this.$axios.get('/v1/solicitud/solicitudesCanceladas/agente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.solicitudesCanceladas++;
        })
      });
      //Solicitudes Recotización
      this.$axios.get('/v1/solicitud/solicitudesParaRecotizacion/agente/' + `${this.agenteUuid}`, config).then(resp => {
        resp.data.map(element => {
          element
          this.solicitudesRecotizar++;
        })
      });
    },

    verSolicitud(id) {
      this.$router.push(`/seguimiento-solicitud/${id}`)
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
  mounted() {
    this.obtenerSolicitudes()
    this.obtenerSolicitudesGeneral()
  }
};
</script>
<style scoped>
.card {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}

.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}

.card:hover:before {
  transform: scale(6.15);
}
</style>
