import BK_GCC from "~/assets/productos/BK_GCC.png"
import BK_BA_HOGAR from "~/assets/productos/BK_BODEGA_AURRERA_hogar.png"
import BK_BA from "~/assets/productos/BK_BODEGA_AURRERA.png"
import BK_GCC_HOGAR from "~/assets/productos/gcc_hogar.png"

export const ListPoliza = {
    data: function () {
        return {
            agentes: [],
            productos: [],
            statusList: [
                { id: 0, name: "Nueva" },
                { id: 1, name: "Emitida Pendente de Pago" },
                { id: 2, name: "Pagada Parcial" },
                { id: 3, name: "Pagada Total" },
                { id: 4, name: "Renovada" },
                { id: 5, name: "Finalizada" },
                { id: 6, name: "Cancelada" },
                { id: 8, name: "Emitido el certificado" },
            ],
            cancelacionFinalizacionList: [
                { id: 0, name: "Falta de pago" },
                { id: 1, name: "Incremento en prima / Renovación" },
                { id: 2, name: "Mal servicio agente" },
                { id: 3, name: "Mal servicion compañía" },
                { id: 4, name: "Económicas" },
                { id: 5, name: "Inconformidad con el producto" },
                { id: 6, name: "Mejora en competencia" },
                { id: 7, name: "Ya no tiene necesidad / Fallecimiento / Cambio de Agente" },
            ],
            typePolizaList: [
                "Nueva",
            ],
            landList: [
                "AIG",
                "ANA",
                "AXA",
                "COMPARADOR",
                "COMPARADOR-ADT",
                "COMPARADOR-AUTOCOSMOS",
                "GNP",
                "HDI",
                "PROVEEDORES",
                "QUÁLITAS",
                "SGMM SEGURIFY",
                "SURA",
                "SURA MOVILIDAD",
                "SURA SGMM",
            ],
            aseguradorasList: [
                { id: 1, name: 'Afirme' },
                { id: 2, name: 'ANA' },
                { id: 3, name: 'GNP' },
                { id: 4, name: 'Primero' },
                { id: 5, name: 'Qualitas' },
                { id: 6, name: 'Multiva' },
                { id: 7, name: 'Sura' },
                { id: 8, name: 'AIG' },
                { id: 9, name: 'HDI' },
                { id: 10, name: 'Zurich' },
                { id: 11, name: 'AXA' },
                { id: 12, name: 'Banorte' },
            ],
            periodicidadList: [
                { id: 1, name: 'Anual' },
                { id: 2, name: 'Semestral' },
                { id: 3, name: 'Trimestral' },
                { id: 4, name: 'Mensual' },
            ],
            clienteList: [
                { id: 1, name: 'Cliente 1' },
                { id: 2, name: 'Cliente 2' },
                { id: 3, name: 'Cliente 3' },
                { id: 4, name: 'Cliente 4' },
            ],
            formaPagoList: [
                { id: 1, name: 'TC Bradescard' },
                { id: 2, name: 'Tarjeta de Débito' },
                { id: 3, name: 'Depósito en banco' },
                { id: 4, name: 'Transferencia' },
                { id: 5, name: 'Cheque' },
                { id: 6, name: 'Efectivo' },
                { id: 7, name: 'Domiciliación' },
                { id: 8, name: 'Cargo único' },
            ],
            generoList: ["Masculino", "Femenino"],
            regimentList: [
                "Física",
                "Moral",
            ],
            estados: [
                { id: 1, name: 'Aguascalientes' },
                { id: 2, name: 'Baja California' },
                { id: 3, name: 'Baja California Sur' },
                { id: 4, name: 'Campeche' },
                { id: 5, name: 'Coahuila de Zaragoza' },
                { id: 6, name: 'Colima' },
                { id: 7, name: 'Chiapas' },
                { id: 8, name: 'Chihuahua' },
                { id: 9, name: 'Ciudad de México' },
                { id: 10, name: 'Durango' },
                { id: 11, name: 'Guanajuato' },
                { id: 12, name: 'Guerrero' },
                { id: 13, name: 'Hidalgo' },
                { id: 14, name: 'Jalisco' },
                { id: 15, name: 'Estado de México' },
                { id: 16, name: 'Michoacán de Ocampo' },
                { id: 17, name: 'Morelos' },
                { id: 18, name: 'Nayarit' },
                { id: 19, name: 'Nuevo León' },
                { id: 20, name: 'Oaxaca' },
                { id: 21, name: 'Puebla' },
                { id: 22, name: 'Querétaro' },
                { id: 23, name: 'Quintana Roo' },
                { id: 24, name: 'San Luis Potosí' },
                { id: 25, name: 'Sinaloa' },
                { id: 26, name: 'Sonora' },
                { id: 27, name: 'Tabasco' },
                { id: 28, name: 'Tamaulipas' },
                { id: 29, name: 'Tlaxcala' },
                { id: 30, name: 'Veracruz de Ignacio de la Llave' },
                { id: 31, name: 'Yucatán' },
                { id: 32, name: 'Zacatecas' },
            ],
            img_productos:{
                "Incapacidad_Ap":{
                    "BK_GCC": BK_GCC,
                    "BK_BODEGA_AURRERA": BK_BA,
                    "BK_C&A": BK_GCC,
                    "BK_PROMODA": BK_GCC,
                    "BK_SHASA": BK_GCC,
                    "PLCC_C&A": BK_GCC,
                },
                "Hogar":{
                    "BK_GCC": BK_GCC_HOGAR,
                    "BK_BODEGA_AURRERA": BK_BA_HOGAR,
                    "BK_C&A": BK_GCC_HOGAR,
                    "BK_PROMODA": BK_GCC_HOGAR,
                    "BK_SHASA": BK_GCC_HOGAR,
                    "PLCC_C&A": BK_GCC_HOGAR,
                },
            }
        }
    }
}