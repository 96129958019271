<template>
  <v-row class="mb-14">
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          Leads #{{ form.id }}
          <v-spacer></v-spacer>
          <v-btn
            @click="$router.go(-1)"
            style="background-color: #ff0042; color: white;"
          >
            FINALIZAR
          </v-btn>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="7">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    label="Estatus Lead"
                    :items="estatusLeads"
                    v-model="form.status"
                    placeholder="Requerido"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="ID Lead"
                    v-model="form.id"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Campaña"
                    v-model="form.campania"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-autocomplete
                    label="Producto"
                    :items="productos"
                    v-model="form.producto"
                    placeholder="Requerido"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Régimen"
                    v-model="form.regimen"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-autocomplete
                    label="Última disposición"
                    :items="ultimasDisposiciones"
                    v-model="form.ultimaDisposicion"
                    placeholder="Requerido"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Número de llamadas"
                    v-model="form.numLlamadas"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Campaña"
                    v-model="form.campania"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="* Nombre"
                    v-model="form.nombre"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>

                <template v-if="form.regime == 'Física'">
                  <!-- para regimen fiscal fisico -->
                  <v-col cols="3">
                    <v-autocomplete
                      label="Género"
                      :items="generos"
                      v-model="form.sexo"
                      placeholder="Requerido"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Fecha De Nacimiento"
                      v-model="form.fecha"
                      placeholder="Requerido"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Edad"
                      v-model="form.edad"
                      placeholder="Requerido"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Estado Civil"
                      v-model="form.estadoCivil"
                      placeholder="Requerido"
                    ></v-text-field>
                  </v-col>
                </template>

                <template v-if="form.regime == 'Moral'">
                  <!-- para regimen fiscal Moral -->
                  <v-col cols="3">
                    <v-text-field
                      label="Razón Social"
                      v-model="form.razonSocial"
                      placeholder="Requerido"
                    ></v-text-field>
                  </v-col>
                </template>

                <v-col cols="3">
                  <v-text-field
                    label="RFC"
                    v-model="form.rfc"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Teléfono movil"
                    v-model="form.celular"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Teléfono de casa"
                    v-model="form.telefono"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Dirección"
                    v-model="form.direccion"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Código postal"
                    v-model="form.codigoPostal"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Estado de la república"
                    v-model="form.estado"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    label="Ciudad"
                    v-model="form.ciudad"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Municipio"
                    v-model="form.municipio"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Terminación tarjeta"
                    v-model="form.fourUD"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Día de corte de tarjeta"
                    v-model="form.corte"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Etiqueta"
                    v-model="form.etiqueta"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Creado Por"
                    v-model="form.creadoPor"
                    placeholder="Requerido"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fecha de Creación"
                    v-model="form.fechaModificacion"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="última modificación por"
                    v-model="form.modificadoPor"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fecha última modificación"
                    v-model="form.ultimaDisposicion"
                    placeholder="Requerido"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  form && form.id && form.productoAseguradora == 'Asistencias'
                "
                dense
                justify="end"
              >
                <v-col cols="12" md="8" lg="6">
                  <agregarTelefonoButton
                    :tarjeta="form.cuenta"
                    :telefono="form.telefono"
                    :leadId="form.id"
                  ></agregarTelefonoButton>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="5">
          <v-row>
            <v-col cols="12">
              <Llamadas v-if="load" :identificador="form.id" />
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12">
              <Documentos
                v-if="load"
                :identificador="form.id"
                :opcion="opcion"
              />
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12">
              <Emails v-if="load" :identificador="form.id" />
            </v-col>
          </v-row>

          <v-row class="mt-1">
            <v-col cols="12">
              <Polizas
                v-if="load"
                :identificador="form.id"
                :producto="form.producto"
                :corte="form.corte"
                :productoAseguradora="form.productoAseguradora"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ListExpanded } from "@/plugins/mixins";
import Documentos from "./../../components/Documentos.vue";
import Emails from "./../../components/Emails.vue";
import Llamadas from "./../../components/Llamadas.vue";
import Polizas from "./../../components/Poliza.vue";
import agregarTelefonoButton from "@/components/telefonoContacto/agregarTelefonoButton.vue";

export default {
  name: "Bradescard.DetallesLeads",
  components: {
    Documentos,
    Emails,
    Llamadas,
    Polizas,
    agregarTelefonoButton,
  },
  props: ["detalles"],
  mixins: [ListExpanded],
  data: function() {
    return {
      load: false,
      form: {
        id: null,
        uuid: null,
        id_bradescard: null,
        medio: null,
        status: null,
        nombre: null,
        apellido_Paterno: null,
        apellido_Materno: null,
        rfc: null,
        edad: null,
        sexo: null, // se repitio
        estadoCivil: null,
        telefono: null,
        celular: null,
        correo: null,
        direccion: null,
        estado: null,
        ciudad: null,
        codigoPostal: null,
        producto: null,
        etiqueta: null,
        fourUD: null,
        corte: null,
        agenteId: null,
        operadorId: null,
        ultimaDisposicion: null,
        numLlamadas: null,
        campania: null,
        fecha: null,
        fechaModificacion: null,
        modificadoPor: null,
        creadoPor: null,
      },
      agenteAccessToken: localStorage.getItem("agenteAccessToken"),
      opcion: "lead",
    };
  },
  async mounted() {
    this.getProductos();
    await this.LoadDetalleLead();
  },
  methods: {
    async LoadDetalleLead() {
      try {
        let { data } = await this.$axios.get(
          `v1/bradescard/uuid/${this.$route.params.uuid}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        this.form = { ...data };
        this.load = true;
      } catch (exception) {
        console.error(exception);
      }
    },
  },
};
</script>

<style></style>
