<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-text-field
                    label="Nombre"
                    v-model="nombre"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-checkbox
                    label="¿Enviar por correo electrónico?"
                    v-model="enviarPorCorreo"
                ></v-checkbox>
            </v-col>
            <v-col cols="6">
                <v-select
                    label="Tipo"
                    :items="tipoItems"
                    v-model="tipoSelected"
                    required
                ></v-select>
            </v-col>
            <v-col cols="6" v-if="tipoSelected == 'Documento'">
                <v-select
                    label="Tipo de Doocumento*"
                    :items="tipoDocumentoItems"
                    v-model="tipoDocumentoSelected"
                    required
                ></v-select>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    label="Descripción"
                    v-model="descripcion"
                    required
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-file-input
                    v-model="archivos"
                    color="primary"
                    counter
                    label="Adjuntos*"
                    multiple
                    prepend-icon="mdi-paperclip"
                    :show-size="1000"
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                        >
                            {{ text }}
                        </v-chip>

                        <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                        >
                            +{{ archivos.length - 2 }} File(s)
                        </span>
                    </template>
                </v-file-input>
            </v-col>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        label="Creado por"
                        v-model="creadoPor"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        label="Fecha de Creación"
                        v-mask="'##/##/####'"
                        v-model="fechaCreacion"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-row>
        <small>*campo requerido</small>

        <!-- ALERT MODAL -->
        <v-col cols="auto">
            <v-dialog 
                transition="dialog-bottom-transition" 
                max-width="400" 
                v-model="dialog"
            >
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar color="success" dark>
                            Confirmación de envío
                        </v-toolbar>

                        <v-card-text>
                            <div class="text-h4" style="margin-top: 10px; ">
                                Envio de documento exitoso!
                            </div>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn text @click="dialog = false, $router.push(`/seguimiento-solicitud/${solicitud}`)">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
        <!-- END ALERT MODAL -->

    </v-container>
</template>

<script>
export default {
    props: {
        accionGuardar: Boolean,
        solicitud: Number,
        poliza: Number,
        cuota: Number,
        cliente: Number,
        usuario: Number,
    },
    data() {
        return {
            dialog: false,
            tipoItems: [
                "Nota",
                "Documento"
            ],
            tipoDocumentoItems: [
                "Acta constitutiva",
                "Comprobante de Domicilio",
                "Comprobante Médico",
                "Contrato firmado del Agente",
                "Cotización",
                "Cédula del Agente",
                "Identificación",
                "Poder Notarial",
                "Póliza",
                "RC de Agente",
                "Tarjeta de Circulación",
                "Otro",
            ],
            nombre: null,
            enviarPorCorreo: null,
            tipoSelected: null,
            tipoDocumentoSelected: null,
            descripcion: null,
            archivos: [],
            creadoPor: null,
            fechaCreacion: null,
            modificadoPor: null,
            fechaUltimaModificacion: null
        }
    },
    watch: {
        accionGuardar(val) {
            if (!val) return
            this.enviarDocumentos();
        },
        dialog(val) {
            if (!val) return
            setTimeout(() => (this.dialog = false), 2500)
        },
    },
    methods: {
        enviarDocumentos() {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                    'Content-Type': 'multipart/form-data'
                },
            };
            const formData = new FormData();
            for( var i = 0; i < this.archivos.length; i++ ){
                let file = this.archivos[i];
                formData.append('file', file);
            }
            formData.append('nota', this.descripcion ? this.descripcion : null);
            formData.append('tipo', this.tipoSelected ? this.tipoSelected : null);
            formData.append('tipo_documento', this.tipoDocumentoSelected ? this.tipoDocumentoSelected : null);
            formData.append('nombre', this.nombre ? this.nombre : null);
            if (this.solicitud) {
                this.$axios.post(`/v1/solicitud/documento/upload/${this.solicitud}`, formData,config)
                .then(function (result) {
                    console.log(result);
                    if (result.status == 200) {
                        this.dialog = true;
                    }
                    setTimeout(() => (this.$router.push(`/seguimiento-solicitud/${this.solicitud}`)), 2500);
                }, function (error) {
                    console.log(error);
                });
            } else if (this.poliza) {
                this.$axios.post(`/v1/emision/documento/upload/${this.poliza}`, formData,config)
                .then(function (result) {
                    console.log(result);
                    if (result.status == 200) {
                        this.dialog = true;
                    }
                    setTimeout(() => (this.$router.push(`/emision/${this.poliza}`)), 2500);
                }, function (error) {
                    console.log(error);
                });
            } else if (this.cuota) {
                this.$axios.post(`/v1/cuota/documento/upload/${this.cuota}`, formData,config)
                .then(function (result) {
                    console.log(result);
                    if (result.status == 200) {
                        this.dialog = true;
                    }
                    setTimeout(() => (this.$router.push(`/visualizacion-comisiones/${this.cuota}`)), 2500);
                }, function (error) {
                    console.log(error);
                });
            } else if (this.cliente) {
                this.$axios.post(`/v1/clientes/documento/upload/${this.cliente}`, formData,config)
                .then(function (result) {
                    console.log(result);
                    if (result.status == 200) {
                        this.dialog = true;
                    }
                    setTimeout(() => (this.$router.push(`/cliente-detalle/${this.cliente}`)), 2500);
                }, function (error) {
                    console.log(error);
                });
            } else if (this.usuario) {
                this.$axios.post(`/v1/agente/documento/upload/${this.usuario}`, formData,config)
                .then(function (result) {
                    console.log(result);
                    if (result.status == 200) {
                        this.dialog = true;
                    }
                    setTimeout(() => (this.$router.push(`/inicio`)), 2500);
                }, function (error) {
                    console.log(error);
                });
            }
        }
    }
}
</script>