<template>
    <v-footer fixed style="text-align:center;">
        <p style="text-align:center;width:100%; margin: 10px 0px">Segurify {{ new Date().getFullYear() }} <a
                target="_blank" href="/terminos-y-condiciones">Terminos y condiciones</a> y <a target="_blank"
                href="/aviso-de-privacidad">Aviso de privacidad</a> </p>
    </v-footer>

</template>

<script>
    export default {
        data: () => {
            return{

            }
        }, methods:{
            
        }
    }
</script>