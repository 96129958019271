<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Pólizas Emitidas</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <v-select
            label="Canal"
            outlined
            style="max-width: 420px"
            class="mt-5"
            :items="canales"
            item-text="name"
            item-value="id"
            v-model="canal"
            v-show="canShow"
          ></v-select>
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker
            style="width: 100%"
            v-model="dates"
            range
          ></v-date-picker>
          <v-btn
            rounded
            color="primary"
            style="width: 100%"
            @click="consultar"
            :loading="loading"
            :disabled="loadingAgentes"
          >
            <span>
              {{
                loadingAgentes ? "Cargando datos de agentes" : "Consultar"
              }}</span
            >
          </v-btn>
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
          >
            <download-csv :data="reportDownload" name="Informe_bradescard.csv">
              Descargar Información
            </download-csv>
          </v-btn>
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :loading="loadingRetro"
            :disabled="bloquear"
            v-show="puedeDescargarRetro"
          >
            <download-csv :data="retroDownloadData" name="Informe_retro.csv">
              Descargar Informe Retro
            </download-csv>
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner
                :md-diameter="100"
                :md-stroke="10"
                md-mode="indeterminate"
              ></md-progress-spinner>
            </div>
          </div>

          <v-data-table
            :headers="columns"
            :items="polizas"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Buscar"
                class="mx-4"
              ></v-text-field>
            </template>

            <template v-slot:item.uuid="{ item }">
              <v-btn
                @click="
                  $router.push('/administrador/editar-emision/' + item.uuid)
                "
              >
                Ver
              </v-btn>
            </template>

            <template v-slot:item.urlPoliza="{ item }">
              <v-btn
                :href="
                  'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza
                "
                >Descargar</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      loadingRetro: false,
      loadingAgentes: false,
      reportType: "lead",
      polizas: [],
      search: "",
      reportDownload: [],
      reportDownloadTI: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        // { id: 1, name: "AGENTES" },
        { id: 2, name: "SEGUROS" },
        // { id: 3, name: "DIRECTOS" },
        { id: 4, name: "ASISTENCIAS" },
      ],

      columns: [
        { text: "No. Póliza", value: "idPoliza" },
        { text: "Estatus de la Póliza", value: "status" },
        { text: "Cliente", value: "cliente" },
        { text: "Agente", value: "agente" },
        { text: "Nombre", value: "nombreProducto" },
        { text: "Ramo", value: "ramo" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Nueva / Renovacion", value: "nuevaRenovacion" },
        { text: "Inicio de Vigencia", value: "inicioVigencia" },
        { text: "Fin de Vigencia", value: "finVigencia" },
        { text: "Periodicidad de Pago", value: "periodicidadPago" },
        { text: "Monto de la Póliza", value: "montoPoliza" },
        { text: "Ejecutivo", value: "ejecutivo" },
      ],
      agentes: [],
      productos: [],
      retroDownloadData: [],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },
    consultar() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment
            .utc(this.dates[0])
            .add(6, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .add(6, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .add(6, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      this.ejecutarRetro({ fechas, config });

      this.$axios
        .post(
          `/v1/emision/${this.canal ? this.canal : 0}/1/${this.agenteUuid}`,
          fechas,
          config
        )
        .then((value) => {
          this.loading = false;
          this.reportDownload = [];
          this.reportDownloadTI = [];
          this.polizas = [];

          value.data.map((element) => {
            this.polizas.push({
              idPoliza: element.poliza,
              status: element.status ? element.status : "No Disponible",
              // cliente: moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
              cliente: element.nombreCliente
                ? element.nombreCliente
                : `${element.nombre}` +
                  " " +
                  `${element.apellido_Paterno}` +
                  " " +
                  `${element.apellido_Materno}`,
              agente: element.nombreAgente,
              producto: element.producto,
              nombreProducto: element.nombreProducto,
              ramo: element.nombreRamo,
              aseguradora: element.nombreAseguradora,
              nuevaRenovacion: element.nuevaRenovacion,
              inicioVigencia: element.fechaInicioVigencia
                ? moment
                    .utc(element.fechaInicioVigencia)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              finVigencia: element.fechaFinVigencia
                ? moment
                    .utc(element.fechaFinVigencia)
                    .subtract(6, "hours")
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              periodicidadPago: element.periodicidad,
              montoPoliza: "$" + element.montoPoliza,
              ejecutivo: element.opertadorId
                ? this.getAgentName(element.opertadorId)
                : "No disponible",
            });

            this.reportDownload.push({
              Certificado: element.poliza ? element.poliza : "No Disponible",
              "No. Póliza": element.polizaAseguradora
                ? element.polizaAseguradora
                : "No disponible",
              "Póliza ID Interna": element.id ? element.id : "No Disponible",
              "Certificado bradescard": element.certificadoBradescard
                ? element.certificadoBradescard
                : "No Disponible",
              "Poliza sustituida": element.polizaSustituida
                ? element.polizaSustituida
                : "No Disponible",
              "Fecha Emision": moment
                .utc(element.fechaEmision)
                .format("DD/MM/YYYY HH:mm:ss"),
              "Estatus de la Póliza": element.status
                ? element.status
                : "No disponible",
              "Fecha de cancelacion": element.fechaCancelacion
                ? moment.utc(element.fechaCancelacion).format("DD/MM/YYYY")
                : "No Disponible",
              "Motivo cancelacion": element.motivoCancelacion
                ? element.motivoCancelacion
                : "No Disponible",
              Cliente: element.nombreCliente
                ? element.nombreCliente
                : `${element.nombre}` +
                  " " +
                  `${element.apellido_Paterno}` +
                  " " +
                  `${element.apellido_Materno}`,
              "Nombre producto": element.nombreProducto
                ? element.nombreProducto
                : "No disponible",
              Ramo: element.nombreRamo ? element.nombreRamo : "No Disponible",
              Aseguradora: element.nombreAseguradora
                ? element.nombreAseguradora
                : "No disponible",
              "Nueva / Renovación": element.nuevaRenovacion
                ? element.nuevaRenovacion
                : "No disponible",
              "Inicio de Vigencia": element.fechaInicioVigencia
                ? moment
                    .utc(element.fechaInicioVigencia)
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Fin de Vigencia": element.fechaFinVigencia
                ? moment
                    .utc(element.fechaFinVigencia)
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Periodicidad de Pago": element.periodicidad
                ? element.periodicidad
                : "No disponible",
              "Número de Cuotas": element.numCuotas
                ? element.numCuotas
                : "No disponible",
              "Prima neta": element.montoPoliza
                ? element.montoPoliza
                : "No disponible",
              "Prima total": element.primaTotal
                ? element.primaTotal
                : "No disponible",
              "Pago mensual": element.primerPago
                ? element.primerPago
                : "No disponible",
              "Forma de pago": element.formaPago
                ? element.formaPago == 1
                  ? "TC Bradescard"
                  : element.formaPago == 2
                  ? "Tarjeta de Débito"
                  : element.formaPago == 3
                  ? "Depósito en banco"
                  : element.formaPago == 4
                  ? "Transferencia"
                  : element.formaPago == 5
                  ? "Cheque"
                  : element.formaPago == 6
                  ? "Efectivo"
                  : element.formaPago == 7
                  ? "Domiciliación"
                  : element.formaPago == 8
                  ? "Cargo único"
                  : "No disponible"
                : "No disponible",
              "Fecha primer pago": element.fechaPrimerPago
                ? moment
                    .utc(element.fechaPrimerPago)
                    .format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
              "Nombre ejecutivo": element.opertadorId
                ? this.getAgentName(element.opertadorId)
                : "No disponible",
              "Lead Bradescard": element.idBradescard
                ? element.idBradescard
                : "No disponible",
              "Producto Bradescard": element.productoBradescard
                ? element.productoBradescard
                : "No disponible",
              "Fecha de Venta": element.fechaVenta
                ? moment.utc(element.fechaVenta).format("DD/MM/YYYY HH:mm:ss")
                : "No Disponible",
            });

            this.bloquear = false;
          });
        });
    },
    newEmision() {
      this.$router.push("/administrador/carga-emisiones");
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    getAgentes() {
      this.loadingAgentes = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/agente/list`, config).then((response) => {
        this.agentes = response.data;
        console.log({ agentes: response.data });
        this.loadingAgentes = false;
      });
    },
    getAgentName(id) {
      var user = this.agentes.filter((agent) => agent.id === id);
      if (user != "-1")
        return user[0].nombreCompleto
          ? user[0].nombreCompleto
          : "No disponible";
      else return "No disponible";
    },
    async obtenerProducto() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let { data } = await this.$axios.get(
        "/v1/productos/listaProductos",
        config
      );
      this.loading = false;
      this.producto = [];
      data.map((element) => {
        this.producto.push({
          id: element.id,
          nombre: element.nombre,
          titulo: `${element.ramo.toUpperCase()} / ${element.nombre.toUpperCase()} / ${element.nombreAseguradora.toUpperCase()}`,
          ramo: element.ramo,
          compania: element.nombreAseguradora.toUpperCase(),
          fechaCreacion: element.fechaCreacion.slice(0, 10),
          fechaModificacion: element.fechaModificacion
            ? element.fechaModificacion
            : "No Disponible",
          check: false,
        });
      });
    },

    ejecutarRetro({ fechas, config }) {
      this.loadingRetro = true;

      this.$axios
        .post("/v1/emision/reporte/informeRetro", fechas, config)
        .then((response) => {
          this.loadingRetro = false;
          this.retroDownloadData = response.data.data.emisiones.map(
            (emision) => ({
              RFC: emision.rfc,
              "CODIGO DE PRODUCTO": emision.codigoProductoBradescard,
              CERTIFICADO: emision.certificadoBradescard,
              "FECHA EMISION": emision.fechaEmision,
              "FECHA INICIO VIGENCIA": moment
                .utc(emision.fechaCancelacion)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss"),
              STATUS: emision.status,
              "FECHA CANCELACION": moment
                .utc(emision.fechaCancelacion)
                .format("DD/MM/YYYY HH:mm:ss"),
              "MOTIVO CANCELACION": emision.motivoCancelacion,
              "ID POLIZA": emision.id,
            })
          );
        });
    },
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
    canShow() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "DIRECTOR",
        "FINANZAS",
        "BACKOFFICE",
        "OPERACIONES",
        "MANAGERCC",
        "ADMINCC",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentRol() {
      let roles = [];
      this.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },

    puedeDescargarRetro() {
      const rolesPermitidos = ["ADMIN", "AUDITOR", "BACKOFFICE"];

      return !!rolesPermitidos.find((r) => r == this.currentRol);
    },
  },
  mounted() {
    this.obtenerProducto();
    this.getAgentes();
  },
};
</script>
