import { mainAxios } from "@/mainAxios";

export default {
    namespaced: true,
    state: {
        llamadas: []
    }, mutations: {
        setLlamadas(state, payload) {
            state.llamadas = payload
        }
    }, actions: {
        async getLlamadas({ commit }, payload) {
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken
                },
            }
            let {data} = await mainAxios.get(`v1/bradescard/lead/llamadas/${payload}`, config)
            commit('setLlamadas', data);
        }
    }
}