var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-12"},[_c('v-row',{staticClass:"mb-10",attrs:{"dense":"","align":"center"}},[_c('v-img',{attrs:{"max-height":_vm.height_log1,"max-width":_vm.width_log1,"src":_vm.logo_1}}),_c('h2',{staticClass:"ml-5"},[_vm._v("Leads bradescard")]),_c('v-spacer'),_c('v-img',{attrs:{"max-height":_vm.width_log2,"max-width":_vm.height_log2,"src":_vm.logo_2}})],1),_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-card',{attrs:{"dense":""}},[(_vm.renderGhapics)?_c('v-card-text',[_c('div',{staticClass:"chart",attrs:{"id":"pie"}})]):_c('v-card-text',[_c('v-progress-circular',{attrs:{"size":100,"indeterminate":"","color":"primary"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-card',{attrs:{"dense":""}},[(_vm.renderGhapics)?_c('v-card-text',[_c('div',{staticClass:"chart",attrs:{"id":"bar"}})]):_c('v-card-text',[_c('v-progress-circular',{attrs:{"size":100,"indeterminate":"","color":"primary"}})],1)],1)],1)],1),_c('v-row',[(_vm.showListadoMarcacion)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-card',{attrs:{"dense":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Leads para marcación")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.Disposiciones,"items-per-page":5,"dense":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No se encontro registros asignados ")]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success","to":{
                name: 'DetalleLeadBradescard',
                params: { uuid: item.uuid },
              }}},[_c('v-icon',[_vm._v("mdi-phone")])],1)]}}],null,false,482715799)})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"dense":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Agendas del día")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headAgenda,"items":_vm.agenda,"items-per-page":5,"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{
                name: 'DetalleLeadBradescard',
                params: { uuid: item.uuid },
              }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.medio",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.medio ? item.medio : "No disponible")+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No se encontro registros asignados ")]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }