<template>
  <div>
    <!-- HEAD TITLE -->
    <v-row style="margin-top: 10px">
      <v-col cols="6">
        <h2>Clientes</h2>
      </v-col>

      <v-col cols="6">
        <v-btn @click="goBack()" elevation="2" style="margin-left: 527px">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper v-model="stepper" non-linear>
                <v-stepper-header>
                  <v-stepper-step step="1" editable>
                    Datos del Cliente
                  </v-stepper-step>

                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">

                    <v-row style="padding: 20px">

                      <!-- End 2nd Col -->
                      <v-col cols="4">
                        <v-select label="Estatus del cliente" :items="estadoList" item-text="estado" item-value="id"
                          v-model="status"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Número de cliente" v-model="numCliente" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Regimen" v-model="regimenSelected" :items="regimenList"></v-select>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field label="Nombre(s)" v-model="nombres"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Apellido Paterno" v-model="apellidoPaterno"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Apellido Materno" v-model="apellidoMaterno"></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-select label="Genero" v-model="generoSelected" :items="generoList"></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field v-model="fechaNacimiento" label="Fecha de Nacimiento"
                          placeholder="Requerido (dd/mm/yyyy)" v-mask="'##/##/####'" prepend-icon="mdi-calendar">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select label="Estado Civil" v-model="estadoCivilSelected" :items="estadoCivilList">
                        </v-select>
                      </v-col>

                      <v-col cols="4" v-if="regimenSelected != 'Física'">
                        <v-text-field label="Nombre de la Empresa" v-model="nombreEmpresa"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimenSelected != 'Física'">
                        <v-text-field label="Razon social" v-model="razonSocial"></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimenSelected != 'Física'">
                        <v-text-field v-model="fechaConstitucion" label="Fecha de Constitucion"
                          placeholder="Requerido (DD/MM/AAAA)" v-mask="'##/##/####'" prepend-icon="mdi-calendar">
                        </v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field label="RFC" v-model="rfc" :rules="rfcRules"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="4">
                              <v-select
                                :items="estados"
                                item-text="estadoRepublica"
                                item-value="id"
                                v-model="estadoRepublicaSelected"
                                label="Estado de la República"
                              ></v-select>
                            </v-col> -->
                      <v-col cols="4">
                        <v-text-field label="Correo Electrónico" v-model="correoElectronico" :rules="emailRules">
                        </v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field label="Teléfono" v-model="celular" v-mask="'##########'"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Telefono de oficina" v-model="telefonoOficina" v-mask="'##########'">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete label="Agente" :items="agentes" item-text="nombre" item-value="id"
                          v-model="agente" placeholder="Requerido"></v-autocomplete>
                      </v-col>

                      <!-- <v-col cols="6">
                                <v-textarea
                                label="Direccion de Facturacion"
                                v-model="direccionFacturacion"
                                rows="auto"
                                readonly
                                
                                ></v-textarea>
                            </v-col>
                            <v-col cols="6">
                                <v-textarea
                                label="Direccion de Recidencial"
                                v-model="direccionRecidencial"
                                rows="auto"
                                readonly
                                ></v-textarea>
                            </v-col> -->

                      <v-col cols="6">
                        <v-text-field label="Creado por" v-model="creadoPor"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="fechaCreacion" label="Fecha de creacion"
                          placeholder="Requerido (dd/mm/yyyy)" prepend-icon="mdi-calendar"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Modificado por" v-model="modificadoPor"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Ultima modificacion" v-model="ultimaModificacion"
                          prepend-icon="mdi-calendar"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-btn color="primary" class="mt-5" @click="sendCliente()">
                      Guardar
                    </v-btn>
                    <!-- <v-btn color="primary" style="margin-left: 15px" class="mt-5" @click="direccionFactura = true">
                          Actualizar Dirección Facturacion 
                        </v-btn>
                        <v-btn color="primary" style="margin-left: 15px" class="mt-5" @click="direccionRecidencia = true">
                          Actualizar Dirección Recidencial 
                        </v-btn> -->
                  </v-stepper-content>

                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <!-- ALERT MODAL -->
    <v-col cols="auto">
      <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="success" dark>Confirmación de envío</v-toolbar>
            <v-card-text>
              <div class="text-h4" style="margin-top: 10px; ">
                Actualización de cliente exitoso!
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false, $router.push(`/seguimiento-cliente/${this.$route.params.id}`)">
                Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="direccionFactura">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="info" dark>Direccion de Facturacion</v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field label="Calle" v-model="jsonDireccionFactura.calle"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Numero Exterior" v-model="jsonDireccionFactura.numExterior"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Numero Interior" v-model="jsonDireccionFactura.numInterior"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Codigo Postal" v-model="jsonDireccionFactura.codigoPostal"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Estado" v-model="jsonDireccionFactura.estado"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Municipio" v-model="jsonDireccionFactura.municipio"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Pais" v-model="jsonDireccionFactura.pais"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="editarDireccion(direccionFacturacionId, 0)">Guardar Dirección</v-btn>
              <v-btn text @click="dialog.value = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="direccionRecidencia">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="info" dark>Direccion Recidencial</v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field label="Calle" v-model="jsonDireccionRecidencia.calle"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Numero Exterior" v-model="jsonDireccionRecidencia.numExterior"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Numero Interior" v-model="jsonDireccionRecidencia.numInterior"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Codigo Postal" v-model="jsonDireccionRecidencia.codigoPostal"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Estado" v-model="jsonDireccionRecidencia.estado"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Municipio" v-model="jsonDireccionRecidencia.municipio"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Pais" v-model="jsonDireccionRecidencia.pais"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="editarDireccion(direccionRecidencialId, 1)">Editar</v-btn>
              <v-btn text @click="dialog.value = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <!-- END OF ALERT MODAL -->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      emailRules: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Dirección de correo incorrecta'
        },
      ],
      rfcRules: [
        (v) => (v || '').length <= 13 || 'RFC No debe ser mayor de 13 caracteres',
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(v) ||
          "RFC no es valido recordar escribir en mayusculas",
      ],
      inputsRequeridos: [
        value => !!value || 'Campo Requerido',
      ],
      tipoNoGeneral: [
        value => value != 'General' || 'Cambiar el tipo de Cliente',
      ],
      menuInicio: false,
      menuFin: false,
      menuFechaNacimiento: false,
      dialog: false,
      direccionFactura: false,
      direccionRecidencia: false,
      bandera: true,
      stepper: 1,
      cliente: [],
      bodyCliente: null,
      mostrarFormulario2: false,
      infoTarjetaComercial: null,
      origenCliente: [
        "Correo electronico",
        "Llamada telefónica",
        "Presencial",
        "Whatsapp",
        "Portal",
        "Otro",
      ],
      agentes: [],
      estadoList: [
        { id: 0, estado: "Nuevo" },
        { id: 1, estado: "Activo" },
        { id: 2, estado: "Inactivo" },
        { id: -1, estado: "Baja" },
      ],
      estadoCivilList: [
        "Soltero(a)",
        "Casado(a)",
      ],
      categoria: [
        // "Ahorro",
        "Auto",
        // "Auto Flotilla",
        // "Daños Grandes riesgos",
        // "Daños Pyme Aseg.",
        // "Hogar",
        // "Fianzas",
        // "Gastos Médicos",
        // "Gastos Médicos Colectivo",
        // "Vida",
        // "Vida Colectivo",
        // "Queja",
        // "Felicitación",
        // "Pregunta",
        // "Reclamo",
        // "Sugerencia",
      ],
      estados: [
        { id: 1, estadoRepublica: 'Aguascalientes' },
        { id: 2, estadoRepublica: 'Baja California' },
        { id: 3, estadoRepublica: 'Baja California Sur' },
        { id: 4, estadoRepublica: 'Campeche' },
        { id: 5, estadoRepublica: 'Coahuila de Zaragoza' },
        { id: 6, estadoRepublica: 'Colima' },
        { id: 7, estadoRepublica: 'Chiapas' },
        { id: 8, estadoRepublica: 'Chihuahua' },
        { id: 9, estadoRepublica: 'Ciudad de México' },
        { id: 10, estadoRepublica: 'Durango' },
        { id: 11, estadoRepublica: 'Guanajuato' },
        { id: 12, estadoRepublica: 'Guerrero' },
        { id: 13, estadoRepublica: 'Hidalgo' },
        { id: 14, estadoRepublica: 'Jalisco' },
        { id: 15, estadoRepublica: 'Estado de México' },
        { id: 16, estadoRepublica: 'Michoacán de Ocampo' },
        { id: 17, estadoRepublica: 'Morelos' },
        { id: 18, estadoRepublica: 'Nayarit' },
        { id: 19, estadoRepublica: 'Nuevo León' },
        { id: 20, estadoRepublica: 'Oaxaca' },
        { id: 21, estadoRepublica: 'Puebla' },
        { id: 22, estadoRepublica: 'Querétaro' },
        { id: 23, estadoRepublica: 'Quintana Roo' },
        { id: 24, estadoRepublica: 'San Luis Potosí' },
        { id: 25, estadoRepublica: 'Sinaloa' },
        { id: 26, estadoRepublica: 'Sonora' },
        { id: 27, estadoRepublica: 'Tabasco' },
        { id: 28, estadoRepublica: 'Tamaulipas' },
        { id: 29, estadoRepublica: 'Tlaxcala' },
        { id: 30, estadoRepublica: 'Veracruz de Ignacio de la Llave' },
        { id: 31, estadoRepublica: 'Yucatán' },
        { id: 32, estadoRepublica: 'Zacatecas' },
      ],
      estadoRepublicaSelected: null,
      regimenList: [
        "Física",
        "Moral",
      ],
      regimenSelected: null,
      agenteUuid: localStorage.getItem("agenteUuid"),
      status: "",
      estadoCliente: "",
      origenClienteSelected: null,
      estadoSelected: null,
      estadoCivilSelected: null,
      tipoSelected: null,
      categoriaSelected: "Auto",
      agente: null,
      nombres: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      fechaNacimiento: null,
      fechaConstitucion: null,
      fechaCreacion: null,

      generoList: ["Masculino", "Femenino"],
      generoSelected: null,
      numCliente: null,

      celular: null,
      telefonoOficina: null,
      correoElectronico: null,
      codigoPostal: null,
      edad: null,
      direccion: null,
      rfc: null,

      nombreEmpresa: null,
      razonSocial: null,
      creadoPor: '',
      modificadoPor: '',
      ultimaModificacion: null,


      direccionFacturacion: '',
      direccionRecidencial: '',
      direccionFacturacionId: '',
      direccionRecidencialId: '',
      jsonDireccionFactura: {},
      jsonDireccionRecidencia: {},
    };
  },
  watch: {
    dialog(val) {
      if (!val) return

      setTimeout(() => (this.dialog = false), 4000)
    }
  },
  methods: {

    goBack() {
      return this.$router.go(-1);
    },
    sendCliente() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let request = {
        status: this.status,
        regimen: this.regimenSelected,
        nombres: this.nombres,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        genero: this.generoSelected == "Femenino" ? "F" : "M",
        fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        estadoCivil: this.estadoCivilSelected,
        nombreEmpresa: this.nombreEmpresa,
        razonSocial: this.razonSocial,
        fechaConstitucion: this.fechaConstitucion ? moment(this.fechaConstitucion, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        rfc: this.rfc,
        //estadoId: this.estadoRepublicaSelected,
        correo: this.correoElectronico,
        celular: this.celular,
        telefonoOficina: this.telefonoOficina,
        agenteId: this.agente,
        direccionFacturacionId: this.direccionFacturacionId,
        direccionResidenciaId: this.direccionResidenciaId,
        fechaCreacion: this.fechaCreacion ? moment(this.fechaCreacion, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        creadoPor: this.creadoPor,
        modificadoPor: this.agenteUuid,
        //ultimaModificacion: this.ultimaModificacion,
      };

      this.$axios
        .put("/v1/cliente/update/" + this.$route.params.id, request, config)
        .then((response) => {
          console.log("Entro!");
          if (response.status == 200) {
            if (this.checkCotizacionRenovacionAprobada == true) {
              this.generarPrePoliza();
            }
            this.dialog = true;
          }
          //setTimeout(() => (this.$router.push(`/cliente-detalle/${this.$route.params.id}`)), 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /****METODOS PARA OBTENER LOS DATOS DE LA SOLICITUD****/
    obtnerDatosCliente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/cliente/uuid/${this.$route.params.id}`, config).then((response) => {
        this.cliente.push(response.data);
        this.numCliente = response.data.id,
          this.bodyCliente = response.data.body ? JSON.parse(response.data.body) : '';
        // console.log(this.bodyCliente);
        // this.agente = response.data.body.agente;
        // this.tipo = response.data.body.oportunidades;
        this.nombres = response.data.nombre ? response.data.nombre : ''
        this.apellidoPaterno = response.data.apellidoPaterno ? response.data.apellidoPaterno : ''
        this.apellidoMaterno = response.data.apellidoMaterno ? response.data.apellidoMaterno : ''
        this.fechaNacimiento = response.data.fechaNacimiento ? moment(response.data.fechaNacimiento).format('DD/MM/YYYY') : ''
        this.status = response.data.status ? response.data.status : 0
        this.celular = response.data.telefonoMovil ? response.data.telefonoMovil : ''
        this.telefonoOficina = response.data.telefonoOficina ? response.data.telefonoOficina : ''
        this.correoElectronico = response.data.correo ? response.data.correo : ''
        this.direccionFacturacionId = response.data.direccionFacturacionId ? response.data.direccionFacturacionId : ''
        this.direccionResidenciaId = response.data.direccionResidenciaId ? response.data.direccionResidenciaId : ''
        this.estadoCivilSelected = response.data.estadoCivil ? response.data.estadoCivil : ''
        this.rfc = response.data.rfc ? response.data.rfc : ''
        this.generoSelected = response.data.genero ? response.data.genero == 'M' ? 'Masculino' : 'Femenino' : ''
        this.nombreEmpresa = response.data.nombreEmpresa ? response.data.nombreEmpresa : ''
        this.razonSocial = response.data.razonSocial ? response.data.razonSocial : ''
        this.regimenSelected = response.data.regimen ? response.data.regimen : ''
        this.fechaConstitucion = response.data.fechaConstitucion ? moment(response.data.fechaConstitucion).format('DD/MM/YYYY') : ''
        this.creadoPor = response.data.creadoPor ? response.data.creadoPor : 'No Disponible'
        this.fechaCreacion = response.data.fechaCreacion ? moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible'
        this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : 'No Disponible'
        this.ultimaModificacion = response.data.ultimaModificacion ? moment.utc(response.data.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible'
        this.agente = response.data.agenteId ? response.data.agenteId : ''

      });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/agente/list', config).then(response => {
        response.data.map(element => {
          this.agentes.push({
            nombre: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
            id: element.id,
            uuid: element.uuid
          })

          this.agentes.map(element => {
            this == element.uuid ? this.modificadoPor = element.nombre : ''
          })
        })
      });
    },
    /****FIN DE METODOS PARA OBTENER LOS DATOS DE LA SOLICITUD****/


  },

  computed: {
    titulo() {
      return "Cliente de " + this.tipo + " " + this.producto;
    },
  },
  mounted() {
    this.obtnerDatosCliente(),
      this.obtenerAgentes()
  },
};
</script>
