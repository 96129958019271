<template>
    <v-row style="padding: 10px">
        <v-row>
            <v-col cols="4">
                <v-select
                    label="Plan"
                    placeholder="Requerido"
                    v-model="plan"
                    :items="['Vida Pagos Limitados(VPL)', 'Ordinario', 'Temporal']"
                ></v-select>
            </v-col>
            <v-col cols="4">
                <v-select
                    label="Fumador"
                    placeholder="Requerido"
                    :items="['Si', 'No']"
                    v-model="fumador"
                ></v-select>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Suma Asegurada"
                    placeholder="Requerido"
                    type="number"
                    prefix="$"
                    v-model="sumaAsegurada"
                ></v-text-field>
            </v-col>

            <v-col cols="4">
                <v-select
                    label="Muerte Accidental"
                    placeholder="Requerido"
                    :items="coberturasAdicionalesItems"
                    v-model="muerteAccidental"
                ></v-select>
            </v-col>
            <v-col cols="4">
                <v-select
                    label="Pérdidas Orgánicas"
                    placeholder="Requerido"
                    :items="coberturasAdicionalesItems"
                    v-model="perdidasOrganicas"
                ></v-select>
            </v-col>
            <v-col cols="4">
                <v-select
                    label="Invalidez total y permanente"
                    placeholder="Requerido"
                    :items="coberturasAdicionalesItems"
                    v-model="invalidezTotalPermanente"
                ></v-select>
            </v-col>
            <v-col cols="4">
                <v-select
                    label="Gastos funerarios"
                    placeholder="Requerido"
                    :items="coberturasAdicionalesItems"
                    v-model="gastosFunerarios"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn
                    color="primary"
                    @click="sendSolicitud()"
                    :disabled="!botonGuardar"
                >
                    Guardar
                </v-btn>
                <v-btn
                    color="primary" 
                    style="margin-left: 10px"
                    v-show="rolToCompare != 'AGENT'"
                >
                    Siguiente
                </v-btn>
            </v-col>
        </v-row>

        <!-- ALERT MODAL -->
        <v-col cols="auto">
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="400"
            v-model="dialog"
          >
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="success" dark
                  >Confirmación de envío</v-toolbar
                >
                <v-card-text>
                  <div class="text-h4" style="margin-top: 10px; ">
                    Envio de solicitud exitoso!
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    @click="
                      (dialog.value = false), $router.push('/solicitudes')
                    "
                    >Cerrar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <!-- END OF ALERT MODAL -->
    </v-row>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        origenSolicitud: String,
        statusSolicitud: String,
        agente: Number,
        tipo: String,
        ramo: String,
        producto: Number,
        detalleSolicitud: String,
        nombres: String,
        apellidoPaterno: String,
        apellidoMaterno: String,
        estadoCivil: String,
        estadoNacimiento: String,
        genero: String,
        fechaNacimiento: String,
        rfc: String,
        tipoPersona: String,
        telefono: String,
        correo: String,
        codigoPostal: String,
        direccion: String,
        inicioVigencia: String,
        finVigencia: String,
        periodicidad: String,
        formaPago: String,
        operador: Number,
        solicitudCompleta: String,
        procesoCotizacion: String,
        comercialAsignado: Number,
        razonSocial: String,
    },
    data() {
        return {
            rolToCompare: JSON.parse(localStorage.getItem("roles"))[0].rol,
            dialog: false,
            plan: null,
            fumador: null,
            sumaAsegurada: null,
            muerteAccidental: null,
            perdidasOrganicas: null,
            invalidezTotalPermanente: null,
            gastosFunerarios: null,
            coberturasAdicionalesItems: ['Si ampara', 'No ampara'],
        }
    },
    watch: {
        dialog(val) {
            if (!val) return;

            setTimeout(() => (this.dialog = false), 2500);
        },
    },
    methods: {
        sendSolicitud() {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            let request = {
                /* CAMPOS DE COMPONENTE PADRE */
                origenSolicitudSelected: this.origenSolicitud,
                agenteId: this.agente,
                tipoSelected: this.tipo,
                categoriaSelected: this.ramo,
                productoId: this.producto,
                detalle: this.detalleSolicitud ? this.detalleSolicitud : "",
                nombres: this.nombres,
                apellidoPaterno: this.apellidoPaterno,
                apellidoMaterno: this.apellidoMaterno,
                estadoCivilSelected: this.estadoCivil,
                estadoRepublicaSelected: this.estadoNacimiento,
                genero: this.genero,
                fechaNacimiento: this.fechaNacimiento ? moment(this.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
                rfc: this.rfc,
                regimen_persona: this.tipoPersona,
                celular: this.telefono,
                correoElectronico: this.correo,
                codigoPostal: this.codigoPostal,
                direccion: this.direccion,
                inicioVigencia: moment(this.inicioVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                finVigencia: moment(this.finVigencia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                periodicidadPago: this.periodicidad == 'Anual' ? 1
                                : this.periodicidad == 'Semestral' ? 2
                                : this.periodicidad == 'Trimestral' ? 3
                                : this.periodicidad == 'Mensual' ? 4 : '',
                formaPagoSelected: this.formaPago,
                operadorUuid: this.operador,
                solicitudCompletaSelected: this.solicitudCompleta ? this.solicitudCompleta : false,
                procesoCotizacionSelected: this.procesoCotizacion,
                checkPendienteInfo: this.checkPendienteInfo ? this.checkPendienteInfo : null,
                checkCancelacion: this.checkCancelacion ? this.checkCancelacion : null,
                causaPendienteInformacion: this.causaPendienteInformacion ? this.causaPendienteInformacion : null,
                comercial_asignado: this.comercialAsignado ? this.comercialAsignado : null,
                razon_social: this.razonSocial ? this.razonSocial : null,
                /* FIN DE CAMPOS DE COMPONENTE PADRE */

                /* CAMPOS DE COMPONENTE HIJO(RAMO) */
                plan: this.plan,
                fumador: this.fumador,
                sumaAsegurada: this.sumaAsegurada,
                muerteAccidental: this.muerteAccidental,
                perdidasOrganicas: this.perdidasOrganicas,
                invalidezTotalPermanente: this.invalidezTotalPermanente,
                gastosFunerarios: this.gastosFunerarios,
            };

            this.$axios
            .post("v1/solicitud/insert/", request, config)
            .then((response) => {
                // console.log("Entro!", request);
                if (response.status == 200) {
                    this.dialog = true;
                }
                setTimeout(() => this.$router.push("/solicitudes"), 2500);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },
    computed: {
        botonGuardar() {
            if (this.tipoPersona == 'Moral') {
                return(
                    this.origenSolicitud != null &&
                    this.statusSolicitud != null &&
                    this.agente != null &&
                    this.tipo != null &&
                    this.ramo != null &&
                    this.estadoNacimiento != null &&
                    this.detalleSolicitud != null &&
                    this.tipoPersona != null &&
                    this.razonSocial != null &&
                    this.rfc != null &&
                    this.telefono != null &&
                    this.correo != null &&
                    this.codigoPostal != null &&
                    this.direccion != null &&
                    this.inicioVigencia != null &&
                    this.finVigencia != null &&
                    this.periodicidad != null &&
                    this.formaPago != null &&

                    /* CAMPOS DEL RAMO */
                    this.plan != null &&
                    this.sumaAsegurada != null
                )
            }
            return(
                this.origenSolicitud != null &&
                this.statusSolicitud != null &&
                this.agente != null &&
                this.tipo != null &&
                this.ramo != null &&
                this.detalleSolicitud != null &&
                this.nombres != null &&
                this.apellidoPaterno != null &&
                this.genero != null &&
                this.fechaNacimiento != null &&
                this.estadoNacimiento != null &&
                this.estadoCivil != null &&
                this.tipoPersona != null &&
                this.rfc != null &&
                this.telefono != null &&
                this.correo != null &&
                this.codigoPostal != null &&
                this.direccion != null &&
                this.inicioVigencia != null &&
                this.finVigencia != null &&
                this.periodicidad != null &&
                this.formaPago != null &&

                /* CAMPOS DEL RAMO */
                this.plan != null &&
                this.sumaAsegurada != null
            )
        }
    }
}
</script>

<style>

</style>