<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte Actual Sales Ahorro</h1>
        </v-col>
      </v-row>
      <v-row class="mb-15" style="padding: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker style="width: 100%" v-model="dates" range></v-date-picker>
          <v-btn rounded color="primary" style="width: 100%" dark @click="generateReportAhorro">Generar Reporte</v-btn>
          <v-btn rounded color="primary" style="width: 100%; margin-top: 20px" dark>
            <download-csv :data="reportDownload" name="ahorro.csv">
              {{ downloadText }}
            </download-csv>
          </v-btn>
        </v-col>
        <v-col>
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <v-data-table v-if="!loading && reportType == 'ahorro'" :headers="columns2" :items="report" item-key="name"
            class="elevation-1" :search="search" :custom-filter="filterOnlyCapsText" :loading="loading"
            loading-text="Cargando...">
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

var moment = require("moment-timezone");

export default {
  data() {
    return {
      dates: [],
      downloadText: "Descargar Reporte",
      downloading: false,
      loading: false,
      reportType: "ahorro",
      report: [],
      reportDownload: [],

      search: "",
      columns2: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Teléfono", value: "telefono" },
        { text: "Objetivo Ahorro", value: "objetivoAhorro" },
        { text: "Correo Electronico", value: "correoElectronico" },
        { text: "Fecha", value: "fecha" },
        { text: "Ciudad", value: "ciudad" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },
    generateReportAhorro() {
      let vm = this;
      vm.reportType = 'ahorro';
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null
      let finUTC = null

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[1]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        } else {
          inicioUTC = moment.utc(this.dates[0]).format('YYYY-MM-DD HH:mm:ss')
          finUTC = moment.utc(this.dates[0]).add(24, 'hours').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        }
      }

      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };

      vm.loading = true;
      this.$axios.post('/v1/reporte/leadsas-ahorro', fechas, config).then((response) => {
        vm.report = []
        vm.reportDownload = []
        response.data.map(element => {
          vm.report.push({
            "nombre": element.nombre,
            "telefono": element.telefono,
            "objetivoAhorro": element.objetivoAhorro,
            "correoElectronico": element.correoElectronico,
            "fecha": moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
            "ciudad": element.ciudad,
          })
        })
        response.data.map(element => {
          vm.reportDownload.push({
            "Uuid": element.uuid,
            "Nombre": element.nombre,
            "Apellido Materno": element.apellidoMaterno,
            "Apellido Paterno": element.apellidoPaterno,
            "Correo Electronico": element.correoElectronico,
            "Telefono": element.telefono,
            "Ciudad": element.ciudad,
            "Objetivo Ahorro": element.objetivoAhorro,
            "Medio": element.medio,
            "Edad": element.edad,
            "Fecha": moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss'),
          })
        })
        vm.loading = false;
      });
    },
    downloadReportAsAhorro() {
      let vm = this;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
        responseType: "blob",
      };
      vm.downloading = true;
      let fechas = {
        fechaInicio: this.dates[0],
        fechaFin: this.dates[1],
      };
      vm.downloadText = "Generando reporte...";
      this.$axios.post('/v1/reporte/leadsas-ahorro/download', fechas, config).then((response) => {
        vm.downloading = false;
        vm.downloadText = "Descargar";
        require("downloadjs")(
          response.data,
          "ReporteLeadsAsAhorro.xls",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        //download(response.data, "ReporteLeads.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      });
    },

    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
