<template>
  <FormUser :form.sync="usuario" class="mb-12">
    <template #title>
      <h1 style="padding-left: 15px">Usuarios</h1>
    </template>

    <template #action>
      <v-row>
        <v-col cols="12" style="text-align: left">
          <v-btn color="primary" @click="setNewUser()" class="mr-4" :disabled="validador">
            Crear
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </FormUser>
</template>

<script>
import moment from "moment";
import FormUser from "./components/FormUsuarios.vue";

export default {
  components: {
    FormUser
  },
  data() {
    return {
      usuario: {
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        password: null,
        confirmPassword: null,
        sexo: null,
        fechaNacimiento: null,
        rol: null,
        nacimientoEstadoId: null,
        celular: null,
        telefono: null,
        correo: null,
        status: 0,
        motivoBaja: null,
        ramo: null,
        canal: null,
        landing: null,
        vip: false,
        creadoPor: null,
        fechaCreacion: null,
        modificadoPor: null,
        fechaModificacion: null,
      },
      usuarioAgente: {
        fuenteIngreso: null,
        regimen: null,
        asociadoPromotoria: null,
        tipoAgente: null,
        razonSocial: null,
        clabeBancaria: null,
        claveAgente: null,
        tipoCedula: null,
        nivelAgente: null,
        numeroCartera: null,
        cedula: null,
        rc: null,
        vigenciaCedula: null,
        vigenciaPolizaRC: null
      },
    };
  },
  mounted() { },
  computed: {
    validador() {
      let valdadorSum = 0
      this.usuario.password ? this.usuario.password.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.nombre ? this.usuario.nombre.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.apellidoPaterno ? this.usuario.apellidoPaterno.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.telefono ? this.usuario.telefono.length > 9 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.correo ? this.usuario.correo.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.rfc ? this.usuario.rfc.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.fechaNacimiento ? this.usuario.fechaNacimiento.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.fechaRegistro ? this.usuario.fechaRegistro.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.ramo ? this.usuario.ramo.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      this.usuario.canal ? this.usuario.canal.length > 0 ? valdadorSum = valdadorSum + 1 : '' : ''
      if (this.usuario.nacimientoEstadoId)  valdadorSum = valdadorSum + 1
      if (valdadorSum == 11) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    setNewUser() {
      console.log(this.usuarioAgente);


      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let dataUser = {
        nombre: this.usuario.nombre,
        apellidoPaterno: this.usuario.apellidoPaterno,
        apellidoMaterno: this.usuario.apellidoMaterno,
        sexo: this.usuario.sexo,
        fechaNacimiento: moment(this.usuario.fechaNacimiento,"DD/MM/YYYY").format("YYYY-MM-DD"),
        fechaRegistro: moment.utc(this.usuario.fechaRegistro, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
        nacimientoEstadoId: this.usuario.nacimientoEstadoId,
        telefono: this.usuario.telefono,
        celular: this.usuario.celular,
        correo: this.usuario.correo,
        rfc: this.usuario.rfc,
        password: this.usuario.password,
        rol: this.usuario.rol,
        landings: this.usuario.landing ? this.usuario.landing : null,
        status: 1,
        motivoBaja: this.usuario.motivoBaja ? this.usuario.motivoBaja : null,
        fechaBaja: this.usuario.fechaBaja
          ? moment
            .utc(this.usuario.fechaBaja)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm")
          : null,
        ramos: this.usuario.ramo,
        canales: this.usuario.canal,
        agenteAsociadoPromotoria: this.usuarioAgente.asociadoPromotoria
          ? this.usuarioAgente.asociadoPromotoria
          : null,

        creadoPor: this.usuario.creadoPor,
        modificadoPor: this.usuario.modificadoPor,

        // fechaRegistro: this.usuario.fechaCreacion,
        // fechaModificacion: this.usuario.fechaModificacion
      };

      this.$axios.post(`/v1/agente/insert`, dataUser, config).then((value) => {
        if (value.data.successful) {
          this.$toast.success("Agente Creado");
          this.$router.push("/usuario/verAgentes");
        }
        else this.$toast.error(value.data.message);
      });
    },
  },
};
</script>

<style>
.mayusculas {
  text-transform: uppercase;
}
</style>
