import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";



export const getIspiriumBalance = async () => {
  try {
    const serverResponse = await mainAxios.get(`/v1/solicitud/inspirum/balance`, getDefaultHeaders());
    return serverResponse.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};