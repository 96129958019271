<template>
    <div>
        <v-card style="margin-bottom: 40px" class="pt-1 mt-4">
            <v-toolbar flat dense>
                <v-toolbar-title class="text-subtitle-1">BENEFICIARIOS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="porcentaje != 100" small color="primary" @click="openBeneficiario"  :disabled="beneficiarios.length == 5">
                    Crear
                </v-btn>
            </v-toolbar>

            <v-divider></v-divider>

            <v-data-table :headers="columns" :items="beneficiarios" item-key="name" class="elevation-1"
                :loading="loading" loading-text="Cargando..." dense>
                <template v-slot:item.accion="{ item }">
                    <v-icon small class="mr-2" @click="openBeneficiario(item)">
                        mdi-eye
                    </v-icon>
                </template>

                <template v-slot:item.nombre="{ item }">
                    {{item.nombre}} {{item.apellidoPaterno}} {{item.apellidoMaterno}}
                </template>

                <template v-slot:item.porcentaje="{ item }">
                    {{item.porcentaje}}%
                </template>
            </v-data-table>
        </v-card>

        <DialogBeneficiarios
        v-if="dialog"
        :open.sync="dialog"
        :uuid="uuid"
        :porcentajeTotal="porcentaje"
        :rfcPoliza="rfcPoliza"
        :emision="emisionId" 
        :emisionStatus="emisionStatus"/>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
import DialogBeneficiarios from "./BeneficiariosDialog.vue";
export default {
    name: 'Beneficiarios',
    components: {
        DialogBeneficiarios
    },
    props: {
        emisionId: {
            type: Number,
            required: true
        },
        rfcPoliza: {
            type: String,
            default: "",
        },
        emisionStatus: {
            type: String,
            default: "",
        }
        
    }, data() {
        return {
            loading: false,
            dialog: false,
            uuid: null,
            columns: [
                { text: "Nombre", sortable: false, value: "nombre" },
                { text: "RFC", sortable: false, value: "rfc" },
                { text: "Fecha nacimiento", sortable: false, value: "fechaNacimiento" },
                { text: "Porcentaje", sortable: false, value: "porcentaje", align:'center' },
                { text: "Creado por", sortable: false, value: "creadoPor" },
                { text: "Fecha creación", sortable: false, value: "fechaCreacion" },
                { text: "Ver", sortable: false, value: "accion" }
            ]
        }
    }, computed: {
        ...mapState('polizas', ['porcentaje', 'beneficiarios'])
    }, mounted() {
        this.getBeneficiarios(this.emisionId);
    }, methods: {
        ...mapActions("polizas", ['getBeneficiarios']),
        openBeneficiario(row = null) {
            if (row != null) {
                this.uuid = row.uuid;  
            }
            this.dialog = true;
        }
    }
}
</script>