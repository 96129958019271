<template>
  <div>
    <v-card color="grey lighten-4" flat tile style="margin-top: 20px">
      <v-toolbar>
        <v-toolbar-title>
          Cliente #{{ numCliente }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn :disabled="roles == 'AGENT'" icon @click="verClientesEditable(clientes[0].uuid)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn @click="goBack()">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
        </v-btn>
      </v-toolbar>
      <p style="margin-top: 15px; padding-bottom: 10px">
        {{ this.nombres + ' ' + this.apellidoPaterno + ' ' + (this.apellidoMaterno ? this.apellidoMaterno : '') }}
      </p>
    </v-card>
    <v-divider></v-divider>

    <v-row>
      <v-col cols="7">
        <v-form>
          <v-row>
            <v-col>
              <v-stepper non-linear>
                <v-stepper-header>
                  <v-stepper-step step="1" editable>
                    Datos del Cliente
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- 1ST ITEM (FORM) -->
                  <v-stepper-content step="1">
                    <v-row style="padding: 20px">
                      <v-col cols="4">
                        <v-text-field label="Estatus del cliente" v-model="status" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Número de cliente" v-model="numCliente" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Regimen" v-model="regimen" readonly></v-text-field>
                      </v-col>
                      <!-- End 2nd Col -->
                      <v-col cols="4">
                        <v-text-field label="Nombre(s)" v-model="nombres" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Apellido Paterno" v-model="apellidoPaterno" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Apellido Materno" v-model="apellidoMaterno" readonly></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field label="Genero" v-model="genero" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Fecha de Nacimiento" v-model="fechaNacimiento" prepend-icon="mdi-calendar"
                          readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Estado Civil" v-model="estadoCivilSelected" readonly></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="regimen != 'Física'">
                        <v-text-field label="Nombre de la Empresa" v-model="nombreEmpresa" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimen != 'Física'">
                        <v-text-field label="Razon social" v-model="razonSocial" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="regimen != 'Física'">
                        <v-text-field label="Fecha de Constitucion" v-model="fechaConstitucion"
                          prepend-icon="mdi-calendar" readonly></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field label="RFC" v-model="rfc" readonly></v-text-field>
                      </v-col>
                      <!-- <v-col cols="4">
                            <v-text-field
                              label="Estados de la República"
                              v-model="estadoSelected"
                              readonly
                            ></v-text-field>
                          </v-col> -->
                      <v-col cols="4">
                        <v-text-field label="Correo Electrónico" v-model="correoElectronico" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Teléfono" v-model="celular" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Telefono de oficina" v-model="telefonoOficina" readonly></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="Agente" v-model="agente" readonly></v-text-field>
                      </v-col>

                      <!-- <v-col cols="6">
                            <v-textarea
                              label="Direccion de Facturacion"
                              v-model="direccionFacturacion"
                              rows="auto"
                              readonly
                            ></v-textarea>
                          </v-col>
                          <v-col cols="6">
                            <v-textarea
                              label="Direccion de Recidencial"
                              v-model="direccionRecidencial"
                              rows="auto"
                              readonly
                            ></v-textarea>
                          </v-col> -->
                      <v-col cols="6">
                        <v-text-field label="Creado por" v-model="creadoPor" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Fecha de creacion" v-model="fechaCreacion" prepend-icon="mdi-calendar"
                          readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Modificado por" v-model="modificadoPor" readonly></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Ultima modificacion" v-model="ultimaModificacion"
                          prepend-icon="mdi-calendar" readonly></v-text-field>
                      </v-col>

                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-col cols="5">
        <v-card style="margin-bottom: 40px" class="pt-1">
          <v-row>
            <v-col cols="10">
              <v-subheader>NOTAS Y DOCUMENTOS</v-subheader>
            </v-col>
            <v-col style="aling: right" class="mt-2" cols="2">
              <v-btn small color="primary" @click="documentsDialog = true">
                Crear
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-data-table :headers="columnsDocumentos" :items="documentos" item-key="name" class="elevation-1"
            loading-text="Cargando..." dense>
            <template v-slot:top>
              <!-- <v-text-field
                                    v-model="search"
                                    label="Buscar"
                                    class="mx-4"
                                    ></v-text-field> -->
            </template>
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verDocumento(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-card style="margin-top: 15px">
          <v-row>
            <v-col cols="10">
              <v-subheader>EMAILS</v-subheader>
            </v-col>
            <!-- <v-col cols="4">
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col> -->
            <v-col cols="2">
              <v-btn @click="newCorreoDialog = true" small color="primary">
                Crear
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-data-table :headers="columns" :items="correos" item-key="name" class="elevation-1"
            loading-text="Cargando..." dense>
            <template v-slot:top>
              <v-text-field label="Buscar" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.asunto }}</td>
                <td>{{ row.item.fecha }}</td>
                <td>{{ row.item.remitente }}</td>
                <td style="cursor:pointer;" @click="verDetalleCorreo(row.item)">
                  <v-icon light small class="mr-2">mdi-eye</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- ADD DOCUMENTS -->
    <v-dialog v-model="documentsDialog" max-width="750px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Creación de Documento / Nota</span>
        </v-card-title>

        <div style="padding: 30px">
          <DocumentosNotasModal :accionGuardar="accionGuardar" :cliente="numCliente" />
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="documentsDialog = false">
            Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text @click="accionGuardar = true, documentsDialog = false">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MODAL VER DOCUMENTOS -->
    <v-dialog v-model="dialogDetalleDocumentos" max-width="750px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle de Documento / Nota</span>
        </v-card-title>

        <template>
          <v-container style="padding: 30px">
            <v-row>
              <v-col cols="6">
                <v-text-field label="Nombre" v-model="detallesDocumento.nombre" readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox label="¿Enviar por correo electrónico?" readonly></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-select label="Tipo" :items="tipoItems" v-model="detallesDocumento.tipo" required readonly></v-select>
              </v-col>
              <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                <v-select label="Tipo de Doocumento*" :items="tipoDocumentoItems"
                  v-model="detallesDocumento.tipoDocumento" required readonly></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Descripción" v-model="detallesDocumento.descripcion" required readonly>
                </v-text-field>
              </v-col>
              <v-col cols="4" v-if="detallesDocumento.url">
                <template>
                  <v-file-input v-model="documentos" color="primary" label="Adjuntos*">
                    <template v-slot:selection="{ index }">
                      <v-chip v-if="index < 1" color="primary" dark label small @click="detallesDocumento.url">
                        {{ detallesDocumento.nombre }}
                      </v-chip>

                      <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                        +{{ documentos.length - 2 }} Archivos(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
              <v-col cols="2" v-if="detallesDocumento.url">
                <v-btn text :href="detallesDocumento.url" small color="primary" class="mt-4">
                  <v-icon>mdi-download</v-icon>
                  Descargar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Creado por" v-model="detallesDocumento.creadoPor" readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Fecha de Creación" v-mask="'##/##/####'" v-model="detallesDocumento.fecha"
                  readonly></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card>
    </v-dialog>

    <!-- SEND EMAIL -->
    <v-dialog v-model="newCorreoDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Crear Correo</span>
        </v-card-title>
        <div style="padding:40px;">
          <EmailForm :cliente="numCliente" />
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="newCorreoDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- MODAL DE CORREO -->
    <v-dialog v-model="dialogCorreo" width="600px">
      <v-card style="padding: 30px">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Para" v-model="correoOpen.remitente" hide-details="auto" readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="CC" v-model="correoOpen.cc" hide-details="auto" readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="BCC" v-model="correoOpen.bcc" hide-details="auto" readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Asunto" v-model="correoOpen.asunto" hide-details="auto" readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="correoOpen.cuerpo" label="Mensaje" readonly></v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="correoOpen.documentos">
          <v-col>
            <p>Descargar Adjuntos</p>
          </v-col>
        </v-row>
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <v-chip v-for="(documento, index) in correoOpen.documentos" v-bind:key="index" color="primary" outlined
              :href="documento.url" style="cursor: pointer">
              <v-icon left>
                mdi-tray-arrow-down
              </v-icon>
              {{ documento.nombre }}
            </v-chip>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogCorreo = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import DocumentosNotasModal from '../../components/DocumentosNotasModal.vue';
import EmailForm from '../../components/EmailForm.vue';
export default {
  components: {
    DocumentosNotasModal,
    EmailForm,
  },
  data() {
    return {
      documentsDialog: false,
      newCorreoDialog: false,
      dialogDetalleDocumentos: false,
      dialogCorreo: false,
      detallesDocumento: {},
      correoOpen: {},
      accionGuardar: false,
      roles: JSON.parse(localStorage.getItem("roles"))[0].rol,
      clientes: [],
      correos: [],
      documentos: [],
      agentes: [],
      columns: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      estados: [
        { id: 1, estadoRepublica: 'Aguascalientes' },
        { id: 2, estadoRepublica: 'Baja California' },
        { id: 3, estadoRepublica: 'Baja California Sur' },
        { id: 4, estadoRepublica: 'Campeche' },
        { id: 5, estadoRepublica: 'Coahuila de Zaragoza' },
        { id: 6, estadoRepublica: 'Colima' },
        { id: 7, estadoRepublica: 'Chiapas' },
        { id: 8, estadoRepublica: 'Chihuahua' },
        { id: 9, estadoRepublica: 'Ciudad de México' },
        { id: 10, estadoRepublica: 'Durango' },
        { id: 11, estadoRepublica: 'Guanajuato' },
        { id: 12, estadoRepublica: 'Guerrero' },
        { id: 13, estadoRepublica: 'Hidalgo' },
        { id: 14, estadoRepublica: 'Jalisco' },
        { id: 15, estadoRepublica: 'Estado de México' },
        { id: 16, estadoRepublica: 'Michoacán de Ocampo' },
        { id: 17, estadoRepublica: 'Morelos' },
        { id: 18, estadoRepublica: 'Nayarit' },
        { id: 19, estadoRepublica: 'Nuevo León' },
        { id: 20, estadoRepublica: 'Oaxaca' },
        { id: 21, estadoRepublica: 'Puebla' },
        { id: 22, estadoRepublica: 'Querétaro' },
        { id: 23, estadoRepublica: 'Quintana Roo' },
        { id: 24, estadoRepublica: 'San Luis Potosí' },
        { id: 25, estadoRepublica: 'Sinaloa' },
        { id: 26, estadoRepublica: 'Sonora' },
        { id: 27, estadoRepublica: 'Tabasco' },
        { id: 28, estadoRepublica: 'Tamaulipas' },
        { id: 29, estadoRepublica: 'Tlaxcala' },
        { id: 30, estadoRepublica: 'Veracruz de Ignacio de la Llave' },
        { id: 31, estadoRepublica: 'Yucatán' },
        { id: 32, estadoRepublica: 'Zacatecas' },
      ],
      tipoItems: [
        "Nota",
        "Documento"
      ],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Contrato firmado del Agente",
        "Cotización",
        "Cédula del Agente",
        "Identificación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      bodyClientes: null,
      uuid: this.$route.params.uuid,
      numeroClientes: null,
      estadoClientes: "",
      tipo: "",
      procesoCotizacion: "",
      isAgent: false,

      numCliente: null,

      origenClientesSelected: '',
      estadoSelected: '',
      tipoSelected: '',
      categoriaSelected: '',
      agente: '',
      productoSelected: '',
      productoId: '',
      ejecutivo: '',
      seguimientoClientes: '',
      polizaRelacionada: '',
      oportunidades: '',
      nombres: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      fechaNacimiento: '',
      selectedGenero: '',
      celular: '',
      correoElectronico: '',
      edad: '',
      direccion: '',
      coberturasSelected: '',
      formaPagoSelected: '',
      estadoRepublicaSelected: '',
      estadoRepublicaId: '',
      estadoCivilSelected: '',
      codigoPostal: '',
      clientesCompletaSelected: '',
      rfc: '',
      scrollInvoked: 0,
      direccionFacturacion: '',
      direccionRecidencial: '',

      status: '',
      regimen: '',
      genero: '',
      nombreEmpresa: '',
      razonSocial: '',
      fechaConstitucion: '',
      telefonoOficina: '',
      creadoPor: '',
      fechaCreacion: '',
      modificadoPor: '',
      ultimaModificacion: '',
    };
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
    onScroll() {
      this.scrollInvoked++
    },
    verDetalleCorreo(correo) {
      console.log("Correo open:", correo);
      this.dialogCorreo = true;
      this.correoOpen = correo;
    },
    verDocumento(documento) {
      this.dialogDetalleDocumentos = true
      this.detallesDocumento = documento
    },
    obtnerDatosClientes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios
        .get(`v1/cliente/uuid/${this.uuid}`, config)
        .then((response) => {
          this.clientes.push(response.data);
          this.bodyClientes = response.data.body ? JSON.parse(response.data.body) : '';
          response.data.correos ? response.data.correos.map(element => {
            this.correos.push({
              asunto: element.subject,
              fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : null,
              cuerpo: element.cuerpo,
              remitente: element.remitente,
              documentos: element.documentos
            })
          }) : ''
          response.data.documentosCarga ? response.data.documentosCarga.map(element => {
            this.documentos.push({
              nombre: element.nombre ? element.nombre : 'No Disponible',
              fecha: element.fecha ? moment.utc(element.fecha).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : 'No Disponible',
              tipo: element.tipo ? element.tipo : 'No Disponible',
              tipoDocumento: element.tipoDocumento ? element.tipoDocumento : 'No Disponible',
              descripcion: element.nota ? element.nota : 'No Disponible',
              creadoPor: element.creadoPor ? element.creadoPor : 'No Disponible',
              url: element.url
            })
          }) : ''
          this.numCliente = response.data.id,
            this.nombres = response.data.nombre ? response.data.nombre : ''
          this.apellidoPaterno = response.data.apellidoPaterno ? response.data.apellidoPaterno : ''
          this.apellidoMaterno = response.data.apellidoMaterno ? response.data.apellidoMaterno : ''
          this.fechaNacimiento = response.data.fechaNacimiento ? moment(response.data.fechaNacimiento).format('DD/MM/YYYY') : ''
          this.status = response.data.status ? parseInt(response.data.status) == -1 ? 'Baja' : parseInt(response.data.status) == 1 ? 'Activo' : parseInt(response.data.status) == 2 ? 'Inactivo' : 'Nuevo' : 'Nuevo'
          this.celular = response.data.telefonoMovil ? response.data.telefonoMovil : ''
          this.telefonoOficina = response.data.telefonoOficina ? response.data.telefonoOficina : ''
          this.correoElectronico = response.data.correo ? response.data.correo : ''
          this.estadoCivilSelected = response.data.estadoCivil ? response.data.estadoCivil : ''
          this.rfc = response.data.rfc ? response.data.rfc : ''
          this.genero = response.data.genero ? response.data.genero == 'M' ? 'Masculino' : 'Femenino' : ''
          this.nombreEmpresa = response.data.nombreEmpresa ? response.data.nombreEmpresa : ''
          this.razonSocial = response.data.razonSocial ? response.data.razonSocial : ''
          this.regimen = response.data.regimen ? response.data.regimen : ''
          this.fechaConstitucion = response.data.fechaConstitucion ? moment(response.data.fechaConstitucion).format('DD/MM/YYYY') : ''
          this.creadoPor = response.data.creadoPor ? response.data.creadoPor : 'No Disponible'
          this.fechaCreacion = response.data.fechaCreacion ? moment.utc(response.data.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible'
          this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : 'No Disponible'
          this.ultimaModificacion = response.data.ultimaModificacion ? moment.utc(response.data.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : 'No Disponible'
          this.agenteId = response.data.agenteId ? response.data.agenteId : ''

        });
      this.obtenerProductos();
      this.obtenerAgentes();
      this.obtenerEstadoRepublica();
    },
    formatDate(date) {
      let formattedDate = new Date(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
      return formattedDate;
    },
    verClientesEditable(uuid) {
      this.$router.push(`/actualizar-clientes/${uuid}`);
    },
    obtenerEstadoRepublica() {
      this.estados.map(element => {
        // console.log(element);
        this.estadoRepublicaId == element.id ? this.estadoRepublicaSelected = element.estadoRepublica : ''
      })
      // for (let i = 0; i < this.estados.length; i++) {
      //   if (typeof (this.estados[i]) === 'object') {
      //     try {
      //       this.estados[i] = JSON.parse(JSON.stringify(this.estados[i]));
      //       this.estadoRepublicaId == this.estados[i] ? this.estadoRepublicaSelected = this.estados[i].estadoRepublica : ''
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   }
      //   console.log(this.estados);
      // }
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/agente/list', config).then(response => {
        response.data.map(element => {
          this.agentes.push({
            nombre: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
            id: element.id,
            uuid: element.uuid
          })
        })

        this.agentes.map(element => {
          this.agenteId == element.id ? this.agente = element.nombre : ''
        })
      });
    },

    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get('/v1/producto/list', config).then(response => {
        // console.log('Productos: ', response.data);
        response.data.map(element => {
          // this.productos.push({
          //   nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
          //   id: element.id
          // })

          this.productoId == element.id ? this.productoSelected = `${element.ramo.toUpperCase()}` + ' / ' + `${element.nombre.toUpperCase()}` + ' / ' + `${element.nombreAseguradora.toUpperCase()}` : ''
        })
      });
    },
    infoCompleta() {
      if (this.clientesCompletaSelected == 1) {
        return 'Si'
      } else if (this.clientesCompletaSelected == 0) {
        return 'No'
      }
    },
  },
  mounted() {
    this.estados, this.obtnerDatosClientes(), this.infoCompleta(), this.obtenerAgentes()
  },
};
</script>

<style scoped>
.v-stepper__step__step {
  background-color: white;
}
</style>